import React from "react";
import { ChakraProvider, useTheme } from "@chakra-ui/react";
import { ThemeProvider as JssThemeProvider } from "react-jss";
import { theme } from "../theme/theme";

const IntegratedThemeProvider = ({ children }) => {
  const chakraTheme = useTheme();

  return <JssThemeProvider theme={chakraTheme}>{children}</JssThemeProvider>;
};

export const AppThemeProvider = ({ children }) => (
  <ChakraProvider theme={theme}>
    <IntegratedThemeProvider>{children}</IntegratedThemeProvider>
  </ChakraProvider>
);
