import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { AxiosError } from "axios";
import { Tenant } from "../../types";
import useJwt from "../../hooks/useJwt";

const url = "tenants";

const useTenantsApi = () => {
  const { privateAxiosV1 } = useJwt();

  const getAllTenants = async (): Promise<Tenant[]> => {
    return privateAxiosV1
      .get(`/${url}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
      });
  };

  const getTenant = async (id: string): Promise<Tenant> => {
    return privateAxiosV1
      .get(`/${url}`, {
        params: { id: id },
      })
      .then((resp) => {
        return resp.data && resp.data.length > 0 && resp.data[0];
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
      });
  };

  const deactivateTenant = async (id: string): Promise<Tenant> => {
    return privateAxiosV1
      .delete(`/${url}?id=${id}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
      });
  };

  const updateTenant = async (tenant: Tenant): Promise<Tenant> => {
    return privateAxiosV1
      .patch(`/${url}`, tenant)
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
      });
  };

  const createTenant = async (
    name: string,
    domain: string,
    access: boolean
  ): Promise<Tenant> => {
    const tenant: Partial<Tenant> = {
      name: name,
      domain: domain,
      publisher_access_to_tenant: access,
    };
    return privateAxiosV1
      .post(`/${url}`, tenant)
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
      });
  };

  return {
    getTenant,
    getAllTenants,
    deactivateTenant,
    updateTenant,
    createTenant,
  };
};

export default useTenantsApi;
