import React, { useState } from "react";
import { Box, Button, Input } from "@chakra-ui/react";

interface MfaChallengeProps {
  isVerified: boolean;
  qrCode?: string;
  verifyCode: (code: string) => void;
}

const MfaChallenge: React.FC<MfaChallengeProps> = (props) => {
  const { isVerified, qrCode, verifyCode } = props;
  const [enteredCode, setEnteredCode] = useState<string>("");

  const inputListener = (e: any) => {
    setEnteredCode(e.target.value);
  };

  const handleVerifyClick = () => {
    verifyCode(enteredCode);
  };

  return (
    <Box
      borderWidth={"1px"}
      borderRadius={"lg"}
      style={{
        border: "1px solid",
        borderColor: "#DDDDDD",
        borderRadius: "10px",
        padding: "14px",
      }}
    >
      {!isVerified ? (
        <>
          {qrCode ? (
            <>
              <Box> 1. Scan following QR code with your Authenticator app:</Box>
              <img alt={qrCode} src={qrCode} />
              <Box>2.</Box>
            </>
          ) : null}
          <Box>Enter the code from your Authenticator app:</Box>
          <Input
            style={{ border: "1px solid black" }}
            value={enteredCode}
            onChange={(event) => inputListener(event)}
          />
          {enteredCode.length > 5 ? (
            <Button onClick={handleVerifyClick} style={{ height: "30px" }}>
              Verify
            </Button>
          ) : null}
        </>
      ) : (
        <Box>MFA Verified!</Box>
      )}
    </Box>
  );
};
export default MfaChallenge;
