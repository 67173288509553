import React from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useTheme,
} from "@chakra-ui/react";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
  },
  breadCrumbs: {
    fontSize: 15,
    fontFamily: theme.typography.fontFamilies.body,
    fontWeight: "normal",
  },
  createButton: {
    fontSize: 14,
    height: 32,
    color: "white",
    fontFamily: theme.typography.fontFamilies.body,
    backgroundColor: theme.colors.purple.base,
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: theme.colors.purple.lightBase,
    },
    "&:focus": {
      backgroundColor: theme.colors.purple.dark,
    },
  },
  paper: {
    backgroundColor: "white",
    paddingLeft: 0,
    paddingRight: 0,
  },
  heading: {
    backgroundColor: "white",
    fontFamily: theme.typography.fontFamilies.body,
    paddingLeft: theme.spacing.base * 4,
    marginBottom: theme.spacing.base * 4,
    fontSize: 20,
  },
  content: {
    overflowX: "auto",
  },
}));
export type BreadcrumbBlockLink = {
  title: string;
  link?: string | undefined;
  isCurrent: boolean;
};
export interface BreadcrumbsBlockProps {
  links: BreadcrumbBlockLink[];
}
const BreadcrumbsBlock: React.FC<BreadcrumbsBlockProps> = ({ links }) => {
  const classes = useStyles();
  const theme = useTheme<DefaultTheme>();

  return (
    <div>
      <Breadcrumb className={classes.breadCrumbs}>
        {links.map((link, index) => (
          <BreadcrumbItem
            key={index}
            color={
              link.isCurrent ? theme.colors.grey.dark : theme.colors.purple.base
            }
            isCurrentPage={link.isCurrent}
          >
            <BreadcrumbLink href={link.link}>{link.title}</BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </div>
  );
};
export default BreadcrumbsBlock;
