import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

export const inputTheme = defineMultiStyleConfig({
  variants: {
    outline: definePartsStyle({
      field: {
        background: "white",
      },
    }),
  },
  defaultProps: {
    variant: "outline",
  },
});
