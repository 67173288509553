import { AxiosError } from "axios";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { Publisher, PublisherAndUser } from "../../types";
import useJwt from "../../hooks/useJwt";

const ADMIN_TOKEN = process.env.ADMIN_TOKEN;

const url = "publisher";
const usePublishersApi = () => {
  const { privateAxiosV1, privateAxiosV2 } = useJwt();

  const getPublisher = async (
    publisher_id: string,
    access_token?: string | undefined
  ): Promise<Publisher> => {
    return privateAxiosV1
      .get(`/${url}`, {
        params: { publisher_id: publisher_id, token: access_token },
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
      });
  };

  const getPublishers = async (
    tenant_id: number | string | null,
    token: string | null
  ): Promise<Publisher[]> => {
    const params = {
      limit: 500,
    };
    if (tenant_id != null) {
      params["tenant_id"] = tenant_id;
    }
    if (token != null) {
      params["token"] = token;
    }

    return privateAxiosV1
      .get(`/${url}s`, {
        params: { tenant_id: tenant_id, limit: 500 },
      })
      .then((resp) => {
        return resp.data && resp.data.publishers;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
      });
  };

  const createPublisherAndPrimaryUser = async ({
    publisher_name,
    publisher_url,
    first_name,
    last_name,
    email,
  }): Promise<PublisherAndUser> => {
    return privateAxiosV2
      .post(`/publishers/users?token=${ADMIN_TOKEN}`, {
        name: publisher_name,
        source_url: publisher_url,
        first_name,
        last_name,
        email,
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const updatePublisher = async (publisher: Partial<Publisher>) => {
    const formData = new FormData();
    Object.keys(publisher).forEach((key) => {
      formData.append(key, publisher[key]);
    });
    return privateAxiosV2
      .patch("/publishers", formData)
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
      });
  };

  const createPublisher = async (
    name: string,
    source_url: string,
    picker_preference: string,
    tenant_id?: string
  ): Promise<PublisherAndUser> => {
    return privateAxiosV1
      .post(`/publishers?token=${ADMIN_TOKEN}`, {
        name: name,
        source_url: source_url,
        picker_preference: picker_preference,
        tenant_id: tenant_id,
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };
  const deactivatePublisher = async (
    publisher_id: number
  ): Promise<Publisher[]> => {
    return privateAxiosV1
      .delete(`/publishers/${publisher_id}`, { params: { token: ADMIN_TOKEN } })
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
      });
  };

  return {
    getPublisher,
    getPublishers,
    createPublisherAndPrimaryUser,
    updatePublisher,
    createPublisher,
    deactivatePublisher,
  };
};

export default usePublishersApi;
