import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Spacer,
  useTheme,
} from "@chakra-ui/react";
import usePublishersApi from "../../services/api/Publishers";
import GlobalContext from "../../components/GlobalContext";
import { Publisher } from "../../types";
import useJwt from "../../hooks/useJwt";
import { faPlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import CreatePubModal from "../../components/common/Publisher/CreatePubModal";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import ListTable from "../../components/common/Layouts/ListTable";
import { FontAwesome } from "../../components/common/icon";

const PublishersIndex = (): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [publishers, setPublishers] = useState<Publisher[]>([]);
  const [filteredPubs, setFilteredPubs] = useState<Publisher[]>([]);
  const publishersApi = usePublishersApi();
  const { currentUser, publisher } = useContext(GlobalContext);
  const { decodeJwt } = useJwt();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const fetchPublishers = async () => {
    const tenantId =
      decodeJwt.user.role !== "tenantadmin" ? null : currentUser.tenant_id;
    if (currentUser) {
      const publishers = await publishersApi.getPublishers(tenantId, null);
      setPublishers(publishers);
      setFilteredPubs(publishers);
    }
  };
  useEffect(() => {
    if (
      decodeJwt.user.role == "publisheradmin" ||
      decodeJwt.user.role == "manager"
    ) {
      navigate(`/publishers/${currentUser?.publisher_id}/edit`, {
        state: { publisher },
      });
    }
    fetchPublishers().then(() => setLoading(false));
  }, [currentUser]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const searchTerm = event.target.value;
    if (searchTerm) {
      setFilteredPubs(
        publishers.filter((pub) => {
          return pub.name.toLowerCase().includes(searchTerm.toLowerCase());
        })
      );
    } else {
      setFilteredPubs(publishers);
    }
  };

  const viewHandler = (item: any) => {
    navigate("/publishers/" + item.id + "/edit", { state: { item } });
  };

  return (
    <Box>
      <Flex paddingBottom={theme.spacing.base * 2}>
        <BreadcrumbsBlock
          links={[
            { title: "Settings", isCurrent: false },
            { title: "Publishers", isCurrent: true },
          ]}
        />

        {decodeJwt.user.role == "tenantadmin" ||
        decodeJwt.user.role == "fanpoweradmin" ? (
          <>
            <Spacer />
            <Button
              leftIcon={<FontAwesome icon={faPlus} />}
              onClick={() => setIsOpen(!isOpen)}
            >
              Create New
            </Button>
          </>
        ) : null}
      </Flex>
      <Box mt={4}>
        <InputGroup>
          <InputLeftAddon>Find Publisher</InputLeftAddon>
          <Input
            onChange={handleChange}
            type="text"
            placeholder="Enter a Publisher name..."
          />
        </InputGroup>
      </Box>
      <Divider orientation="horizontal" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <ListTable
            heading={"Publishers"}
            headers={[
              { title: "Action" },
              { title: "Publisher" },
              { title: "Tenant" },
              { title: "Last Updated" },
            ]}
            list={filteredPubs}
            columns={[
              { name: "name", type: "string" },
              { name: "tenant_id", type: "string" },
              { name: "updated_at", type: "date" },
            ]}
            actions={[
              {
                name: "Edit",
                actionHandler: viewHandler,
                icon: <FontAwesome icon={faPenToSquare} />,
              },
            ]}
          />
          <CreatePubModal
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(!isOpen);
              fetchPublishers();
            }}
          />
        </>
      )}
    </Box>
  );
};

export default PublishersIndex;
