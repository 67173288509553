import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import { Link } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from "./fanpowerlogo.png";
import { appStyles } from "../../../constants/styles";
import UserDisplay from "./UserDisplay";
import GlobalContext from "../../GlobalContext";
import { SetShowSidebar, ShowSidebar } from "../../../types";
import { IconButton } from "@chakra-ui/react";
import { FontAwesome } from "../icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    display: "flex",
    position: "fixed",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: appStyles.headerHeight,
    top: 0,
    left: 0,
    backgroundColor: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.grey.lightBase}`,
    padding: `0 ${theme.spacing.base * 2}px`,
    zIndex: 1,
    [theme.mediaQuery(theme.breakpoints.medium)]: {
      padding: `0 ${theme.spacing.base * 10}px`,
    },
  },
  container: {
    display: "flex",
    position: "relative",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: appStyles.headerHeight,
    [theme.mediaQuery(theme.breakpoints.medium)]: {
      justifyContent: "space-between",
    },
  },
  logo: {
    display: "none",
    position: "relative",
    width: 166,
    height: 42,
    [theme.mediaQuery(theme.breakpoints.medium)]: {
      display: "block",
    },
    "& img": {
      display: "block",
      position: "relative",
      width: "100%",
      height: "auto",
    },
  },
  menuTrigger: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing.base,
    [theme.mediaQuery(theme.breakpoints.medium)]: {
      display: "none",
    },
  },
}));

const AppHeader: React.FC = () => {
  const classes = useStyles();
  const { currentUser, setShowSidebar, showSidebar } =
    useContext(GlobalContext);

  const handleMobileClick = (
    showSidebar: ShowSidebar,
    setShowSidebar: SetShowSidebar
  ): void => {
    if (showSidebar) return null;
    setShowSidebar(true);
  };
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.menuTrigger}>
          {currentUser ? (
            <IconButton
              aria-label="menu"
              disabled={showSidebar}
              onClick={() => handleMobileClick(showSidebar, setShowSidebar)}
            >
              <FontAwesome icon={faBars} />
            </IconButton>
          ) : null}
        </div>
        <div className={classes.logo}>
          <Link to="/">
            <img src={logo} alt="FanPower Portal" role="banner" />
          </Link>
        </div>
        <div>{currentUser ? <UserDisplay /> : null}</div>
      </div>
    </div>
  );
};

export default AppHeader;
