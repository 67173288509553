import axios, { AxiosError } from "axios";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";

const getNotionPage = async (pageId: string) => {
  return await axios
    .get("/api/notion/page", { params: { page_id: pageId } })
    .then((response) => {
      return response.data;
    })
    .catch((er: AxiosError) => {
      pickupErrorHandlerWeb(er);
    });
};

export const NotionApiService = {
  getNotionPage,
};
