import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { userRole } from "../../constants/permissions";
import { useCookies } from "react-cookie";
import { Publisher, PUGlobalContext, TokenUser } from "../../types";
import useRefreshToken from "../../hooks/useRefreshToken";
import usePublishersApi from "../../services/api/Publishers";
import useJwt from "../../hooks/useJwt";
import useTagsApi from "../../services/api/Tags";
import { User } from "../../../server/models/user.model";
import jwt_decode from "jwt-decode";

const NODE_API_URL_V1 = process.env.REACT_APP_NODE_API_URL;
const ADMIN_TOKEN = process.env.REACT_APP_ADMIN_TOKEN;

const GlobalContext = createContext<PUGlobalContext>({
  currentUser: null,
  setCurrentUser: () => null,
  doLogout: () => null,
  showSidebar: null,
  setShowSidebar: () => null,
  publisher: null,
  setPublisher: () => null,
  tenant: null,
  publishers: [],
  tags: [],
  setTags: () => null,
  isGlobalDataReady: null,
});

export const GlobalProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [publisher, setPublisher] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [tags, setTags] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["pu_portal"]);
  const [currentUser, setCurrentUser] = useState(
    cookies.pu_portal ? cookies.pu_portal : null
  );
  const refresh = useRefreshToken();
  const publishersApi = usePublishersApi();
  const { jwt, setJwt } = useJwt();
  const tagsApi = useTagsApi();
  const [isGlobalDataReady, setIsGlobalDataReady] = useState(false);

  const mergePermissions = async (
    user: User,
    publisher: Publisher
  ): Promise<any> => {
    const tokenDecode = jwt_decode(jwt);
    const basePermissions = userRole[(tokenDecode as TokenUser).user.role];

    const pubSettings = publisher.settings;
    const publisherBasedPermissions = {
      aiContentCreate:
        pubSettings.ai_content_access &&
        pubSettings.ai_content_access === "true"
          ? basePermissions.aiContentCreate
          : false,
      aiContentView:
        pubSettings.ai_content_access &&
        pubSettings.ai_content_access === "true"
          ? basePermissions.aiContentView
          : false,
    };

    const combined = {
      ...basePermissions,
      ...publisherBasedPermissions,
    };

    return combined;
  };

  useEffect(() => {
    if (currentUser && !cookies?.pu_portal) {
      doLogout();
    }
  }, [cookies?.pu_portal]);

  useEffect(() => {
    if (cookies?.pu_portal?.refresh_token && !jwt) {
      refresh()
        .then((res) => {
          setJwt(res.token);
          setCurrentUser(res.user);
          setIsGlobalDataReady(true);
        })
        .catch((err) => {
          console.error(err);
          doLogout();
        });
    } else {
      setIsGlobalDataReady(true);
    }
  }, []);

  //auth changes on login/app refresh use effect will build general state
  useEffect(() => {
    if (jwt && currentUser) {
      tagsApi.getAll().then((tags) => setTags(tags));
    }
    if (jwt && currentUser && !publisher) {
      publishersApi
        .getPublisher(currentUser.publisher_id, currentUser.access_token)
        .then(async (resp) => {
          // page refresh
          const setUser = {
            ...currentUser,
            permissions: await mergePermissions(currentUser, resp),
          };

          setCurrentUser(setUser);
          setPublisher(resp);
        });
      publishersApi.getPublishers(null, null).then((res) => {
        setPublishers(res);
      });
    }
  }, [jwt, currentUser]);

  const doLogout = (callback?: () => unknown | void): void => {
    axios
      .patch(
        `${NODE_API_URL_V1}/users/${currentUser.id}`,
        {
          refresh_token: null,
        },
        {
          headers: { token: ADMIN_TOKEN },
        }
      )
      .then(async () => {
        setShowSidebar(false);
        await Promise.all([
          removeCookie("pu_portal"),
          setCurrentUser(null),
          setPublisher(null),
          setPublishers([]),
          setTenant(null),
          setIsGlobalDataReady(true),
        ]);
      });

    if (callback) {
      callback();
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        doLogout: doLogout,
        showSidebar,
        setShowSidebar,
        publisher,
        setPublisher,
        tenant,
        publishers,
        tags,
        setTags,
        isGlobalDataReady,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
