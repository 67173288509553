import { FontSizeOption } from "../../types";

export const fontSizeOptions = (sizes: number[]): FontSizeOption[] => {
  return sizes.map((size: number) => ({
    title: `${size}`,
    model: `${size}px`,
    view: {
      name: "span",
      styles: {
        "font-size": "16px",
      },
    },
  }));
};
