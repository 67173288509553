import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Spacer,
  useTheme,
} from "@chakra-ui/react";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import Loader from "../../components/Loader";
import ListTable from "../../components/common/Layouts/ListTable";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import useTenantsApi from "../../services/api/Tenants";
import { useSnackbar } from "notistack";
import { Tenant } from "../../types";
import TenantModal from "./TenantModal";
import { useNavigate, useParams } from "react-router-dom";

const TenantsIndex: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const tenantsApi = useTenantsApi();
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const fetchTenants = async (afterClose?) => {
    await tenantsApi
      .getAllTenants()
      .then((res) => {
        setItems(res);
        setFilteredItems(res);
        setLoading(false);

        // if tenant_id is in the url, open the modal but skip if the modal is already open (afterClose == true)
        if (tenant_id && !afterClose) {
          const tenant = res.find(
            (tenant) => parseInt(String(tenant.id)) === parseInt(tenant_id)
          );
          if (tenant) {
            setCurrentItem(tenant);
            setModalIsOpen(true);
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error fetching data", { variant: "error" });
      });
  };
  useEffect(() => {
    fetchTenants();
  }, []);

  const deleteDialogOnConfirm = async () => {
    setLoading(true);
    await tenantsApi
      .deactivateTenant(currentItem.id)
      .then((res) => {
        enqueueSnackbar(`Tenant "${currentItem.name}" deactivated`, {
          variant: "success",
        });
        fetchTenants();
        setCurrentItem(null);
        setModalIsOpen(false);
      })
      .catch((err) => {
        enqueueSnackbar("Error deactivating tenant", { variant: "error" });
      });
  };

  const viewHandler = (item: any) => {
    setCurrentItem(item);
    setModalIsOpen(true);
    navigate({ pathname: `/settings/tenants/${item.id}` });
  };

  const handleFilter = (search: string, items: Tenant[]) => {
    let filtered = items;
    if (search.length) {
      filtered = filtered.filter(
        (l) =>
          l.name.toLowerCase().includes(search) ||
          l.domain.toLowerCase().includes(search) ||
          l.id.toString().includes(search)
      );
    }
    return filtered;
  };

  useEffect(() => {
    if (search.trim().length) {
      const filtered = handleFilter(search.trim().toLowerCase(), items);
      setFilteredItems(filtered);
    } else {
      setFilteredItems(items);
    }
  }, [search]);

  return (
    <Box w={"full"}>
      <Flex paddingBottom={theme.spacing.base * 2}>
        <BreadcrumbsBlock
          links={[
            { title: "Settings", isCurrent: false },
            { title: "Tenants", isCurrent: true },
          ]}
        />
        <Spacer />
        <Button
          leftIcon={<AddIcon />}
          onClick={() => setModalIsOpen(!modalIsOpen)}
        >
          Create New Tenant
        </Button>
      </Flex>
      <Flex>
        <Box>
          <InputGroup>
            <InputLeftAddon>Find Tenant</InputLeftAddon>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={"Enter search term..."}
            />
          </InputGroup>
        </Box>
      </Flex>
      <Divider orientation={"horizontal"} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <ListTable
            heading={"Tenants"}
            headers={[
              { title: "Actions" },
              { title: "ID" },
              { title: "Tenant" },
              { title: "Website" },
              { title: "Created" },
              { title: "Publishers" },
            ]}
            list={filteredItems}
            columns={[
              { name: "id", type: "string" },
              { name: "name", type: "string" },
              { name: "domain", type: "string" },
              { name: "created_at", type: "string" },
              { name: "publisher_count", type: "string" },
            ]}
            actions={[
              { name: "Edit", actionHandler: viewHandler, icon: <EditIcon /> },
            ]}
          />
          <TenantModal
            isOpen={modalIsOpen}
            onClose={() => {
              const page = window.sessionStorage.getItem("listPage");
              navigate({
                pathname: "/settings/tenants",
                search: `page=${page}`,
              });
              setCurrentItem(null);
              setModalIsOpen(false);
              fetchTenants(true);
            }}
            tenant={currentItem}
            deactivateCallback={deleteDialogOnConfirm}
          />
        </>
      )}
    </Box>
  );
};

export default TenantsIndex;
