import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { Pick } from "../../types";
import { useTheme } from "@chakra-ui/react";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  "@keyframes growRight": {
    from: { width: 0 },
    to: { width: ({ result }) => `${result}%` },
  },
  bar: {
    display: "block",
    height: "calc(100% - 4px)",
    borderTopLeftRadius: 98,
    borderBottomLeftRadius: 98,
    backgroundColor: ({ isClicked }) =>
      isClicked ? theme.colors.primary["lightBase"] : "#91979D",
    opacity: 0.2,
    marginLeft: 2,
    animation: "$growRight 1s cubic-bezier(0.280, 0.520, 0.630, 0.990) 1",
    width: ({ result }) => `${result}%`,
  },
  textContainer: {
    display: "flex",
    position: "absolute",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: theme.spacing.base,
    boxSizing: "border-box",
    textTransform: "uppercase",
    fontFamily: theme.typography.fontFamilies.body,
    width: 300,
  },
  resultContainer: {
    display: "flex",
    position: "relative",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  resultText: {
    color: "#9D9D9E",
    textTransform: "uppercase",
    fontFamily: theme.typography.fontFamilies.body,
    fontSize: 12,
    padding: "0 10px 0 10px",
  },
  buttonRoot: {
    height: 38,
    borderRadius: 98,
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing.base,
    border: `1px solid`,
    borderColor: theme.colors.primary["base"],
    boxSizing: "border-box",
    overflow: "hidden",
    backgroundColor: ({ isClicked }) =>
      isClicked ? theme.colors.primary["light"] : "#F9F9FA",
  },
}));

interface PickBarProps {
  result: number;
  displayText: string;
  handleClick: (e: any) => void;
  pick: Pick;
}

const PickBar: React.FC<PickBarProps> = ({
  result,
  displayText,
  handleClick,
  pick,
}) => {
  const theme = useTheme<DefaultTheme>();
  const [isClicked, setIsClicked] = useState(true);
  const styleProps = {
    result,
    isClicked,
  };
  const classes = useStyles({ ...styleProps, theme });

  return (
    <button
      className={classes.buttonRoot}
      value={pick.id}
      onClick={() => {
        setIsClicked(!isClicked);
        handleClick(pick.id);
      }}
    >
      <div className={classes.bar}></div>
      <div className={classes.textContainer}>
        <div className={classes.resultText}>{displayText}</div>
        <div className={classes.resultContainer}>
          <div className={classes.resultText}>{result}%</div>
        </div>
      </div>
    </button>
  );
};

export default PickBar;
