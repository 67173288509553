import { extendTheme } from "@chakra-ui/react";
import { buttonTheme } from "./components/Button";
import { colors } from "./colors";
import { formLabel } from "./components/Label";
import { typography, fontFamilies } from "./fonts";
import { textTheme } from "./components/Text";
import { headingTheme } from "./components/Heading";
import { dividerTheme } from "./components/Divider";
import { linkTheme } from "./components/Link";
import { inputTheme } from "./components/Input";
import { selectTheme } from "./components/Select";
import { textareaTheme } from "./components/Textarea";

// Base Unit Spacings
const spacing = {
  base: 4, // DO NOT CHANGE THIS VALUE UNLESS YOU REALLY MEAN IT!
};

// Default zIndex values
const zIndex = {
  overlay: 999,
};

// Default Border Radius
const borderRadius = 4;

// Breakpoints
const breakpoints = {
  small: 787,
  medium: 1029,
  large: 1280,
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
};

// Default Gradient Values
const gradients = {
  main: `linear-gradient(112.25deg, #914EFF -20.38%, #5D1BFF 122.56%)`,
  hover: `linear-gradient(112.25deg, #914EFF 35.38%, #5D1BFF 122.56%)`,
  disabled:
    "linear-gradient(112.25deg, rgba(145,78,255,0.2) -20.38%, rgba(93,27,255,0.2) 122.56%)",
};

// Media Queries
const mediaQuery = (minWidth: number) => {
  return `@media (min-width: ${minWidth}px)`;
};

const components = {
  Button: buttonTheme,
  FormLabel: formLabel,
  Text: textTheme,
  Heading: headingTheme,
  Divider: dividerTheme,
  Link: linkTheme,
  Input: inputTheme,
  Select: selectTheme,
  Textarea: textareaTheme,
};

export const theme = extendTheme({
  borderRadius,
  breakpoints,
  colors,
  gradients,
  spacing,
  typography,
  zIndex,
  mediaQuery,
  components,
  fonts: fontFamilies,
});

// https://chakra-ui.com/docs/styled-system/theme
