import React from "react";
import { SearchBarProps } from "../../types";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Button, InputGroup, Text } from "@chakra-ui/react";
import { FontAwesome } from "../../components/common/icon";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";

const searchHeight = 46;

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    display: "flex",
    position: "relative",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    borderRadius: theme.borderRadius,
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.grey.lightBase}`,
    height: searchHeight,
    marginTop: theme.spacing.base * 8,
  },
  searchContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: 46,
    height: searchHeight,
  },
  inputContainer: {
    flex: "1 1 auto",
  },
  input: {
    border: "none",
    backgroundColor: "transparent",
    width: "100%",
    height: `calc(${searchHeight}px - 2px)`,
    "&:focus, &:active": {
      border: "none",
      outline: "none",
    },
  },
  errorContainer: {
    position: "relative",
    width: "100%",
    marginTop: theme.spacing.base * 2,
    marbinBottom: theme.spacing.base * 4,
    paddingLeft: theme.spacing.base,
  },
}));

const SearchBar: React.FC<SearchBarProps> = ({
  id,
  field: { name, value, onChange },
  placeholder,
  isSubmitting,
  form: { errors },
}) => {
  const classes = useStyles();
  return (
    <>
      <InputGroup className={classes.root}>
        <div className={classes.searchContainer}>
          <FontAwesome icon={faMagnifyingGlass} />
        </div>
        <div className={classes.inputContainer}>
          <input
            className={classes.input}
            type="text"
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        </div>
        <Button type="submit" size="sm" mr={2} disabled={isSubmitting}>
          Search
        </Button>
      </InputGroup>
      {errors && errors[name] ? (
        <Text color="red" variant="body2">
          {errors[name]}
        </Text>
      ) : null}
    </>
  );
};

export default SearchBar;
