import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { AxiosError } from "axios";
import {
  CurrentUser,
  DraftProp,
  v2Prop,
  Prop,
  PropViewModel,
  MultiSelectItem,
  PropsSearchQuery,
} from "../../types";
import useJwt from "../../hooks/useJwt";
import GlobalContext from "../../components/GlobalContext";
import { useContext } from "react";

const url = "props";
const usePropsApi = () => {
  const { privateAxiosV2 } = useJwt();
  const { currentUser } = useContext(GlobalContext);
  const deleteProp = async (prop: v2Prop): Promise<boolean> => {
    return privateAxiosV2
      .delete(`/${url}/${prop.id}`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const getAll = async (
    number: number,
    status: string,
    limit: number,
    authors: number[]
  ): Promise<PropViewModel> => {
    return await privateAxiosV2
      .get(`/${url}`, {
        params: {
          limit: limit,
          offset: number * 10 - 10,
          status: status,
          authorPublisherIds: authors,
        },
      })
      .then((response) => {
        const prop = response.data;
        if (!prop.selected_leagues) {
          prop.selected_leagues = [];
        }
        return prop;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
      });
  };

  const getProp = async (propId: string | number): Promise<DraftProp> => {
    return await privateAxiosV2
      .get(`/${url}/${propId}`)
      .then((response) => {
        const prop = response.data;
        prop.selected_tags = response.data.tags;
        return prop;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
  };

  const createDraftProp = async (
    user: CurrentUser,
    propVals: DraftProp
  ): Promise<Prop> => {
    propVals.first_name = user.first_name;
    propVals.last_name = user.last_name;
    propVals.email = user.email;
    propVals.user_id = user.id;

    return await privateAxiosV2
      .post(`/${url}`, propVals)
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
  };

  const updateDraftProp = async (
    user: CurrentUser,
    prop: DraftProp
  ): Promise<Prop> => {
    prop.first_name = user.first_name;
    prop.last_name = user.last_name;
    prop.email = user.email;
    prop.user_id = user.id;

    return await privateAxiosV2
      .patch(`/${url}/${prop.id}`, prop)
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
  };

  const searchProps = async ({
    search,
    status,
    tags,
    leagues,
    publishers,
    limit,
    open,
  }: PropsSearchQuery): Promise<PropViewModel> => {
    const params = {};
    if (search) params["search"] = search;
    if (status) params["status"] = status;
    if (leagues && leagues.length) params["leagues[]"] = leagues;
    if (tags && tags.length) params["tags[]"] = tags;
    params["limit"] = limit ? limit : 100;
    if (publishers) params["publishers"] = publishers;
    if (open) params["open"] = open;
    return await privateAxiosV2
      .get(`/${url}`, {
        params,
      })
      .then((response) => {
        const prop = response.data;
        if (!prop.selected_leagues) {
          prop.selected_leagues = [];
        }
        return prop;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
      });
  };

  const getPropsAsMultiSelect = async (
    search: PropsSearchQuery
  ): Promise<MultiSelectItem[]> => {
    const propsPayload = await searchProps(search);
    const { props } = propsPayload;
    return props.map((prop: v2Prop) => {
      return {
        id: Number(prop.id),
        name: prop.proposition,
        created_at: prop.created_at,
      };
    });
  };

  const getPropInsights = async (propId: string): Promise<any> => {
    return await privateAxiosV2
      .get(`/${url}/${propId}/insights`)
      .then((res) => res.data)
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
      });
  };

  const getMixpanelInsights = async (
    event_name: string,
    property_name: string,
    values: string[]
  ) => {
    return await privateAxiosV2
      .get(`/mixpanel/event/count`, {
        params: {
          event_name,
          property_name,
          values,
        },
      })
      .then((res) => res.data)
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
      });
  };

  const gradeProp = async (id: string, picks: any[]) => {
    return await privateAxiosV2
      .patch(`/${url}/${id}/grade`, { picks })
      .then((res) => res.data)
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
  };

  const contextualProp = async (league: number) => {
    return await privateAxiosV2
      .get(`/${url}/contextual`, { params: { league: league } })
      .then((res) => res.data)
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
  };

  return {
    getAll,
    createDraftProp,
    getProp,
    updateDraftProp,
    deleteProp,
    searchProps,
    getPropsAsMultiSelect,
    getPropInsights,
    getMixpanelInsights,
    gradeProp,
    contextualProp,
  };
};
export default usePropsApi;
