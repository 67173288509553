import { Carousel, PropAPIResponse, Publisher, DraftProp } from "../types";

const EMBED_API_URL = process.env.EMBED_API_URL;

// PROPS
export const generatePropEmbedCode = (
  user: Partial<Publisher>,
  prop?: PropAPIResponse,
  draftProp?: Partial<DraftProp>
): string =>
  `<div class="pickup-js-prop-picker" data-propid="${
    prop ? prop.id.raw : draftProp.id
  }"></div><script>window.addEventListener("load",function(){if(!document.getElementById("pickup-js-script")){var e=document.createElement("script");e.src="${EMBED_API_URL}/pickup-picker-v1.js?current="+Date.now(),e.id="pickup-js-script",window.puDataLayer=window.puDataLayer||[],function(){window.puDataLayer.push(...arguments)}("${
    user.access_token
  }","${user.id}",!1),document.body.appendChild(e)}});</script>`;

export const generatePropHtmlEmbedCode = (
  prop?: PropAPIResponse,
  draftProp?: DraftProp
): string =>
  `<div class="pickup-js-prop-picker" data-propid="${
    prop ? prop.id.raw : draftProp.id
  }"></div>`;

export const generatePropShortcode = (
  prop?: PropAPIResponse,
  draftProp?: Partial<DraftProp>
): string => `[pickup_prop id="${prop ? prop.id.raw : draftProp.id}"]`;

// CAROUSELS
export const generateCarouselEmbedCode = (
  carousel: Carousel,
  user: Partial<Publisher>
): string =>
  `<div class="pickup-js-prop-picker" data-carouselid="${carousel.id}"></div><script>window.addEventListener("load",function(){if(!document.getElementById("pickup-js-script")){var e=document.createElement("script");e.src="${EMBED_API_URL}/pickup-picker-v1.js?current="+Date.now(),e.id="pickup-js-script",window.puDataLayer=window.puDataLayer||[],function(){window.puDataLayer.push(...arguments)}("${user.access_token}","${user.id}",!1),document.body.appendChild(e)}});</script>`;

export const generateCarouselHtmlEmbedCode = (carousel: Carousel): string =>
  `<div class="pickup-js-prop-picker" data-carouselid="${carousel.id}"></div>`;

export const generateCarouselShortcode = (carousel: Carousel): string =>
  `[fanpower_carousel id="${carousel.id}"]`;
