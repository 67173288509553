import { AddIcon } from "@chakra-ui/icons";
import {
  Text,
  UnorderedList,
  Box,
  ListItem,
  IconButton,
} from "@chakra-ui/react";
import React from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  autoTagWrap: {
    border: "1px solid gray",
    borderRadius: 4,
    padding: "8px",
    "& p": {
      fontSize: "80%",
      fontWeight: "bold",
    },
  },
  result: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 18,
    color: theme.colors.black,
    marginBottom: theme.spacing.base,
    "& button": {
      marginRight: theme.spacing.base * 4,
    },
  },
}));

interface GeneratedTagsListProps {
  suggestedTags: string[];
  onClick: (e: any) => void;
  text?: string;
}

export const GeneratedTagsList: React.FC<GeneratedTagsListProps> = ({
  suggestedTags,
  onClick,
  text,
}) => {
  const classes = useStyles();
  return (
    <Box>
      {suggestedTags.length > 0 ? (
        <Box className={classes.autoTagWrap}>
          <Text>{text}</Text>
          <UnorderedList listStyleType={"none"} margin={0}>
            {suggestedTags.map((tag) => {
              return (
                <ListItem title={'Click "+" to use this tag'} key={tag}>
                  <Box className={classes.result}>
                    <IconButton
                      aria-label="add result"
                      value={tag}
                      onClick={onClick}
                      title={`Use tag '${tag}'`}
                      size={"xs"}
                      variant="outline"
                      marginLeft={"10px"}
                      icon={<AddIcon boxSize={2} />}
                    />
                    {tag}
                  </Box>
                </ListItem>
              );
            })}
          </UnorderedList>
        </Box>
      ) : null}
    </Box>
  );
};
