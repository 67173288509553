import React, {
  ChangeEventHandler,
  useContext,
  useEffect,
  useState,
} from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import { FormikErrors } from "formik";
import { mapArrayToMultiselect } from "../../../helpers/transformers";
import { MultiSelectItem, Tag } from "../../../types";
import { MultiselectSet } from "./MultiselectSet";
import useTagsApi from "../../../services/api/Tags";
import GlobalContext from "../../GlobalContext";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
const useStyles = createUseStyles((theme: DefaultTheme) => ({
  input: {
    height: 40,
    fontSize: 14,
    fontFamily: theme.typography.fontFamilies.body,
    color: theme.colors.grey.dark,
    borderColor: theme.colors.grey.lightBase,
    backgroundColor: theme.colors.white,
  },
}));

export interface TagsMultiselectProps {
  label: string;
  id: string;
  value: Tag[];
  onChange: ChangeEventHandler;
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  placeholder: string;
  setActive?: (boolean: boolean) => void;
  maxLength?: number;
  showCharCount?: boolean;
  wrapLabels?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  reset: boolean;
  touched?: boolean;
  createNew?: boolean;
  className?: string;
  updateSelectionCallback?: (selection: MultiSelectItem[]) => void;
  externalTag?: string;
}

export const TagsMultiselectSet: React.FC<TagsMultiselectProps> = ({
  label,
  id,
  value,
  onChange,
  errors,
  placeholder,
  isRequired = true,
  reset,
  touched,
  createNew = false,
  className = null,
  updateSelectionCallback = null,
  externalTag = null,
}) => {
  const classes = useStyles();
  const [values] = useState<Array<MultiSelectItem>>(
    value.length
      ? mapArrayToMultiselect(value as unknown as MultiSelectItem[], "name")
      : []
  );
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [createNewTag, setCreateNewTag] = useState(null);
  const [updateInputValue, setUpdateInputValue] =
    useState<MultiSelectItem>(null);
  const [externalTagState] = useState<string>(null);
  const tagsApi = useTagsApi();
  const { tags, setTags } = useContext(GlobalContext);

  const { enqueueSnackbar } = useSnackbar();
  const createTag = async (inputValue: string) => {
    setIsCreatingTag(true);
    const newTag = await tagsApi
      .create(inputValue, inputValue.replace(" ", "-"))
      .catch((error: AxiosError) => {
        const message =
          error.response.status === 400
            ? "This tag already exists. Please select the tag in the menu."
            : "There was an error creating your tag, please try again.";
        enqueueSnackbar(message, { variant: "error" });
      });

    if (newTag) {
      enqueueSnackbar("Tag created!");
      tagsApi
        .getAll()
        .then((updatedTags) => {
          setTags(updatedTags);
        })
        .catch((error) =>
          enqueueSnackbar(
            "There was an error fetching tags, please try again.",
            {
              variant: "error",
            }
          )
        );
    }
    setIsCreatingTag(false);
    return newTag;
  };

  useEffect(() => {
    if (externalTag && externalTag !== externalTagState) {
      // check if tag already exists
      const found = tags.find(
        (tag) => tag.name.toLowerCase() === externalTag.toLowerCase()
      );

      if (found) {
        if (
          !values.find((t) => t.name.toLowerCase() == found.name.toLowerCase())
        ) {
          setUpdateInputValue({ id: found.id, name: found.name });
        }
        return;
      }
      createTag(externalTag).then((newTag) => {
        if (newTag && newTag.length > 0) {
          setUpdateInputValue({ id: newTag[0].id, name: newTag[0].name });
        }
      });
    }
  }, [externalTag]);

  useEffect(() => {
    if (createNew) {
      setCreateNewTag({ isCreating: isCreatingTag, onCreate: createTag });
    } else {
      setCreateNewTag(null);
    }
  }, [createNew]);

  return (
    <>
      <MultiselectSet
        label={label}
        id={id}
        value={values}
        items={tags}
        onChange={onChange}
        isRequired={isRequired}
        errors={errors}
        touched={touched}
        placeholder={placeholder}
        reset={reset}
        createNew={createNewTag}
        updateSelectionCallback={updateSelectionCallback}
        updateInputValue={updateInputValue}
      />
    </>
  );
};
