import { AxiosResponse } from "axios";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import useJwt from "../../hooks/useJwt";
import { EmbedTarget, EmbedTargetRecord } from "../../types";

const useEmbedsApi = () => {
  const { privateAxiosV2 } = useJwt();

  const getAllEmbeds = async (
    publisher_id?: string
  ): Promise<EmbedTargetRecord[]> => {
    return await privateAxiosV2
      .get(`/embeds`, {
        params: {
          publisher_id: publisher_id,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const getEmbedById = async (embed_id: string): Promise<EmbedTargetRecord> => {
    return await privateAxiosV2
      .get(`/embeds/${embed_id}`)
      .then((res) => {
        return res.data[0];
      })
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const createEmbed = async (
    embedValues: EmbedTarget
  ): Promise<EmbedTargetRecord> => {
    return await privateAxiosV2
      .post(`/embeds`, {
        name: embedValues.name,
        description: embedValues.description,
        content_type: embedValues.content_type,
        publisher_id: embedValues.publisher_id,
        author_user_id: embedValues.author_user_id,
        content: JSON.stringify(embedValues.content),
      })
      .then((res) => {
        return res.data.success[0];
      })
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const updateEmbed = async (
    id: string,
    embedValues: EmbedTarget
  ): Promise<EmbedTargetRecord> => {
    return await privateAxiosV2
      .patch(`/embeds/${id}`, {
        name: embedValues.name,
        description: embedValues.description,
        content_type: embedValues.content_type,
        content: JSON.stringify(embedValues.content),
        publisher_id: embedValues.publisher_id,
      })
      .then((res) => {
        return res.data.success[0];
      })
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const deleteEmbed = async (id: string): Promise<AxiosResponse> => {
    return await privateAxiosV2.delete(`/embeds/${id}`).catch((err) => {
      pickupErrorHandlerWeb(err);
      throw err;
    });
  };

  return {
    getAllEmbeds,
    getEmbedById,
    createEmbed,
    updateEmbed,
    deleteEmbed,
  };
};

export default useEmbedsApi;
