import { Button, Tooltip } from "@chakra-ui/react";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ListTable from "../../components/common/Layouts/ListTable";
import useContestsApi from "../../services/api/Contests";
import { ContestRecord } from "../../types";
import ContestPropModal from "./ContestPropModal";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../../components/common/icon";

interface ContestProps {
  contest: ContestRecord;
  setContest: React.Dispatch<any>;
}

const ContestProps: React.FC<ContestProps> = ({ contest, setContest }) => {
  const contestsApi = useContestsApi();
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = useState(false);
  const [props, setProps] = useState([]);

  useEffect(() => {
    setProps(contest.props);
  }, [contest]);

  //removes prop from contest
  const handleRemoveProp = async (prop: any) => {
    await contestsApi
      .removeProp(prop.contest_id, prop.prop_id)
      .then((res) => {
        setContest(res);
        enqueueSnackbar("Prop removed", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar("Error removing prop", { variant: "error" });
      });
  };

  const sortByPlayOrder = () => {
    const sorted = [...props].sort((a, b) => {
      if (a.play_order < b.play_order) return -1;
      if (a.play_order > b.play_order) return 1;
      return 0;
    });

    setProps(sorted);
  };

  return (
    <>
      {props ? (
        <>
          <Tooltip label="Search and add props to the contest">
            <Button
              leftIcon={<FontAwesome icon={faPlus} />}
              onClick={() => setModalOpen(true)}
            >
              Add Prop
            </Button>
          </Tooltip>
          <ListTable
            heading={"Contest Props"}
            list={props}
            headers={[
              { title: "Action" },
              { title: "Prop Id" },
              { title: "Proposition" },
              { title: "Play Order", onClick: sortByPlayOrder },
              { title: "Start Date" },
            ]}
            columns={[
              { name: "prop_id", type: "string" },
              { name: "proposition", type: "string" },
              { name: "play_order", type: "string" },
              { name: "start_date", type: "date" },
            ]}
            actions={[
              {
                name: "Remove",
                actionHandler: null,
                icon: <FontAwesome icon={faTrash} />,
              },
            ]}
            onDelete={handleRemoveProp}
            alertTitle={"Remove this Prop?"}
            disablePageTrack={true}
            warnText={"Are you sure you want to remove this prop?"}
          />
          <ContestPropModal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            setContest={setContest}
            contest={contest}
          />
        </>
      ) : null}
    </>
  );
};

export default ContestProps;
