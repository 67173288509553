import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import useJwt from "../../hooks/useJwt";
import { Event } from "../../types";

const useEventsApi = () => {
  const { privateAxiosV1 } = useJwt();

  const getEvents = async (): Promise<Event[]> => {
    return await privateAxiosV1
      .get(`/events`)
      .then((res) => res.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  return {
    getEvents,
  };
};

export default useEventsApi;
