import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

export const selectTheme = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    field: {
      background: "white",
    },
  }),
});
