import React, { ReactNode } from "react";

import { FormControl, FormLabel, Text, FormHelperText } from "@chakra-ui/react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { FormikErrors, FormikTouched } from "formik";
import { isString } from "lodash";
const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
    maxWidth: "900px",
  },
  formComponent: {
    marginBottom: "12px",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      marginRight: theme.spacing.base * 4,
      minHeight: 0,
      maxWidth: 450,
    },
  },
  formLabel: {
    fontSize: 15,
    color: theme.colors.grey.dark,
    fontFamily: theme.typography.fontFamilies.body,
  },
  label: {
    fontFamily: theme.typography.fontFamilies.body,
    fontSize: 15,
    color: theme.colors.grey.dark,
  },
  proposition: {
    height: 40,
    fontSize: 16,
    fontFamily: theme.typography.fontFamilies.body,
    color: theme.colors.grey.dark,
    borderColor: theme.colors.grey.lightBase,
    backgroundColor: theme.colors.white,
  },
  flexLabel: {
    " & .flexible": {
      display: "flex",
    },
    " & .flexMember": {
      width: "78%",
    },
  },
  error: {
    fontFamily: theme.typography.fontFamilies.body,
    color: theme.colors.red.base,
    fontSize: 14,
    //position: "relative",
    //top: -40,
  },
}));

export interface InputGroupWrapperProps {
  label: string;
  value: string | number;
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  maxLength?: number;
  showCharCount?: boolean;
  wrapLabels?: boolean;
  children: ReactNode | undefined;
  isRequired: boolean;
  touched?: boolean | FormikTouched<Date>;
  isInvalid?: boolean;
  helperText?: string;
}

export const InputGroupWrapper: React.FC<InputGroupWrapperProps> = ({
  label,
  value,
  errors,
  maxLength,
  showCharCount,
  wrapLabels = false,
  children,
  isRequired,
  touched,
  isInvalid,
  helperText,
}) => {
  const classes = useStyles();

  return (
    <div className={wrapLabels == true ? classes.flexLabel : null}>
      <FormControl
        className={classes.formComponent}
        isRequired={isRequired}
        isInvalid={isInvalid}
      >
        <div className={"flexible"}>
          {label != null ? (
            <FormLabel className={classes.label}>{label}</FormLabel>
          ) : (
            ""
          )}

          <div className={"flexMember"}>
            {children}
            {showCharCount == true &&
            maxLength != undefined &&
            isString(value) ? (
              <Text>{`${
                value ? value.length : 0
              }/${maxLength} Characters`}</Text>
            ) : (
              ""
            )}
            {errors && touched ? (
              <div className={classes.error}>{errors?.toString()}</div>
            ) : (
              ""
            )}
          </div>
        </div>
        {helperText && (
          <FormHelperText fontSize={14}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};
