import React, { useEffect, useState, useContext } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import Notion from "../../components/common/Notion";
import { NotionApiService } from "../../services/api/Notion";
import { Box, Button, HStack } from "@chakra-ui/react";
import GlobalContext from "../../components/GlobalContext";
import { kasperService } from "../../services/api/Kasper";
import Loader from "../../components/Loader";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../../components/common/icon";

const pageId = process.env.REACT_APP_INTEGRATION_PAGE;

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
  },
  box: {
    margin: 15,
  },
}));

const Integrations: React.FC = () => {
  const classes = useStyles();
  const [map, setMap] = useState(null);
  const { currentUser, publisher } = useContext(GlobalContext);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const requestHandler = async () => {
    setSubmitting(true);
    await kasperService
      .sendIntegrationReq(
        currentUser.email,
        currentUser.publisher_name,
        publisher.source_url
      )
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
      });
  };
  useEffect(() => {
    NotionApiService.getNotionPage(pageId).then((res) => {
      setMap(res.recordMap);
    });
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <HStack spacing={"24px"}>
          <Button
            onClick={() => requestHandler()}
            isLoading={submitting}
            isDisabled={success}
            rightIcon={success ? <FontAwesome icon={faCheckCircle} /> : null}
          >
            Request an Integration
          </Button>

          <Box>
            {success ? "Thank You a team member will be in contact soon!" : ""}
          </Box>
        </HStack>
      </div>
      {map ? <Notion recordMap={map} /> : <Loader />}
    </div>
  );
};

export default Integrations;
