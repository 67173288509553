import { Role } from "../../../server/models/user.model";
import { UserPermissions } from "../../types";

export const userRole: { [key in Role]: UserPermissions } = {
  creator: {
    displayName: "Creator",
    name: "creator",
    rank: 1,
    propSearch: true,
    self_serve_props_access: "pending",
    aiContentView: true,
    aiContentCreate: true,
    viewReporting: true,
  },
  manager: {
    displayName: "Manager",
    rank: 5,
    name: "manager",
    adEditor: true,
    smsEditor: true,
    propSearch: true,
    self_serve_props_access: "pending",
    publisherSettings: true,
    fanEngagement: true,
    propCarousels: true,
    aiContentView: true,
    aiContentCreate: true,
    viewReporting: true,
  },
  publisheradmin: {
    displayName: "Publisher Admin",
    name: "publisheradmin",
    rank: 10,
    adEditor: true,
    smsEditor: true,
    propSearch: true,
    self_serve_props_access: "pending",
    fanEngagement: true,
    propCarousels: true,
    manageUsers: true,
    publisherSettings: true,
    aiContentView: true,
    aiContentCreate: true,
    viewReporting: true,
  },
  tenantadmin: {
    displayName: "Tenant Admin",
    name: "tenantadmin",
    rank: 15,
    adEditor: true,
    smsEditor: true,
    propSearch: true,
    self_serve_props_access: "pending",
    fanEngagement: true,
    propCarousels: true,
    manageUsers: true,
    publisherSettings: true,
    aiContentView: true,
    aiContentCreate: true,
    viewReporting: true,
  },
  fanpoweradmin: {
    displayName: "Fanpower Admin",
    name: "fanpoweradmin",
    rank: 99,
    adZones: true,
    adEditor: true,
    smsEditor: true,
    propSearch: true,
    self_serve_props_access: "pending",
    fanEngagement: true,
    propCarousels: true,
    manageUsers: true,
    publisherSettings: true,
    aiContentView: true,
    aiContentCreate: true,
    fanpoweradmin: true,
    viewReporting: true,
  },
};
