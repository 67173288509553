import React, { ChangeEventHandler } from "react";
import { Textarea } from "@chakra-ui/react";
import { FormikErrors } from "formik";
import { InputGroupWrapper } from "./InputGroupWrapper";

export interface TextAreaGroupProps {
  label: string;
  id: string;
  value?: string;
  onChange?: ChangeEventHandler;
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  placeholder?: string;
  setActive?: (boolean: boolean) => void;
  maxLength?: number;
  showCharCount?: boolean;
  wrapLabels?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  touched?: boolean;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  helperText?: string;
  isInvalid?: boolean;
  className?: string;
}

export const TextAreaGroup: React.FC<TextAreaGroupProps> = ({
  label,
  id,
  value,
  onChange,
  errors,
  placeholder,
  setActive,
  maxLength,
  showCharCount,
  wrapLabels = false,
  isRequired,
  isDisabled = false,
  touched,
  setFieldTouched,
  helperText,
  isInvalid,
  className,
}) => {
  return (
    <div className={className}>
      <InputGroupWrapper
        label={label}
        value={value}
        errors={errors}
        touched={touched}
        showCharCount={showCharCount}
        wrapLabels={wrapLabels}
        maxLength={maxLength}
        isRequired={isRequired}
        isInvalid={isInvalid}
        helperText={helperText}
      >
        <Textarea
          id={id}
          value={value != undefined ? value : placeholder}
          onChange={onChange}
          onFocus={() => {
            setActive(true);
          }}
          onBlur={() => {
            setActive(false);
            setFieldTouched(id, true, true);
          }}
          maxLength={maxLength}
          disabled={isDisabled}
        />
      </InputGroupWrapper>
    </div>
  );
};
