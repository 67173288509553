import { defineStyleConfig } from "@chakra-ui/react";

export const buttonTheme = defineStyleConfig({
  baseStyle: {},
  sizes: {
    // You have to pass a size when using in defaultProps
    // even if it's empty
    md: {},
  },
  variants: {
    solid: {
      background: "gradients.500",
      color: "white",
      _hover: {
        background: "gradients.hover",
      },
      _active: {
        background: "gradients.300",
      },
    },
    outline: {
      _hover: {
        background: "rgb(118, 75, 255, 0.1)",
      },
      _active: {
        background: "rgb(118, 75, 255, 0.2)",
      },
    },
    ghost: {
      _hover: {
        background: "rgb(118, 75, 255, 0.2)",
      },
      _active: {
        background: "rgb(118, 75, 255, 0.3)",
      },
    },
    link: {
      color: "fanpower.600",
    },
    action: {
      fontSize: "xs",
      fontWeight: "semilight",
      height: 7,
      px: 2,
      color: "fanpower.900",
      _hover: {
        color: "fanpower.700",
      },
      _active: {
        color: "fanpower.500",
      },
    },
    alert: {
      background: "red.500",
      color: "white",
      _hover: {
        background: "red.400",
      },
      _active: {
        background: "red.300",
      },
    },
    cancel: {
      background: "gray.500",
      color: "white",
      _hover: {
        background: "gray.400",
      },
      _active: {
        background: "gray.300",
      },
    },
  },
  defaultProps: {
    colorScheme: "fanpower",
    size: "md",
    variant: "solid",
  },
});
