import React from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import Editor from "ckeditor5-custom-build";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FormLabel, Text, useTheme } from "@chakra-ui/react";
import { HtmlEditorProps } from "../../types";
import { colors } from "./colors";
import { toolbarItems } from "./toolbar";
import { fontSizeOptions } from "./fontSizeOptions";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  editorRoot: {
    "& i": {
      fontStyle: "italic",
    },
  },
  formLabel: {
    fontSize: 15,
    color: theme.colors.grey.dark,
  },
}));

const HtmlEditor: React.FC<HtmlEditorProps> = ({
  id,
  label,
  onChange,
  width = "100%",
  height = 250,
  field, // You have access to field.value here, which is managed by Formik
  form,
  placeholder = "",
}) => {
  const classes = useStyles();
  const theme = useTheme<DefaultTheme>();

  return (
    <div className={classes.editorRoot}>
      {label ? (
        <FormLabel className={classes.formLabel} htmlFor={field.name}>
          {label}
        </FormLabel>
      ) : null}
      <CKEditor
        editor={Editor}
        config={{
          placeholder: field.value ? "" : placeholder,
          fontSize: {
            options: fontSizeOptions([9, 11, 13, 16, 24, 32, 48, 64]),
            supportAllValues: true,
          },
          toolbar: {
            items: toolbarItems,
          },
          fontColor: {
            columns: 10,
            documentColors: 200,
            colors: colors,
          },
          fontBackgroundColor: {
            columns: 10,
            documentColors: 200,
            colors: colors,
          },
        }}
        data={field.value} // Use field.value here instead of initialValue
        onReady={(editor) => {
          if (field.value) {
            editor.setData(field.value);
          }
          editor.editing.view.document.on(
            "enter",
            (evt, data) => {
              if (data.isSoft) {
                editor.execute("enter");
              } else {
                editor.execute("shiftEnter");
              }

              data.preventDefault();
              evt.stop();
              editor.editing.view.scrollToTheSelection();
            },
            { priority: "high" }
          );
          if (height) {
            editor.editing.view.change((writer) => {
              writer.setStyle(
                "height",
                typeof height === "number" ? height + "px" : height,
                editor.editing.view.document.getRoot()
              );
            });
          }
        }}
        onChange={async (event, editor) => {
          const data = editor.getData();
          if (onChange) {
            onChange(data);
          } else {
            form.setFieldValue(id, data);
          }
        }}
      />
      {!!form.errors[field.name] && !!form.touched[field.name] && (
        <Text color="red">{form.errors[field.name]}</Text>
      )}
    </div>
  );
};

export default HtmlEditor;
