import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { version } from "../../package.json";

import Main from "./Main";

Sentry.init({
  dsn: "https://9d2db35bc517498c8c3f9e381b0ee03b@o527352.ingest.sentry.io/5979081",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 0.05,
  release: "Portal-" + version,
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<Main />);
