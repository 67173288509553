import React, { ChangeEventHandler } from "react";
import { Input } from "@chakra-ui/react";
import { FormikErrors } from "formik";
import { InputGroupWrapper } from "./InputGroupWrapper";

export interface TextInputGroupProps {
  label: string;
  id: string;
  value: string;
  onChange: ChangeEventHandler;
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  placeholder: string;
  setActive?: (boolean: boolean) => void;
  maxLength?: number;
  showCharCount?: boolean;
  wrapLabels?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  touched?: boolean;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
}

export const TextInputGroup: React.FC<TextInputGroupProps> = ({
  label,
  id,
  value,
  onChange,
  errors,
  placeholder,
  setActive,
  maxLength,
  isRequired,
  showCharCount,
  wrapLabels = false,
  isDisabled = false,
  touched,
  setFieldTouched,
}) => {
  return (
    <>
      <InputGroupWrapper
        label={label}
        value={value}
        errors={errors}
        touched={touched}
        showCharCount={showCharCount}
        wrapLabels={wrapLabels}
        maxLength={maxLength}
        isRequired={isRequired}
      >
        <Input
          placeholder={placeholder}
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          disabled={isDisabled}
          onFocus={() => {
            setActive(true);
          }}
          onBlur={() => {
            setActive(false);
            typeof setFieldTouched == "function" &&
              setFieldTouched(id, true, true);
          }}
        />
      </InputGroupWrapper>
    </>
  );
};
