import React, { ReactNode } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import { Flex, Heading, Spacer, useTheme } from "@chakra-ui/react";
import BreadcrumbsBlock, { BreadcrumbBlockLink } from "./BreadcrumbsBlock";
import Paper from "../Paper";
const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
  },
  breadCrumbs: {
    fontSize: 15,
    fontFamily: theme.typography.fontFamilies.body,
    fontWeight: "normal",
  },
  createButton: {
    fontSize: 14,
    height: 32,
    color: "white",
    fontFamily: theme.typography.fontFamilies.body,
    backgroundColor: theme.colors.purple.base,
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: theme.colors.purple.lightBase,
    },
    "&:focus": {
      backgroundColor: theme.colors.purple.dark,
    },
  },
  heading: {
    fontFamily: theme.typography.fontFamilies.body,

    marginBottom: theme.spacing.base * 4,
    fontSize: 20,
  },
  content: {
    overflowX: "auto",
  },
}));

export interface NoBorderLayoutProps {
  children: ReactNode | undefined;
  title: string;
  headerButton?: ReactNode | undefined;
  breadcrumb?: BreadcrumbBlockLink[];
}
const NoBorderLayout: React.FC<NoBorderLayoutProps> = ({
  children,
  title,
  headerButton,
  breadcrumb,
}) => {
  const classes = useStyles();
  const theme = useTheme<DefaultTheme>();
  return (
    <div className={classes.root}>
      <div>
        <Flex paddingBottom={theme.spacing.base * 2}>
          <BreadcrumbsBlock links={breadcrumb} />
          <Spacer />
          {headerButton}
        </Flex>
        <Paper>
          <Heading as="h4" size="md" className={classes.heading}>
            {title}
          </Heading>
          <div className={classes.content}>{children}</div>
        </Paper>
      </div>
    </div>
  );
};
export default NoBorderLayout;
