import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  Select,
  Text,
  Textarea,
  useTheme,
} from "@chakra-ui/react";
import { faCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import AdComponent from "../../../components/Ad";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import MultiSelect from "../../../components/MultiSelect";
import { pickupErrorHandlerWeb } from "../../../helpers/pickupErrorHandlerWeb";
import GlobalContext from "../../../components/GlobalContext";
import {
  Ad,
  AdFormValues,
  AdZone,
  EditAdProps,
  MultiSelectItem,
  Publisher,
  State,
  Tag,
} from "../../../types";
import domtoimage from "dom-to-image";
import DatePicker from "../../../components/DatePicker";
import RenderAdType from "./RenderAdType";
import {
  mapObjectArrayToIds,
  mapArrayToMultiselect,
} from "../../../helpers/transformers";
import { filterPublishersByTenant } from "./adHelpers";
import useJwt from "../../../hooks/useJwt";
import Loader from "../../../components/Loader";
import { FontAwesome } from "../../../components/common/icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
    fontFamily: theme.typography.fontFamilies.body,
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  breadcrumbs: {
    fontSize: 15,
  },
  renderContainer: {
    maxWidth: 450,
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: theme.spacing.base * 2,
    },
  },
  adPreview: {
    height: 200,
  },
  formComponent: {
    marginBottom: theme.spacing.base * 4,
    maxWidth: 450,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      marginBottom: theme.spacing.base * 4,
      marginRight: theme.spacing.base * 4,
      minHeight: 0,
      maxWidth: 450,
    },
  },
  desktopButtons: {
    display: "none",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      display: "block",
    },
  },
  mobileButtons: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing.base * 4,
    "& > *": {
      height: 50,
      margin: [theme.spacing.base * 2, 0],
    },
    [theme.mediaQuery(theme.breakpoints.small)]: {
      display: "none",
    },
  },
  previewContainer: {
    "& i": {
      fontStyle: "italic",
    },
  },
  adImage: {
    display: "flex",
    flexDirection: "column",
  },
}));

const MAX_FILE_SIZE_IN_BYTES = 2000 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const CreateAd: React.FC = () => {
  const classes = useStyles();
  const { currentUser, publisher } = useContext(GlobalContext);
  const formRef = useRef<FormikProps<AdFormValues>>();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [ad] = useState<Ad>(
    location.state ? (location.state as EditAdProps).ad : null
  );
  const [adZones, setAdZones] = useState<Array<AdZone>>();
  const [selectedAdZone, setSelectedAdZone] = useState<AdZone>();
  const [zone, setZone] = useState<AdZone>();
  const [publishers, setPublishers] = useState<Array<Publisher>>();
  const [states, setStates] = useState<Array<MultiSelectItem>>();
  const [tags, setTags] = useState<Array<MultiSelectItem>>();
  const [adPreview, setAdPreview] = useState<string | null>();
  const [renderSms, setRenderSms] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reset, setReset] = useState(false);
  const { privateAxiosV1, decodeJwt } = useJwt();
  const theme = useTheme<DefaultTheme>();

  useEffect(() => {
    const fetchZones = async () => {
      try {
        await privateAxiosV1.get(`/adzones`).then((response) => {
          setAdZones(response.data.adzones);
        });
      } catch (error) {
        pickupErrorHandlerWeb(error);
      }
    };

    const fetchPublishers = async () => {
      try {
        await privateAxiosV1
          .get(`/publishers`, {
            params: { limit: 10000 },
          })
          .then((response) => {
            if (!response || !response.data || !response.data.publishers) {
              throw new Error("Unable to retrieve publishers");
            }
            if (decodeJwt.user.role == "fanpoweradmin") {
              setPublishers(response.data.publishers);
            } else if (decodeJwt.user.role == "tenantadmin") {
              setPublishers(
                response.data.publishers.filter(
                  (p) =>
                    publisher.tenant_id && p.tenant_id == publisher.tenant_id
                )
              );
            } else {
              setPublishers([publisher]);
            }
          });
      } catch (error) {
        pickupErrorHandlerWeb(error);
      }
    };

    const fetchStates = async () => {
      try {
        await privateAxiosV1.get(`/states`).then((response) => {
          const multiSelectStates = response.data.map((state: State) => {
            return {
              id: state.id,
              name: state.state,
            };
          });
          setStates(multiSelectStates);
        });
      } catch (error) {
        pickupErrorHandlerWeb(error);
      }
    };

    const fetchTags = async () => {
      try {
        await privateAxiosV1
          .get(`/tags`, {
            params: { limit: 3000 },
          })
          .then((response) => {
            const multiSelectTags = response.data.map((tag: Tag) => {
              return {
                id: tag.id,
                name: tag.name,
                value: tag.slug,
              };
            });
            setTags(multiSelectTags);
          });
      } catch (error) {
        pickupErrorHandlerWeb(error);
      }
    };
    if (loading) {
      Promise.all([
        fetchZones(),
        fetchPublishers(),
        fetchStates(),
        fetchTags(),
      ]);
    }
  }, [reset, ad]);

  useEffect(() => {
    if (adZones && publishers && states && tags) setLoading(false);
  }, [adZones, publishers, states, tags]);

  useEffect(() => {
    if (ad?.background_type === "static") {
      setAdPreview("static");
    }
    if (ad?.background_type === "dynamic") {
      setAdPreview("dynamic");
    }
    if (ad?.background_type === "code") {
      setAdPreview("code");
    }
    if (ad?.zones[0].sms) {
      setZone(ad?.zones[0]);
      setRenderSms(true);
    }
  }, []);

  const getAdImage = () => {
    return new Promise((resolve) => {
      const node = document.querySelector(
        '[data-testid*="background"]'
      ) as HTMLElement;
      domtoimage.toBlob(node).then((data) => {
        resolve(data);
      });
    });
  };

  const handleZoneRender = (e: React.BaseSyntheticEvent) => {
    const zoneId = e.target.value;
    for (const zone of adZones) {
      setSelectedAdZone(zone);
      if (zone.id === zoneId) {
        setZone(zone);
        if (zone.sms) {
          setRenderSms(true);
        }
        if (!zone.sms) {
          formRef.current.setFieldValue("sms", "");
          setRenderSms(false);
        }
        break;
      }
    }
  };

  const handleAdPreviewRender = (e: React.BaseSyntheticEvent) => {
    const adType = e.target.value;
    if (adType === "static") {
      formRef.current.setFieldValue("ad_copy", "");
      formRef.current.setFieldValue("showDynamic", false);
      setAdPreview("static");
    }
    if (adType === "dynamic") {
      formRef.current.setFieldValue("showDynamic", true);
      setAdPreview("dynamic");
    }
    if (adType === "code") {
      formRef.current.setFieldValue("ad_copy", "");
      formRef.current.setFieldValue("showDynamic", false);
      setAdPreview("code");
    }
  };

  const enqueueAd = (ad: Ad, message: string) => {
    const aws = ad.ad_image;
    return (
      <div className={classes.adImage}>
        <>{message}</>
        <img src={aws} alt="ad_image"></img>
      </div>
    );
  };

  const createAd = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    const createdAd = await privateAxiosV1
      .post<Ad>(`/ads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);

    if (createdAd) {
      enqueueSnackbar(
        enqueueAd(createdAd, `New ad "${createdAd.ad_name}" created!`),
        {
          variant: "success",
        }
      );
      setReset(true);
      setAdPreview(null);
      setZone(null);
      setRenderSms(false);
    }

    return createdAd;
  };

  const editAd = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    const editedAd = await privateAxiosV1
      .put<Ad>(`/ads`, formData)
      .then((res) => res.data);

    if (editedAd) {
      enqueueSnackbar(enqueueAd(editedAd, "Ad updated!"), {
        variant: "success",
      });
    }
    return editedAd;
  };

  const handleSubmit = async (values: AdFormValues) => {
    let ad_image = null;
    if (values.background_type !== "code") {
      ad_image = await getAdImage()
        .then((data: Blob) => {
          return new File([data], "adImage", { type: "image/png" });
        })
        .catch((err) => {
          pickupErrorHandlerWeb(err);
        });
    }

    const {
      ad_name,
      background_type,
      ad_url,
      ad_zone_id,
      status,
      publishers,
      states,
      tags,
      sms_message,
      background_image,
      foreground_image,
      ad_copy,
      ad_start_time,
      ad_end_time,
      ad_code,
    } = values;

    const data = {
      ...(ad && ad.id && { id: ad.id }),
      ad_name: ad_name.trim(),
      background_type,
      ad_url,
      ad_zone_id,
      status,
      publishers,
      states,
      tags,
      sms_message,
      ...(typeof background_image !== "string" && { background_image }),
      ...(foreground_image &&
        background_type === "dynamic" && {
          foreground_image: foreground_image,
        }),
      ad_copy,
      ad_start_time: ad_start_time.toISOString(),
      ...(ad_end_time && { ad_end_time: ad_end_time?.toISOString() }),
      ad_image,
      author_publisher_id: currentUser.publisher_id,
      ad_code,
    };

    if (ad) {
      return editAd(data).catch((err) => {
        enqueueSnackbar(err.response.data, { variant: "error" });
        pickupErrorHandlerWeb(err);
        throw err;
      });
    }
    return createAd(data).catch((error) => {
      enqueueSnackbar(error.response.data, { variant: "error" });
      pickupErrorHandlerWeb(error);
      throw error;
    });
  };

  if (loading) return <Loader />;

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          ad_name: (ad && ad.ad_name) || "",
          background_type: (ad && ad.background_type) || "",
          ad_url: (ad && ad.ad_url) || "",
          ad_zone_id: (ad && ad.ad_zone_id) || "",
          status: (ad && ad.status) || "",
          publishers:
            (ad && mapObjectArrayToIds(ad.publishers as Publisher[])) ||
            mapObjectArrayToIds([publisher]),
          states: (ad && mapObjectArrayToIds(ad.states as State[])) || [],
          tags: (ad && mapObjectArrayToIds(ad.tags as Tag[])) || [],
          sms_message: (ad && ad.sms_message) || "",
          background_image: (ad && (ad.background_image as string)) || null,
          foreground_image: (ad && (ad.foreground_image as string)) || null,
          ad_copy: (ad && ad.ad_copy) || "",
          ad_start_time:
            ad && ad.ad_start_time ? new Date(ad.ad_start_time) : undefined,
          ad_end_time:
            ad && ad.ad_end_time ? new Date(ad.ad_end_time) : undefined,
          showDynamic: ad?.background_type === "dynamic",
          ad_code: (ad && ad.ad_code) || "",
        }}
        validationSchema={Yup.object().shape({
          ad_name: Yup.string()
            .max(100, "Character limit 100")
            .required("Name is required"),
          background_type: Yup.string().required("Ad type is required"),
          ad_url: Yup.string().matches(
            /(https):\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/,
            "Full URL with 'https://' is required"
          ),
          ad_zone_id: Yup.string().required("Ad zone is required"),
          status: Yup.string().required("Ad status is required"),
          ad_start_time: Yup.date()
            .default(undefined)
            .nullable()
            .required("Ad Start Time can not be empty"),
          ad_end_time: Yup.date()
            .default(undefined)
            .nullable()
            .when(["ad_start_time"], (ad_start_time: Date, Yup) => {
              return Yup.test({
                test: (ad_end_time) =>
                  ad_start_time && ad_end_time
                    ? ad_start_time < ad_end_time
                    : true,
                message: "Ad Start Time can not be greater than Ad End Time",
              });
            }),
          showDynamic: Yup.boolean(),
          ad_copy: Yup.string().when("showDynamic", {
            is: true,
            then: Yup.string().required("Dynamic ads require ad copy"),
            otherwise: Yup.string(),
          }),
          ad_code: Yup.string().when("background_type", {
            is: "code",
            then: Yup.string().required("Ad Code required for code type ads"),
            otherwise: Yup.string(),
          }),
          background_image: Yup.mixed().when("background_type", {
            is: "code",
            then: Yup.mixed().optional(),
            otherwise: Yup.mixed().required(
              "Ad image and/or background is required"
            ),
          }),
          foreground_image: Yup.mixed().nullable(),
        })}
        innerRef={formRef}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await handleSubmit(values)
            .then(() => {
              if (!ad) {
                resetForm();
              }
            })
            .catch();
          setSubmitting(false);
          setReset(false);
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          errors,
          handleBlur,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <div className={classes.nav}>
              <Breadcrumb className={classes.breadcrumbs}>
                <BreadcrumbItem color={theme.colors.primary.base}>
                  <BreadcrumbLink>Endzone</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="/ads">
                    Ads
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink>{ad ? "Edit" : "Create"}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <div className={classes.desktopButtons}>
                <Button
                  leftIcon={<FontAwesome icon={faCheck} />}
                  type="submit"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>

                <Button
                  leftIcon={<FontAwesome icon={faCircleXmark} />}
                  variant="outline"
                  as={Link}
                  to="/ads"
                >
                  Cancel
                </Button>
              </div>
            </div>
            <Divider orientation="horizontal" />

            <Heading>{ad ? "Edit Ad" : "Create Ad"}</Heading>

            <Text mb={10}>* Required fields</Text>
            <Heading as="h4" size="md" marginY={4}>
              General
            </Heading>
            <FormControl
              className={classes.formComponent}
              isInvalid={errors.ad_name && touched.ad_name}
              isRequired
            >
              <FormLabel>Name</FormLabel>
              <Input
                id="ad_name"
                placeholder="Ad name..."
                value={values.ad_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormHelperText fontSize={14}>
                Names must be unique
              </FormHelperText>
              <FormErrorMessage fontSize={14}>
                {errors.ad_name}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              className={classes.formComponent}
              isInvalid={errors.ad_url && touched.ad_url}
            >
              <FormLabel>URL</FormLabel>
              <Input
                id="ad_url"
                placeholder="Ad URL..."
                value={values.ad_url}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormHelperText fontSize={14}>
                {`Include "https://" prefix`}
              </FormHelperText>
              <FormErrorMessage fontSize={14}>{errors.ad_url}</FormErrorMessage>
            </FormControl>

            <div>
              <FormControl
                className={classes.formComponent}
                isInvalid={errors.ad_zone_id && touched.ad_zone_id}
                isRequired
              >
                <FormLabel>Zone</FormLabel>
                <Select
                  id="ad_zone_id"
                  placeholder="Select ad zone..."
                  value={values.ad_zone_id}
                  onChange={(e) => {
                    handleZoneRender(e);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                >
                  {adZones &&
                    adZones.map((zone) => (
                      <option key={zone.id} value={zone.id}>
                        {zone.name}
                      </option>
                    ))}
                </Select>
                <FormErrorMessage fontSize={14}>
                  {errors.ad_zone_id}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                className={classes.formComponent}
                isInvalid={errors.status && touched.status}
                isRequired
              >
                <FormLabel>Status</FormLabel>
                <Select
                  id="status"
                  placeholder="Select ad status..."
                  value={values.status}
                  onChange={(e) => {
                    if (e.target.value == "published") {
                      values.ad_start_time = new Date();
                    } else if (
                      e.target.value == "scheduled" &&
                      values.ad_start_time &&
                      values.ad_start_time <= new Date()
                    ) {
                      values.ad_start_time = undefined;
                    } else if (e.target.value == "archived") {
                      values.ad_end_time = new Date();
                    }
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                >
                  <option value="published">Publish Now</option>
                  <option value="draft">Draft</option>
                  <option value="archived">Archive Now</option>
                  <option value="scheduled">Scheduled</option>
                </Select>
                <FormErrorMessage fontSize={14}>
                  {errors.status}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                className={classes.formComponent}
                isInvalid={
                  errors.ad_start_time != "" && touched.ad_start_time == true
                }
                isRequired
              >
                <FormLabel>
                  Ad Start Date/Time TZ(
                  {Intl.DateTimeFormat().resolvedOptions().timeZone})
                </FormLabel>
                <DatePicker
                  id="ad_start_time"
                  onChange={(date) => {
                    if (date > new Date()) {
                      setFieldValue("ad_start_time", date);
                      if (values.status == "published") {
                        setFieldValue("status", "scheduled");
                      }
                    } else if (
                      date <= new Date() &&
                      values.status == "scheduled"
                    ) {
                      setFieldValue("status", "published");
                      setFieldValue("ad_start_time", new Date());
                    } else if (
                      date <= new Date() &&
                      values.status == "published"
                    ) {
                      setFieldValue("ad_start_time", new Date());
                    } else if (date <= new Date()) {
                      setFieldValue("ad_start_time", new Date());
                    } else setFieldValue("ad_start_time", date);
                  }}
                  selectedDate={values.ad_start_time}
                  onBlur={() => {
                    setFieldTouched("ad_start_time", true, true);
                  }}
                />
                <FormErrorMessage fontSize={14}>
                  {errors.ad_start_time as string}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                className={classes.formComponent}
                isInvalid={errors.ad_end_time != ""}
              >
                <FormLabel>
                  Ad End Date/Time TZ(
                  {Intl.DateTimeFormat().resolvedOptions().timeZone})
                </FormLabel>
                <DatePicker
                  id="ad_end_time"
                  onChange={(date) => {
                    if (values.status == "archived") {
                      setFieldValue("ad_end_time", new Date());
                    } else setFieldValue("ad_end_time", date);
                  }}
                  selectedDate={values.ad_end_time}
                />
                <FormErrorMessage fontSize={14}>
                  {errors.ad_end_time as string}
                </FormErrorMessage>
              </FormControl>
              <Heading as="h4" size="md" marginY={4}>
                Targeting
              </Heading>
              {decodeJwt.user.role == "fanpoweradmin" ||
              decodeJwt.user.role == "tenantadmin" ? (
                <MultiSelect
                  name="publishers"
                  items={filterPublishersByTenant(
                    publishers,
                    values.publishers,
                    currentUser
                  )}
                  values={
                    ad
                      ? mapArrayToMultiselect(
                          ad.publishers as Publisher[],
                          "name"
                        )
                      : mapArrayToMultiselect([publisher], "name")
                  }
                  label="Publisher(s)"
                  placeholder="Search publishers..."
                  reset={reset}
                  className={classes.formComponent}
                />
              ) : null}
              <MultiSelect
                name="states"
                items={states}
                values={
                  ad
                    ? mapArrayToMultiselect(ad.states as State[], "state")
                    : null
                }
                label="States"
                placeholder="Search states..."
                reset={reset}
                className={classes.formComponent}
              />

              <MultiSelect
                name="tags"
                items={tags}
                values={
                  ad ? mapArrayToMultiselect(ad.tags as Tag[], "name") : null
                }
                label="Tags"
                placeholder="Search tags..."
                reset={reset}
                className={classes.formComponent}
              />
              <Heading as="h4" size="md" marginY={4}>
                Content
              </Heading>
              <FormControl
                className={classes.formComponent}
                isInvalid={errors.background_type && touched.background_type}
                isRequired
              >
                <FormLabel>Type</FormLabel>
                <Select
                  id="background_type"
                  placeholder="Select ad type..."
                  value={values.background_type}
                  onChange={(e) => {
                    handleAdPreviewRender(e);
                    handleChange(e);
                    formRef.current.setFieldValue("background_image", "");
                    formRef.current.setFieldValue("foreground_image", "");
                    formRef.current.setFieldValue("ad_copy", "");
                  }}
                  onBlur={handleBlur}
                >
                  <option value="static">Static</option>
                  <option value="dynamic">Dynamic</option>
                  <option value="code">Ad Code</option>
                </Select>
                <FormHelperText fontSize={14}>
                  Upload graphic or create your own
                </FormHelperText>
                <FormErrorMessage fontSize={14}>
                  {errors.background_type}
                </FormErrorMessage>
              </FormControl>
              <div className={classes.renderContainer}>
                {values.background_type != "code" ? (
                  <>
                    <Text>Ad Preview</Text>
                    <div className={classes.previewContainer}>
                      {(zone || ad?.zones[0]) && values.background_image ? (
                        <AdComponent
                          name="Ad Preview"
                          height={zone ? zone.height : ad.zones[0].height}
                          width={zone ? zone.width : ad.zones[0].width}
                          backgroundImage={
                            values.background_image
                              ? typeof values.background_image === "string"
                                ? values.background_image
                                : URL.createObjectURL(values.background_image)
                              : ""
                          }
                          foregroundImage={
                            values.foreground_image && values.showDynamic
                              ? typeof values.foreground_image === "string"
                                ? values.foreground_image
                                : URL.createObjectURL(values.foreground_image)
                              : ""
                          }
                          copy={values.ad_copy}
                        />
                      ) : (
                        <Image
                          src="https://playpickup.s3.us-east-2.amazonaws.com/assets/portal/ad-preview-default.jpg"
                          alt="default pickup ad preview"
                        />
                      )}
                      {zone || ad?.zones[0] ? (
                        <Text mt={8}>
                          {zone
                            ? `Zone Dimensions: ${zone.width} x ${zone.height} pixels`
                            : `Zone Dimensions: ${ad.zones[0].width} x ${ad.zones[0].height} pixels`}
                        </Text>
                      ) : null}
                      <RenderAdType
                        ad={ad}
                        adPreview={adPreview}
                        formRef={formRef?.current}
                        selectedAdZone={selectedAdZone}
                        MAX_FILE_SIZE_IN_BYTES={MAX_FILE_SIZE_IN_BYTES}
                        SUPPORTED_FORMATS={SUPPORTED_FORMATS}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <Text>Ad Code</Text>

                    <Textarea
                      id={"ad_code"}
                      placeholder={"paste ad code here"}
                      value={values.ad_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size={"lg"}
                      backgroundColor="white"
                    />
                  </>
                )}

                {renderSms ? (
                  <FormControl
                    className={classes.formComponent}
                    style={{ marginBottom: theme.spacing.base * 4 }}
                  >
                    <FormLabel>First-Time Fan Pick SMS</FormLabel>
                    <Textarea
                      id="sms_message"
                      placeholder="SMS copy..."
                      value={values.sms_message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="sm"
                    />
                    <FormHelperText
                      fontSize={14}
                    >{`${values.sms_message.length} Characters`}</FormHelperText>
                  </FormControl>
                ) : null}
              </div>
            </div>

            <div className={classes.mobileButtons}>
              <Button
                leftIcon={<FontAwesome icon={faCheck} />}
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Save
              </Button>

              <Button
                leftIcon={<FontAwesome icon={faCircleXmark} />}
                variant="outline"
                as={Link}
                to="/ads"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default CreateAd;
