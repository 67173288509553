import React, { useContext, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import usePublishersApi from "../../../services/api/Publishers";
import useUsersApi from "../../../services/api/Users";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import Paper from "../../../components/common/Paper";
import GlobalContext from "../../../components/GlobalContext";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "center",
    alignItems: "100%",
    marginTop: "15vh",
  },
  paper: {
    maxWidth: 568,
    backgroundColor: theme.colors.white,
  },
  label: {
    fontSize: 16,
    fontFamily: "'Ringside-Condensed', Helvetica, sans-serif",
    textTransform: "uppercase",
    padding: 4,
  },
}));

type Values = {
  publisher_name: string;
  publisher_url: string;
  first_name: string;
  last_name: string;
  email: string;
};

const PublisherSignUp = (): JSX.Element => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const publishersApi = usePublishersApi();
  const usersApi = useUsersApi();

  const { doLogout, currentUser } = useContext(GlobalContext);

  useEffect(() => {
    if (currentUser) {
      doLogout();
    }
  }, []);

  const handleSubmit = async (values: Values, setSubmitting) => {
    try {
      await publishersApi
        .createPublisherAndPrimaryUser(values)
        .then(async () => {
          setSubmitting(false);
          enqueueSnackbar("Account created!", {
            variant: "success",
          });
          await usersApi
            .recoverWithAdminToken(
              values.email,
              window.location.origin + "/login/recovery"
            )
            .then(() => {
              enqueueSnackbar(
                "Check email for password reset and instructions",
                {
                  variant: "success",
                }
              );
              navigate("/login");
            });
        });
    } catch (err) {
      let message =
        "Unable to create new Publisher, contact support@fanpower.io for help";
      if (err.response.status == 422) {
        message = err.response.data.error;
      }
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} padding={30}>
        <Heading variant="heading2" mb={4}>
          Publisher Sign Up
        </Heading>
        <Text>Create a new Publisher and set your primary User account</Text>
        <Divider orientation="horizontal" />
        <Formik
          initialValues={{
            publisher_name: "",
            publisher_url: "",
            first_name: "",
            last_name: "",
            email: "",
            password: "",
          }}
          validationSchema={Yup.object({
            publisher_name: Yup.string().required("Publisher name is required"),
            publisher_url: Yup.string()
              .url("Must be valid URL with https://")
              .required("Publisher URL is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            email: Yup.string()
              .email("Must be valid email")
              .required("Email is required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSubmit(values, setSubmitting);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Flex direction="column" gap={4}>
                <FormControl>
                  <FormLabel htmlFor="publisher_name">Publisher Name</FormLabel>
                  <Input
                    id="publisher_name"
                    name="publisher_name"
                    placeholder="FanPower"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="publisher_url">Publisher URL</FormLabel>
                  <Input
                    id="publisher_url"
                    name="publisher_url"
                    placeholder="https://fanpower.io"
                  />
                </FormControl>
                <Flex gap={2}>
                  <FormControl>
                    <FormLabel htmlFor="first_name">First Name</FormLabel>
                    <Input
                      id="first_name"
                      name="first_name"
                      placeholder="Tom"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="last_name">Last Name</FormLabel>
                    <Input
                      id="last_name"
                      name="last_name"
                      placeholder="Brady"
                    />
                  </FormControl>
                </Flex>
              </Flex>
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  name="email"
                  placeholder="tom.brady@fanpower.io"
                />
              </FormControl>
              <Button disabled={isSubmitting} w="full" mt={4}>
                Complete Signup
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default PublisherSignUp;
