import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import React, { ReactChild } from "react";
import { Box } from "@chakra-ui/react";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  boxBorder: {
    border: `1px solid ${theme.colors.grey.lightBase}`,
    borderRadius: 6,
    paddingLeft: theme.spacing.base * 5,
    paddingRight: theme.spacing.base * 5,
    paddingBottom: 12,
    marginTop: 40,
  },
  boxTitle: {
    fontFamily: theme.typography.fontFamilies.body,
    top: -14,
    textAlign: "center",
    height: 16,
    width: 100,
    position: "relative",
    background: theme.colors.grey.light,
    opacity: 1,
    marginBottom: theme.spacing.base * 8,
    marginLeft: theme.spacing.base * 3,
    color: theme.colors.grey.base,
  },
}));

export interface BorderedBoxProps {
  children: ReactChild;
  title: string;
}

export const BorderedBox: React.FC<BorderedBoxProps> = ({
  children,
  title,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.boxBorder}>
        <Box className={classes.boxTitle}>{title}</Box>
        {children}
      </div>
    </div>
  );
};
