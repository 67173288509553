// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./RingsideCondensedSSm-BoldItalic_Web.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./RingsideCondensedSSm-BoldItalic_Web.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./RingsideCondensed-MediumItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./RingsideCondensed-MediumItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./RingsideCondensed-SemiboldItalic_Web.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./RingsideCondensed-SemiboldItalic_Web.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Ringside-Condensed";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
}
@font-face {
  font-family: "Ringside-Condensed-Thin";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
}
@font-face {
  font-family: "Ringside-Condensed-Semibold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff");
}
`, "",{"version":3,"sources":["webpack://./src/client/fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC;0DACkE;AACpE;AACA;EACE,sCAAsC;EACtC;0DAC6D;AAC/D;AACA;EACE,0CAA0C;EAC1C;0DACmE;AACrE","sourcesContent":["@font-face {\n  font-family: \"Ringside-Condensed\";\n  src: url(\"./RingsideCondensedSSm-BoldItalic_Web.woff2\") format(\"woff2\"),\n    url(\"./RingsideCondensedSSm-BoldItalic_Web.woff\") format(\"woff\");\n}\n@font-face {\n  font-family: \"Ringside-Condensed-Thin\";\n  src: url(\"./RingsideCondensed-MediumItalic.woff2\") format(\"woff2\"),\n    url(\"./RingsideCondensed-MediumItalic.woff\") format(\"woff\");\n}\n@font-face {\n  font-family: \"Ringside-Condensed-Semibold\";\n  src: url(\"./RingsideCondensed-SemiboldItalic_Web.woff2\") format(\"woff2\"),\n    url(\"./RingsideCondensed-SemiboldItalic_Web.woff\") format(\"woff\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
