import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import { useParams, useLocation } from "react-router-dom";
import {
  DraftPick,
  DraftProp,
  EditDraftPropProps,
  MultiSelectItem,
  Tag,
} from "../../../types";
import { Formik } from "formik";
import * as Yup from "yup";

import { pickupErrorHandlerWeb } from "../../../helpers/pickupErrorHandlerWeb";
import GlobalContext from "../../../components/GlobalContext";
import useLeaguesApi from "../../../services/api/League";
import useTagsApi from "../../../services/api/Tags";
import usePropsApi from "../../../services/api/Props";
import usePicksApi from "../../../services/api/Picks";
import { useSnackbar } from "notistack";
import { PropCreateEditForm } from "../../../components/Props/PropCreateEditForm";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  useTheme,
} from "@chakra-ui/react";
import Loader from "../../../components/Loader";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
    maxWidth: "900px",
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  breadCrumbs: {
    fontSize: 15,
    fontFamily: theme.typography.fontFamilies.body,
    fontWeight: "normal",
  },
}));

const PropsEditIndex: React.FC = () => {
  const { propId } = useParams<{ propId: string }>();
  const location = useLocation();
  const [draftProp, setDraftProp] = useState<DraftProp>(
    location.state ? (location.state as EditDraftPropProps).prop : null
  );
  const [draftPicks, setDraftPicks] = useState<Array<DraftPick>>([]);
  const [draftPickDisplayTitle, setDisplayTitle] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [draftPickTitles, setTitles] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [draftPickTags, setDraftPickTags] = useState<Tag[][]>([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const { currentUser, publisher } = useContext(GlobalContext);
  const classes = useStyles();
  const [reset] = useState(false);
  const [leagues, setLeagues] = useState<Array<MultiSelectItem>>();
  const [tags, setTags] = useState<Array<MultiSelectItem>>();
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const propsApi = usePropsApi();
  const leaguesApi = useLeaguesApi();
  const tagsApi = useTagsApi();
  const picksApi = usePicksApi();
  const theme = useTheme();

  const triggerPickUpdate = async () => {
    await fetchPicks();
  };

  const fetchProp = async () => {
    if (draftProp == null) {
      try {
        setDraftProp(await propsApi.getProp(propId));
      } catch (error) {
        if (error.response.status !== 403) {
          pickupErrorHandlerWeb(error);
          enqueueSnackbar("Could not load prop", { variant: "error" });
        }
      }
    }
  };

  const prunePicks = (pickList, index) => {
    if (pickList.length == 1) {
      pickList = [];
    } else {
      pickList.splice(index, 1);
    }

    return pickList;
  };

  const removePickHandler = async (index: number, pick: DraftPick) => {
    if (pick && pick.created_at) {
      try {
        await picksApi.deleteDraftPick(pick.id, draftProp.id);
      } catch (e) {
        pickupErrorHandlerWeb(e);
        enqueueSnackbar("Could not remove pick. Please try again later", {
          variant: "error",
        });

        return false;
      }
    }
    await refreshPicks(prunePicks([...draftPicks], index));
    return true;
  };

  /**
   * Same routine used to initially populate picks also used to update state when pick gets removed.
   * @param picks
   */
  const refreshPicks = async (picks: DraftPick[]) => {
    const titles = ["", "", "", "", "", "", "", ""];
    const displayTitles = ["", "", "", "", "", "", "", ""];
    const tags = [[], [], [], [], [], [], [], []];

    picks.map((row, index) => {
      if (row.title) {
        titles[index] = row.title;
      }
      if (row.display_title) {
        displayTitles[index] = row.display_title;
      }
      if (row.selected_tags) {
        tags[index] = row.selected_tags;
      }
    });

    setDisplayTitle(displayTitles);
    setTitles(titles);
    setDraftPickTags(tags);
    setDraftPicks(picks);
  };

  const fetchPicks = async () => {
    try {
      // axiosPrivatev1.interceptors.request.use((config) => {
      //   config.headers["Authorization"] = `Bearer ${auth}`;
      //   return config;
      // });
      const picks = await picksApi.getPicksByPropId(propId);
      await refreshPicks(picks);
    } catch (error) {
      if (error.response.status !== 403) {
        pickupErrorHandlerWeb(error);
        enqueueSnackbar("Could not load picks, please try again later", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    const initialLoadPropAndPicks = async () => {
      if (draftProp == null) {
        await fetchProp();
      }
      if (draftPicks == null || draftPicks.length < 1) {
        await fetchPicks();
      }
    };

    const fetchLeagues = async () => {
      try {
        setLeagues(await leaguesApi.getLeaguesAsMultiSelect());
      } catch (error) {
        pickupErrorHandlerWeb(error);
      }
    };

    const fetchTags = async () => {
      try {
        setTags(await tagsApi.getTagsAsMultiSelect());
      } catch (error) {
        pickupErrorHandlerWeb(error);
      }
    };
    if (loading) {
      Promise.all([fetchLeagues(), fetchTags(), initialLoadPropAndPicks()]);
    }
  }, []);

  useEffect(() => {
    if (tags && leagues && draftPicks.length > 0 && draftProp && loading) {
      setLoading(false);
    }
  }, [tags, leagues, draftPicks, draftProp]);

  /**
   * Bring updated list of picks in
   * @param newList
   */
  const updateDraftPicks = (newList: DraftPick[]) => {
    setDraftPicks(newList);
  };

  const handleSubmit = async (values) => {
    if (values.leagues.length < 1) {
      enqueueSnackbar("Select at least one league", { variant: "error" });
      return false;
    }
    let updatedProp;
    try {
      updatedProp = await propsApi.updateDraftProp(currentUser, {
        id: propId,
        close_at: values.close_date ? values.close_date : null,
        proposition: values.prop_title,
        selected_leagues: values.leagues,
        league: leagues
          .filter((l) => l.id == values.leagues[0])[0]
          .name.toLowerCase(),
        selected_tags: values.tags,
        status: currentUser.permissions.self_serve_props_access,
      });
    } catch (e) {
      // show error
      enqueueSnackbar(e.response.data, { variant: "error" });
      return false;
    }

    if (updatedProp) {
      try {
        updatedProp.picks = await picksApi.processDraftPicksUpdate(
          draftPicks,
          propId,
          currentUser,
          8,
          values.pick_display_titles,
          values.pick_titles,
          values.pick_tags
        );
      } catch (e) {
        // show error
        enqueueSnackbar(e.response.data.error, { variant: "error" });
        return false;
      }
    }

    enqueueSnackbar("Prop updated successfully!", { variant: "success" });
    return updatedProp;
  };

  const initEditView = () => {
    window.location.reload();
  };

  if (loading) return <Loader />;

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          prop_title: (draftProp && draftProp.proposition) || "",
          leagues: (draftProp && draftProp.leagues) || [],
          close_date: (draftProp && draftProp.close_at) || null,
          tags: (draftProp && draftProp.selected_tags) || [],
          pick_display_titles: draftPickDisplayTitle,
          pick_titles: draftPickTitles,
          pick_tags: draftPickTags,
        }}
        validationSchema={Yup.object().shape({
          prop_title: Yup.string()
            .max(75, "character limit 75")
            .required("Prop name is required!"),
          leagues: Yup.array().required("At least one league is required"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await handleSubmit(values)
            .then((resp) => {
              // setLoading(true);
              fetchProp().then(() => {
                fetchPicks();
              });

              setSubmitting(false);
              // resetForm();
              // initEditView();
            })
            .catch((e) => {
              console.log(e);
            });
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
        }) => (
          <>
            <div className={classes.nav}>
              <Breadcrumb className={classes.breadCrumbs}>
                <BreadcrumbItem>
                  <BreadcrumbLink href="/" color={theme.colors.purple.base}>
                    Tools
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink
                    href="/props/index"
                    color={theme.colors.purple.base}
                  >
                    Props
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink color={theme.colors.grey.dark}>
                    {location.pathname.split("/").pop()}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
            <Divider orientation="horizontal" />
            <PropCreateEditForm
              isSubmitting={isSubmitting}
              values={values}
              handleChange={handleChange}
              errors={errors}
              leagues={leagues}
              tags={tags}
              reset={reset}
              touched={touched}
              setFieldTouched={setFieldTouched}
              picks={draftPicks}
              loading={loading}
              setFieldValue={setFieldValue}
              draftProp={draftProp}
              draftPickUpdateHandler={updateDraftPicks}
              triggerPickUpdate={triggerPickUpdate}
              removePickHandler={removePickHandler}
              setActive={setActive}
              active={active}
              publisher={publisher}
            />
          </>
        )}
      </Formik>
      <Divider orientation="horizontal" />
    </div>
  );
};
export default PropsEditIndex;
