import React, { ChangeEventHandler, EventHandler } from "react";
import { MultiSelectItem } from "../../types";
import { TextInputGroup } from "../FormElements/TextInputGroup";
import { FormikErrors, FormikValues } from "formik";
import { BorderedBox } from "../FormElements/BorderedBox";
import { Button, FormControl, Text } from "@chakra-ui/react";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { toNumber } from "lodash";
import { ToolTipButton } from "../FormElements/ToolTipButton";
import { TagsMultiselectSet } from "../FormElements/Fieldsets/TagsMultiselectSet";
import { FontAwesome } from "../common/icon";
import { GeneratedTagsList } from "./GeneratedTagsList";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  formComponent: {
    marginBottom: theme.spacing.base * 5,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      marginRight: theme.spacing.base * 4,
      minHeight: 0,
      width: 450,
    },
  },
}));

export interface PickInputGroupProps {
  onChange: ChangeEventHandler;
  errors: FormikErrors<FormikValues>;
  tags: Array<MultiSelectItem>;
  reset: boolean;
  removePickHandler: EventHandler<never>;
  pickId: string;
  values: FormikValues;
  setActive: (boolean: boolean) => void;
  suggestedPickTags: Array<string>;
}

export const PickInputGroup: React.FC<PickInputGroupProps> = ({
  onChange,
  errors,
  reset,
  values,
  removePickHandler,
  pickId,
  setActive,
  suggestedPickTags,
}) => {
  const classes = useStyles();
  const [selectedSuggestedTag, setSelectedSuggestedTag] = React.useState("");

  const addSuggestedPickTag = (e) => {
    e.preventDefault();
    const tag = e.target.value;

    setSelectedSuggestedTag(tag);
    //remove tag
    const index = suggestedPickTags.indexOf(tag);
    if (index !== -1) {
      suggestedPickTags.splice(index, 1);
    }
  };

  return (
    <BorderedBox title={"Pick " + (toNumber(pickId) + 1)}>
      <div>
        <div>
          <FormControl className={classes.formComponent}>
            <TextInputGroup
              label={"Pick Display Title"}
              id={"pick_display_titles[" + pickId + "]"}
              value={values.pick_display_titles[pickId]}
              onChange={onChange}
              maxLength={50}
              errors={errors.display_title}
              placeholder={"Pick Display Title"}
              setActive={setActive}
              isRequired={true}
            ></TextInputGroup>
            <Text mx={16}>
              {`${values.pick_display_titles[pickId]?.length}/50 Characters`}
            </Text>
            <ToolTipButton>
              Pick title is what will display on the pick button for the fan
              (see prop preview above). Typically an answer to the Prop Title.
              Example: &quot;Arizona Cardinals&quot;.
            </ToolTipButton>
          </FormControl>
          <FormControl className={classes.formComponent}>
            <TextInputGroup
              label={"Statement"}
              id={"pick_titles[" + pickId + "]"}
              value={values.pick_titles[pickId]}
              onChange={onChange}
              errors={errors.title}
              placeholder={"Pick Statement"}
              setActive={setActive}
            ></TextInputGroup>
            <ToolTipButton>
              Pick Statement is what the fan pick signifies. Example:
              &quot;Arizona Cardinals will win the Super Bowl&quot;.
            </ToolTipButton>
          </FormControl>
          <FormControl className={classes.formComponent}>
            {suggestedPickTags.length > 0 ? (
              <GeneratedTagsList
                text="Generated tags click to add"
                suggestedTags={suggestedPickTags}
                onClick={addSuggestedPickTag}
              />
            ) : null}
            <TagsMultiselectSet
              id={"pick_tags[" + pickId + "]"}
              value={values.pick_tags[pickId]}
              label="Tags"
              isRequired={false}
              onChange={onChange}
              errors={""}
              placeholder="Search tags for pick..."
              createNew={true}
              touched={null}
              setActive={setActive}
              reset={reset}
              externalTag={selectedSuggestedTag}
            />
            <ToolTipButton>
              Tags on a pick should be directly related to the pick itself. This
              may include a team, athlete, etc.
            </ToolTipButton>
          </FormControl>
        </div>
        <Button
          variant="alert"
          id={pickId}
          size={"sm"}
          leftIcon={<FontAwesome icon={faCircleXmark} />}
          onClick={removePickHandler}
        >
          Remove Pick
        </Button>
      </div>
    </BorderedBox>
  );
};
