import useJwt from "../../hooks/useJwt";
import {AxiosResponse} from "axios";
import {pickupErrorHandlerWeb} from "../../helpers/pickupErrorHandlerWeb";
import axios from "axios/index";
import {AuthenticationResponse} from "../../../server/models/user.model";

const useAuthApi = () => {
  const {privateAxiosV2, privateAxiosV1} = useJwt();

  const getQrCode = async (id: string): Promise<any> => {
    const response = await privateAxiosV2.get(`/auth/mfa/code`)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err) => {
        pickupErrorHandlerWeb(err);
      });
    return response;
  };

  const login = async(email: string, password: string, adminToken: string, mfaResponse?: string): Promise<AuthenticationResponse> => {
    return await privateAxiosV1
      .post( `/users/login`,
        {
          email: email,
          password: password,
          answer: mfaResponse
        },
        {
          headers: { token: adminToken },
        }
      )
      .then(async (response) => {
        return response.data;
      }).catch((err) => {
        return err;
      });
  }

  const verifyQrCodeFirstTime = async (id: string, answer: string): Promise<any> => {
    return await privateAxiosV2.post(
      '/auth/mfa/add',
      {
        id,
        token: answer
      }
    ).then((res) => {
      return res.data;
    }).catch((err) => {
      pickupErrorHandlerWeb(err);
      return false;
    });
  }

  const resetMfa = async (id: string): Promise<any> => {
    return await privateAxiosV2.delete(
      '/auth/mfa',

    ).then((res) => {
      return true;
    }).catch((err) => {
      pickupErrorHandlerWeb(err);
      return false;
    });
  }

  const profile = async (): Promise<any> => {
    return await privateAxiosV1.get(
      '/users/profile',

    ).then((res) => {
      return res.data;
    }).catch((err) => {
      pickupErrorHandlerWeb(err);
      return false;
    });

  }

  return {
    getQrCode,
    verifyQrCodeFirstTime,
    resetMfa,
    profile,
    login
  }

};

export default useAuthApi;