import React from "react";
import { createUseStyles } from "react-jss";
import fanpowerlogo from "./fanpower.png";

const useStyles = createUseStyles({
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    position: "absolute",
    width: "80px",
    height: "80px",
  },
  loader: {
    border: "4px solid #f3f3f3",
    borderRadius: "50%",
    borderTop: "4px solid #8653FF",
    width: "140px",
    height: "140px",
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img className={classes.image} src={fanpowerlogo} alt="fanpower logo" />
      <div className={classes.loader}></div>
    </div>
  );
};

export default Loader;
