import React from "react";
import {
  Box,
  PlacementWithLogical,
  Tag,
  Tooltip,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

export interface ToolTipProp {
  children: string;
  title?: string;
  variant?: string;
  colorScheme?: string;
  isOpen?: boolean;
  placement?: PlacementWithLogical;
}

export const ToolTipButton: React.FC<ToolTipProp> = ({
  children,
  title = "Tool Tip",
  variant,
  colorScheme = "purple",
  isOpen,
  placement = "right-start",
}) => {
  return (
    <Wrap>
      <WrapItem>
        <Tooltip label={children} isOpen={isOpen} placement={placement}>
          <Box>
            <Tag variant={variant} colorScheme={colorScheme}>
              {title}
            </Tag>
          </Box>
        </Tooltip>
      </WrapItem>
    </Wrap>
  );
};
