import React from "react";
import { Box, Button, Flex, HStack, Spacer } from "@chakra-ui/react";

export interface PaginatorProps {
  page: number;
  pageLimit: number;
  isPrevNext?: boolean;
  totalItems: number;
  onPaginate: (page: number) => void;
}
const MAX_BUTTONS = 5;
const Paginator: React.FC<PaginatorProps> = ({
  page,
  pageLimit,
  onPaginate,
  isPrevNext,
  totalItems,
}) => {
  const totalPages = Math.ceil(totalItems / pageLimit);
  const handleOnPaginate = (pageNumber: number) => {
    onPaginate(pageNumber);
  };

  const overFlow = () => <Flex alignItems="end">...</Flex>;
  const pageButton = (pageNumber: number) => {
    return (
      <Button
        key={pageNumber}
        variant={pageNumber === page ? "solid" : "outline"}
        size="sm"
        onClick={() => {
          handleOnPaginate(pageNumber);
        }}
      >
        {pageNumber}
      </Button>
    );
  };

  const getButtons = (pages: number[]) => {
    if (page <= MAX_BUTTONS || totalPages == MAX_BUTTONS + 1) {
      return pages.slice(1, MAX_BUTTONS + 1).map((pgNum) => pageButton(pgNum));
    }
    if (page > pages.length - MAX_BUTTONS) {
      return pages
        .slice(pages.length - MAX_BUTTONS - 1, pages.length - 1)
        .map((pgNum) => pageButton(pgNum));
    }

    // It doesn't make much sense to have an even number for MAX_BUTTONS, so we force the padding to offset the start
    // and end index by equal amounts.
    const pad =
      (MAX_BUTTONS - 1) % 2 === 0 ? (MAX_BUTTONS - 1) / 2 : MAX_BUTTONS / 2;

    return pages
      .slice(page - pad - 1, page + pad)
      .map((pgNum) => pageButton(pgNum));
  };

  const pagingButtons = () => {
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    return (
      <HStack spacing={1}>
        {pageButton(1)}
        {page > MAX_BUTTONS && MAX_BUTTONS + 1 < totalPages ? overFlow() : null}
        {getButtons(pages)}
        {page <= totalPages - MAX_BUTTONS && MAX_BUTTONS + 1 < totalPages
          ? overFlow()
          : null}
        {MAX_BUTTONS + 1 < totalPages ? pageButton(totalPages) : null}
      </HStack>
    );
  };

  const pagingPreviousNext = () => {
    return (
      <HStack spacing={1}>
        {pageButton(1)}
        {totalPages === 3 ? pageButton(2) : null}
        {totalPages < 4 ? null : (
          <Button
            disabled={page === 1}
            variant="outline"
            size="xs"
            onClick={() => {
              handleOnPaginate(page - 1);
            }}
          >
            Prev
          </Button>
        )}
        {totalPages < 4 ? null : (
          <Button
            disabled={page === totalPages}
            variant="outline"
            size="xs"
            onClick={() => {
              handleOnPaginate(page + 1);
            }}
          >
            Next
          </Button>
        )}
        {totalPages > 1 ? pageButton(totalPages) : null}
      </HStack>
    );
  };

  return (
    <Flex px={4} pt={4}>
      <Box color="gray">
        {`Showing ${(page * pageLimit - pageLimit + 1).toLocaleString()} - ${
          page * pageLimit > totalItems
            ? totalItems.toLocaleString()
            : (page * pageLimit).toLocaleString()
        }
           of ${totalItems.toLocaleString()} items`}
      </Box>
      <Spacer />
      {pageLimit < totalItems ? (
        <Box>{isPrevNext ? pagingPreviousNext() : pagingButtons()}</Box>
      ) : null}
    </Flex>
  );
};
export default Paginator;
