import React, { useState, useEffect, useContext } from "react";
import { DefaultTheme } from "../../types/theme";
import {
  Flex,
  Spacer,
  Input,
  InputGroup,
  InputLeftAddon,
  Box,
  Divider,
  useTheme,
} from "@chakra-ui/react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useAiApi from "../../services/api/Ai";
import ListTable from "../../components/common/Layouts/ListTable";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { AiJob } from "../../types";
import DropDown from "../../components/FilterDropDown";
import { socket } from "../../helpers/sockets";
import GlobalContext from "../../components/GlobalContext";
import Loader from "../../components/Loader";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../../components/common/icon";

const ContentIndex: React.FC = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [editorFilter, setEditorFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const { publisher } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const aiApi = useAiApi();
  const theme = useTheme<DefaultTheme>();

  const fetchJobs = async () => {
    return aiApi
      .publisherJobs()
      .then((res: AiJob[]) => {
        setJobs(res);
        setFilteredJobs(res);
        setLoading(false);
        return res;
      })
      .catch((err) => console.error(err));
  };

  const viewHandler = (item: any) => {
    navigate("/ai-content/" + item.id + "/view", { state: item });
  };

  const deleteDialogOnConfirm = async (item: any) => {
    try {
      await aiApi.deleteJob(item.id);
      const updatedJobs = jobs.filter((job) => {
        if (job.id != item.id) {
          return job;
        }
      });
      const updatedFilterJobs = filteredJobs.filter((job) => {
        if (job.id != item.id) {
          return job;
        }
      });
      setJobs(updatedJobs);
      setFilteredJobs(updatedFilterJobs);

      enqueueSnackbar("Job successfully deleted.", { variant: "success" });
    } catch (e) {
      pickupErrorHandlerWeb(e);
      enqueueSnackbar("Could not delete Job, try again later", {
        variant: "error",
      });
    }
  };

  const handleFilter = (
    editorFilter: string,
    typeFilter: string,
    search: string,
    jobs: AiJob[]
  ) => {
    let filtered = jobs;

    if (editorFilter.length) {
      filtered = filtered.filter(
        (j) => j.editor.toLowerCase() == editorFilter.toLowerCase()
      );
    }
    if (search.trim().length) {
      filtered = filtered.filter((j) =>
        j.title.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (typeFilter.length) {
      filtered = filtered.filter(
        (j) => j.type.toLowerCase() == typeFilter.toLowerCase()
      );
    }

    return filtered;
  };

  useEffect(() => {
    if (editorFilter.length || search.trim().length || typeFilter.length) {
      const filtered = handleFilter(editorFilter, typeFilter, search, jobs);
      setFilteredJobs(filtered);
    } else {
      setFilteredJobs(jobs);
    }
  }, [search, editorFilter, typeFilter]);

  //useEffect socket handler
  useEffect(() => {
    if (publisher) {
      socket.connect();
    }
    return () => {
      publisher ? socket.removeListener(String(publisher.id)) : null;
      socket.disconnect();
    };
  }, [publisher]);

  useEffect(() => {
    if (publisher) {
      fetchJobs().then((res: AiJob[]) => {
        socket.on(String(publisher.id), async (data) => {
          for (const job of res) {
            if (job.uuid == data.uuid) {
              job.status = data.status;
              enqueueSnackbar(`Job ${job.title} updated to ${data.status}`, {
                variant: "success",
              });
            }
          }

          setJobs(res);
          setFilteredJobs(res);
        });
      });
    }
  }, [publisher]);

  return (
    <Box w="full">
      <Flex paddingBottom={theme.spacing.base * 2}>
        <BreadcrumbsBlock
          links={[
            { title: "Content", isCurrent: false },
            { title: "Archive", isCurrent: true },
          ]}
        />
      </Flex>
      <Flex flexDirection={"column"}>
        <Box>
          <InputGroup>
            <InputLeftAddon>Find Job</InputLeftAddon>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={"Enter search term..."}
            />
          </InputGroup>
        </Box>
        <Spacer />
        <Box display={"flex"}>
          <DropDown
            options={jobs
              .map((job) => job.type)
              .filter((value, i, self) => self.indexOf(value) === i)}
            setValue={setTypeFilter}
            overrides={{ placeHolder: "type" }}
          />
          <DropDown
            options={jobs
              .map((job) => job.editor)
              .filter((value, i, self) => self.indexOf(value) === i)}
            setValue={setEditorFilter}
            overrides={{ placeHolder: "editor" }}
          />
        </Box>
      </Flex>
      <Divider orientation="horizontal" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <ListTable
            heading={"Content Archive"}
            headers={[
              { title: "Action" },
              { title: "Title" },
              { title: "Editor" },
              { title: "Last Updated" },
              { title: "Type" },
              { title: "Status" },
            ]}
            list={filteredJobs}
            columns={[
              { name: "title", type: "string" },
              { name: "editor", type: "string" },
              { name: "updated_at", type: "date" },
              { name: "type", type: "badge", color: "aiChip" },
              { name: "status", type: "badge", color: "aiChip" },
            ]}
            onDelete={deleteDialogOnConfirm}
            alertTitle="Delete this Job?"
            actions={[
              {
                name: "View",
                actionHandler: viewHandler,
                icon: <FontAwesome icon={faPenToSquare} />,
              },
              {
                name: "Delete",
                actionHandler: null,
                icon: <FontAwesome icon={faTrash} />,
              },
            ]}
          />
        </>
      )}
    </Box>
  );
};

export default ContentIndex;
