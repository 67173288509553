import React, { ChangeEventHandler, useState } from "react";
import { DraftPick, MultiSelectItem } from "../../types";
import { PickInputGroup } from "./PickInputGroup";
import { FormikErrors, FormikValues } from "formik";
import { Button } from "@chakra-ui/react";
import { DeleteAlertDialog } from "../common/deleteAlertDialog";
import { useSnackbar } from "notistack";
import Loader from "../Loader";

export interface PropPicksInputProps {
  draftPicks: DraftPick[];
  onChange: ChangeEventHandler;
  errors: FormikErrors<FormikValues>;
  values: FormikValues;
  tags: Array<MultiSelectItem>;
  reset: boolean;
  loading: boolean;
  draftPickUpdateHandler: (picks: DraftPick[]) => void;
  setActive: (boolean: boolean) => void;
  removePickHandler: (index: number, removedPick: DraftPick | null) => void;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean
  ) => void;
  suggestedPickTags: Array<string[]>;
}

export const PickGroupList: React.FC<PropPicksInputProps> = ({
  draftPicks,
  onChange,
  errors,
  tags,
  reset,
  values,
  loading,
  draftPickUpdateHandler,
  setActive,
  removePickHandler,
  setFieldValue,
  suggestedPickTags,
}) => {
  const getEmptyPick = (id): DraftPick => {
    return {
      selected_tags: [],
      display_title: "",
      id: id,
      prop_id: "",
      state: null,
      title: "",
    };
  };

  const { enqueueSnackbar } = useSnackbar();
  const [selectedIndex, setSelectedIndex] = useState<number>(null);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const dialogCloseHandler = () => {
    setSelectedIndex(null);
    setDialogIsOpen(false);
  };

  const confirmDeleteHandler = async () => {
    const index = selectedIndex;
    const found = draftPicks[index];

    await removePickHandler(index, found);
    prunePickValues(index);
    enqueueSnackbar("Pick " + found ? "Deleted" : "Removed", {
      variant: "success",
    });

    dialogCloseHandler();
  };

  const prunePickValues = (index: number) => {
    setActive(true);
    const pickDisplayTitles = [...values.pick_display_titles];
    pickDisplayTitles.splice(index, 1);
    pickDisplayTitles.push("");
    setFieldValue("pick_display_titles", pickDisplayTitles);
    const pickTitles = [...values.pick_titles];
    pickTitles.splice(index, 1);
    pickTitles.push("");
    setFieldValue("pick_titles", pickTitles);
    const pickTags = [...values.pick_tags];
    pickTags.splice(index, 1);
    pickTags.push([]);
    setFieldValue("pick_tags", pickTags);

    setTimeout(() => setActive(false), 1000);
  };

  const removeClickHandler = async (event) => {
    const targetId = event.target.id;

    setSelectedIndex(targetId);
    setDialogIsOpen(true);
  };

  const addClickHandler = () => {
    const pickList = [...draftPicks];
    if (pickList.length >= 10) {
      return;
    }
    pickList.push(getEmptyPick(pickList.length + 1));
    draftPickUpdateHandler(pickList);
  };

  if (loading) return <Loader />;

  return (
    <div>
      {draftPicks.map((pick, index) => (
        <PickInputGroup
          key={index}
          onChange={onChange}
          errors={errors}
          tags={tags}
          reset={reset}
          removePickHandler={removeClickHandler}
          pickId={index.toString()}
          values={values}
          setActive={setActive}
          suggestedPickTags={
            suggestedPickTags[index] ? suggestedPickTags[index] : []
          }
        ></PickInputGroup>
      ))}

      {draftPicks.length < 10 && (
        <Button variant={"ghost"} onClick={addClickHandler} size={"sm"}>
          Add Pick +
        </Button>
      )}
      <DeleteAlertDialog
        isOpen={dialogIsOpen}
        onClose={dialogCloseHandler}
        alertTitle={"Delete this pick?"}
        objectId={null}
        confirmHandler={confirmDeleteHandler}
        warnText={null}
      />
    </div>
  );
};
