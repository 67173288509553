import React from "react";
import { SnackbarProvider } from "notistack";
import { GlobalProvider } from "./components/GlobalContext";
import "./fonts/fonts.css";
import App from "./App";
import { JwtProvider } from "./components/JwtContext";
import { AppThemeProvider } from "./providers/ThemeProvider";

// TEMP FIX TO IMPORT UI THEME
// eslint-disable-next-line @typescript-eslint/no-explicit-any

const Main: React.FC = () => {
  return (
    <>
      <AppThemeProvider>
        <JwtProvider>
          <GlobalProvider>
            <SnackbarProvider
              maxSnack={4}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <App />
            </SnackbarProvider>
          </GlobalProvider>
        </JwtProvider>
      </AppThemeProvider>
    </>
  );
};

export default Main;
