import { InputGroup, Input, InputRightElement, Button } from "@chakra-ui/react";
import React from "react";
import { DefaultTheme } from "../../types/theme";

interface PropSearchProps {
  value: string;
  onChange: (value: string) => void;
  onClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
  defaultTheme: DefaultTheme;
}

const PropSearch = ({ value, onChange, onClick }: PropSearchProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onClick(event);
    }
  };

  return (
    <InputGroup>
      <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <InputRightElement>
        <Button size="sm" mr={2} onClick={onClick}>
          Search
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default PropSearch;
