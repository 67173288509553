import React from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { Flex, Box, useTheme, Heading, Text, Link } from "@chakra-ui/react";

const colors = {
  completed: {
    foreground: "#3CC13B",
    background: "#EBF9EB",
  },
  failed: {
    foreground: "#B67F15",
    background: "#FEF6E8",
  },
  started: {
    foreground: "#7A60FF",
    background: "#EBEFFF",
  },
  upcoming: {
    foreground: "#91979D",
    background: "#F9F9FA",
  },
};

interface JobTrackerProps {
  status: string;
  statuses: any[];
}

const useStyles = createUseStyles(() => ({
  jobProgress: {
    height: 40,
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    fontSize: 12,
  },
}));

const JobTracker: React.FC<JobTrackerProps> = ({ statuses, status }) => {
  const theme = useTheme<DefaultTheme>();
  const classes = useStyles({ theme });

  const determineColor = (statuses: any[], status: string, index: number) => {
    const check = status.split(" ");
    if (check[0] == statuses[index].status && check[1] == "started") {
      return "started";
    } else if (statuses.findIndex((item) => item.status == check[0]) > index) {
      return "completed";
    } else if (check[0] == statuses[index].status && check[1] == "failed") {
      return "failed";
    } else return "upcoming";
  };

  return (
    <>
      <Flex flexDirection={"row"}>
        <Box
          className={classes.jobProgress}
          style={{
            borderRadius: `10px 0 0 10px`,
            backgroundColor:
              colors[determineColor(statuses, status, 0)].background,
            border: `1px solid ${
              colors[determineColor(statuses, status, 0)].foreground
            }`,
            color: colors[determineColor(statuses, status, 0)].foreground,
            boxShadow:
              determineColor(statuses, status, 0) == "started" ||
              determineColor(statuses, status, 0) == "failed"
                ? `inset 0px 0px 5px ${
                    colors[determineColor(statuses, status, 0)].foreground
                  }`
                : "",
          }}
        >
          {statuses[0].display + " " + determineColor(statuses, status, 0)}
        </Box>

        {statuses.slice(1, statuses.length - 1).map((s, i) => {
          return (
            <Box
              key={i}
              className={classes.jobProgress}
              style={{
                backgroundColor:
                  colors[determineColor(statuses, status, Number(i) + 1)]
                    .background,
                border: `1px solid ${
                  colors[determineColor(statuses, status, Number(i) + 1)]
                    .foreground
                }`,
                color:
                  colors[determineColor(statuses, status, Number(i) + 1)]
                    .foreground,
                boxShadow:
                  determineColor(statuses, status, Number(i) + 1) ==
                    "started" ||
                  determineColor(statuses, status, Number(i) + 1) == "failed"
                    ? `inset 0px 0px 5px ${
                        colors[determineColor(statuses, status, Number(i) + 1)]
                          .foreground
                      }`
                    : "",
              }}
            >
              {s.display +
                " " +
                determineColor(statuses, status, Number(i) + 1)}
            </Box>
          );
        })}
        <Box
          className={classes.jobProgress}
          style={{
            borderRadius: `0 10px 10px 0`,
            backgroundColor:
              colors[determineColor(statuses, status, statuses.length - 1)]
                .background,
            border: `1px solid ${
              colors[determineColor(statuses, status, statuses.length - 1)]
                .foreground
            }`,
            color:
              colors[determineColor(statuses, status, statuses.length - 1)]
                .foreground,
          }}
        >
          {statuses[statuses.length - 1].display +
            " " +
            determineColor(statuses, status, statuses.length - 1)}
        </Box>
      </Flex>
      {status.toLowerCase().includes("failed") ? (
        <Box
          mt={4}
          p={4}
          maxW="800px"
          border="1px"
          borderColor="#ffc65c"
          borderRadius="4px"
          bg="#ffc65c36"
          boxShadow="md"
        >
          <Heading>Content Creation encountered error</Heading>
          <Text>
            Part of the content generation processed failed and it could not be
            completed. Retry your request or contact support.
          </Text>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default JobTracker;
