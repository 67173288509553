import React, { useState, useEffect, useContext } from "react";
import {
  Flex,
  Spacer,
  Button,
  Divider,
  useTheme,
  Tooltip,
} from "@chakra-ui/react";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import ListTable from "../../components/common/Layouts/ListTable";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";
import useContestsApi from "../../services/api/Contests";
import { useNavigate } from "react-router-dom";
import useJwt from "../../hooks/useJwt";
import { ContestRecord } from "../../types";
import GlobalContext from "../../components/GlobalContext";
import { faPlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../../components/common/icon";

const ContestIndex: React.FC = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [contests, setContests] = useState([]);
  const [filteredContests, setFilteredContests] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const contestsApi = useContestsApi();
  const navigate = useNavigate();
  const { decodeJwt } = useJwt();
  const { publishers, publisher } = useContext(GlobalContext);

  const filterByRole = (contests: ContestRecord[]) => {
    let filtered = contests;
    if (decodeJwt.user.role == "publisheradmin") {
      filtered = contests.filter(
        (c) => c.publisher_id == Number(decodeJwt.user.publisher_id)
      );
    } else if (decodeJwt.user.role == "tenantadmin") {
      const tenantPubs = publishers
        .filter((pub) => pub.tenant_id == publisher.tenant_id)
        .map((p) => p.id);
      filtered = contests.filter((c) => tenantPubs.includes(c.publisher_id));
    }
    return filtered;
  };

  const fetchContests = async () => {
    await contestsApi
      .getContests()
      .then((res: ContestRecord[]) => {
        const filteredContests = filterByRole(res);
        setContests(filteredContests);
        setFilteredContests(filteredContests);
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("Error fetching tags", { variant: "error" });
      });
  };

  const viewHandler = (item: any) => {
    navigate(`/props/contests/view/${item.id}`, { state: item });
  };

  useEffect(() => {
    if (publisher && publishers.length) fetchContests();
  }, [publisher, publishers]);

  return (
    <div>
      <Flex paddingBottom={theme.spacing.base * 2}>
        <BreadcrumbsBlock
          links={[
            { title: "Props", isCurrent: false },
            { title: "Contests", isCurrent: true },
          ]}
        ></BreadcrumbsBlock>
        <Spacer />
        <Tooltip label={"Get started by creating new contest details"}>
          <Button
            leftIcon={<FontAwesome icon={faPlus} />}
            onClick={() => navigate("/props/contests/view")}
          >
            Create New
          </Button>
        </Tooltip>
      </Flex>
      <Divider orientation="horizontal" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <ListTable
            heading={"Contests"}
            headers={[
              { title: "Action" },
              { title: "Name" },
              { title: "Description" },
              { title: "Slug" },
              { title: "Start Date" },
            ]}
            list={filteredContests}
            columns={[
              { name: "name", type: "string" },
              { name: "description", type: "string" },
              { name: "slug", type: "string" },
              { name: "start_date", type: "date" },
            ]}
            actions={[
              {
                name: "Edit",
                actionHandler: viewHandler,
                icon: <FontAwesome icon={faPenToSquare} />,
              },
            ]}
          />
        </>
      )}
    </div>
  );
};

export default ContestIndex;
