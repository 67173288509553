import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { AxiosError } from "axios";
import { CurrentUser, DraftPick, Pick } from "../../types";
import useJwt from "../../hooks/useJwt";

const NODE_API_URL_V2 = process.env.REACT_APP_NODE_API_URL_V2;

const url = "picks";

const usePicksApi = () => {
  const { privateAxiosV1, privateAxiosV2 } = useJwt();

  const createPick = async (
    user: CurrentUser,
    pick: DraftPick
  ): Promise<Pick> => {
    pick.email = user.email;
    pick.first_name = user.first_name;
    pick.last_name = user.last_name;
    pick.user_id = user.id;

    return await privateAxiosV2
      .post(`/props/${pick.prop_id}/${url}`, pick)
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        return er;
      });
  };

  const updatePick = async (
    user: CurrentUser,
    propId: string | number,
    pick: DraftPick
  ): Promise<Pick> => {
    pick.email = user.email;
    pick.first_name = user.first_name;
    pick.last_name = user.last_name;
    pick.user_id = user.id;

    return await privateAxiosV2
      .put(`/props/${propId}/${url}/${pick.id}`, pick)
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
  };

  const processDraftPicksUpdate = async (
    picks: DraftPick[],
    propId: string | number,
    user: CurrentUser,
    limit: number,
    displayTitles: string[],
    titles: string[],
    tagIds: [string[]]
  ): Promise<Pick[]> => {
    const createdPicks: Pick[] = [];
    for (let i = 0; i < limit; i++) {
      if (picks[i] && picks[i].created_at) {
        // do update
        const pickToUpdate = picks[i];
        pickToUpdate.display_title = displayTitles[i];
        pickToUpdate.title =
          titles[i].trim().length > 0 ? titles[i] : displayTitles[i];
        pickToUpdate.selected_tags = tagIds[i];
        const updatedPick = await updatePick(user, propId, pickToUpdate);
        createdPicks.push(updatedPick);
      } else {
        // do create
        if (displayTitles[i]) {
          let newPick = null;
          try {
            newPick = await createPick(user, {
              title: titles[i].trim().length > 0 ? titles[i] : displayTitles[i],
              display_title: displayTitles[i],
              selected_tags: tagIds[i],
              state: "pending",
              prop_id: propId.toString(),
            });
          } catch (e) {
            pickupErrorHandlerWeb(e);
            throw e;
          }
          if (newPick) {
            createdPicks.push(newPick);
          }
        }
      }
    }
    return createdPicks;
  };

  const getPicksByPropId = async (
    propId: string | number
  ): Promise<DraftPick[]> => {
    return privateAxiosV1
      .get(`/${url}?prop_id=${propId}`)
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
  };

  const deleteDraftPick = async (
    pickId: string | number,
    propId: string | number
  ): Promise<boolean> => {
    return privateAxiosV2
      .delete(`/props/${propId}/${url}/${pickId}/draft`)
      .then(() => {
        return true;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        return false;
      });
  };

  return {
    getPicksByPropId,
    processDraftPicksUpdate,
    deleteDraftPick,
  };
};
export default usePicksApi;
