import React, { useEffect, useState, useContext } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Heading,
  Tag,
  Divider,
  useTheme,
} from "@chakra-ui/react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { User } from "../../../server/models/user.model";
import { userRole } from "../../constants/permissions";
import { Formik, Form } from "formik";
import { faCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { EditUserProps, Publisher, SingleSelectItem } from "../../types";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { Role } from "../../../server/models/user.model";
import GlobalContext from "../../components/GlobalContext";
import { SingleSelectFieldset } from "../../components/FormElements/Fieldsets/SingleSelectFieldset";
import usePublishersApi from "../../services/api/Publishers";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import useUsersApi from "../../services/api/Users";
import useTenantsApi from "../../services/api/Tenants";
import { isString } from "lodash";
import useJwt from "../../hooks/useJwt";
import Loader from "../../components/Loader";
import { FontAwesome } from "../../components/common/icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
    fontFamily: theme.typography.fontFamilies.body,
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  breadcrumbs: {
    fontSize: 15,
  },
  desktopButtons: {
    display: "none",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      display: "block",
    },
  },
  mobileButtons: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing.base * 4,
    "& > *": {
      height: 50,
      margin: [theme.spacing.base * 2, 0],
    },
    [theme.mediaQuery(theme.breakpoints.small)]: {
      display: "none",
    },
  },
  buttonGroup: {
    justifyContent: "space-between",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      justifyContent: "right",
    },
  },
  formRow: {
    display: "flex",
    flexDirection: "column",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      flexDirection: "row",
    },
  },
}));

const CreateUser: React.FC = () => {
  const location = useLocation();
  const { decodeJwt } = useJwt();
  const publishersApi = usePublishersApi();
  const tenantsApi = useTenantsApi();
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<User>(
    location.state ? (location.state as EditUserProps).user : null
  );
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(true);
  const usersApi = useUsersApi();
  const { currentUser, publisher } = useContext(GlobalContext);
  const [allPublishers, setAllPublishers] = useState<Publisher[]>([]);
  const [showPublisher, setShowPublisher] = useState(false);
  const [editPublisher, setEditPublisher] = useState(false);
  const [showTenant, setShowTenant] = useState(false);
  const [editTenant, setEditTenant] = useState(false);
  const [allowRoleEdit, setAllowRoleEdit] = useState(false);
  const [showRoles, setShowRoles] = useState(false);
  const [roles, setRoles] = useState([]);
  const theme = useTheme();

  const [publisherOptions, setPublisherOptions] = useState<SingleSelectItem[]>(
    []
  );
  const [tenantOptions, setTenantOptions] = useState<SingleSelectItem[]>([]);

  const fetchEditUser = async (id: number | string) => {
    if (user && user.id === id) {
      return;
    }
    if (!id) {
      return;
    }
    try {
      const userResponse = await usersApi.getUser(id, currentUser.access_token);
      setUser(userResponse);
    } catch (e) {
      pickupErrorHandlerWeb(e);
      enqueueSnackbar("Could not fetch user, please try again", {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    fetchEditUser(userId).then(() => {
      setLoading(false);
    });
  }, [reset]);

  useEffect(() => {
    if (currentUser) {
      let shouldLoadTenant = false;
      switch (decodeJwt?.user.role) {
        case "tenantadmin":
          setShowTenant(true);
          setEditTenant(false);
          setShowPublisher(true);
          setEditPublisher(true);
          setShowRoles(true);
          break;
        case "publisheradmin":
          setShowPublisher(false);
          setEditPublisher(false);
          setShowTenant(false);
          setEditTenant(false);
          setShowRoles(false);
          break;
        case "fanpoweradmin":
          setShowPublisher(true);
          setEditPublisher(true);
          setShowTenant(true);
          setEditTenant(true);
          setShowRoles(true);
          shouldLoadTenant = true;
          break;
        default:
          setShowPublisher(false);
          setEditPublisher(false);
          setShowTenant(false);
          setShowRoles(false);
          setEditTenant(false);
      }
      // Edit a user.
      if (user && user.role) {
        setAllowRoleEdit(
          currentUser.permissions.rank >= userRole[user.role].rank
        );
      }
      // Create a new user.
      if (!userId) {
        setAllowRoleEdit(true);
      }

      if (shouldLoadTenant) {
        loadTenants();
      } else {
        loadPubOptions(false).then(() => {
          // do nothing
        });
      }
    }
  }, [currentUser, publisher, reset]);

  useEffect(() => {
    filterRoles();
  }, [showRoles]);

  const filterRoles = (tenant = false): void => {
    if (!showRoles) return;
    const roles = [];
    let hasTenant = !!user?.tenant;
    if (tenant) hasTenant = tenant;

    Object.keys(userRole).map((role: Role) => {
      if (currentUser.permissions.rank >= userRole[role].rank) {
        if (
          !hasTenant &&
          (decodeJwt?.user.role === "fanpoweradmin" ||
            decodeJwt?.user.role === "tenantadmin") &&
          role === "tenantadmin"
        ) {
          // dont' add
        } else {
          roles.push(role);
        }
      }
    });
    setRoles(roles);
  };

  const loadTenants = async () => {
    let tenantOpts = [];
    const tentants = await tenantsApi.getAllTenants();
    tenantOpts = tentants.map((row) => {
      return { label: row.name, value: row.id };
    });
    setTenantOptions(tenantOpts);
    await loadPubOptions(true);
  };

  const loadPubOptions = async (all: boolean) => {
    if (!publisher) {
      return;
    }

    let pubOpts = [];

    let pubs = [];

    pubs = await publishersApi.getPublishers(
      all ? null : publisher.tenant_id,
      currentUser.access_token
    );
    setAllPublishers(pubs);

    pubOpts = pubs.map((row) => {
      return { label: row.name, value: row.id };
    });
    if (!publisher.tenant_id) {
      pubOpts = pubOpts.filter((row) => {
        return !row.tenant_id;
      });
    }

    setPublisherOptions(pubOpts);
  };

  const refreshPublisherOptions = async (newTenantId: string | number) => {
    //setEditPublisher(false);
    if (!isString(newTenantId)) {
      newTenantId = newTenantId.toString();
    }

    //if (newTenantId != publisher.tenant_id) {
    const pubs = [];
    allPublishers.forEach((row) => {
      if ((!newTenantId && !row.tenant_id) || row.tenant_id == newTenantId) {
        pubs.push({ label: row.name, value: row.id });
      }
    });

    await setPublisherOptions(pubs);

    setEditPublisher(true);
    //}
  };

  const createUser = async (data): Promise<User> => {
    const user = await usersApi.createUser(data);
    const { message } = user as { message: string };
    if (message && message === "User with supplied email already exists") {
      enqueueSnackbar("User with supplied email already exists", {
        variant: "error",
      });
    } else {
      if (user) {
        const { email } = user as User;
        enqueueSnackbar("User created!", { variant: "success" });
        await usersApi.recover(
          email,
          `${window.location.origin}/login/recovery`
        );
        return user as User;
      }
    }
  };

  const editUser = async (data): Promise<User> => {
    const user = await usersApi.patchUser(data);

    if (user) {
      setUser(user);
      navigate(location.pathname, { replace: true, state: { user: user } });
      enqueueSnackbar("User updated!", { variant: "success" });
    }
    return user;
  };

  const handleSubmit = async (values) => {
    const { first_name, last_name, email, role, publisher_id, tenant_id } =
      values;
    const data = {
      ...(user && user.id && { id: user.id }),
      ...{ first_name: first_name },
      ...{ last_name: last_name },
      ...{ email: email },
      ...{ role: role },
      ...{ publisher_name: currentUser.publisher_name },
      ...{ publisher_token: currentUser.access_token }, // this isn't going to work for tenant or fp admins
      ...{ publisher_id: publisher_id },
    };
    let updatedUser: User | void;
    if (user) {
      updatedUser = await editUser(data).catch((err) => {
        enqueueSnackbar(err.response.data, { variant: "error" });
      });
    } else {
      updatedUser = await createUser(data).catch((err) => {
        enqueueSnackbar(err.response.data, { variant: "error" });
      });
    }
    if (updatedUser && tenant_id) {
      // set value on the user so the select populated correctly.
      updatedUser.tenant_id = tenant_id;
    }
    return updatedUser;
  };

  useEffect(() => {
    setLoading(false);
  }, [reset]);

  const onChange = (change) => {
    if (change.target.id === "tenant_id") {
      filterRoles(!!change.target.value);
    }
  };

  const handleResetMfa = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (userId) {
      await usersApi
        .resetUserMfa(userId)
        .then(() => {
          enqueueSnackbar("User MFA Reset", { variant: "success" });
        })
        .catch(() => {
          enqueueSnackbar("Could not reset User MFA", { variant: "error" });
        });
    }
  };

  if (loading) return <Loader />;

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          first_name: (user && user.first_name) || "",
          last_name: (user && user.last_name) || "",
          email: (user && user.email) || "",
          role: (user && user.role) || "creator",
          publisher_id: (user && user.publisher_id) || currentUser.publisher_id,
          tenant_id: (user && user.tenant_id) || "",
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string()
            .max(100, "character limit 100")
            .required("First Name is required!"),
          last_name: Yup.string()
            .max(100, "character limit 100")
            .required("Last Name is required!"),
          email: Yup.string()
            .email("Invalid email format")
            .required("Required"),
          role: Yup.string(),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await handleSubmit(values).then((updatedUser: User) => {
            if (updatedUser) {
              resetForm({
                values: {
                  first_name: updatedUser.first_name || "",
                  last_name: updatedUser.last_name || "",
                  email: updatedUser.email || "",
                  role: updatedUser.role || "creator",
                  publisher_id:
                    updatedUser.publisher_id || currentUser.publisher_id,
                  tenant_id: updatedUser.tenant_id || "",
                },
              });
              setReset(false);
            }
          });

          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          errors,
          touched,
          handleBlur,
          setFieldTouched,
        }) => (
          <Form onChange={onChange}>
            <div className={classes.nav}>
              <Breadcrumb className={classes.breadcrumbs}>
                <BreadcrumbItem color={theme.colors.primary.base}>
                  <BreadcrumbLink>Settings</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="/users">
                    Users
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink color={theme.colors.grey.dark}>
                    {user ? "Edit" : "Add"}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <div className={classes.desktopButtons}>
                <Button
                  leftIcon={<FontAwesome icon={faCheck} />}
                  type="submit"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>

                <Button
                  leftIcon={<FontAwesome icon={faCircleXmark} />}
                  variant="outline"
                  as={Link}
                  to="/users"
                >
                  Cancel
                </Button>
              </div>
            </div>
            <Divider orientation="horizontal" />
            <Heading>{user ? "User Edit" : "User Add"}</Heading>
            <div className={classes.formRow}>
              <FormControl isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  placeholder="First Name"
                  id="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  placeholder="Last Name"
                  id="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className={classes.formRow}>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  placeholder="Email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormControl>
              {showRoles ? (
                <FormControl isRequired>
                  <FormLabel>Role</FormLabel>
                  <Select
                    id="role"
                    name="role"
                    onChange={handleChange}
                    value={values.role}
                    disabled={!allowRoleEdit}
                  >
                    {roles.map((role: Role) => {
                      return (
                        <option key={role} value={role}>
                          {userRole[role].displayName}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : null}
            </div>

            <div>
              {editTenant ? (
                <SingleSelectFieldset
                  label={"Tenant"}
                  id={"tenant_id"}
                  options={tenantOptions}
                  value={values.tenant_id?.toString()}
                  onChange={async (e) => {
                    handleChange(e);
                    await refreshPublisherOptions(
                      (e.target as HTMLSelectElement).value
                    );
                  }}
                  errors={errors.tenant_id}
                  onBlur={handleBlur}
                  touched={touched.tenant_id}
                  placeholder={"No Tenant"}
                  setFieldTouched={setFieldTouched}
                />
              ) : null}

              {editPublisher && publisherOptions.length > 0 ? (
                <SingleSelectFieldset
                  label={"Publisher"}
                  id={"publisher_id"}
                  options={publisherOptions}
                  value={values.publisher_id?.toString()}
                  onChange={handleChange}
                  errors={errors.publisher_id}
                  onBlur={handleBlur}
                  touched={touched.publisher_id}
                  placeholder={"Select publisher"}
                  setFieldTouched={setFieldTouched}
                />
              ) : null}
              {showPublisher && user?.publisher ? (
                <>
                  <Tag style={{ margin: "30px" }} size={"md"} variant={"solid"}>
                    Publisher: {user.publisher}
                  </Tag>
                </>
              ) : null}
              {showTenant && user?.tenant ? (
                <>
                  <Tag
                    style={{ margin: "30px" }}
                    size={"md"}
                    variant={"solid"}
                    colorScheme={"cyan"}
                  >
                    Tenant: <strong> {user.tenant}</strong>
                  </Tag>
                </>
              ) : null}
            </div>
            {currentUser.role === "fanpoweradmin" && (
              <Button onClick={handleResetMfa}>Reset MFA</Button>
            )}
            <div className={classes.mobileButtons}>
              <Button
                leftIcon={<FontAwesome icon={faCheck} />}
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Save
              </Button>

              <Button
                leftIcon={<FontAwesome icon={faCircleXmark} />}
                variant="outline"
                as={Link}
                to="/users"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <Divider orientation="horizontal" />
    </div>
  );
};

export default CreateUser;
