import React from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { FormButtonProps } from "../../types";
import { FontAwesome } from "../common/icon";
import { faCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  desktopButtons: {
    display: "none",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      display: "block",
    },
    "& button:not(:last-child)": {
      marginRight: "15px",
    },
  },
}));

const DesktopButtons: React.FC<FormButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.desktopButtons}>
        <Button
          leftIcon={<FontAwesome icon={faCheck} />}
          type="submit"
          isDisabled={props.isSubmitting}
          isLoading={props.isSubmitting}
          disabled={props.submitDisabled}
        >
          Save
        </Button>

        <Button
          leftIcon={<FontAwesome icon={faCircleXmark} />}
          variant="outline"
          as={Link}
          to={props.cancelRoute}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DesktopButtons;
