import React, { useEffect, useState } from "react";
import {
  Button,
  ModalFooter,
  ModalBody,
  useTheme,
  Input,
  Box,
  Flex,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { DefaultTheme } from "../../types/theme";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import useContestsApi from "../../services/api/Contests";
import { useSnackbar } from "notistack";
import useFansApi from "../../services/api/Fans";
import FanSearchTable from "./FanSearchTable";
import CommonModal from "../../components/Modal";

interface ContestFanModalProps {
  isOpen: boolean;
  onClose: () => void;
  contestFans?: any[];
  setContestFans: React.Dispatch<any>;
}

const ContestFanModal: React.FC<ContestFanModalProps> = ({
  isOpen,
  onClose,
  contestFans,
  setContestFans,
}) => {
  const [search, setSearch] = useState("");
  const { id } = useParams<{ id: string }>();
  const { getFans } = useFansApi();
  const [fanSearch, setFanSearch] = useState([]);
  const contestsApi = useContestsApi();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme<DefaultTheme>();
  const [fan, setFan] = useState(null);

  useEffect(() => {
    if (search.length > 2) {
      getFans({ search })
        .then((res) => {
          setFanSearch(res.fans);
        })
        .catch((err) => {
          enqueueSnackbar("Error searching fans", { variant: "error" });
        });
    } else {
      setFanSearch([]);
    }
  }, [search]);

  const handleSubmit = async (values) => {
    return await contestsApi
      .addFan(id, values)
      .then((res) => {
        setContestFans([...contestFans, fan]);
        enqueueSnackbar("Fan added", { variant: "success" });
        return res;
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error adding fan", { variant: "error" });
      });
  };
  return (
    <CommonModal
      header={"Search Fans"}
      isOpen={isOpen}
      onClose={() => {
        setFan(null);
        onClose();
      }}
      styles={{ maxWidth: "800px" }}
    >
      <Formik
        initialValues={{
          fan_id: undefined,
        }}
        validationSchema={Yup.object().shape({
          fan_id: Yup.number()
            .required("Fan selection is required")
            .test(
              "fan-already-added",
              "Fan already added to contest",
              (value) => {
                return contestFans.find((f) => f.id == value) ? false : true;
              }
            ),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await handleSubmit(values).then((res) => {
            resetForm();
            setFan(null);
            setSearch("");
          });
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, errors, touched, setFieldValue }) => (
          <Form>
            <ModalBody>
              {fan ? (
                <FormControl
                  isRequired
                  isInvalid={touched.fan_id && !!errors.fan_id}
                >
                  <Flex justifyContent={"space-between"}>
                    <Box>{fan.username}-</Box>
                    <Box>{fan.email}</Box>
                    <Button
                      type="button"
                      size={"sm"}
                      onClick={() => setFan(null)}
                    >
                      Remove
                    </Button>
                  </Flex>
                  <FormErrorMessage>
                    {typeof errors.fan_id == "string" ? errors.fan_id : null}
                  </FormErrorMessage>
                </FormControl>
              ) : (
                <>
                  <Input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={"search for a fan"}
                  />
                  {fanSearch.length ? (
                    <FanSearchTable
                      title="Fans"
                      caption="Select a fan to add to Contest"
                      collection={fanSearch}
                      onClick={(e) => {
                        setFieldValue("fan_id", e.id);
                        setFan(e);
                      }}
                    />
                  ) : null}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  setFan(null);
                  onClose();
                }}
              >
                Close
              </Button>
              <Button ml={3} type="submit" isDisabled={isSubmitting}>
                {"Add"}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </CommonModal>
  );
};

export default ContestFanModal;
