import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { AxiosError } from "axios";

// const url = "carousels/publisher";
const NODE_API_URL_v2 = process.env.REACT_APP_NODE_API_URL_V2;

import { GetCarouselPayload, MutateCarouselPayload } from "../../types";
import useJwt from "../../hooks/useJwt";

const url = "/carousels";
const useCarouselsApi = () => {
  const { privateAxiosV2 } = useJwt();

  const createCarousel = async (
    payload: MutateCarouselPayload
  ): Promise<GetCarouselPayload> => {
    return await privateAxiosV2
      .post(`${NODE_API_URL_v2}${url}`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
  };
  const update = async (
    carouselId: number,
    payload: MutateCarouselPayload
  ): Promise<GetCarouselPayload> => {
    return await privateAxiosV2
      .patch(`${NODE_API_URL_v2}${url}/${carouselId}`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
  };

  const fetchCarousel = async (
    id: number | string
  ): Promise<GetCarouselPayload> => {
    return await privateAxiosV2
      .get(`${NODE_API_URL_v2}${url}/${id}/edit`)
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
      });
  };

  const getCarousels = async (
    publisher_id: number
  ): Promise<GetCarouselPayload[]> => {
    return privateAxiosV2
      .get(`${NODE_API_URL_v2}${url}/publisher/${publisher_id}/all`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
      });
  };

  const deleteCarousel = async (carouselId: number): Promise<boolean> => {
    return await privateAxiosV2
      .delete(`${NODE_API_URL_v2}${url}/${carouselId}`)
      .then((response) => {
        return true;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
  };

  return {
    create: createCarousel,
    fetchCarousel,
    update,
    getCarousels,
    deleteCarousel,
  };
};
export default useCarouselsApi;
