import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Notion from "../../components/common/Notion";
import { NotionApiService } from "../../services/api/Notion";
import Loader from "../../components/Loader";

const pageId = process.env.REACT_APP_TUTORIAL_PAGE;

const useStyles = createUseStyles(() => ({
  root: {
    width: "100%",
  },
}));

const Tutorials: React.FC = () => {
  const classes = useStyles();
  const [map, setMap] = useState(null);

  useEffect(() => {
    NotionApiService.getNotionPage(pageId).then((res) => {
      setMap(res.recordMap);
    });
  }, []);
  return (
    <div className={classes.root}>
      {map ? <Notion recordMap={map} /> : <Loader />}
    </div>
  );
};

export default Tutorials;
