import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";

import { appStyles } from "../../../constants/styles";
import GlobalContext from "../../GlobalContext";
import { PUGlobalContext } from "../../../types";
import { useTheme } from "@chakra-ui/react";

const useStyles = createUseStyles({
  root: ({
    theme,
    currentUser,
  }: {
    theme: DefaultTheme;
    currentUser: PUGlobalContext["currentUser"];
  }) => ({
    display: "block",
    position: "relative",
    overflow: "hidden",
    width: "100%",
    margin: 0,
    padding: `${theme.spacing.base * 8 + appStyles.headerHeight}px ${
      theme.spacing.base * 4
    }px ${theme.spacing.base * 8}px`,
    zIndex: 0,
    "& > div": {
      margin: "0 auto",
      width: "100%",
      maxWidth: 1920,
    },
    [theme.mediaQuery(theme.breakpoints.medium)]: {
      marginTop: appStyles.headerHeight + theme.spacing.base,
      marginLeft: appStyles.sidebarWidth + theme.spacing.base,
      marginRight: !currentUser
        ? appStyles.sidebarWidth + theme.spacing.base
        : 0,
      padding: theme.spacing.base * 8,
    },
  }),
});

interface PageContainerProps {
  children: React.ReactNode;
}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  const context = useContext(GlobalContext);
  const theme = useTheme<DefaultTheme>();
  const classes = useStyles({ ...context, theme });

  return <div className={classes.root}>{children}</div>;
};

export default PageContainer;
