import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  useTheme,
} from "@chakra-ui/react";
import { FormikProps } from "formik";
import useTagsApi from "../../services/api/Tags";
import useLeaguesApi from "../../services/api/League";
import usePropsApi from "../../services/api/Props";
import MultiSelect from "../../components/MultiSelect";
import EmbedContentTable, { EmbedContentItem } from "./EmbedContentTable";
import { EmbedTargetFormValues } from "./Create";
import useCarouselsApi from "../../services/api/Carousels";
import { CurrentUser } from "../../types";
import { DefaultTheme } from "../../types/theme";
import { shuffle } from "lodash";
import RenderReorderButtons from "./RenderReorderButtons";
import PropSearch from "./PropSearch";
import FlipMove from "react-flip-move";

interface RenderContentTypeProps {
  formik: FormikProps<EmbedTargetFormValues>;
  currentUser: CurrentUser;
}

const RenderContentType = ({ formik, currentUser }: RenderContentTypeProps) => {
  const [props, setProps] = useState([]);
  const [propInputValue, setPropInputValue] = useState("");
  const [selectedProp, setSelectedProp] = useState<EmbedContentItem>();
  const [selectedPropCollection, setSelectedPropCollection] = useState<
    EmbedContentItem[]
  >([]);
  const [selectedCarousel, setSelectedCarousel] = useState<EmbedContentItem>();
  const [carousels, setCarousels] = useState([]);
  const [tags, setTags] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const { getCarousels, fetchCarousel } = useCarouselsApi();
  const { getTagsAsMultiSelect } = useTagsApi();
  const { getLeaguesAsMultiSelect } = useLeaguesApi();
  const { searchProps, getProp } = usePropsApi();
  const initialRender = useRef(true);
  const theme = useTheme<DefaultTheme>();

  useEffect(() => {
    switch (formik.values.content_type) {
      case "carousel_id":
        if (!carousels.length) {
          getCarousels(currentUser.publisher_id).then((res) => {
            const mappedCarousels = res.map((carousel) => {
              return {
                id: carousel.id,
                title: carousel.name,
                created_at: carousel.created_at,
              };
            });
            setCarousels(mappedCarousels);
          });
        }
        if (formik.values.carousel_id) {
          fetchCarousel(formik.values.carousel_id).then((res) => {
            setSelectedCarousel({
              id: +res.id,
              title: res.name,
              created_at: res.created_at,
            });
          });
        }
        break;
      case "tags":
        if (!tags.length) {
          getTagsAsMultiSelect().then((res) => {
            setTags(res);
          });
        }
        break;
      case "leagues":
        if (!leagues.length) {
          getLeaguesAsMultiSelect().then((res) => {
            setLeagues(res);
          });
        }
        break;
      case "prop_id":
        if (formik.values.prop_id) {
          getProp(formik.values.prop_id).then((res) => {
            setSelectedProp({
              id: +res.id,
              title: res.proposition,
              created_at: res.created_at,
            });
          });
        }
        break;
      case "prop_collection":
        if (formik.values.prop_collection) {
          const propIds = formik.values.prop_collection.map((id) => +id);
          Promise.all(propIds.map((id) => getProp(id))).then((res) => {
            const mappedProps = res.map((prop) => {
              return {
                id: +prop.id,
                title: prop.proposition,
                created_at: prop.created_at,
              };
            });
            setSelectedPropCollection(mappedProps);
          });
        }
        break;
      default:
        null;
    }
  }, [formik.values.content_type]);

  const handleSearchProps = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const propsResponse = await searchProps({
      search: propInputValue,
      status: "pending",
      limit: 10,
      open: true,
    });
    const mappedProps = propsResponse.props.map((prop) => {
      return {
        id: prop.id,
        title: prop.proposition,
        created_at: prop.created_at,
      };
    });
    setProps(mappedProps);
  };

  const handleSelectProp = (prop: EmbedContentItem) => {
    formik.setFieldValue("prop_id", prop.id);
    setSelectedProp(prop);
    setPropInputValue("");
    setProps([]);
  };

  const handleAddPropToCollection = (prop: EmbedContentItem) => {
    if (selectedPropCollection.find((p) => p.id === prop.id)) {
      return;
    }
    if (selectedPropCollection.length < 5) {
      const newPropCollection = [...selectedPropCollection, prop];
      setSelectedPropCollection(newPropCollection);
      formik.setFieldValue(
        "prop_collection",
        newPropCollection.map((p) => p.id)
      );
      handleSortByChange(newPropCollection);
    } else {
      formik.setErrors({
        prop_collection: "You can only have up to 5 props in a collection",
      });
    }
  };

  const handleUnselectPropFromCollection = (prop: EmbedContentItem) => {
    const newPropCollection = selectedPropCollection.filter(
      (p) => p.id !== prop.id
    );
    setSelectedPropCollection(newPropCollection);
    formik.setFieldValue(
      "prop_collection",
      newPropCollection.map((p) => p.id)
    );
  };

  const handleSelectCarousel = (carousel: EmbedContentItem) => {
    formik.setFieldValue("carousel_id", carousel.id);
    setSelectedCarousel(carousel);
  };

  const handleSortByChange = (array: EmbedContentItem[]) => {
    if (formik.values.sort_by === "newest") {
      const sortedProps = array.sort((a, b) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
      setSelectedPropCollection(sortedProps);
      formik.setFieldValue(
        "prop_collection",
        sortedProps.map((p) => p.id)
      );
    }
    if (formik.values.sort_by === "oldest") {
      const sortedProps = array.sort((a, b) => {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      });
      setSelectedPropCollection(sortedProps);
      formik.setFieldValue(
        "prop_collection",
        sortedProps.map((p) => p.id)
      );
    }
    if (formik.values.sort_by === "random") {
      const shuffledProps = shuffle(array);
      setSelectedPropCollection(shuffledProps);
      formik.setFieldValue(
        "prop_collection",
        shuffledProps.map((p) => p.id)
      );
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      handleSortByChange(selectedPropCollection);
    }
  }, [formik.values.sort_by]);

  switch (formik.values.content_type) {
    case "prop_id":
      return (
        <FormControl
          isInvalid={formik.touched.prop_id && !!formik.errors.prop_id}
        >
          <FormLabel htmlFor="prop_id">Prop</FormLabel>
          <PropSearch
            value={propInputValue}
            onChange={setPropInputValue}
            onClick={handleSearchProps}
            defaultTheme={theme}
          />
          {props.length ? (
            <EmbedContentTable
              title="Prop"
              caption="Select one prop to add to your Embed Target"
              collection={props}
              onClick={handleSelectProp}
            />
          ) : null}
          {selectedProp ? (
            <Tag
              size="lg"
              variant="solid"
              background={theme.colors.primary.base}
              color={theme.colors.white}
              marginTop={4}
            >
              <TagLabel>{selectedProp.title}</TagLabel>
              <TagCloseButton
                as="button"
                onClick={() => {
                  setSelectedProp(null);
                  formik.setFieldValue("prop_id", "");
                }}
              />
            </Tag>
          ) : null}
          <FormErrorMessage>{formik.errors.prop_id}</FormErrorMessage>
        </FormControl>
      );
    case "prop_collection":
      return (
        <>
          <FormControl
            isInvalid={
              formik.touched.prop_collection && !!formik.errors.prop_collection
            }
          >
            <FormLabel htmlFor="prop_collection">Find Props</FormLabel>
            <PropSearch
              value={propInputValue}
              onChange={setPropInputValue}
              onClick={handleSearchProps}
              defaultTheme={theme}
            />
            <FormHelperText>
              Select up to 5 Props for your Prop Collection
            </FormHelperText>
            <FormErrorMessage>{formik.errors.prop_collection}</FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={formik.touched.sort_by && !!formik.errors.sort_by}
          >
            <Select
              id="sort_by"
              name="sort_by"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sort_by}
            >
              <option value="custom">Custom Order</option>
              <option value="random">Random</option>
              <option value="newest">Newest First</option>
              <option value="oldest">Oldest First</option>
            </Select>
            <FormHelperText>
              Select Custom Order to Adjust the Order Your Props Appear in the
              Embed
            </FormHelperText>
            <FormErrorMessage>{formik.errors.sort_by}</FormErrorMessage>
          </FormControl>
          {props.length ? (
            <EmbedContentTable
              title="Prop"
              caption="Select a Prop to add to your Prop Collection"
              collection={props}
              onClick={handleAddPropToCollection}
            />
          ) : null}
          <FlipMove>
            {selectedPropCollection.length
              ? selectedPropCollection.map((prop, i) => {
                  return (
                    <Box key={prop.id}>
                      <Tag
                        size="lg"
                        variant="solid"
                        background={theme.colors.primary.base}
                        color={theme.colors.white}
                        marginTop={4}
                      >
                        {formik.values.sort_by === "custom" && (
                          <RenderReorderButtons
                            name="prop_collection"
                            index={i}
                            setFieldValue={formik.setFieldValue}
                            selectedValues={selectedPropCollection}
                            setSelectedValues={setSelectedPropCollection}
                          />
                        )}
                        <TagLabel>{prop.title}</TagLabel>
                        <TagCloseButton
                          as="button"
                          onClick={() => handleUnselectPropFromCollection(prop)}
                        />
                      </Tag>
                    </Box>
                  );
                })
              : null}
          </FlipMove>
        </>
      );
    case "carousel_id":
      return (
        <FormControl
          isInvalid={formik.touched.carousel_id && !!formik.errors.carousel_id}
        >
          <FormLabel htmlFor="carousel_id">Carousel</FormLabel>
          {carousels.length && !selectedCarousel ? (
            <EmbedContentTable
              title="Carousel"
              caption="Select one Carousel to add to your Embed Target"
              collection={carousels}
              onClick={handleSelectCarousel}
            />
          ) : null}
          {selectedCarousel ? (
            <Tag
              size="lg"
              variant="solid"
              background={theme.colors.primary.base}
              color={theme.colors.white}
              marginTop={4}
            >
              <TagLabel>{selectedCarousel.title}</TagLabel>
              <TagCloseButton
                as="button"
                onClick={() => {
                  setSelectedCarousel(null);
                  formik.setFieldValue("carousel_id", "");
                }}
              />
            </Tag>
          ) : null}
          <FormErrorMessage>{formik.errors.carousel_id}</FormErrorMessage>
        </FormControl>
      );
    case "tags":
      return (
        <FormControl isInvalid={formik.touched.tags && !!formik.errors.tags}>
          {tags.length ? (
            <MultiSelect
              name="tags"
              label="Tags"
              placeholder="Search for tags..."
              items={tags}
              formik={formik}
              values={
                formik.values.tags
                  ? tags
                      .filter((t) => formik.values.tags.includes(t.id))
                      .map((t) => {
                        return { id: t.id, name: t.name };
                      })
                  : []
              }
            />
          ) : (
            <Spinner />
          )}
          <FormErrorMessage>{formik.errors.tags}</FormErrorMessage>
        </FormControl>
      );
    case "leagues":
      return (
        <FormControl
          isInvalid={formik.touched.leagues && !!formik.errors.leagues}
        >
          {leagues.length ? (
            <MultiSelect
              name="leagues"
              label="Leagues"
              placeholder="Search for leagues..."
              items={leagues}
              formik={formik}
              values={
                formik.values.leagues
                  ? leagues
                      .filter((l) => formik.values.leagues.includes(l.id))
                      .map((l) => {
                        return { id: l.id, name: l.name };
                      })
                  : []
              }
            />
          ) : (
            <Spinner />
          )}
          <FormErrorMessage>{formik.errors.leagues}</FormErrorMessage>
        </FormControl>
      );
    default:
      return null;
  }
};

export default RenderContentType;
