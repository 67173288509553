import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import CopyButton from "../../../components/common/Buttons/CopyButton";
import { EmbedBuilderValues } from "./Builder";
import Paper from "../../../components/common/Paper";
import { Heading, Textarea } from "@chakra-ui/react";
const EMBED_API_URL = process.env.REACT_APP_NODE_API_URL.replace("/v1", "");

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    flex: "0 1 100%",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: theme.spacing.base * 4,
    marginBottom: theme.spacing.base * 6,
    backgroundColor: theme.colors.white,
    "&:hover": {
      border: `1px solid ${theme.colors.purple.lightBase}`,
    },
    [theme.mediaQuery(926)]: {
      flex: "0 1 47%",
    },
    [theme.mediaQuery(1340)]: {
      flex: "0 1 28%",
    },
  },
  meta: {
    display: "flex",
    flexFlow: "row nowrap",
    position: "relative",
    width: "100%",
    marginBottom: theme.spacing.base * 4,
    color: theme.colors.grey.dark,
    "&  div": {
      position: "relative",
      paddingRight: theme.spacing.base * 3.5,
      "&:after": {
        display: "block",
        position: "absolute",
        right: 7,
        top: 3,
        height: "75%",
        width: 1,
        backgroundColor: theme.colors.grey.dark,
        content: '""',
      },
    },
    "& p": {
      display: "inline-block",
      fontSize: 10,
      [theme.mediaQuery(theme.breakpoints.medium)]: {
        fontSize: 12,
      },
    },
  },
  propIdContainer: {
    "&:after": {
      display: "none !important",
    },
  },
  propositionContainer: {
    textAlign: "left",
    width: "100%",
  },
  chipContainer: {
    display: "flex",
    width: "100%",
    position: "relative",
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: theme.spacing.base * 4,
    marginBottom: theme.spacing.base * 3,
  },
  chip: {
    flex: "0 1 auto",
    height: "auto",
    backgroundColor: theme.colors.grey.lightBase,
    marginRight: theme.spacing.base,
    marginBottom: theme.spacing.base,
    cursor: "default",
    "&:hover, &:active, &:focus": {
      backgroundColor: theme.colors.grey.lightBase,
      border: `1px solid ${theme.colors.grey.lightBase}`,
    },
  },
  closeContainer: {
    width: "100%",
    textAlign: "left",
    color: theme.colors.grey.dark,
    marginTop: theme.spacing.base,
    marginBottom: theme.spacing.base * 3,
  },
  scriptContainer: {
    display: "flex",
    position: "relative",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  wide: {
    width: "300px",
    "& h5": {
      margin: [theme.spacing.base, 0],
    },
  },
  tall: {
    height: "200px",
  },
}));
const handleCopy = (target: string) => {
  const input = document.getElementById(target) as HTMLInputElement;
  navigator.clipboard.writeText(input.value);
};

const EmbedCodes: React.FC<EmbedBuilderValues> = (props) => {
  const [jsCode, setJsCode] = useState<string>("");
  const [htmlCode, setHtmlCode] = useState<string>("");
  const [wpCode, setWpCode] = useState<string>("");

  const buildParams = (props: EmbedBuilderValues, prefix = "data-") => {
    let params = "";
    if (props.tags.length > 0) {
      params += ` ${prefix}tags="${props.tags.map((tag) => tag.id).join(",")}"`;
    }
    if (props.leagues.length > 0) {
      params += ` ${prefix}leagues="${props.leagues
        .map((league) => league.id)
        .join(",")}"`;
    }
    if (props.type == "url" && props.url) {
      params += ` ${prefix}url="${props.url}"`;
    }
    return params;
  };

  const buildJsCode = (props: EmbedBuilderValues) => {
    if (!props.user || !props.type) {
      return "";
    }
    const params = buildParams(props);
    return `<div class="pickup-js-prop-picker" ${params}></div>
       <script>window.addEventListener("load",function(){if(!document.getElementById("pickup-js-script")){var e=document.createElement("script");e.src="${EMBED_API_URL}/pickup-picker-v1.js?current="+Date.now(),e.id="pickup-js-script",window.puDataLayer=window.puDataLayer||[],function(){window.puDataLayer.push(...arguments)}("${props.user.access_token}","${props.user.id}",!1),document.body.appendChild(e)}});</script>`;
  };

  const buildHtmlCode = (props: EmbedBuilderValues) => {
    if (!props.user || !props.type) {
      return "";
    }
    const params = buildParams(props);
    return `<div class="pickup-js-prop-picker" ${params}></div>`;
  };

  const buildWpCode = (props: EmbedBuilderValues) => {
    if (!props.user || !props.type) {
      return "";
    }
    const params = buildParams(props, "");
    return `[pickup_prop ${params}]`;
  };

  useEffect(() => {
    setJsCode(buildJsCode(props));
    setHtmlCode(buildHtmlCode(props));
    setWpCode(buildWpCode(props));
  }, [props.type, props.url, props.tags, props.leagues, props.user]);

  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.root}>
        <div className={classes.wide}>
          <Heading variant="heading5">Full JavaScript Embed</Heading>
          <div className={classes.scriptContainer}>
            <Textarea
              id={"full-js-embed"}
              name={"full-js-embed"}
              defaultValue={jsCode}
              readOnly={true}
            />
            <CopyButton
              propId={"full-js-embed"}
              onClick={() => handleCopy("full-js-embed")}
            />
          </div>
        </div>
        <div className={classes.wide}>
          <Heading variant="heading5">HTML Only Embed</Heading>
          <div className={classes.scriptContainer}>
            <Textarea
              id={"html-embed"}
              name={"html-embed"}
              defaultValue={htmlCode}
              readOnly={true}
            />
            <CopyButton
              propId={"html-embed"}
              onClick={() => handleCopy("html-embed")}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default EmbedCodes;
