import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useTheme,
} from "@chakra-ui/react";
import { DefaultTheme } from "../../types/theme";
import React from "react";

export interface DeleteAlertProps {
  isOpen: boolean;
  onClose: () => void;
  alertTitle: string;
  objectId: string | number;
  confirmHandler: (objectId: number | string) => void;
  warnText: string;
}

export const DeleteAlertDialog: React.FC<DeleteAlertProps> = ({
  isOpen,
  onClose,
  alertTitle,
  objectId,
  confirmHandler,
  warnText,
}) => {
  const cancelRef = React.useRef();
  const theme = useTheme<DefaultTheme>();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {alertTitle}
          </AlertDialogHeader>

          <AlertDialogBody fontFamily={theme.typography.fontFamilies.body}>
            {warnText != null
              ? warnText
              : "Are you sure? You can not undo this action afterwards."}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button
              variant="alert"
              id="deletemodal"
              onClick={() => {
                confirmHandler(objectId);
                onClose();
              }}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
