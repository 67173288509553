import { MultiSelectItem } from "../types";

export const mapArrayToMultiselect = <T = unknown>(
  sourceArray: T[],
  nameProperty: string
): MultiSelectItem[] => {
  if (!sourceArray) return [];
  return sourceArray.map((item: T) => ({
    id: Number(item["id"]),
    name: item[nameProperty],
    created_at: item["created_at"],
  }));
};

export const mapObjectArrayToIds = <T = unknown>(
  sourceArray: T[]
): number[] => {
  if (!sourceArray || sourceArray.length < 1) {
    return [];
  }
  return sourceArray.map((item: T) => Number(item["id"]));
};
