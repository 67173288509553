import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

interface IconProps {
  icon: IconProp;
  size?: SizeProp;
}

export const FontAwesome: React.FC<IconProps> = ({ icon, size }) => {
  return <FontAwesomeIcon size={size ? size : "sm"} icon={icon} />;
};
