import React, { useState, useContext, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import { Button, Flex, Spacer, useTheme } from "@chakra-ui/react";
import {
  faPlus,
  faTrash,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import GlobalContext from "../../../components/GlobalContext";
import { pickupErrorHandlerWeb } from "../../../helpers/pickupErrorHandlerWeb";
import { useNavigate } from "react-router-dom";
import { GetCarouselPayload } from "../../../types";
import { useSnackbar } from "notistack";
import useCarouselsApi from "../../../services/api/Carousels";
import Loader from "../../../components/Loader";
import BreadcrumbsBlock from "../../../components/common/Layouts/BreadcrumbsBlock";
import ListTable from "../../../components/common/Layouts/ListTable";
import { FontAwesome } from "../../../components/common/icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
  },
}));

const CarouselsIndex: React.FC = () => {
  const { currentUser } = useContext(GlobalContext);
  const classes = useStyles();
  const [carousels, setCarousels] = useState<GetCarouselPayload[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const carouselsApi = useCarouselsApi();
  const theme = useTheme();

  const deleteDialogOnConfirm = async (item: any) => {
    try {
      try {
        await carouselsApi.deleteCarousel(item.id);
      } catch (e) {
        pickupErrorHandlerWeb(e);
        enqueueSnackbar("Could not delete carousel, try again later", {
          variant: "error",
        });
        return;
      }
      const updatedCarousels = carousels.filter(
        (carousel: GetCarouselPayload) => {
          if (carousel.id != item.id) {
            return carousel;
          }
        }
      );

      setCarousels(updatedCarousels);
      enqueueSnackbar("Carousel successfully deleted.", { variant: "success" });
    } catch (e) {
      pickupErrorHandlerWeb(e);
      enqueueSnackbar("Could not delete carousel, try again later", {
        variant: "error",
      });
    }
    return;
  };

  const fetchCarousels = async () => {
    try {
      const carousels = await carouselsApi.getCarousels(
        currentUser.publisher_id
      );
      setCarousels(carousels);
      setLoading(false);
    } catch (err) {
      pickupErrorHandlerWeb(err);
    }
  };

  const viewHandler = (item: any) => {
    navigate("/prop-carousels/" + item.id + "/edit");
  };

  useEffect(() => {
    fetchCarousels();
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <Flex paddingBottom={theme.spacing.base * 2}>
          <BreadcrumbsBlock
            links={[
              { title: "Props", isCurrent: false },
              { title: "Prop Carousels", isCurrent: true },
            ]}
          />
          <Spacer />
          <Button
            leftIcon={<FontAwesome icon={faPlus} />}
            as={Link}
            to="/prop-carousels/create"
          >
            Create New
          </Button>
        </Flex>
        {loading ? (
          <Loader />
        ) : (
          <ListTable
            heading={"Carousels"}
            headers={[
              { title: "Actions" },
              { title: "Created Date" },
              { title: "Name" },
              { title: "League" },
              { title: "Tags" },
              { title: "Private Props" },
              { title: "SDK Enabled" },
              { title: "Active" },
            ]}
            list={carousels}
            columns={[
              { name: "created_at", type: "date" },
              { name: "name", type: "string" },
              { name: "league", type: "badge", color: "aiChip" },
              { name: "tags", type: "array", field: "name" },
              { name: "private_props", type: "string" },
              { name: "sdk", type: "string" },
              { name: "active", type: "string" },
            ]}
            onDelete={deleteDialogOnConfirm}
            alertTitle="Delete this Carousel?"
            actions={[
              {
                name: "View",
                actionHandler: viewHandler,
                icon: <FontAwesome icon={faPenToSquare} />,
              },
              {
                name: "Delete",
                actionHandler: null,
                icon: <FontAwesome icon={faTrash} />,
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};
export default CarouselsIndex;
