export const toolbarItems = [
  "undo",
  "redo",
  "|",
  "link",
  "|",
  "fontFamily",
  "fontSize",
  "|",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "superscript",
  "subscript",
  "|",
  "fontColor",
  "fontBackgroundColor",
  "highlight",
  "|",
  "alignment",
  "outdent",
  "indent",
  "|",
  "specialCharacters",
  "horizontalLine",
  "|",
  "removeFormat",
];
