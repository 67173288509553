import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { userRole } from "../../constants/permissions";
import useJwt from "../../hooks/useJwt";
import { RestrictedRouteProps } from "../../types";
import GlobalContext from "../GlobalContext";

const RestrictedRoute: React.FC<RestrictedRouteProps> = ({
  userPermission,
  fallbackRoute,
  component: Comp,
}) => {
  const { currentUser } = useContext(GlobalContext);
  const { decodeJwt } = useJwt();

  return (
    <>
      {!currentUser ? (
        <Navigate to="/login" />
      ) : !userRole[decodeJwt.user.role][userPermission] ? (
        <Navigate to={fallbackRoute} />
      ) : (
        <Comp />
      )}
    </>
  );
};

export default RestrictedRoute;
