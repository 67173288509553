import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { DefaultTheme } from "../../types/theme";
import {
  Button,
  Flex,
  Box,
  Spacer,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Select,
  Divider,
  useTheme,
} from "@chakra-ui/react";
import {
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import GlobalContext from "../../components/GlobalContext";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { Ad } from "../../types";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useJwt from "../../hooks/useJwt";
import Loader from "../../components/Loader";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import ListTable from "../../components/common/Layouts/ListTable";
import { FontAwesome } from "../../components/common/icon";

const FETCH_LIMIT = 2000;
// const PAGE_LIMIT = 10; // Max items displayed per page

const Ads: React.FC = () => {
  const [ads, setAds] = useState<Ad[]>([]);
  const [filteredAds, setFilteredAds] = useState<Ad[]>([]);
  const [search, setSearch] = useState("");
  const { currentUser } = useContext(GlobalContext);
  const { privateAxiosV1 } = useJwt();
  const theme = useTheme<DefaultTheme>();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [filterStatus, setFilterStatus] = useState("");

  const editAd = (ad: Ad) => {
    navigate("/ads/edit", { state: { ad } });
  };

  const deleteAd = async (item: any) => {
    try {
      const ad = await privateAxiosV1
        .delete(`/ads`, {
          params: { id: item.id },
        })
        .catch((err) => {
          throw err;
        });
      if (!ad.data) {
        throw new Error("Error deleting ad");
      }
      getAds()
        .then(() =>
          enqueueSnackbar("Successfully deleted Ad", {
            variant: "success",
          })
        )
        .catch((err) => {
          throw err;
        });
    } catch (err) {
      enqueueSnackbar("Ad could not be deleted", { variant: "error" });
      pickupErrorHandlerWeb(err);
    }
  };

  const getAds = async (): Promise<void> => {
    try {
      const ads = await privateAxiosV1.get(`/ads`, {
        params: {
          limit: FETCH_LIMIT,
          author_publisher_id: currentUser.publisher_id,
          author_tenant_id: currentUser.tenant_id,
          user_id: currentUser.id,
        },
      });

      if (!ads.data) {
        throw new Error("Could not get ads!");
      }

      setFilteredAds(ads.data.ads);
      setAds(ads.data.ads);
    } catch (err) {
      pickupErrorHandlerWeb(err);
    }
  };

  useEffect(() => {
    getAds();
  }, []);

  const handleFilter = (status: string, search: string, ads: any[]) => {
    let filtered = [];
    if (status.length && search.trim().length) {
      filtered = ads.filter(
        (a) =>
          a.status.toLowerCase() == status.toLowerCase() &&
          a.ad_name.toLowerCase().includes(search.toLowerCase())
      );
    } else if (status.length) {
      filtered = ads.filter(
        (a) => a.status.toLowerCase() == status.toLowerCase()
      );
    } else if (search.trim().length) {
      filtered = ads.filter((a) =>
        a.ad_name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return filtered;
  };

  useEffect(() => {
    if (filterStatus.length || search.trim().length) {
      const filtered = handleFilter(filterStatus, search, ads);
      setFilteredAds(filtered);
    } else {
      setFilteredAds(ads);
    }
  }, [filterStatus, search]);

  return (
    <Box w="full">
      {ads && ads.length ? (
        <div>
          <Flex paddingBottom={theme.spacing.base * 2}>
            <BreadcrumbsBlock links={[{ title: "Ads", isCurrent: true }]} />

            <Spacer />
            <Button
              leftIcon={<FontAwesome icon={faPlus} />}
              as={Link}
              to="/ads/create"
            >
              Create New
            </Button>
          </Flex>
          <Flex direction="column">
            <Box>
              <InputGroup>
                <InputLeftAddon>Find Ad</InputLeftAddon>
                <Input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={"Enter search term..."}
                />
              </InputGroup>
            </Box>
            <Box display="flex" alignItems={"center"} paddingTop={1}>
              <Text>Filter By:</Text>
              <Select
                placeholder="Status"
                maxWidth={250}
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <option value="published">Published</option>
                <option value="scheduled">Scheduled</option>
                <option value="draft">Draft</option>
                <option value="archived">Archived</option>
              </Select>
            </Box>
          </Flex>
          <Divider orientation="horizontal" />
          <ListTable
            heading={"Ads"}
            headers={[
              { title: "Actions" },
              { title: "Name" },
              { title: "Publisher" },
              { title: "Status" },
            ]}
            list={filteredAds}
            columns={[
              { name: "ad_name", type: "string" },
              { name: "publishers", type: "array", field: "name" },
              { name: "status", type: "badge" },
            ]}
            onDelete={deleteAd}
            alertTitle={"Delete this Ad?"}
            actions={[
              {
                name: "View",
                actionHandler: editAd,
                icon: <FontAwesome icon={faPenToSquare} />,
              },
              {
                name: "Delete",
                actionHandler: null,
                icon: <FontAwesome icon={faTrash} />,
              },
            ]}
          />
        </div>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default Ads;
