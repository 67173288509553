/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, useTheme } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction } from "react";
import { FormikHelpers } from "formik";
import { DefaultTheme } from "../../types/theme";
import { faUpDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../../components/common/icon";

interface RenderReorderButtonsProps {
  name: string;
  index: number;
  setFieldValue: FormikHelpers<unknown>["setFieldValue"];
  selectedValues: unknown[];
  setSelectedValues: Dispatch<SetStateAction<unknown[]>>;
}

const RenderReorderButtons = ({
  name,
  index,
  setFieldValue,
  selectedValues,
  setSelectedValues,
}: RenderReorderButtonsProps) => {
  const theme = useTheme<DefaultTheme>();

  return (
    <>
      <IconButton
        variant="link"
        aria-label="Move Up"
        icon={<FontAwesome icon={faUpDown} />}
        onClick={() => {
          if (index != 0) {
            const newSelectedValues = [...selectedValues];
            const temp = newSelectedValues[index];
            newSelectedValues[index] = newSelectedValues[index - 1];
            newSelectedValues[index - 1] = temp;
            setSelectedValues(newSelectedValues);
            setFieldValue(
              name,
              newSelectedValues.map((p: any) => p.id)
            );
          }
        }}
        disabled={index === 0}
        background={theme.colors.primary.base}
        color={theme.colors.white}
        px={0}
      />
      <IconButton
        variant="link"
        aria-label="Move Down"
        icon={<FontAwesome icon={faUpDown} />}
        onClick={() => {
          if (index != selectedValues.length - 1) {
            const newSelectedValues = [...selectedValues];
            const temp = newSelectedValues[index];
            newSelectedValues[index] = newSelectedValues[index + 1];
            newSelectedValues[index + 1] = temp;
            setSelectedValues(newSelectedValues);
            setFieldValue(
              name,
              newSelectedValues.map((p: any) => p.id)
            );
          }
        }}
        disabled={index === selectedValues.length - 1}
        background={theme.colors.primary.base}
        color={theme.colors.white}
        px={0}
      />
    </>
  );
};

export default RenderReorderButtons;
