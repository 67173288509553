import React from "react";
import { useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import classNames from "classnames";
import { User } from "../../../server/models/user.model";
import { useSnackbar } from "notistack";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { passwordMatch } from "../../constants/styles";
import { Button, Divider, Heading, Input } from "@chakra-ui/react";
import Paper from "../../components/common/Paper";

const NODE_API_URL_V1 = process.env.REACT_APP_NODE_API_URL;
const ADMIN_TOKEN = process.env.REACT_APP_ADMIN_TOKEN;

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "center",
    alignItems: "100%",
    marginTop: "15vh",
  },
  paper: {
    maxWidth: 568,
    backgroundColor: theme.colors.white,
  },
  inputContainer: {
    marginBottom: theme.spacing.base * 5,
  },
  buttonContainer: {
    marginTop: theme.spacing.base * 5,
    "& button": {
      padding: 0,
    },
  },
}));

const Recovery: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  return (
    <div className={classes.root}>
      <Paper
        className={classNames({
          [classes.paper]: true,
        })}
        padding={30}
      >
        <Heading variant="heading2">Reset Password</Heading>
        <Divider orientation="horizontal" />
        <div>
          <Formik
            initialValues={{
              password: "",
              passwordConfirm: "",
            }}
            validationSchema={Yup.object({
              password: Yup.string()
                .matches(
                  passwordMatch.matcher,
                  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                )
                .required("Missing password!"),
              passwordConfirm: Yup.string().oneOf(
                [Yup.ref("password"), null],
                "Passwords must match"
              ),
            })}
            onSubmit={async (values) => {
              return await axios
                .post<User>(
                  `${NODE_API_URL_V1}/users/reset`,
                  {
                    password: values.password,
                    reset_password_token: params.get("token"),
                    reset_password_expires: params.get("time"),
                  },
                  {
                    headers: { token: ADMIN_TOKEN },
                  }
                )
                .then(() => {
                  return navigate("/login");
                })
                .catch((err) => {
                  pickupErrorHandlerWeb(err);
                  enqueueSnackbar("Something went wrong, please try again", {
                    variant: "error",
                  });
                });
            }}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form noValidate={true}>
                <div className={classes.inputContainer}>
                  <Field
                    id="password"
                    name="password"
                    label="New Password"
                    onChange={handleChange}
                    value={values.password}
                    type="password"
                    component={Input}
                  />
                </div>
                <div className={classes.inputContainer}>
                  <Field
                    id="passwordConfirm"
                    name="passwordConfirm"
                    label="Confirm Password"
                    type="password"
                    onChange={handleChange}
                    value={values.passwordConfirm}
                    component={Input}
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <Button disabled={isSubmitting} type={"submit"}>
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Paper>
    </div>
  );
};

export default Recovery;
