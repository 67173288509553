import { defineStyleConfig } from "@chakra-ui/react";

export const dividerTheme = defineStyleConfig({
  baseStyle: {},
  variants: {
    padded: {
      mt: 4,
    },
  },
  defaultProps: {
    variant: "padded",
  },
});
