import React from "react";
import { FormikErrors, FormikTouched } from "formik";
import { InputGroupWrapper } from "../InputGroupWrapper";
import DatePicker from "../../DatePicker";

export interface DatepickerInputProps {
  label: string;
  id: string;
  value: Date | null;
  onChange: (date: Date) => void;
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  setActive?: (boolean: boolean) => void;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean
  ) => void;
  touchedHandler: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  maxLength?: number;
  showCharCount?: boolean;
  wrapLabels?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  touched?: FormikTouched<Date>;
  showTimeSelect?: boolean;
  dateFormat?: string;
}

export const DatepickerFieldset: React.FC<DatepickerInputProps> = ({
  label,
  id,
  value,
  errors,
  maxLength,
  isRequired,
  wrapLabels = false,
  setFieldValue,
  touchedHandler,
  touched,
  showTimeSelect = true,
  dateFormat = "Pp",
}) => {
  return (
    <>
      <InputGroupWrapper
        label={label}
        value={""}
        errors={errors}
        touched={touched}
        showCharCount={false}
        wrapLabels={wrapLabels}
        maxLength={maxLength}
        isRequired={isRequired}
      >
        <DatePicker
          id={id}
          selectedDate={value ? new Date(value) : null}
          onChange={(e) => {
            if (!e) {
              setFieldValue(id, null);
            } else {
              setFieldValue(id, e);
            }
            console.log(e);
          }}
          isClearable={true}
          onBlur={() => {
            touchedHandler(id, true, true);
          }}
          showTimeSelect={showTimeSelect}
          dateFormat={dateFormat}
        />
      </InputGroupWrapper>
    </>
  );
};
