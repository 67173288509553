import React, { ChangeEventHandler, FocusEventHandler } from "react";
import { Select } from "@chakra-ui/react";
import { FormikErrors } from "formik";
import { InputGroupWrapper } from "../InputGroupWrapper";
import { SingleSelectItem } from "../../../types";

export interface SingleSelectFieldsetProps {
  label: string;
  id: string;
  value: string | number;
  options: SingleSelectItem[];
  onChange: ChangeEventHandler;
  onBlur: FocusEventHandler<HTMLSelectElement>;
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  placeholder: string;
  wrapLabels?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  touched?: boolean;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  helperText?: string;
}

export const SingleSelectFieldset: React.FC<SingleSelectFieldsetProps> = ({
  label,
  id,
  value,
  options,
  onChange,
  onBlur,
  errors,
  placeholder,
  isRequired,
  wrapLabels = false,
  isDisabled = false,
  touched,
  setFieldTouched,
  helperText,
}) => {
  return (
    <>
      <InputGroupWrapper
        label={label}
        value={value}
        errors={errors}
        touched={touched}
        showCharCount={false}
        wrapLabels={wrapLabels}
        maxLength={null}
        isRequired={isRequired}
        helperText={helperText}
      >
        <Select
          placeholder={placeholder ? placeholder : "Select option"}
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          onFocus={() => {
            setFieldTouched(id, true, true);
          }}
          onBlur={(e) => {
            onBlur(e);
            setFieldTouched(id, true, true);
          }}
          disabled={isDisabled}
          required={isRequired}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </InputGroupWrapper>
    </>
  );
};
