import React, { useContext } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobalContext from "../../components/GlobalContext";
import { AiPropSuggestion } from "../../types";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import useAiApi from "../../services/api/Ai";
import { useNavigate } from "react-router-dom";
import useTagsApi from "../../services/api/Tags";
import usePicksApi from "../../services/api/Picks";
import { useSnackbar } from "notistack";

const AiPropSuggestion = ({ setActive, leagues }): JSX.Element => {
  const { currentUser, setTags } = useContext(GlobalContext);
  const aiApi = useAiApi();
  const tagsApi = useTagsApi();
  const { getPicksByPropId } = usePicksApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      url: "",
      picks: "2",
      selected_leagues: "",
    },
    validationSchema: Yup.object({
      url: Yup.string()
        .url("Must be valid URL")
        .required("Article URL is required"),
      picks: Yup.string().required(),
      selected_leagues: Yup.string().required("League is required"),
    }),
    onSubmit: async (values) => {
      setActive(true);
      await aiApi
        .generatePropSuggestion({
          ...values,
          user_id: currentUser.id,
          publisher_id: currentUser.publisher_id,
        })
        .then(async (res) => {
          if (res.data.prop_id) {
            const picks = await getPicksByPropId(res.data.prop_id);
            // do tags
            const tags = await aiApi.generatePropTags({
              prop: res.data.title,
              picks: res.data.picks,
            });
            if (tags.question && tags.question.length)
              await tagsApi.createAndAssignTags(
                tags.question,
                res.data.prop_id
              );
            for (let i = 0; i < res.data.picks.length; i++) {
              const pick = picks.find(
                (p) => p.display_title == res.data.picks[i]
              );
              if (tags.choices && tags.choices.length && pick.id) {
                await tagsApi.createAndAssignPickTags(tags.choices[i], pick.id);
              }
            }
            //update context tag state if new tags were created
            tagsApi.getAll().then((tags) => setTags(tags));
            navigate(`/props/${res.data.prop_id}/edit`);
          } else {
            enqueueSnackbar(
              "Could not generate prop, try a different website",
              { variant: "error" }
            );
            throw new Error("Could not generate prop");
          }
        })
        .catch((err) => {
          formik.setFieldError(
            "url",
            "Could not gather content from this URL. Try a different URL."
          );

          pickupErrorHandlerWeb(err);
          return false;
        });
    },
  });

  return (
    <Flex
      direction="row"
      w="full"
      bg="fanpower.900"
      color="white"
      p={15}
      borderRadius={5}
      mb={15}
    >
      <Flex direction="column" w="50%" maxW={500}>
        <Heading variant="heading2">🤖 AI Prop Suggestions</Heading>
        <form onSubmit={formik.handleSubmit}>
          <VStack spacing={4} align="start">
            <FormControl isInvalid={formik.errors.url && formik.touched.url}>
              <FormLabel htmlFor="url">Article URL</FormLabel>
              <Input
                id="url"
                name="url"
                placeholder="https://fanpower.io"
                onChange={formik.handleChange}
                value={formik.values.url}
                disabled={formik.isSubmitting}
                color="fanpower.900"
              />
              <FormErrorMessage>{formik.errors.url}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="picks">Number of Picks</FormLabel>
              <RadioGroup
                id="picks"
                name="picks"
                defaultValue={formik.values.picks}
                onChange={(value) => formik.setFieldValue("picks", value)}
                isDisabled={formik.isSubmitting}
              >
                <Stack direction="row">
                  <Radio value="2">Two</Radio>
                  <Radio value="3">Three</Radio>
                  <Radio value="4">Four</Radio>
                  <Radio value="5">Five</Radio>
                  <Radio value="6">Six</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl
              isInvalid={
                formik.errors.selected_leagues &&
                formik.touched.selected_leagues
              }
            >
              <Select
                id="selected_leagues"
                name="selected_leagues"
                placeholder="Select League"
                onChange={(e) =>
                  formik.setFieldValue("selected_leagues", e.target.value)
                }
                isDisabled={formik.isSubmitting}
                color="fanpower.900"
              >
                {leagues.map((league) => (
                  <option key={league.id} value={league.id}>
                    {league.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {formik.errors.selected_leagues}
              </FormErrorMessage>
            </FormControl>
            <Button type="submit" isLoading={formik.isSubmitting}>
              Generate Prop Suggestion
            </Button>
          </VStack>
        </form>
      </Flex>
      <Flex direction="column" w="50%" maxW={500} ml={20}>
        <Heading variant="heading3" mb={15}>
          {"What's this?"}
        </Heading>
        <Text variant="body">
          {
            "Powered by ChatGPT -- We use AI to analyze your article content and generate an original prop that is engaging and relevant to your content."
          }
        </Text>
        <Text variant="body">
          {
            "Copy and paste your article URL, select how many picks you want, and click 'Generate'. We'll do the rest! Save the prop as-is or edit it to your liking."
          }
        </Text>
      </Flex>
    </Flex>
  );
};

export default AiPropSuggestion;
