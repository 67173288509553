import { defineStyleConfig } from "@chakra-ui/react";
import { fontFamilies } from "../fonts";

export const headingTheme = defineStyleConfig({
  baseStyle: {
    fontFamily: fontFamilies.headline,
    fontWeight: 600,
    letterSpacing: "0.1px",
    color: "inherit",
  },
  sizes: {
    h1: {
      fontSize: { base: "34px", md: "48px" },
      lineHeight: { base: "38px", md: "54px" },
      textTransform: "uppercase",
    },
    h2: {
      fontSize: { base: "28px", md: "36px" },
      lineHeight: { base: "34px", md: "40px" },
      textTransform: "uppercase",
    },
    h3: {
      fontSize: { base: "22px", md: "28px" },
      lineHeight: { base: "26px", md: "34px" },
    },
    h4: {
      fontSize: { base: "16px", md: "22px" },
      lineHeight: { base: "18px", md: "28px" },
    },
    h5: {
      fontSize: { base: "13px", md: "16px" },
      lineHeight: { base: "15px", md: "22px" },
    },
    h6: {
      fontSize: { base: "11px", md: "13px" },
      lineHeight: { base: "13px", md: "16px" },
    },
  },
  defaultProps: {
    size: "h2",
  },
});
