import { Box, Divider, Flex, Spacer, useTheme } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import useContestsApi from "../../services/api/Contests";
import { ContestRecord } from "../../types";
import ContestDetailForm from "./ContestDetailsForm";
import ContestFans from "./ContestFanList";
import ContestProps from "./ContestPropList";

const ContestDetailView: React.FC = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [contest, setContest] = useState<ContestRecord>(location.state);
  const [contestFans, setContestFans] = useState([]);
  const contestsApi = useContestsApi();
  const theme = useTheme();

  useEffect(() => {
    if (id) {
      contestsApi.getContestbyId(id).then((res) => {
        if (res.props[0] == null) {
          res.props = [];
        }
        setContest(res);
      });

      contestsApi.getContestFans(id).then((res) => {
        setContestFans(res);
      });
    }
  }, [id]);

  return (
    <>
      <Flex paddingBottom={theme.spacing.base * 2}>
        <BreadcrumbsBlock
          links={[
            { title: "Props", isCurrent: false },
            { title: "Contests", isCurrent: false, link: "/props/contests" },
            { title: "View", isCurrent: true },
          ]}
        />
      </Flex>
      <ContestDetailForm contest={contest} setContest={setContest} />
      {contest ? (
        <>
          <Box position="relative" padding="10">
            <Divider />
          </Box>
          <ContestProps contest={contest} setContest={setContest} />
        </>
      ) : null}
      {contest && contest.contest_type == "manual" ? (
        <>
          <Box position="relative" padding="10">
            <Divider />
          </Box>
          <ContestFans
            contestFans={contestFans}
            setContestFans={setContestFans}
          />
        </>
      ) : null}
    </>
  );
};

export default ContestDetailView;
