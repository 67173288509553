import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Flex,
  Spacer,
  Input,
  InputGroup,
  InputLeftAddon,
  Divider,
  useTheme,
  Box,
} from "@chakra-ui/react";
import {
  faPlus,
  faCheck,
  faTrash,
  faPenToSquare,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import GlobalContext from "../../components/GlobalContext";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { useNavigate, useLocation } from "react-router-dom";
import { Prop, v2Prop } from "../../types";
import usePropsApi from "../../services/api/Props";
import { useSnackbar } from "notistack";
import DropDown from "../../components/FilterDropDown";
import useJwt from "../../hooks/useJwt";
import Loader from "../../components/Loader";
import PropGradeModal from "./PropGradeModal";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import ListTable from "../../components/common/Layouts/ListTable";
import { FontAwesome } from "../../components/common/icon";

const FETCH_LIMIT = 5000; // Max items the api will fetch

const PropsIndex: React.FC = () => {
  const [props, setProps] = useState<v2Prop[]>([]);
  const { currentUser, publishers, publisher } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [currentProp, setCurrentProp] = useState<v2Prop>(null);
  const [gradeProp, setGradeProp] = useState<Prop>(null);
  const [propGradeModalIsOpen, setPropGradeModalIsOpen] =
    useState<boolean>(false);
  const [authors, setAuthors] = useState([]);
  const [userFilter, setUserFilter] = useState("");
  const [pubFilter, setPubFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("pending");
  const [filteredProps, setFilteredProps] = useState([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const propsApi = usePropsApi();
  const { decodeJwt } = useJwt();
  const [search, setSearch] = useState("");
  const theme = useTheme();

  const gradePropHandler = async (subject: v2Prop) => {
    const fullProp = await propsApi.getProp(subject.id);
    setGradeProp(fullProp as unknown as Prop);
    setPropGradeModalIsOpen(true);
  };

  const deleteDialogOnConfirm = async (item: any) => {
    try {
      await propsApi.deleteProp(item);
      const updatedProps = props.filter((prop: v2Prop) => {
        if (prop.id != item.id) {
          return prop;
        }
      });
      const updatedFilteredProps = filteredProps.filter((prop: v2Prop) => {
        if (prop.id != item.id) {
          return prop;
        }
      });

      setProps(updatedProps);
      setFilteredProps(updatedFilteredProps);
      enqueueSnackbar("Prop successfully deleted.", { variant: "success" });
    } catch (e) {
      pickupErrorHandlerWeb(e);
      enqueueSnackbar("Could not delete prop, try again later", {
        variant: "error",
      });
    }
    return;
  };

  const fetchProps = async () => {
    try {
      const authors = [currentUser.publisher_id];
      const propsRes = { props: [], count: 0 };

      //filter propsRes to current user
      if (decodeJwt?.user.role !== "fanpoweradmin") {
        if (publishers.length > 0 && publisher.tenant_id) {
          for (const pub of publishers) {
            if (
              pub.tenant_id == publisher.tenant_id &&
              pub.id != currentUser.publisher_id
            ) {
              authors.push(pub.id);
            }
          }
        }

        const [closedResult, gradedResult, draftResult, pendingResult] =
          await Promise.allSettled([
            propsApi.getAll(1, "closed", FETCH_LIMIT, authors),
            propsApi.getAll(1, "graded", FETCH_LIMIT, authors),
            propsApi.getAll(1, "draft", FETCH_LIMIT, authors),
            propsApi.getAll(1, "pending", FETCH_LIMIT, authors),
          ]);
        handlePropRes(
          closedResult,
          gradedResult,
          draftResult,
          pendingResult,
          propsRes
        );
      } else {
        const [closedResult, gradedResult, draftResult, pendingResult] =
          await Promise.allSettled([
            propsApi.getAll(1, "closed", FETCH_LIMIT, []),
            propsApi.getAll(1, "graded", FETCH_LIMIT, []),
            propsApi.getAll(1, "draft", FETCH_LIMIT, []),
            propsApi.getAll(1, "pending", FETCH_LIMIT, []),
          ]);
        handlePropRes(
          closedResult,
          gradedResult,
          draftResult,
          pendingResult,
          propsRes
        );
      }

      setProps(propsRes.props);
      const pending = propsRes.props.filter((p) => p.status == "pending");
      setFilteredProps(pending);

      setLoading(false);
    } catch (err) {
      pickupErrorHandlerWeb(err);
    }
  };

  const handlePropRes = (
    closedProps,
    gradedProps,
    draftProps,
    pendingProps,
    propsRes
  ) => {
    if (closedProps.status == "fulfilled") {
      propsRes.props = [...propsRes.props, ...closedProps.value.props];
      propsRes.count += closedProps.value.count;
    }
    if (gradedProps.status == "fulfilled") {
      propsRes.props = [...propsRes.props, ...gradedProps.value.props];
      propsRes.count += gradedProps.value.count;
    }
    if (draftProps.status == "fulfilled") {
      propsRes.props = [...propsRes.props, ...draftProps.value.props];
      propsRes.count += draftProps.value.count;
    }
    if (pendingProps.status == "fulfilled") {
      propsRes.props = [...propsRes.props, ...pendingProps.value.props];
      propsRes.count += pendingProps.value.count;
    }
    //collect authors
    const authors = propsRes.props.map((p) => {
      p["author"] = p.author_first_name + " " + p.author_last_name;
      return p.author_first_name + " " + p.author_last_name;
    });

    setAuthors(authors.filter((item, i) => authors.indexOf(item) === i));
  };

  const handleFilter = (
    user: string,
    status: string,
    publisher: string,
    search: string,
    props: any[]
  ) => {
    let pubId = null;
    let filtered = props;

    if (status.length) {
      filtered = filtered.filter((p) => p.status == status);
    }

    if (publisher.length) {
      const findPub = publishers.find(
        (p) => p.name.toLowerCase().trim() == publisher.toLowerCase()
      );
      pubId = findPub ? findPub.id : null;
      filtered = filtered.filter((p) => p.author_publisher_id == pubId);
    }
    if (user.length) {
      filtered = filtered.filter(
        (p) =>
          p.author_first_name.toLowerCase() +
            " " +
            p.author_last_name.toLowerCase() ==
          user.toLowerCase()
      );
    }
    if (search.trim().length) {
      filtered = filtered.filter((p) =>
        p.proposition.toLowerCase().includes(search.trim().toLowerCase())
      );
    }
    return filtered;
  };

  useEffect(() => {
    if (
      userFilter.length ||
      pubFilter.length ||
      search.trim().length ||
      statusFilter.length
    ) {
      const filtered = handleFilter(
        userFilter,
        statusFilter,
        pubFilter,
        search,
        props
      );

      setFilteredProps(filtered);
    } else {
      setFilteredProps(props);
    }
  }, [userFilter, pubFilter, search, statusFilter]);

  useEffect(() => {
    if (publisher && publishers.length) fetchProps();
  }, [publishers, publisher]);

  const viewHandler = (item: any) => {
    navigate("/props/" + item.id + "/edit");
  };

  const insightHandler = (item: any) => {
    navigate("/props/" + item.id + "/insight");
  };

  return (
    <Box w="full">
      <Flex paddingBottom={theme.spacing.base * 2}>
        <BreadcrumbsBlock links={[{ title: "Props", isCurrent: true }]} />

        <Spacer />
        <Button
          leftIcon={<FontAwesome icon={faPlus} />}
          as={Link}
          to="/props/create"
        >
          Create New
        </Button>
      </Flex>
      {loading ? (
        <Loader />
      ) : (
        <>
          <InputGroup>
            <InputLeftAddon>Search</InputLeftAddon>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={"Enter search term..."}
            />
          </InputGroup>
          <Flex flexWrap={"wrap"}>
            <DropDown
              options={authors}
              setValue={setUserFilter}
              overrides={{ placeHolder: "user" }}
            />
            <DropDown
              options={["pending", "draft", "graded", "closed"]}
              setValue={setStatusFilter}
              overrides={{ defaultValue: "pending" }}
            />
            {publishers.length ? (
              <DropDown
                options={publishers
                  .filter(
                    (pub) =>
                      props.filter(
                        (prop) => Number(prop.author_publisher_id) == pub.id
                      ).length
                  )
                  .map((p) => p.name)}
                setValue={setPubFilter}
                overrides={{ placeHolder: "publisher" }}
              />
            ) : null}
          </Flex>
          <Divider orientation="horizontal" />
          <ListTable
            heading={"Props"}
            headers={[
              { title: "Actions" },
              { title: "Title" },
              { title: "League" },
              { title: "Submitted Date" },
              { title: "Status" },
              { title: "Author" },
            ]}
            list={filteredProps}
            columns={[
              { name: "proposition", type: "string" },
              { name: "league", type: "badge", color: "aiChip" },
              { name: "created_at", type: "date" },
              { name: "status", type: "badge", color: "aiChip" },
              { name: "author", type: "string" },
            ]}
            onDelete={deleteDialogOnConfirm}
            alertTitle="Delete this Prop?"
            actions={[
              {
                name: "Edit",
                actionHandler: viewHandler,
                icon: <FontAwesome icon={faPenToSquare} />,
              },
              {
                name: "Delete",
                actionHandler: null,
                icon: <FontAwesome icon={faTrash} />,
              },
              {
                name: "Insights",
                actionHandler: insightHandler,
                icon: <FontAwesome icon={faCircleQuestion} />,
              },
              {
                name: "Grade",
                actionHandler: gradePropHandler,
                icon: <FontAwesome icon={faCheck} />,
              },
            ]}
          />
        </>
      )}

      <PropGradeModal
        isOpen={propGradeModalIsOpen}
        onClose={() => {
          setPropGradeModalIsOpen(!propGradeModalIsOpen);
          if (gradeProp.grading_in_progress) {
            const updateProp = filteredProps;
            const prop = updateProp.find((p) => p.id == gradeProp.id);
            prop.grading_in_progress = true;
            setFilteredProps(updateProp);
          }
          setGradeProp(null);
        }}
        prop={gradeProp}
      />
    </Box>
  );
};
export default PropsIndex;
