import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { LinkBlockProps, NavActions } from "../../types";
import { NavLink } from "react-router-dom";
import { appStyles } from "../../constants/styles";
import GlobalContext from "../GlobalContext";
import { Button, UnorderedList, ListItem, Link, Flex } from "@chakra-ui/react";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../common/icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  navItem: {
    "& > button": {
      background: "none",
      textTransform: "none",
      minHeight: theme.spacing.base * 3.5,
      lineHeight: "inherit",
      letterSpacing: "inherit",
      borderRadius: 0,
      textAlign: "left",
    },
    listStyleType: "none",
  },
  link: {
    display: "block",
    fontSize: 12,
    color: "#615E66",
    width: "100%",
    padding: `${theme.spacing.base * 2}px ${appStyles.sidebarLeftPadding}px`,
    "&:hover": {
      backgroundColor: theme.colors.grey.lightBase,
    },
    "&:focus": {
      fontWeight: "bold",
      backgroundColor: theme.colors.grey.lightBase,
    },
    "& > svg": {
      marginRight: theme.spacing.base * 2,
    },
  },
}));

const LinkBlock: React.FC<LinkBlockProps> = ({ links, title }) => {
  const classes = useStyles();
  const { currentUser, doLogout } = useContext(GlobalContext);
  const [showLinks, setShowLinks] = useState(true);

  if (!links) {
    return <div />;
  }

  const linkActions: NavActions = {
    logout: () => doLogout(),
  };

  return (
    <UnorderedList m={0}>
      <Flex alignItems={"center"}>
        <Button
          rightIcon={
            showLinks ? (
              <FontAwesome icon={faMinus} />
            ) : (
              <FontAwesome icon={faPlus} />
            )
          }
          onClick={() => setShowLinks(!showLinks)}
          w={"100%"}
          justifyContent={"left"}
          // size="sm"
          _hover={{ backgroundColor: "transparent" }}
          textTransform={"uppercase"}
          fontSize={"12px"}
          variant={"ghost"}
        >
          {title}
        </Button>
      </Flex>
      {showLinks &&
        links.map((link) => (
          <React.Fragment key={link.value}>
            {!link.permissions ||
            currentUser?.permissions?.[link.permissions] ? (
              <ListItem className={classes.navItem}>
                {link.action ? (
                  <Button
                    onClick={linkActions[link.action]}
                    className={classes.link}
                    variant="full"
                  >
                    {link.icon}
                    {link.value}
                  </Button>
                ) : link.external ? (
                  <Link
                    className={classes.link}
                    href={link.path}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.icon}
                    {link.value}
                  </Link>
                ) : (
                  <NavLink to={link.path} className={classes.link}>
                    {link.icon}
                    {link.value}
                  </NavLink>
                )}
              </ListItem>
            ) : null}
          </React.Fragment>
        ))}
    </UnorderedList>
  );
};

export default LinkBlock;
