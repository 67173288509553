import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { useParams } from "react-router-dom";
import GlobalContext from "../../components/GlobalContext";
import useJwt from "../../hooks/useJwt";
import NoBorderLayout from "../../components/common/Layouts/NoBorder";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Stack,
  Radio,
  FormHelperText,
  FormErrorMessage,
  Switch,
  Select,
  Box,
  Heading,
  Button,
  Flex,
} from "@chakra-ui/react";
import usePublishersApi from "../../services/api/Publishers";
import { Publisher, SingleSelectItem } from "../../types";
import HtmlEditor from "../../components/HtmlEditor";
import { useSnackbar } from "notistack";
import DesktopButtons from "../../components/FormElements/DesktopButtons";
import { useNavigate, useLocation } from "react-router-dom";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import ColorPicker from "../../components/ColorPicker";
import FileUploader from "../../components/FileUploader";
import { DeactivatePublisher } from "./Deactivate";
import Loader from "../../components/Loader";
import useTenantsApi from "../../services/api/Tenants";
import { FontAwesome } from "../../components/common/icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  form: {
    maxWidth: 800,
    padding: theme.spacing.base * 4,
  },
  formControl: {
    marginBottom: theme.spacing.base * 8,
  },
  formBox: {
    border: `1px solid ${theme.colors.grey.lightBase}`,
    lineHeight: "0.1em",
    borderRadius: 6,
    paddingLeft: theme.spacing.base * 5,
    paddingRight: theme.spacing.base * 5,
    marginBottom: "20px",
  },
  formBoxTitle: {
    fontFamily: theme.typography.fontFamilies.body,
    top: -3,
    height: 16,
    width: 200,
    position: "relative",
    background: theme.colors.grey.light,
    opacity: 1,
    marginBottom: theme.spacing.base * 8,
    marginLeft: theme.spacing.base * 3,
    color: theme.colors.grey.base,
  },
}));

const PublisherSettings = (): JSX.Element => {
  const classes = useStyles();
  const { currentUser, publishers } = useContext(GlobalContext);
  const { publisherId } = useParams<{ publisherId: string }>();
  const { decodeJwt } = useJwt();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [publisher, setPublisher] = useState<Publisher>(
    location.state?.item ? (location.state.item as Publisher) : null
  );
  const [editPublisher, setEditPublisher] = useState<Publisher>(null);
  const { updatePublisher, getPublisher } = usePublishersApi();
  const { enqueueSnackbar } = useSnackbar();
  const [tenantOptions, setTenantOptions] = useState<SingleSelectItem[]>([]);
  const tenantsApi = useTenantsApi();

  useEffect(() => {
    const loadTenants = async () => {
      let tenantOpts = [];
      const tentants = await tenantsApi.getAllTenants();
      tenantOpts =
        tentants &&
        tentants.map((row) => {
          return { label: row.name, value: row.id };
        });
      setTenantOptions(tenantOpts);
    };
    const fetchPublisher = async () => {
      if (!publisherId && !publisher.access_token) {
        return;
      }

      const pub = await getPublisher(
        publisherId,
        publisher?.access_token ? publisher.access_token : undefined
      );
      setEditPublisher(pub);
    };
    const checkPermissions = () => {
      if (publisher) {
        if (
          decodeJwt?.user.role == "tenantadmin" &&
          +currentUser?.tenant_id != +publisher?.tenant_id
        ) {
          navigate("/publishers");
        }
        if (
          decodeJwt?.user.role == "publisheradmin" &&
          +currentUser?.publisher_id != +publisher?.id
        ) {
          navigate("/prop-search");
        }
      }
    };

    if (publisher) {
      fetchPublisher().then(() => {
        checkPermissions();
        loadTenants();
        setLoading(false);
      });
    }
  }, [publisher]);

  useEffect(() => {
    if (publisher == null && publisherId) {
      setPublisher(publishers.filter((p) => p.id == Number(publisherId))[0]);
    }
  }, [publisher, publishers]);

  const breadcrumbs = [
    {
      title: "Settings",
      isCurrent: false,
    },
    {
      title: "Publishers",
      link: "/publishers",
      isCurrent: false,
    },
    {
      title: "Edit Publisher",
      isCurrent: true,
    },
  ];

  const copyToClipboard = (str: string) => {
    navigator.clipboard.writeText(str);
  };

  if (loading || !editPublisher) {
    return (
      <NoBorderLayout title={"Publisher Settings"} breadcrumb={breadcrumbs}>
        <Loader />
      </NoBorderLayout>
    );
  }

  return (
    <NoBorderLayout title={"Publisher Settings"} breadcrumb={breadcrumbs}>
      <Stack>
        <Stack direction="row">
          <Heading
            size="sm"
            as="h5"
          >{`Publisher: ${editPublisher.name}`}</Heading>
          {editPublisher.tenant_name && (
            <Heading
              size="sm"
              as="h5"
            >{`Tenant: ${editPublisher.tenant_name}`}</Heading>
          )}
        </Stack>
        <Stack direction="row" alignItems="center">
          <Heading
            size="sm"
            as="h5"
          >{`Access Token: ${editPublisher.access_token}`}</Heading>
          <Button onClick={() => copyToClipboard(editPublisher.access_token)}>
            <FontAwesome icon={faCopy} />
          </Button>
        </Stack>
      </Stack>
      <Formik
        initialValues={{
          reporting: editPublisher?.settings.reporting || "",
          source_url: editPublisher?.source_url || "",
          picker_preference: editPublisher?.picker_preference || "",
          tenant_id: editPublisher?.tenant_id || "",
          outgoing_sms_number: editPublisher?.outgoing_sms_number || "",
          outgoing_email_address: editPublisher?.outgoing_email_address || "",
          discord_guild_id: editPublisher?.discord_guild_id || "",
          terms_link: editPublisher.settings.terms_link || "",
          learn_more_url: editPublisher.settings.learn_more_url || "",
          self_serve_props_access:
            editPublisher?.settings.self_serve_props_access || "draft",
          show_next_prop_prompt:
            editPublisher?.settings.show_next_prop_prompt || "false",
          picker_hide_footer:
            editPublisher?.settings.picker_hide_footer || "false",
          hide_carousel_ad: editPublisher?.settings.hide_carousel_ad || "false",
          require_terms_checkbox:
            editPublisher?.settings.require_terms_checkbox || "false",
          require_terms_content:
            editPublisher?.settings.require_terms_content || "",
          ai_content_access: editPublisher?.settings.ai_content_access,
          picker_logo: editPublisher?.picker_logo || "",
          primary_color: editPublisher?.primary_color || "",
          secondary_color: editPublisher?.secondary_color || "",
          background_color: editPublisher?.background_color || "",
          icon_color: editPublisher?.icon_color || "",
          text_link_color: editPublisher?.text_link_color || "",
        }}
        validationSchema={Yup.object({
          reporting: Yup.string().url("Must be a valid URL"),
          source_url: Yup.string().url("Must be a valid URL"),
          learn_more_url: Yup.string().url("Must be a valid URL"),
          outgoing_email_address: Yup.string().email(
            "Must be a valid email format"
          ),
          outgoing_sms_number: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/,
            "Must be a valid phone number"
          ),
          terms_link: Yup.string().url("Must be a valid URL"),
          require_terms_content: Yup.string().max(
            1000,
            "Must be fewer than 1000 characters"
          ),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          const publisherPayload = {
            id: publisher.id,
            picker_preference: values.picker_preference,
            tenant_id: values.tenant_id.length ? values.tenant_id : null,
            outgoing_sms_number: values.outgoing_sms_number,
            outgoing_email_address: values.outgoing_email_address,
            discord_guild_id: values.discord_guild_id,
            source_url: values.source_url,
            primary_color: values.primary_color,
            secondary_color: values.secondary_color,
            background_color: values.background_color,
            icon_color: values.icon_color,
            text_link_color: values.text_link_color,
            picker_logo: values.picker_logo,
            settings: {
              reporting: values.reporting,
              terms_link: values.terms_link,
              learn_more_url: values.learn_more_url,
              self_serve_props_access: values.self_serve_props_access,
              show_next_prop_prompt: values.show_next_prop_prompt,
              picker_hide_footer: values.picker_hide_footer,
              hide_carousel_ad: values.hide_carousel_ad,
              require_terms_checkbox: values.require_terms_checkbox,
              require_terms_content: values.require_terms_content,
              // prevents this value from being updated if the user is not a fanpoweradmin
              ...(decodeJwt?.user.role === "fanpoweradmin" && {
                ai_content_access: values.ai_content_access,
              }),
            },
          };

          const { settings, ...rest } = publisherPayload;
          const stringifiedSettings = JSON.stringify(settings);
          const newPayload: any = { ...rest, settings: stringifiedSettings };
          const response = await updatePublisher(newPayload);

          if (response === "OK") {
            enqueueSnackbar("Publisher successfully updated!", {
              variant: "success",
            });
            setSubmitting(false);
          } else {
            enqueueSnackbar("Error updating publisher", {
              variant: "error",
            });
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className={classes.form}>
            <FormLabel>Publisher Theme</FormLabel>
            <Box border="1px" borderRadius={5} padding={5} marginBottom={10}>
              <Stack direction="row">
                <FormControl maxWidth={300}>
                  <FormLabel>Picker Logo:</FormLabel>
                  {(editPublisher.picker_logo_url || values.picker_logo) && (
                    <Box margin={5}>
                      <img
                        src={
                          typeof values.picker_logo !== "string"
                            ? URL.createObjectURL(values.picker_logo)
                            : editPublisher.picker_logo_url
                        }
                        alt="publisher picker logo"
                      />
                    </Box>
                  )}
                  <FileUploader name="picker_logo" />
                </FormControl>
                <Stack direction="column" paddingLeft={25}>
                  <FormLabel>Picker Colors:</FormLabel>
                  <FormControl>
                    <Flex>
                      <FormLabel>Primary Color</FormLabel>
                      <ColorPicker
                        initialColor={values.primary_color}
                        name="primary_color"
                        setFieldValue={setFieldValue}
                      />
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <Flex>
                      <FormLabel>Secondary Color</FormLabel>
                      <ColorPicker
                        initialColor={values.secondary_color}
                        name="secondary_color"
                        setFieldValue={setFieldValue}
                      />
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <Flex>
                      <FormLabel>Background Color</FormLabel>
                      <ColorPicker
                        initialColor={values.background_color}
                        name="background_color"
                        setFieldValue={setFieldValue}
                      />
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <Flex>
                      <FormLabel>Icon Color</FormLabel>
                      <ColorPicker
                        initialColor={values.icon_color}
                        name="icon_color"
                        setFieldValue={setFieldValue}
                      />
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <Flex>
                      <FormLabel>Text Link Color</FormLabel>
                      <ColorPicker
                        initialColor={values.text_link_color}
                        name="text_link_color"
                        setFieldValue={setFieldValue}
                      />
                    </Flex>
                  </FormControl>
                </Stack>
              </Stack>
            </Box>
            <FormControl
              className={classes.formControl}
              id="reporting"
              isInvalid={touched.reporting && !!errors.reporting}
            >
              <FormLabel>MixPanel Dashboard</FormLabel>
              <Input
                type="url"
                id="reporting"
                name="reporting"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.reporting}
              />
              <FormErrorMessage>{errors.reporting}</FormErrorMessage>
              <FormHelperText>
                Public share URL that links to the Publisher dashboard.
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              id="source_url"
              isInvalid={touched.source_url && !!errors.source_url}
            >
              <FormLabel>URL</FormLabel>
              <Input
                type="url"
                id="source_url"
                name="source_url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.source_url}
              />
              <FormErrorMessage>{errors.source_url}</FormErrorMessage>
              <FormHelperText>https://fanpower.io</FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              id="learn_more_url"
              isInvalid={touched.learn_more_url && !!errors.learn_more_url}
            >
              <FormLabel>Learn More URL</FormLabel>
              <Input
                type="url"
                id="learn_more_url"
                name="learn_more_url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.learn_more_url}
              />
              <FormErrorMessage>{errors.learn_more_url}</FormErrorMessage>
              <FormHelperText>
                The publisher URL linked to the &apos;Learn More&apos; text. The
                default URL is fanpower.io.
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              id="outgoing_sms_number"
              isInvalid={
                touched.outgoing_sms_number && !!errors.outgoing_sms_number
              }
            >
              <FormLabel>Outgoing SMS Phone Number (10 digit)</FormLabel>
              <Input
                type="tel"
                id="outgoing_sms_number"
                name="outgoing_sms_number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.outgoing_sms_number}
              />
              <FormErrorMessage>{errors.outgoing_sms_number}</FormErrorMessage>
              <FormHelperText>223-244-2426</FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              id="outgoing_email_address"
              isInvalid={
                touched.outgoing_email_address &&
                !!errors.outgoing_email_address
              }
            >
              <FormLabel>Outgoing Email Address</FormLabel>
              <Input
                type="email"
                id="outgoing_email_address"
                name="outgoing_email_address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.outgoing_email_address}
              />
              <FormErrorMessage>
                {errors.outgoing_email_address}
              </FormErrorMessage>
              <FormHelperText>sender@publisher.com</FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              id="discord_guild_id"
              isInvalid={touched.discord_guild_id && !!errors.discord_guild_id}
            >
              <FormLabel>Discord Guild ID</FormLabel>
              <Input
                type="text"
                id="discord_guild_id"
                name="discord_guild_id"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.discord_guild_id}
              />
              <FormErrorMessage>{errors.discord_guild_id}</FormErrorMessage>
              <FormHelperText>
                The discord guild id. Click{" "}
                <a
                  style={{ color: "#1AA1FE" }}
                  rel="noreferrer"
                  target="_blank"
                  href={
                    "https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-"
                  }
                >
                  here
                </a>{" "}
                for more details.
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              id="picker_preference"
              isInvalid={
                touched.picker_preference && !!errors.picker_preference
              }
            >
              <FormLabel>Picker Preference</FormLabel>
              <Select
                placeholder=""
                maxWidth={250}
                onChange={handleChange}
                value={values.picker_preference}
              >
                {[
                  { label: "email", value: "email" },
                  { label: "phone", value: "phone" },
                  { label: "both phone first", value: "phone_first" },
                  { label: "both email first", value: "email_first" },
                ].map((o, i) => {
                  return (
                    <option value={o.value} key={i}>
                      {o.label}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>{errors.picker_preference}</FormErrorMessage>
              <FormHelperText>
                The preferred medium for collecting user data
              </FormHelperText>
            </FormControl>
            {decodeJwt.user.role == "fanpoweradmin" ? (
              <>
                <FormLabel>Tenant</FormLabel>

                <Select
                  id="tenant_id"
                  value={values.tenant_id}
                  onChange={handleChange}
                  placeholder={"No Tenant"}
                >
                  {tenantOptions &&
                    tenantOptions.map((o, i) => {
                      return (
                        <option value={o.value} key={i}>
                          {o.label}
                        </option>
                      );
                    })}
                </Select>
                <FormErrorMessage>{errors.tenant_id}</FormErrorMessage>
              </>
            ) : null}
            <FormControl
              className={classes.formControl}
              id="self_serve_props_access"
            >
              <FormLabel>Self Serve Props Access</FormLabel>
              <RadioGroup
                onChange={(value) =>
                  setFieldValue("self_serve_props_access", value)
                }
                value={values.self_serve_props_access}
              >
                <Stack direction="row">
                  <Radio value="draft">Draft</Radio>
                  <Radio value="pending">Pending</Radio>
                </Stack>
              </RadioGroup>
              <FormHelperText>
                A draft prop requires admin approval before publishing to
                Portal, whereas pending will publish a prop directly.
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              id="show_next_prop_prompt"
            >
              <FormLabel>Show Next Prop Prompt</FormLabel>
              <Switch
                id="show_next_prop_prompt"
                name="show_next_prop_prompt"
                onChange={(e) =>
                  setFieldValue(
                    "show_next_prop_prompt",
                    String(e.target.checked)
                  )
                }
                isChecked={values.show_next_prop_prompt === "true"}
              >
                Show Prompt
              </Switch>
              <FormHelperText>
                Enables the Prop Picker to prompt a new Prop after the fan
                chooses and verifies a Pick.
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              id="picker_hide_footer"
            >
              <FormLabel>Hide Prop Picker Footer</FormLabel>
              <Switch
                id="picker_hide_footer"
                name="picker_hide_footer"
                onChange={(e) =>
                  setFieldValue("picker_hide_footer", String(e.target.checked))
                }
                isChecked={values.picker_hide_footer === "true"}
              >
                Hide Footer
              </Switch>
              <FormHelperText>
                Hides the elements in the Prop Picker footer such as fan profile
                and share buttons.
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl} id="hide_carousel_ad">
              <FormLabel>Hide Carousel Ad</FormLabel>
              <Switch
                id="hide_carousel_ad"
                name="hide_carousel_ad"
                onChange={(e) =>
                  setFieldValue("hide_carousel_ad", String(e.target.checked))
                }
                isChecked={values.hide_carousel_ad === "true"}
              >
                Hide Carousel Ad
              </Switch>
              <FormHelperText>Hides the ad slot on Carousels.</FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              id="terms_link"
              isInvalid={touched.terms_link && !!errors.terms_link}
            >
              <FormLabel>Terms and Conditions URL</FormLabel>
              <Input
                type="url"
                id="terms_link"
                name="terms_link"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.terms_link}
              />
              <FormErrorMessage>{errors.terms_link}</FormErrorMessage>
              <FormHelperText>
                A custom terms and conditions URL embedded in the picker.
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              id="require_terms_checkbox"
            >
              <FormLabel>Require Terms & Conditions</FormLabel>
              <Switch
                id="require_terms_checkbox"
                name="require_terms_checkbox"
                onChange={(e) =>
                  setFieldValue(
                    "require_terms_checkbox",
                    String(e.target.checked)
                  )
                }
                isChecked={values.require_terms_checkbox === "true"}
              >
                Required
              </Switch>
              <FormHelperText>
                Requires the Fans to check a box in the Prop Picker that
                approves of terms and conditions.
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.formControl}
              id="require_terms_content"
              isInvalid={
                touched.require_terms_content && !!errors.require_terms_content
              }
            >
              <FormLabel>Terms & Conditions Content</FormLabel>
              <Field
                id="require_terms_content"
                name="require_terms_content"
                placeholder="Terms & Conditions..."
                component={HtmlEditor}
                onBlur={handleBlur}
              />
              <FormHelperText>
                Content that will appear next to the checkbox on the Prop Picker
                if Terms & Conditions requirement is enabled.
              </FormHelperText>
            </FormControl>

            {decodeJwt?.user.role === "fanpoweradmin" ? (
              <Box className={classes.formBox}>
                <Box className={classes.formBoxTitle}>Publisher Features</Box>
                <FormControl
                  className={classes.formControl}
                  id="ai_content_access"
                >
                  <FormLabel>Access to Generative AI Features</FormLabel>
                  <Switch
                    id="ai_content_access"
                    name="ai_content_access"
                    onChange={(e) =>
                      setFieldValue(
                        "ai_content_access",
                        String(e.target.checked)
                      )
                    }
                    isChecked={values.ai_content_access === "true"}
                  ></Switch>
                  <FormHelperText>
                    Allows publisher to access and use AI Generative tools.
                  </FormHelperText>
                </FormControl>
              </Box>
            ) : null}

            {decodeJwt.user.role == "manager" ? null : (
              <DeactivatePublisher publisher={editPublisher} />
            )}

            <DesktopButtons
              isSubmitting={isSubmitting}
              cancelRoute={"/publishers"}
            />
          </Form>
        )}
      </Formik>
    </NoBorderLayout>
  );
};

export default PublisherSettings;
