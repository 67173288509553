import React, { useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { createUseStyles } from "react-jss";
import { Button, Flex, Box, Select } from "@chakra-ui/react";
import prettier from "prettier/standalone";
import parserHtml from "prettier/parser-html";
import {
  generatePropEmbedCode,
  generatePropShortcode,
} from "../../helpers/embedCodes";
import { DraftProp, Publisher } from "../../types";

const useStyles = createUseStyles(() => ({
  container: {
    height: 200,
    overflow: "auto",
    fontSize: 12,
  },
  btnAndSelect: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    maxWidth: 300,
    paddingTop: 20,
  },
}));

type codeCopyBtnProps = {
  gptResult: string;
  prop: Partial<DraftProp>;
  publisher: Publisher;
};

const CodeCopyBtn: React.FC<codeCopyBtnProps> = ({
  gptResult,
  prop,
  publisher,
}) => {
  const classes = useStyles();
  const [code, setCode] = useState("");
  const [format, setFormat] = useState("markdown");
  const [clicked, setClicked] = useState(false);
  const [jsCode, setJsCode] = useState("");
  const [markdownCode, setMarkdownCode] = useState("");

  const onClick = () => {
    navigator.clipboard.writeText(format == "markdown" ? markdownCode : jsCode);
  };

  useEffect(() => {
    if (!markdownCode.length) {
      let markdown = "";
      const chunks = gptResult.split("\n\n");
      for (let i = 0; i < chunks.length; i++) {
        if (i == 2 && prop.id) {
          markdown += generatePropShortcode(undefined, prop);
          markdown += "\n\n";
        }
        markdown += chunks[i];
        markdown += "\n\n";
      }

      setMarkdownCode(markdown);
    }
  }, []);

  const buildJs = () => {
    let buildJs = "";
    let jsEmbed = false;
    let propEmbed = "";
    const opening = `
      <html lang="en">
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>${prop.proposition}</title>
          <script src="pickup-picker-v1.js"></script>
          </head>
          <body>
          `;

    const closing = `  </body>
        </html>`;
    const nodeList = document.getElementById("gptResult").childNodes;

    for (let i = 0; i < nodeList.length; i++) {
      if (nodeList[i].childNodes.length == 2 && !jsEmbed) {
        //go get the picker
        const nestedNodes = nodeList[i].childNodes;

        for (let j = 0; j < nestedNodes.length; j++) {
          if (j == 0) {
            const firstDiv = nestedNodes[j].childNodes;
            for (let k = 0; k < firstDiv.length; k++) {
              if (k == 2) {
                if (prop.id && publisher) {
                  propEmbed = generatePropEmbedCode(publisher, undefined, prop);
                  buildJs += propEmbed;
                  jsEmbed = true;
                }
              }

              buildJs += (firstDiv[k] as HTMLElement).outerHTML;
            }
          }
        }
      } else {
        if ((nodeList[i] as HTMLElement).outerHTML)
          buildJs += (nodeList[i] as HTMLElement).outerHTML;
      }
    }
    buildJs = opening + buildJs + closing;
    setJsCode(buildJs);
  };

  return (
    <Flex flexDirection={"column"} padding={20}>
      <Box className={classes.container}>
        <SyntaxHighlighter language={format} style={solarizedlight}>
          {format == "markdown"
            ? markdownCode
            : prettier.format(jsCode, {
                parser: "html",
                plugins: [parserHtml],
              })}
        </SyntaxHighlighter>
      </Box>

      <Flex className={classes.btnAndSelect}>
        <Select
          onChange={(e) => {
            setFormat(e.target.value);
            if (e.target.value == "javascript") {
              buildJs();
            }
            setClicked(false);
          }}
          defaultValue="markdown"
        >
          <option value="markdown">Markdown</option>
          <option value="javascript">Html/Javascript</option>
        </Select>
        <Button
          onClick={(e) => {
            e.preventDefault();
            setClicked(true);
            onClick();
          }}
        >
          {clicked ? "Copied" : "Copy"}
        </Button>
      </Flex>
      <Box></Box>
    </Flex>
  );
};

export default CodeCopyBtn;
