import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import GlobalContext from "../../components/GlobalContext";
import { useContext } from "react";
import { User } from "../../../server/models/user.model";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { ProfileEditFormValues } from "../../types";
import useUsersApi from "../../services/api/Users";
import useJwt from "../../hooks/useJwt";
import { Button, Heading, Input, Text, useTheme, Box } from "@chakra-ui/react";
import MfaSignUpDialog from "../../components/Mfa/MfaSignUpDialog";
import { TextInputGroup } from "../../components/FormElements/TextInputGroup";

const useStyles = createUseStyles({
  root: {
    width: "auto",
  },
  form: {
    marginTop: "30px",
    width: "auto",
  },
  title: {},
  inputStyle: ({ theme }: { theme: DefaultTheme }) => ({
    width: "340px",
    height: "44px",
    background: "#F9FAFB",
    border: "1px solid #D0D4D9",
    boxShadow: "inset 1px 1px 2px rgba(0,0,0,0.15)",
    padding: "10px",
    borderRadius: "0",
    color: theme.colors.grey.base,
    marginBottom: "5px",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      width: "",
    },
  }),
  label: ({ theme }: { theme: DefaultTheme }) => ({
    "& label": {
      fontSize: "13px",
      fontFamily: theme.typography.fontFamilies.body,
      lineHeight: "24px",
      paddingBottom: "0px",
      textTransform: "none",
    },
  }),
});

const Profile: React.FC = () => {
  const { currentUser, setCurrentUser } = useContext(GlobalContext);
  const theme = useTheme<DefaultTheme>();
  const [, setCookie] = useCookies(["pu_portal"]);
  const classes = useStyles({ theme });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const usersApi = useUsersApi();
  const { decodeJwt } = useJwt();
  const [, setActive] = useState(false);

  const checkValue = (value: string): boolean => {
    if (value == "") {
      return false;
    }
    return true;
  };

  const handleResponse = (
    updatedUser: User,
    values: ProfileEditFormValues
  ): User => {
    if (checkValue(values.email)) {
      updatedUser.email = values.email;
    }
    if (checkValue(values.last_name)) {
      updatedUser.last_name = values.last_name;
    }
    if (checkValue(values.first_name)) {
      updatedUser.first_name = values.first_name;
    }
    return updatedUser;
  };

  const handleSubmit = async (values) => {
    const { email, first_name, last_name, new_password } = values;
    const data = {
      ...{ id: currentUser.id },
      ...(email.trim() != "" && { email: email.trim() }),
      ...(first_name.trim() != "" && { first_name: first_name.trim() }),
      ...(last_name.trim() != "" && { last_name: last_name.trim() }),
      ...(new_password.trim() != "" && { password: new_password.trim() }),
      ...{ role: decodeJwt.user.role },
      ...{ publisher_id: currentUser.publisher_id },
    };
    return await usersApi
      .patchUser(data)
      .then((response) => {
        const updatedUser = currentUser;
        handleResponse(updatedUser, values);
        setCookie("pu_portal", updatedUser, {
          path: "/",
          secure: true,
          expires: new Date(Date.now() + 3600 * 1000 * 48),
          sameSite: true,
        });
        setCurrentUser(updatedUser);
        return navigate("/prop-search");
      })
      .catch((err) => {
        const message = err.response
          ? err.response.data
          : "Something went wrong, please try again";
        enqueueSnackbar(message, {
          variant: "error",
        });
      });
  };

  const [mfaIsOpen, setMfaIsOpen] = useState<boolean>(false);

  const handleMfaDialogOpen = () => {
    setMfaIsOpen(true);
  };
  const closeMfaDialog = () => {
    setMfaIsOpen(false);
  };

  return (
    <div className={classes.root}>
      <Heading variant="title" className={classes.title}>
        Profile
      </Heading>
      <Text variant="body" style={{ marginTop: "15px" }}>
        Publisher:{currentUser.publisher_name}
      </Text>
      <Formik
        initialValues={{
          first_name: currentUser.first_name || "",
          email: currentUser.email || "",
          last_name: currentUser.last_name || "",
          new_password: "",
          confirm_new_password: "",
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().max(50, "character limit 50"),
          email: Yup.string().email("A valid email address is required!"),
          last_name: Yup.string().max(50, "character limit 50"),
          new_password: Yup.string().matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
          ),
          confirm_new_password: Yup.string().oneOf(
            [Yup.ref("new_password"), null],
            "Passwords don't match!"
          ),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          handleChange,
          values,
          setFieldTouched,
          errors,
          touched,
        }) => (
          <Form className={classes.form}>
            <div className={classes.label}>
              <TextInputGroup
                placeholder="First Name"
                id="first_name"
                value={values.first_name}
                label="First Name"
                onChange={handleChange}
                errors={errors.first_name}
                setFieldTouched={setFieldTouched}
                touched={touched.first_name}
                setActive={setActive}
              />
              <TextInputGroup
                placeholder="Email"
                id="email"
                value={values.email}
                label="Email"
                onChange={handleChange}
                errors={errors.email}
                setFieldTouched={setFieldTouched}
                touched={touched.email}
                setActive={setActive}
              />
              <TextInputGroup
                placeholder="Last Name"
                id="last_name"
                value={values.last_name}
                label="Last Name"
                onChange={handleChange}
                errors={errors.last_name}
                setFieldTouched={setFieldTouched}
                touched={touched.last_name}
                setActive={setActive}
              />
              <TextInputGroup
                placeholder="New Password"
                id="new_password"
                value={values.new_password}
                label="New Password"
                onChange={handleChange}
                errors={errors.new_password}
                setFieldTouched={setFieldTouched}
                touched={touched.new_password}
                setActive={setActive}
              />
              <TextInputGroup
                placeholder="Confirm New Password"
                id="confirm_new_password"
                value={values.confirm_new_password}
                label="Confirm New Password"
                onChange={handleChange}
                errors={errors.confirm_new_password}
                setFieldTouched={setFieldTouched}
                touched={touched.confirm_new_password}
                setActive={setActive}
              />
            </div>
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Form>
        )}
      </Formik>
      {/*<div>
        <Box marginTop="15px" fontWeight="bold">
          MFA: {currentUser.date_verified ? "Enabled" : "Disabled"}
        </Box>
        <Button onClick={handleMfaDialogOpen}>
          2 Factor Auth Configuration
        </Button>
        <MfaSignUpDialog isOpen={mfaIsOpen} onCloseCb={closeMfaDialog} />
      </div>*/}
    </div>
  );
};

export default Profile;
