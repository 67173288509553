import React from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import classNames from "classnames";
import { Divider, Heading, Text } from "@chakra-ui/react";
import Paper from "../../components/common/Paper";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "center",
    alignItems: "100%",
    marginTop: "15vh",
  },
  paper: {
    maxWidth: 568,
    backgroundColor: theme.colors.white,
  },
}));

const Recovery: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper
        className={classNames({
          [classes.paper]: true,
        })}
        padding={30}
      >
        <Heading mb={8} textAlign="center" variant="heading2">
          Thank You For Resetting Your Password
        </Heading>
        <Divider orientation="horizontal" />
        <div>
          <Text>
            Please check your email for a custom link from support@fanpower.io.
            The link to reset your password is valid for 1 hour.
          </Text>
        </div>
      </Paper>
    </div>
  );
};

export default Recovery;
