import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Button,
  Flex,
  Box,
  Spacer,
  Select,
  Text,
  Input,
  InputGroup,
  InputLeftAddon,
  Divider,
  useTheme,
} from "@chakra-ui/react";
import {
  faPlus,
  faTrash,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { userRole } from "../../constants/permissions";
import { PortalUser } from "../../types";
import GlobalContext from "../../components/GlobalContext";
import useUsersApi from "../../services/api/Users";
import useJwt from "../../hooks/useJwt";
import Loader from "../../components/Loader";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import ListTable from "../../components/common/Layouts/ListTable";
import { FontAwesome } from "../../components/common/icon";

const Users: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState<Array<PortalUser>>();
  const [filteredUsers, setFilteredUsers] = useState<Array<PortalUser>>();
  const [roles, setRoles] = useState<string[]>();
  const [filterRole, setFilterRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [, setShowPublisher] = useState(false);
  const [, setShowTenant] = useState(false);
  const { currentUser, publishers } = useContext(GlobalContext);
  const { decodeJwt } = useJwt();
  const usersApi = useUsersApi();
  const theme = useTheme();

  useEffect(() => {
    if (publishers) {
      getUsers();
      const mapRoles = () => {
        const roleArray = [];
        for (const key in userRole) {
          roleArray.push(userRole[key].displayName);
        }
        setRoles(roleArray);
      };
      mapRoles();
    }
  }, [publishers]);

  const handleFilter = (role: string, search: string, users: any[]) => {
    let filtered = [];
    if (role.length && search.trim().length) {
      filtered = users.filter(
        (u) =>
          u.role?.toLowerCase() == role.toLowerCase() &&
          u.email.toLowerCase().includes(search.toLowerCase())
      );
    } else if (role.length) {
      filtered = users.filter(
        (u) => u.role?.toLowerCase() == role.toLowerCase()
      );
    } else if (search.length) {
      filtered = users.filter((u) =>
        u.email.toLowerCase().includes(search.toLowerCase())
      );
    }
    return filtered;
  };

  useEffect(() => {
    if (filterRole.length || searchTerm.trim().length) {
      const filtered = handleFilter(filterRole, searchTerm, users);
      setFilteredUsers(filtered);
    } else if (users) {
      setFilteredUsers(users);
    }
  }, [filterRole, searchTerm]);

  useEffect(() => {
    if (currentUser) {
      if (
        decodeJwt?.user.role === "tenantadmin" ||
        decodeJwt?.user.role === "fanpoweradmin"
      ) {
        setShowPublisher(true);
      }
      if (decodeJwt?.user.role === "fanpoweradmin") {
        setShowTenant(true);
      }
    }
  }, [currentUser]);

  const getUsers = async (): Promise<void> => {
    try {
      const userResponse = await usersApi.getUsers();

      if (!userResponse.data) {
        throw new Error("Could not get users");
      }
      let filteredUsers = userResponse.data;
      if (decodeJwt?.user.role == "publisheradmin") {
        filteredUsers = userResponse.data.filter(
          (u) => u.publisher_id == currentUser.publisher_id
        );
      } else if (decodeJwt?.user.role == "tenantadmin") {
        filteredUsers = userResponse.data.filter(
          (u) => publishers.filter((p) => p.id == u.publisher_id).length
        );
      }
      setUsers(filteredUsers);

      if (filterRole || searchTerm) {
        const filtered = handleFilter(filterRole, searchTerm, filteredUsers);
        setFilteredUsers(filtered);
      } else {
        setFilteredUsers(filteredUsers);
      }

      setLoading(false);
    } catch (error) {
      pickupErrorHandlerWeb(error);
    }
  };
  const editUser = (user: PortalUser) => {
    navigate(`/users/edit/${user.id}`, { state: { user: user } });
  };
  const deleteUser = async (item: any) => {
    try {
      await usersApi.deleteUser(item.id);

      getUsers()
        .then(() =>
          enqueueSnackbar("Successfully deleted User", {
            variant: "success",
          })
        )
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      enqueueSnackbar("User could not be deleted", { variant: "error" });
      pickupErrorHandlerWeb(error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  if (loading) return <Loader />;

  return (
    <Box w="full">
      <Flex paddingBottom={theme.spacing.base * 2}>
        <BreadcrumbsBlock links={[{ title: "Users", isCurrent: true }]} />
        <Spacer />
        <Button
          as={Link}
          leftIcon={<FontAwesome icon={faPlus} />}
          to="/users/create"
        >
          Create New
        </Button>
      </Flex>

      <Flex direction="row">
        <Box>
          <InputGroup>
            <InputLeftAddon style={{ height: 40 }}>Find User</InputLeftAddon>
            <Input
              value={searchTerm}
              onChange={handleChange}
              style={{ height: 40 }}
              type="text"
              placeholder="Enter a search term..."
            />
          </InputGroup>
        </Box>
        <Box display="flex" alignItems={"center"} marginLeft={"12px"}>
          <InputGroup>
            <InputLeftAddon style={{ height: 40 }}>Filter By:</InputLeftAddon>
            <Select
              placeholder="Role"
              maxWidth={250}
              onChange={(e) => setFilterRole(e.target.value)}
            >
              {roles.map((role, i) => (
                <option key={i} value={role.toLowerCase().replace(/\s/g, "")}>
                  {role}
                </option>
              ))}
            </Select>
          </InputGroup>
        </Box>
      </Flex>
      <Divider orientation="horizontal" />
      <ListTable
        heading={"Users"}
        headers={[
          { title: "Actions" },
          { title: "Last Name" },
          { title: "Email" },
          { title: "Role" },
          { title: "Publisher" },
          { title: "Tenant" },
        ]}
        list={filteredUsers}
        columns={[
          { name: "last_name", type: "string" },
          { name: "email", type: "string" },
          { name: "role", type: "string" },
          { name: "publisher", type: "string" },
          { name: "tenant", type: "string" },
        ]}
        onDelete={deleteUser}
        alertTitle={"Delete this User?"}
        actions={[
          {
            name: "View",
            actionHandler: editUser,
            icon: <FontAwesome icon={faPenToSquare} />,
          },
          {
            name: "Delete",
            actionHandler: null,
            icon: <FontAwesome icon={faTrash} />,
          },
        ]}
      />
    </Box>
  );
};

export default Users;
