import { theme } from "../../theme/theme";
import { AppStyles, PasswordMatch } from "../../types";

export const appStyles: AppStyles = {
  headerHeight: 70,
  sidebarWidth: 154,
  sidebarLeftPadding: theme.spacing.base * 4,
};

export const passwordMatch: PasswordMatch = {
  matcher:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
};
