export const fontFamilies = {
  body: "'Inter', Helvetica, sans-serif",
  headline: "'Ringside-Condensed', 'Helvetica', sans-serif",
  heading: "'Ringside-Condensed', Helvetica, sans-serif",
  condensedBoldItalic: "'Ringside-Condensed', Helvetica, sans-serif",
  condensedThin: "'Ringside-Condensed-Thin', Helvetica, sans-serif",
  condensedSemiBold: "'Ringside-Condensed-Semibold', Helvetica, sans-serif",
  mono: "Menlo, monospace",
};

const fontStyles = {
  desktop: {
    heading1: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 48,
      lineHeight: "54px",
      letterSpacing: "0.1px",
      textTransform: "uppercase",
      color: "inherit",
    },
    heading2: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 36,
      lineHeight: "40px",
      letterSpacing: "0.1px",
      textTransform: "uppercase",
      color: "inherit",
    },
    heading3: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 28,
      lineHeight: "34px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
    heading4: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 22,
      lineHeight: "28px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
    heading5: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "22px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
    heading6: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 13,
      lineHeight: "16px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
    body: {
      fontFamily: fontFamilies.body,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 18,
      lineHeight: "30px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
    body2: {
      fontFamily: fontFamilies.body,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 13,
      lineHeight: "20px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
  },
  mobile: {
    heading1: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 34,
      lineHeight: "38px",
      letterSpacing: "0.1px",
      textTransform: "uppercase",
      color: "inherit",
    },
    heading2: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 28,
      lineHeight: "34px",
      letterSpacing: "0.1px",
      textTransform: "uppercase",
      color: "inherit",
    },
    heading3: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 22,
      lineHeight: "26px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
    heading4: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "18px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
    heading5: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 13,
      lineHeight: "15px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
    heading6: {
      fontFamily: fontFamilies.headline,
      fontWeight: 600,
      fontSize: 11,
      lineHeight: "13px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
    body: {
      fontFamily: fontFamilies.body,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 15,
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
    body2: {
      fontFamily: fontFamilies.body,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: "18px",
      letterSpacing: "0.1px",
      color: "inherit",
    },
  },
};

export const typography = {
  fontFamilies,
  fontStyles,
};
