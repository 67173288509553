import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";
import {
  Input,
  Text,
  FormLabel,
  Button,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import {
  faPlus,
  faCircleXmark,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { MultiSelectProps, MultiSelectItem } from "../../types";
import { FontAwesome } from "../common/icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
    fontFamily: theme.typography.fontFamilies.body,
  },
  formLabel: {
    fontSize: 15,
    color: theme.colors.grey.dark,
  },
  searchText: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.colors.grey.dark,
  },
  resultContainer: {
    overflowX: "hidden",
    width: "100%",
    "& > :first-child": {
      marginTop: theme.spacing.base * 2,
    },
  },
  result: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "bold",
    color: theme.colors.black,
    marginBottom: theme.spacing.base,
    "& button": {
      marginRight: theme.spacing.base * 4,
    },
  },
  selectedContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: [theme.spacing.base * 2, 0],
  },
  createButtonContainer: {
    marginTop: theme.spacing.base * 2,
  },
  requiredAsterisk: {
    color: theme.colors.red.base,
  },
}));
const publishOnMultiSelectChange = (name: string, data) => {
  const event = new CustomEvent(`${name}OnMultiSelectChange`, { detail: data });
  document.dispatchEvent(event);
};

const MultiSelect: React.FC<MultiSelectProps> = ({
  name,
  label,
  placeholder,
  items,
  values = null,
  reset = false,
  className = null,
  style = null,
  showAsRequired = false,
  createNew = null,
  updateSelectionCallback = null,
  onSelect,
  updateInputValue = null,
  formik,
}) => {
  const classes = useStyles();
  const formikProps = formik ? formik : useFormikContext();
  const [inputValue, setInputValue] = useState<string>("");
  const [results, setResults] = useState<Array<MultiSelectItem>>([]);
  const [selected, setSelected] = useState<Array<MultiSelectItem>>(
    values || []
  );

  useEffect(() => {
    if (updateInputValue && updateInputValue.name != inputValue) {
      if (updateInputValue.name && updateInputValue.id) {
        setSelected([...selected, updateInputValue]);
      }
    }
  }, [updateInputValue]);

  useEffect(() => {
    if (inputValue) {
      const found = items.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      setResults(found);
    }
  }, [items]);

  useEffect(() => {
    const selectedIds = selected.map((selection: MultiSelectItem) => {
      return selection.id;
    });
    // Compare to old form values and update if changed
    // This prevents an unneeded rerender on initial load
    if (
      JSON.stringify(selectedIds) !== JSON.stringify(formikProps.values[name])
    ) {
      formikProps.setFieldValue(name, selectedIds);
      publishOnMultiSelectChange(name, selectedIds);
    }
  }, [selected]);

  useEffect(() => {
    if (reset) {
      setInputValue("");
      setResults([]);
      setSelected([]);
    }
  }, [reset]);

  const findResults = (search: string) => {
    setInputValue(search);
    if (!search) {
      setResults([]);
    } else {
      const found = items.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setResults(found);
    }
  };

  const onInputChange = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    const search = e.target.value;
    return findResults(search);
  };

  const selectResult = (result: MultiSelectItem) => {
    const index = selected.findIndex(
      (object: MultiSelectItem) =>
        object.name.toLowerCase() === result.name.toLowerCase()
    );
    if (index === -1) {
      setSelected([...selected, result]);
      if (updateSelectionCallback) {
        updateSelectionCallback([...selected, result]);
      }
      setInputValue("");
      setResults([]);
      document.getElementById(`${name}MultiSelectInput`).focus();
    }
  };

  const onResultSelect = (e: React.SyntheticEvent, result: MultiSelectItem) => {
    e.preventDefault();
    selectResult(result);
  };

  const removeSelection = (
    e: React.SyntheticEvent,
    selection: MultiSelectItem
  ) => {
    const index = values.findIndex((v) => v.id == selection.id);
    if (index != -1) {
      values.splice(index, 1);
    }
    e.preventDefault();
    const updated = selected.filter(
      (element: MultiSelectItem) => element.name !== selection.name
    );
    setSelected(updated);
    if (updateSelectionCallback) {
      updateSelectionCallback(updated);
    }
  };

  const showCreateFromInputButton = () => {
    if (inputValue && createNew) {
      return (
        <div className={classes.createButtonContainer}>
          <Button
            aria-label="create"
            leftIcon={<FontAwesome icon={faPlus} />}
            onClick={() => createNew.onCreate(inputValue)}
            isLoading={createNew.isCreating}
          >
            Create New
          </Button>
        </div>
      );
    }
  };

  const showResults = () => {
    if (results && results.length) {
      return results.map((result: MultiSelectItem, i) => {
        return (
          <div key={i} className={classes.result}>
            <IconButton
              aria-label="add result"
              onClick={(e) => onResultSelect(e, result)}
              icon={<FontAwesome icon={faPlus} />}
              variant="outline"
              size="xs"
            />
            <Text>{result.name}</Text>
          </div>
        );
      });
    }
  };

  const showSelected = () => {
    if (selected && selected.length) {
      return selected.map((selection: MultiSelectItem, i) => {
        return (
          <Flex
            key={i}
            align="center"
            background="fanpower.500"
            m={1}
            p={1}
            borderRadius={6}
          >
            <Text color="white" size="sm">
              {selection.name}
            </Text>
            <IconButton
              aria-label="remove selection"
              background="fanpower.500"
              onClick={(e) => removeSelection(e, selection)}
              icon={<FontAwesome icon={faCircleXmark} />}
              size="xs"
            />
          </Flex>
        );
      });
    }
  };

  const clearSelected = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    setInputValue("");
    setResults([]);
    setSelected([]);
    document.getElementById(`${name}MultiSelectInput`).focus();
    if (updateSelectionCallback) {
      updateSelectionCallback([]);
    }
  };

  useEffect(() => {
    if (typeof onSelect == "function") {
      onSelect(selected.map((i) => i.name));
    }
  }, [selected]);

  const renderSelectionContainer = () => {
    if (selected.length) {
      return (
        <>
          <Text
            className={classes.searchText}
            style={{ marginTop: 8 }}
          >{`Current ${label}:`}</Text>
          <div className={classes.selectedContainer}>{showSelected()}</div>
          <Button
            variant="outline"
            onClick={(e) => clearSelected(e)}
            leftIcon={<FontAwesome icon={faTrash} />}
            size="sm"
          >
            {`Clear All ${label}`}
          </Button>
        </>
      );
    }
  };

  return (
    <div className={classNames(classes.root, className)} style={style}>
      <FormLabel className={classes.formLabel}>
        {label}
        {showAsRequired == true ? (
          <span className={classes.requiredAsterisk}> *</span>
        ) : (
          ""
        )}
      </FormLabel>
      <Input
        id={`${name}MultiSelectInput`}
        value={inputValue}
        placeholder={placeholder}
        onChange={(e) => onInputChange(e)}
        autoComplete="off"
      />
      <div
        style={{ height: results.length > 0 ? 150 : null }}
        className={classes.resultContainer}
      >
        {showResults()}
      </div>
      {showCreateFromInputButton()}
      {renderSelectionContainer()}
    </div>
  );
};

export default MultiSelect;
