import { Tenant } from "../../types";
import CommonModal from "../../components/Modal";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { TextInputGroup } from "../../components/FormElements/TextInputGroup";
import { Box, Button, Text, ModalFooter, useTheme } from "@chakra-ui/react";
import { DefaultTheme } from "../../types/theme";
import useTenantsApi from "../../services/api/Tenants";
import { useSnackbar } from "notistack";
import { SingleSelectFieldset } from "../../components/FormElements/Fieldsets/SingleSelectFieldset";
import globalContext from "../../components/GlobalContext";
import { useNavigate } from "react-router-dom";

interface TenantModalProps {
  isOpen: boolean;
  onClose: () => void;
  tenant?: Tenant;
  deactivateCallback?: () => void;
}

const TenantModal: React.FC<TenantModalProps> = (props) => {
  const navigate = useNavigate();
  const tenantsApi = useTenantsApi();
  const { publishers } = useContext(globalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { isOpen, onClose, tenant, deactivateCallback } = props;
  const headerValue = tenant ? "Update Tenant " + tenant.name : "Create Tenant";
  const theme = useTheme<DefaultTheme>();
  const [, setActive] = useState(false);
  const [showDeactivateConfirm, setShowDeactivateConfirm] = useState(false);
  const [tenantPublishers, setTenantPublishers] = useState([]);

  const isNew = !tenant || !tenant.id;

  useEffect(() => {
    if (!tenant || tenant.publisher_count < 1) {
      setTenantPublishers([]);
      return;
    }
    const tenantPublishers = publishers.filter((publisher) => {
      return publisher.tenant_id === tenant.id.toString();
    });
    setTenantPublishers(tenantPublishers);
  }, [tenant]);

  const handleSubmit = async (values) => {
    if (tenant && tenant.id) {
      //update
      return await tenantsApi
        .updateTenant({
          id: tenant.id,
          name: values.name,
          domain: values.domain,
          publisher_access_to_tenant: values.publisher_access_to_tenant,
        })
        .then((res) => {
          enqueueSnackbar("Tenant updated!", { variant: "success" });
          //onClose();
        })
        .catch((err) => {
          enqueueSnackbar(err.response.data, { variant: "error" });
          throw err;
        });
    } else {
      //create
      return await tenantsApi
        .createTenant(
          values.name,
          values.domain,
          values.publisher_access_to_tenant
        )
        .then((res) => {
          enqueueSnackbar("Tenant created!", { variant: "success" });
          //onClose();
        })
        .catch((err) => {
          enqueueSnackbar(err.response.data, { variant: "error" });
          throw err;
        });
    }
  };

  const navigateToPublisher = (id: string) => (e: any) => {
    e.preventDefault();
    navigate("/publishers/" + id + "/edit");
  };

  const handleDeactivateClick = async () => {
    setShowDeactivateConfirm(true);
  };
  const handleDeactivateConfirm = async () => {
    deactivateCallback();
  };

  const handleDeactivateCancel = async () => {
    setShowDeactivateConfirm(false);
  };

  return (
    <CommonModal header={headerValue} isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={{
          name: tenant && tenant.name ? tenant.name : "",
          domain: tenant && tenant.domain ? tenant.domain : "",
          publisher_access_to_tenant:
            tenant && tenant.publisher_access_to_tenant === true
              ? "true"
              : "false",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required"),
          domain: Yup.string()
            .required("Domain is required")
            .url("Must be a valid URL"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(values)
            .then((res) => {
              setSubmitting(false);
            })
            .catch((err) => {
              setSubmitting(false);
            });
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          handleChange,
          setFieldTouched,
          touched,
          handleBlur,
        }) => (
          <Form>
            <TextInputGroup
              label={"Name"}
              id={"name"}
              value={values.name}
              onChange={handleChange}
              errors={errors.name}
              placeholder={"Tenant Name"}
              isRequired={true}
              setFieldTouched={setFieldTouched}
              setActive={setActive}
            />

            <TextInputGroup
              label={"Website"}
              id={"domain"}
              value={values.domain}
              onChange={handleChange}
              errors={errors.domain}
              placeholder={"Tenant Website"}
              isRequired={true}
              setFieldTouched={setFieldTouched}
              setActive={setActive}
            />
            <SingleSelectFieldset
              label={"Publishers have Access"}
              id={"publisher_access_to_tenant"}
              value={values.publisher_access_to_tenant}
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              onChange={handleChange}
              setFieldTouched={setFieldTouched}
              errors={errors.publisher_access_to_tenant}
              onBlur={handleBlur}
              placeholder={"Does publisher have access?"}
            />
            <ModalFooter>
              <Button
                fontWeight="normal"
                fontFamily={theme.typography.fontFamilies.body}
                onClick={onClose}
              >
                Close
              </Button>
              <Button ml={3} type="submit" isDisabled={isSubmitting}>
                {tenant ? "Update" : "Create"}
              </Button>
            </ModalFooter>
            {!isNew ? (
              <Box>
                {tenantPublishers && tenantPublishers.length > 0 ? (
                  <>
                    <Text fontSize={"2xl"}>Publishers</Text>
                    <Box
                      style={{
                        maxHeight: "200px",
                        overflowX: "auto",
                        border: "1px solid lightgray",
                        borderRadius: "6px",
                        padding: "10px",
                        margin: "10px",
                      }}
                    >
                      {tenantPublishers.map((publisher) => {
                        return (
                          <Box
                            style={{
                              borderBottom: "1px solid lightgray",
                            }}
                            key={publisher.id}
                          >
                            {publisher.name} [
                            <Button
                              size={"xs"}
                              variant={"ghost"}
                              onClick={navigateToPublisher(publisher.id)}
                            >
                              view
                            </Button>
                            ]
                          </Box>
                        );
                      })}
                    </Box>
                  </>
                ) : (
                  <Text fontSize={"2xl"}>No Publishers</Text>
                )}
              </Box>
            ) : (
              ""
            )}

            <Box>
              {!isNew ? (
                <>
                  {showDeactivateConfirm ? (
                    <>
                      <Button
                        variant={"alert"}
                        onClick={handleDeactivateConfirm}
                        style={{ marginRight: "10px" }}
                      >
                        Really deactivate?
                      </Button>
                      <Button
                        fontWeight="normal"
                        fontFamily={theme.typography.fontFamilies.body}
                        onClick={handleDeactivateCancel}
                      >
                        No, keep tenant
                      </Button>
                    </>
                  ) : (
                    <Button variant={"alert"} onClick={handleDeactivateClick}>
                      Deactivate Tenant
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </CommonModal>
  );
};

export default TenantModal;
