import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import classNames from "classnames";

import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";

import {
  AuthenticationResponse,
  User,
} from "../../../server/models/user.model";
import GlobalContext from "../../components/GlobalContext";

import { userRole } from "../../constants/permissions";

import { useCookies } from "react-cookie";
import useJwt from "../../hooks/useJwt";
import usePublishersApi from "../../services/api/Publishers";
import {
  Button,
  Divider,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import Paper from "../../components/common/Paper";
import useAuthApi from "../../services/api/Auth";
import MfaChallenge from "../../components/Mfa/MfaChallenge";

const ADMIN_TOKEN = process.env.REACT_APP_ADMIN_TOKEN;

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  "@keyframes shake": {
    "10%, 90%": {
      transform: "translate3d(-1px, 0, 0)",
    },

    "20%, 80%": {
      transform: "translate3d(2px, 0, 0)",
    },

    "30%, 50%, 70%": {
      transform: "translate3d(-4px, 0, 0)",
    },

    "40%, 60%": {
      transform: "translate3d(4px, 0, 0)",
    },
  },
  root: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "center",
    alignItems: "100%",
    marginTop: "15vh",
  },
  paper: {
    maxWidth: 568,
    backgroundColor: theme.colors.white,
  },
  paperAnim: {
    animation: "$shake 0.82s cubic-bezier(.36,.07,.19,.97) both",
    transform: "translate3d(0, 0, 0)",
    backfaceVisibility: "hidden",
    perspective: "1000px",
  },
  inputContainer: {
    marginBottom: theme.spacing.base * 5,
  },
  forgotLink: {
    marginLeft: theme.spacing.base * 1.5,
    fontSize: 12,
    color: theme.colors.primary.base,
    textDecoration: "none",
  },
  createAccountCTA: {
    fontSize: 13,
    marginTop: theme.spacing.base * 4.5,
    color: theme.colors.grey.dark,
    lineHeight: "21px",
    "& a": {
      fontWeight: "bold",
      textDecoration: "none",
      color: theme.colors.primary.base,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
}));

const Login: React.FC = () => {
  const authApi = useAuthApi();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loginError, setLoginError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [challenge, setChallenge] = useState<AuthenticationResponse>(null);
  const [pwd, setPwd] = useState<string>("");
  const [showChallenge, setShowChallenge] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [challengeAttempts, setChallengeAttempts] = useState<number>(0);

  const { setCurrentUser } = useContext(GlobalContext);
  const { setJwt } = useJwt();
  const publishersApi = usePublishersApi();

  const [, setCookie] = useCookies(["pu_portal"]);

  const setAuth = async (response: AuthenticationResponse) => {
    console.log("setAuth");
    const user = response.user as User;
    user.refresh_token = response.tokens.refreshToken;

    setJwt(response.tokens.token);
    const setUser = {
      ...user,
      permissions: await mergePermissions(user),
    };

    setCurrentUser(setUser);
    setCookie("pu_portal", setUser, {
      path: "/",
      secure: true,
      expires: new Date(Date.now() + 3600 * 1000 * 48),
      sameSite: true,
    });
    setIsAuthenticated(true);
    return user;
  };

  const mergePermissions = async (user: User): Promise<any> => {
    const basePermissions = userRole[user.role];
    const publisher = await publishersApi.getPublisher(
      user.publisher_id.toString(),
      user.access_token
    );
    const pubSettings = publisher.settings;

    return {
      ...basePermissions,
      aiContentCreate:
        pubSettings.ai_content_access &&
        pubSettings.ai_content_access === "true"
          ? basePermissions.aiContentCreate
          : false,
      aiContentView:
        pubSettings.ai_content_access &&
        pubSettings.ai_content_access === "true"
          ? basePermissions.aiContentView
          : false,
      self_serve_props_access: "pending",
    };
  };

  const prep2faChallenge = (payload: AuthenticationResponse, pw: string) => {
    setPwd(pw);
    setChallenge(payload);
    setShowChallenge(true);
    if (challengeAttempts > 0) {
      enqueueSnackbar("Incorrect code, try again", { variant: "error" });
    }
  };

  const handle2faSubmit = async (code: string) => {
    setChallengeAttempts(challengeAttempts + 1);
    const response = await authApi.login(
      challenge.user.email,
      pwd,
      ADMIN_TOKEN,
      code
    );
    if (response && response.user && !response.challenge) {
      // login challenge success

      setAuth(response).then((user) => {
        postAuthNavigate(user);
      });
    } else {
      enqueueSnackbar("Incorrect code, try again", { variant: "error" });
    }
  };

  const postAuthNavigate = (user: User) => {
    if (!isAuthenticated) {
      return;
    }
    if (user.first_login) {
      return navigate("/getting-started");
    }
    return navigate("/prop-search");
  };

  useEffect(() => {
    if (loginError) {
      setTimeout(() => {
        setLoginError(false);
      }, 800);
    }
  }, [loginError]);

  return (
    <div className={classes.root}>
      <Paper
        className={classNames({
          [classes.paper]: true,
          [classes.paperAnim]: loginError,
        })}
        padding={30}
      >
        <Heading variant="heading2">Login</Heading>
        {/* <Text>
          Get started with the largest collection of sports props. Integrate
          directly into your website or WordPress site.
        </Text> */}
        <Divider orientation="horizontal" />
        <div>
          <Formik
            enableReinitialize
            initialValues={{ email: "", password: "" }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Valid Email required!")
                .required("Missing valid Email!"),
              password: Yup.string()
                .matches(
                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                )
                .required("Missing password!"),
            })}
            onSubmit={async (values) => {
              return authApi
                .login(values.email, values.password, ADMIN_TOKEN, null)
                .then(async (payload) => {
                  const user = payload.user as User;
                  if (payload.challenge) {
                    // show 2FA challenge
                    prep2faChallenge(payload, values.password);
                  } else {
                    //login success flow / build profile
                    await setAuth(payload);
                  }

                  return user;
                })
                .then((user) => {
                  postAuthNavigate(user);
                })
                .catch((err) => {
                  pickupErrorHandlerWeb(err);
                  if (err.response.status == 401) {
                    enqueueSnackbar("Incorrect email or password", {
                      variant: "error",
                    });
                  } else {
                    enqueueSnackbar("Something went wrong, please try again", {
                      variant: "error",
                    });
                  }
                  setLoginError(true);
                });
            }}
          >
            {({ isSubmitting, handleBlur, handleChange }) => (
              <>
                {!showChallenge ? (
                  <Form noValidate={true}>
                    <div className={classes.inputContainer}>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Input
                        id="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div>
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <Input
                        id="password"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div>
                      <Link className={classes.forgotLink} to="/login/recovery">
                        Forgot Password?
                      </Link>
                    </div>
                    <Button
                      type="submit"
                      mt={4}
                      disabled={isSubmitting}
                      w="full"
                    >
                      Login
                    </Button>
                  </Form>
                ) : null}
              </>
            )}
          </Formik>

          {showChallenge ? (
            <MfaChallenge
              isVerified={isVerified}
              verifyCode={handle2faSubmit}
            />
          ) : (
            <div className={classes.createAccountCTA}>
              Want to grow your audience?
              <br />
              <Link to="/sign-up">Create an account</Link> and begin embedding
              today!
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default Login;
