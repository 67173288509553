import React from "react";
import ReactDatePicker from "react-datepicker";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";

import "react-datepicker/dist/react-datepicker.css";
import { DatePickerProps } from "../../types";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  input: {
    backgroundColor: theme.colors.white,
    fontFamily: theme.typography.fontFamilies.body,
    height: 40,
    position: "relative",
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 7,
    border: "1px solid",
    borderColor: theme.colors.grey.lightBase,
    fontSize: 16,
    outline: "2px solid transparent",
    color: theme.colors.grey.dark,
    "&:focus, &:active": {
      zIndex: 1,
      borderColor: "none",
      boxShadow: `0 0 0 1px #3182ce`, //matching chakra
    },
    width: "100%",
  },
  container: {
    "& .react-datepicker-popper": {
      zIndex: 1,
      width: 375,
    },
    "& .react-datepicker__time-container": {
      fontFamily: theme.typography.fontFamilies.body,
      float: "right",
      borderLeft: `1px solid #aeaeae`,
      width: 100,
    },
    "& .react-datepicker__month-container": {
      fontFamily: theme.typography.fontFamilies.body,
      float: "left",
      width: 270,
    },
    "& .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)":
      {
        right: 100,
      },
    "& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list":
      {
        overflowX: "hidden",
      },
    "& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ":
      {
        width: "100%",
      },
  },
}));

const DatePicker: React.FC<DatePickerProps> = ({ ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ReactDatePicker
        id={props.id}
        selected={props.selectedDate}
        onChange={props.onChange}
        isClearable={props.isClearable}
        showTimeSelect={
          props.showTimeSelect != undefined ? props.showTimeSelect : true
        }
        dateFormat={props.dateFormat != undefined ? props.dateFormat : "Pp"}
        minDate={new Date()}
        timeIntervals={5}
        showPopperArrow={props.showPopperArrow}
        className={classes.input}
        onChangeRaw={props.onBlur}
        {...props}
      />
    </div>
  );
};

export default DatePicker;
