import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import GlobalContext from "../../GlobalContext";
import { User } from "../../../../server/models/user.model";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    display: "flex",
    position: "relative",
    textAlign: "right",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  imageContainer: {
    width: 42,
    height: 42,
    position: "relative",
    borderRadius: "50%",
    border: `1px solid ${theme.colors.grey.light}`,
    overflow: "hidden",
  },
  image: {
    position: "relative",
    objectFit: "cover",
    width: "100%",
  },
  name: {
    order: 2,
    marginLeft: theme.spacing.base * 2,
    fontSize: 14,
    fontFamily: theme.typography.fontFamilies.body,
    color: theme.colors.grey.dark,
    [theme.mediaQuery(theme.breakpoints.medium)]: {
      order: "unset",
      marginRight: theme.spacing.base * 2,
    },
  },
}));

const renderName = (currentUser: User) => {
  const { first_name, last_name, email } = currentUser;
  if (!first_name && !last_name) {
    return email;
  } else {
    return `${first_name} ${last_name}`;
  }
};

const UserDisplay: React.FC = () => {
  const classes = useStyles();
  const { currentUser } = useContext(GlobalContext);
  return (
    <div className={classes.root}>
      <div className={classes.name}>{renderName(currentUser)}</div>
      <div className={classes.imageContainer}>
        <img src={currentUser.gravatar_url} alt="" className={classes.image} />
      </div>
    </div>
  );
};

export default UserDisplay;
