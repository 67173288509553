import React from "react";
import { List, ListItem, Flex, Box, ListIcon, As } from "@chakra-ui/react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CommonModal from "../../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface FanViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  fan: any;
}

const FanViewModal: React.FC<FanViewModalProps> = ({
  isOpen,
  onClose,
  fan,
}) => {
  return (
    <CommonModal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <>
          {fan ? (
            <>
              {" "}
              <Box fontSize="lg">
                {fan && fan.username ? fan.username : fan.id}
              </Box>
              <Box fontSize="md">
                Location:
                {fan.last_known_state
                  ? ` ${fan.last_known_city} ${fan.last_known_state}`
                  : " unknown"}
              </Box>
            </>
          ) : null}
        </>
      }
    >
      {fan ? (
        <Flex flexDirection={"column"}>
          <Box fontSize="md" fontWeight={"bold"} padding={2}>
            Picks relevant to search:
          </Box>
          <List>
            {fan.picks.map((pick, i) => (
              <ListItem key={i}>
                <ListIcon
                  as={FontAwesomeIcon}
                  icon={faCheckCircle}
                  color="green.500"
                />

                {pick.title}
              </ListItem>
            ))}
          </List>
        </Flex>
      ) : (
        "Issue Loading Fan"
      )}
    </CommonModal>
  );
};

export default FanViewModal;
