import React from "react";
import { PickerLoader } from "@playpickup/prop-picker";

export interface StandalonePickerProps {
  propId?: number;
  carouselId?: number;
  accessToken: string;
  publisherId: number;
  host: string;
  sourceUrl: string;
  targetId?: number;
}

const StandalonePicker: React.FC<StandalonePickerProps> = ({
  propId,
  carouselId,
  accessToken,
  publisherId,
  host,
  sourceUrl,
  targetId,
}) => {
  const apiUrl = host.replace("/v1", "").replace("/V1", "");

  return (
    <>
      <PickerLoader
        propId={propId}
        carousel_id={carouselId}
        accessToken={accessToken}
        publisherId={publisherId}
        apiUrl={apiUrl}
        syndicate={false}
        sourceUrl={sourceUrl}
        targetId={targetId}
      />
    </>
  );
};

export default StandalonePicker;
