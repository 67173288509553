import { AxiosResponse } from "axios";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import useJwt from "../../hooks/useJwt";
import {
  AiJob,
  AiJobPayload,
  AiJobPersona,
  AiPropSuggestion,
  MlbGame,
  NascarRaceList,
  NflGame,
} from "../../types";

const useAiApi = () => {
  const { privateAxiosV2 } = useJwt();

  const generatePropSuggestion = async ({
    url,
    picks,
    user_id,
    publisher_id,
    selected_leagues,
  }): Promise<AxiosResponse<AiPropSuggestion>> => {
    return await privateAxiosV2
      .post(`/ai/prop-suggestion`, {
        url,
        picks,
        user_id,
        publisher_id,
        selected_leagues: [selected_leagues],
      })
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const savePropSuggestion = async ({
    prop_suggestion_id,
    prop_id,
    publisher_id,
  }): Promise<AxiosResponse> => {
    return await privateAxiosV2
      .post(`/ai/save-prop-suggestion`, {
        prop_suggestion_id,
        prop_id,
        publisher_id,
      })
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const generatePropTags = async ({
    prop,
    picks,
  }): Promise<{ question: []; choices: [] }> => {
    const tags = await privateAxiosV2
      .post("ai/prop-tag-suggestion", {
        prop,
        picks,
      })
      .then((res) => res.data.tags)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });

    return tags;
  };

  const createJob = async (jobPayload: AiJobPayload): Promise<any> => {
    const { publisher_id, type, user_id, title, params, game_type } =
      jobPayload;
    return await privateAxiosV2
      .post(`/ai/jobs`, {
        publisher_id,
        user_id,
        title,
        params,
        type,
        game_type,
      })
      .then((resp) => resp.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const getMLBGames = async ({ date }): Promise<MlbGame[]> => {
    return await privateAxiosV2
      .get(`/ai/games/mlb/preview`, {
        params: {
          date,
        },
      })
      .then((resp) => resp.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };
  const getNflPreviewGames = async ({
    date,
  }): Promise<{ games: NflGame[]; season: { typeCode: string } }> => {
    return await privateAxiosV2
      .get(`/ai/games/nfl/preview`)
      .then((resp) => resp.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const getNflRecapGames = async (): Promise<any> => {
    return await privateAxiosV2
      .get(`/ai/games/nfl/recap`)

      .then((resp) => resp.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };
  const getNcaafPreviewGames = async (): Promise<{
    games: NflGame[];
    season: { typeCode: string };
  }> => {
    return await privateAxiosV2
      .get(`/ai/games/ncaaf/preview`)
      .then((resp) => resp.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };
  const getNcaafRecapGames = async (): Promise<{
    games: NflGame[];
    season: { typeCode: string };
  }> => {
    return await privateAxiosV2
      .get(`/ai/games/ncaaf/recap`)
      .then((resp) => resp.data[0])
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const getNascarPreviewRaces = async (): Promise<NascarRaceList[]> => {
    return await privateAxiosV2
      .get(`/ai/games/nascar/preview`)
      .then((resp) => resp.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const getPersonasForPublisher = async (
    publisherId: string | number
  ): Promise<AiJobPersona[]> => {
    return await privateAxiosV2
      .get(`/ai/personas/publisher/${publisherId}`)
      .then((resp) => resp.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const getPersonaById = async (
    personaId: string | number
  ): Promise<AiJobPersona> => {
    return await privateAxiosV2
      .get(`/ai/personas/${personaId}`)
      .then((resp) => resp.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const getPersonas = async (): Promise<AiJobPersona[]> => {
    return await privateAxiosV2
      .get(`/ai/personas`)
      .then((resp) => resp.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const publisherJobs = async (): Promise<AiJob[]> => {
    return await privateAxiosV2
      .get(`/ai/jobs`)
      .then((res) => res.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const deleteJob = async (id: string): Promise<AxiosResponse> => {
    return await privateAxiosV2.delete(`/ai/jobs/${id}`).catch((err) => {
      pickupErrorHandlerWeb(err);
      throw err;
    });
  };

  const getJob = async (id: string): Promise<any> => {
    return await privateAxiosV2
      .get(`/ai/jobs/${id}`)
      .then((res) => res.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };
  const createPersona = async (
    payload: Omit<AiJobPersona, "id">
  ): Promise<any> => {
    return await privateAxiosV2
      .post(`/ai/personas`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const updatePersona = async (
    personaId: string,
    payload: AiJobPersona
  ): Promise<AiJobPersona> => {
    return await privateAxiosV2
      .patch(`/ai/personas/${personaId}`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const deletePersona = async (id: string): Promise<any> => {
    return await privateAxiosV2
      .delete(`/ai/personas/${id}`)
      .then((res) => res.data)
      .catch((err) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  return {
    generatePropSuggestion,
    savePropSuggestion,
    getMLBGames,
    getNflPreviewGames,
    getNflRecapGames,
    getNcaafPreviewGames,
    getNcaafRecapGames,
    getNascarPreviewRaces,
    createJob,
    publisherJobs,
    deleteJob,
    getJob,
    deletePersona,
    createPersona,
    updatePersona,
    getPersonas,
    getPersonasForPublisher,
    getPersonaById,
    generatePropTags,
  };
};
export default useAiApi;
