import React, { useState, useEffect } from "react";
import {
  Flex,
  Spacer,
  Input,
  InputGroup,
  InputLeftAddon,
  Box,
  Button,
  Divider,
  useTheme,
} from "@chakra-ui/react";
import {
  faPlus,
  faTrash,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader";
import ListTable from "../../components/common/Layouts/ListTable";
import { useSnackbar } from "notistack";
import useTagsApi from "../../services/api/Tags";
import { Tag } from "../../types";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import TagModal from "./TagModal";
import { FontAwesome } from "../../components/common/icon";

const TagsIndex: React.FC = () => {
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const tagsApi = useTagsApi();
  const [currentTag, setCurrentTag] = useState(null);
  const [tagModalIsOpen, setTagModalIsOpen] = useState<boolean>(false);
  const theme = useTheme();

  const fetchTags = async () => {
    await tagsApi
      .getAll()
      .then((res) => {
        setTags(res);
        setFilteredTags(res);
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("Error fetching tags", { variant: "error" });
      });
  };
  useEffect(() => {
    fetchTags();
  }, []);

  const viewHandler = (item: any) => {
    setCurrentTag(item);
    setTagModalIsOpen(true);
  };

  const deleteDialogOnConfirm = async (item: any) => {
    try {
      await tagsApi.deleteTag(item.id);
      const updatedtags = tags.filter((t) => t.id != item.id);
      setTags(updatedtags);
      const updatedFiltertags = filteredTags.filter((t) => t.id != item.id);
      setFilteredTags(updatedFiltertags);
      enqueueSnackbar("Tag successfully deleted", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Could not delete Tag, try again later", {
        variant: "error",
      });
    }
  };

  const handleFilter = (search: string, tags: Tag[]) => {
    let filtered = tags;
    if (search.length) {
      filtered = filtered.filter((t) => t.name.toLowerCase().includes(search));
    }
    return filtered;
  };

  useEffect(() => {
    if (search.trim().length) {
      const filtered = handleFilter(search.trim().toLowerCase(), tags);
      setFilteredTags(filtered);
    } else {
      setFilteredTags(tags);
    }
  }, [search]);

  return (
    <Box w="full">
      <Flex paddingBottom={theme.spacing.base * 2}>
        <BreadcrumbsBlock
          links={[
            { title: "Props", isCurrent: false },
            { title: "Tags", isCurrent: true },
          ]}
        ></BreadcrumbsBlock>
        <Spacer />
        <Button
          leftIcon={<FontAwesome icon={faPlus} />}
          onClick={() => setTagModalIsOpen(!tagModalIsOpen)}
        >
          Create New
        </Button>
      </Flex>
      <Flex>
        <Box>
          <InputGroup>
            <InputLeftAddon>Find Tag</InputLeftAddon>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={"Enter search term..."}
            />
          </InputGroup>
        </Box>
      </Flex>
      <Divider orientation="horizontal" />

      {loading ? (
        <Loader />
      ) : (
        <>
          <ListTable
            heading={"Tags"}
            headers={[
              { title: "Actions" },
              { title: "Name" },
              { title: "Short Name" },
              { title: "Slug" },
            ]}
            list={filteredTags}
            columns={[
              { name: "name", type: "string" },
              { name: "short_name", type: "string" },
              { name: "slug", type: "string" },
            ]}
            onDelete={deleteDialogOnConfirm}
            alertTitle="Delete this Tag?"
            actions={[
              {
                name: "Edit",
                actionHandler: viewHandler,
                icon: <FontAwesome icon={faPenToSquare} />,
              },
              {
                name: "Delete",
                actionHandler: null,
                icon: <FontAwesome icon={faTrash} />,
              },
            ]}
          />
          <TagModal
            isOpen={tagModalIsOpen}
            onClose={() => {
              setTagModalIsOpen(!tagModalIsOpen);
              setCurrentTag(null);
              fetchTags();
            }}
            tag={currentTag}
          />
        </>
      )}
    </Box>
  );
};

export default TagsIndex;
