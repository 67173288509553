import { defineStyleConfig } from "@chakra-ui/react";
import { fontFamilies } from "../fonts";

export const textTheme = defineStyleConfig({
  baseStyle: {
    fontFamily: fontFamilies.body,
    fontStyle: "normal",
    fontWeight: "normal",
    color: "inherit",
  },
  variants: {
    body: {
      fontSize: { base: "15px", md: "18px" },
      lineHeight: { base: "20px", md: "24px" },
      letterSpacing: "0.1px",
    },
    body2: {
      fontSize: { base: "12px", md: "13px" },
      lineHeight: { base: "18px", md: "20px" },
      letterSpacing: "0.1px",
    },
  },
  defaultProps: {
    variant: "body",
  },
});
