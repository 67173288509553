import React from "react";
import { useSnackbar } from "notistack";
import { AiJobLeague, GamesAndTeams, ParsedSportsGame } from "../../../types";
import useAiApi from "../../../services/api/Ai";
import useJwt from "../../../hooks/useJwt";
import NewJobGameList from "../NewJobGameList";

const MlbGamePreview: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const aiApi = useAiApi();
  const { jwt } = useJwt();

  const parseGames = (gamesResponse): GamesAndTeams => {
    const teams = {};
    const mlbGames: ParsedSportsGame[] = [];
    gamesResponse.forEach((game) => {
      const home = `${game.home.market} ${game.home.name}`;
      const away = `${game.away.market} ${game.away.name}`;
      teams[home] = 1;
      teams[away] = 1;
      const scheduled = new Date(game.scheduled);
      mlbGames.push({
        id: game.id,
        home,
        away,
        date_time: game.scheduled,
        date: `${scheduled.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })}`,
        time: `${scheduled.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZoneName: "short",
        })}`,
      });
    });
    return {
      teams: Object.keys(teams),
      games: mlbGames,
    };
  };
  const fetchGames = async (): Promise<GamesAndTeams> => {
    let mlbGames = [];

    try {
      const currentDate = new Date();
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(currentDate.getDate() + 1);

      const [todaysGames, tomorrowsGames] = await Promise.allSettled([
        aiApi.getMLBGames({ date: currentDate.toISOString().split("T")[0] }),
        aiApi.getMLBGames({ date: tomorrow.toISOString().split("T")[0] }),
      ]);
      if (todaysGames.status == "fulfilled")
        mlbGames = [
          ...todaysGames.value.filter(
            (g) => new Date(g.scheduled).getTime() > new Date().getTime()
          ),
        ];
      if (tomorrowsGames.status == "fulfilled")
        mlbGames = [...mlbGames, ...tomorrowsGames.value];

      return parseGames(mlbGames);
    } catch (error) {
      enqueueSnackbar("There was an error requesting games", {
        variant: "error",
      });
    }
  };

  return (
    <div>
      <NewJobGameList
        fetchGamesCallback={fetchGames}
        path={"/ai-content/mlb/previews"}
        heading={"MLB Game Previews"}
        league={AiJobLeague.MLB}
        jobTitle={"MLB Game Preview"}
      />
    </div>
  );
};

export default MlbGamePreview;
