import React, { useState } from "react";
import { Button, ModalFooter, ModalBody, useTheme } from "@chakra-ui/react";
import { DefaultTheme } from "../../types/theme";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { TextInputGroup } from "../../components/FormElements/TextInputGroup";
import { League } from "../../types";
import useLeaguesApi from "../../services/api/League";
import CommonModal from "../../components/Modal";

interface LeagueModalProps {
  isOpen: boolean;
  onClose: () => void;
  league?: League;
}

const LeagueModal: React.FC<LeagueModalProps> = ({
  isOpen,
  onClose,
  league,
}) => {
  const [, setActive] = useState(false);
  const leaguesApi = useLeaguesApi();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme<DefaultTheme>();

  const submitLeague = async (values) => {
    if (values.id) {
      //update
      return await leaguesApi
        .updateLeague(values.id, values.label, values.description)
        .then((res) =>
          enqueueSnackbar("League updated!", { variant: "success" })
        )
        .catch((err) => {
          enqueueSnackbar(err.response.data, { variant: "error" });
          throw err;
        });
    } else {
      //create
      return await leaguesApi
        .createLeague(values.label, values.description)
        .then((res) =>
          enqueueSnackbar("League created!", { variant: "success" })
        )
        .catch((err) => {
          enqueueSnackbar(err.response.data, { variant: "error" });
          throw err;
        });
    }
  };

  return (
    <CommonModal
      header={league ? "Update League" : "Create League"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          id: league && league.id ? league.id : "",
          label: league && league.label ? league.label : "",
          description: league && league.description ? league.description : "",
        }}
        validationSchema={Yup.object().shape({
          label: Yup.string().required("Label is required"),
          description: Yup.string().required("Description is required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await submitLeague(values)
            .then((res) => {
              setSubmitting(false);
              onClose();
            })
            .catch((err) => {
              setSubmitting(false);
            });
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          handleChange,
          setFieldTouched,
          touched,
          handleBlur,
        }) => (
          <Form>
            <TextInputGroup
              placeholder="Label"
              id="label"
              value={values.label}
              onChange={handleChange}
              errors={errors.label}
              label={"Label"}
              isRequired={true}
              setFieldTouched={setFieldTouched}
              touched={touched.label}
              setActive={setActive}
            />
            <TextInputGroup
              placeholder="Description"
              id="description"
              value={values.description}
              onChange={handleChange}
              errors={errors.description}
              label={"Description"}
              isRequired={true}
              setFieldTouched={setFieldTouched}
              touched={touched.description}
              setActive={setActive}
            />
            <ModalFooter>
              <Button
                fontWeight="normal"
                fontFamily={theme.typography.fontFamilies.body}
                onClick={onClose}
              >
                Close
              </Button>
              <Button ml={3} type="submit" isDisabled={isSubmitting}>
                {league ? "Update" : "Create"}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </CommonModal>
  );
};

export default LeagueModal;
