import { Box, Input, useDisclosure } from "@chakra-ui/react";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { FontAwesome } from "../../components/common/icon";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import ListTable from "../../components/common/Layouts/ListTable";
import Loader from "../../components/Loader";
import useFansApi from "../../services/api/Fans";
import EditFan from "./EditFan";

const FansIndex = () => {
  const [fans, setFans] = useState([]);
  const [selectedFan, setSelectedFan] = useState(null);
  const { getFans } = useFansApi();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchData = async (search?: string) => {
    await getFans({ search, offset: 0, limit: 100 }).then((res) => {
      setFans(res.fans);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const searchFans = async (searchTerm: string) => {
    setTimeout(async () => {
      fetchData(searchTerm);
    }, 1000);
  };

  if (!fans.length) return <Loader />;

  return (
    <Box>
      <BreadcrumbsBlock
        links={[
          { title: "Settings", isCurrent: false },
          { title: "Fans", isCurrent: true },
        ]}
      />
      <Input
        placeholder="Search Fans..."
        onChange={(e) => searchFans(e.target.value)}
        my={4}
      />
      <ListTable
        heading="Fans"
        headers={[
          { title: "Actions" },
          { title: "ID" },
          { title: "First Name" },
          { title: "Last Name" },
          { title: "Username" },
          { title: "Phone" },
          { title: "Email" },
        ]}
        actions={[
          {
            name: "Edit",
            icon: <FontAwesome icon={faPenToSquare} />,
            actionHandler: (item) => {
              setSelectedFan(item);
              onOpen();
            },
          },
        ]}
        columns={[
          { name: "id", type: "number" },
          { name: "first_name", type: "string" },
          { name: "last_name", type: "string" },
          { name: "username", type: "string" },
          { name: "phone_number", type: "string" },
          { name: "email", type: "string" },
        ]}
        list={fans}
      />
      <EditFan isOpen={isOpen} onClose={onClose} fan={selectedFan} />
    </Box>
  );
};

export default FansIndex;
