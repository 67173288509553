import React from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { FormButtonProps } from "../../types";
import { FontAwesome } from "../common/icon";
import { faCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {},
  mobileButtons: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing.base * 4,
    "& > *": {
      height: 50,
      margin: [theme.spacing.base * 2, 0],
    },
    [theme.mediaQuery(theme.breakpoints.small)]: {
      display: "none",
    },
  },
}));

const MobileButtons: React.FC<FormButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.mobileButtons}>
        <Button
          leftIcon={<FontAwesome icon={faCheck} />}
          type="submit"
          isDisabled={props.isSubmitting}
          isLoading={props.isSubmitting}
        >
          Save
        </Button>

        <Button
          leftIcon={<FontAwesome icon={faCircleXmark} />}
          variant="outline"
          as={Link}
          to={props.cancelRoute}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default MobileButtons;
