import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, useTheme } from "@chakra-ui/react";
import { DefaultTheme } from "../../types/theme";
import { useSnackbar } from "notistack";
import { FontAwesome } from "../common/icon";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

interface DownloadCsvProps {
  data: object[];
  fileName: string;
  exclude?: string[];
}

const DownloadCsv = ({ data, exclude, fileName }: DownloadCsvProps) => {
  const [excludedData, setExcludedData] = useState<object[]>();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme<DefaultTheme>();

  useEffect(() => {
    // columns like ID can be removed here
    if (exclude) {
      const excludedData = data.map((item) => {
        const filteredItem = Object.keys(item)
          .filter((key) => !exclude.includes(key))
          .reduce((obj, key) => {
            if (!Array.isArray(item[key])) {
              obj[key] = item[key];
            }
            return obj;
          }, {});
        return filteredItem;
      });
      setExcludedData(excludedData);
    } else {
      setExcludedData(data);
    }
  }, [data, exclude]);

  if (!excludedData) return null;

  return (
    <CSVLink
      data={excludedData}
      filename={`${fileName}.csv`}
      onClick={() => {
        enqueueSnackbar("Your data is ready!", { variant: "success" });
      }}
    >
      <Button leftIcon={<FontAwesome icon={faDownload} />}>Download CSV</Button>
    </CSVLink>
  );
};

export default DownloadCsv;
