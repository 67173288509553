import axios from "axios";
import { User } from "../../../server/models/user.model";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import useJwt from "../../hooks/useJwt";

const NODE_API_URL_V1 = process.env.REACT_APP_NODE_API_URL;
const ADMIN_TOKEN = process.env.REACT_APP_ADMIN_TOKEN;

const useUsersApi = () => {
  const { privateAxiosV1 } = useJwt();

  const createUser = async ({
    first_name,
    last_name,
    email,
    password,
    publisher_token,
    publisher_id,
    role,
  }): Promise<
    | User
    | {
        message: string;
      }
  > => {
    return privateAxiosV1
      .post("/users", {
        firstName: first_name,
        lastName: last_name,
        email,
        password,
        publisherToken: publisher_token,
        publisherId: publisher_id,
        role: role,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err: Error) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const patchUser = async ({
    id,
    first_name,
    last_name,
    email,
    password,
    role,
    publisher_id,
  }) => {
    return privateAxiosV1
      .patch(`/users/${id}`, {
        first_name,
        last_name,
        email,
        password,
        role,
        publisher_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err: Error) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const resetUserMfa = async (id: string) => {
    return privateAxiosV1
      .patch(`/users/${id}`, {
        secret: null,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err: Error) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const getUsers = async () => {
    return privateAxiosV1.get("/users").catch((err: Error) => {
      pickupErrorHandlerWeb(err);
      throw err;
    });
  };

  const deleteUser = async (id: string) => {
    return privateAxiosV1.delete(`/users/${id}`).catch((err: Error) => {
      pickupErrorHandlerWeb(err);
      throw err;
    });
  };

  const recover = async (email: string, location: string) => {
    return privateAxiosV1
      .post("/users/recover", { email, location })
      .catch((err: Error) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  const getUser = async (
    userId: number | string,
    token: string
  ): Promise<User> => {
    return await privateAxiosV1
      .get("/users", {
        params: { token: token, id: userId },
      })
      .then((response) => {
        return response.data;
      })
      .catch((er: Error) => {
        pickupErrorHandlerWeb(er);
      });
  };

  const recoverWithAdminToken = async (email: string, location: string) => {
    return axios
      .post(
        `${NODE_API_URL_V1}/users/recover`,
        { email, location },
        {
          headers: { token: ADMIN_TOKEN },
        }
      )
      .catch((err: Error) => {
        pickupErrorHandlerWeb(err);
        throw err;
      });
  };

  return {
    createUser,
    patchUser,
    getUsers,
    deleteUser,
    recover,
    getUser,
    recoverWithAdminToken,
    resetUserMfa,
  };
};

export default useUsersApi;
