import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import {
  Flex,
  Box,
  FormControl,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Divider,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import usePropsApi from "../../services/api/Props";
import ListTable from "../../components/common/Layouts/ListTable";
import StatusBadge from "../../components/StatusBadge";
import PickBar from "../../components/common/pickBar";
import { ToolTipButton } from "../../components/FormElements/ToolTipButton";
import { MixpanelEvents } from "../../types";
import Chart, { chartBarColor, monthNames } from "../../components/Chart";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import Loader from "../../components/Loader";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
  },
  titleText: {
    display: "block",
    fontFamily: theme.typography.fontFamilies.headline,
    fontWeight: 400,
    fontSize: 28,
    lineHeight: "38px",
    fontStyle: "normal",
  },
  card: {
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
    margin: "16px",
  },
}));

const MixpanelEventsValues = [
  MixpanelEvents.VIEW,
  MixpanelEvents.AD,
  MixpanelEvents.SHARE,
  MixpanelEvents.MESSAGE,
  MixpanelEvents.POINT,
];

const MixpanelLabels = {
  picker_view_event: "Picker Views",
  picker_ad_clicked: "Ads Clicked",
  prop_shared: "Props Shared",
  messaging_processor_complete: "Messages Sent",
  point_worker_event_completed: "Referral Events Completed",
};

const PropInsights: React.FC = () => {
  const { prop_id } = useParams<{ prop_id: string }>();
  const classes = useStyles();
  const [prop, setProp] = useState(null);
  const [fans, setFans] = useState([]);
  const [filterFans, setFilterFans] = useState([]);
  const propsApi = usePropsApi();
  const [loading, setLoading] = useState(true);
  const [checkedItems, setCheckedItems] = useState([]);
  const [mixpanelData, setMixpanelData] = useState(null);

  const fetchInsights = async () => {
    await propsApi
      .getPropInsights(prop_id)
      .then(async (res) => {
        setProp(res.prop);
        setFans(res.fanPicks);
        setFilterFans(res.fanPicks);
        setCheckedItems(res.prop.picks.map((pick) => pick.id));
        if (res.fanPicks.length > 99) {
          const batchCalls = [];
          for (const event of MixpanelEventsValues) {
            batchCalls.push(
              await propsApi.getMixpanelInsights(event, "prop_id", [
                res.prop.id,
              ])
            );
          }
          const result = [];
          await Promise.allSettled(batchCalls).then((values) => {
            for (const resp of values) {
              if (resp.status == "fulfilled") {
                result.push(resp.value);
              }
            }
            mapChartData(result);
          });
        }
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const handleClick = (id: string) => {
    if (!checkedItems.filter((item) => item == id).length) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item != id));
    }
  };

  const mapChartData = (data: any[]) => {
    //create labels
    const labels: string[] = data[0].mixpanel.series.map((k) => {
      return monthNames[parseInt(k.split("-")[1]) - 1];
    });
    //map data
    const datasets = [];
    let index = 0;
    for (const entry of data) {
      const value = Object.values(entry.mixpanel.values);

      const values = value.length
        ? Object.values(entry.mixpanel.values[prop_id])
        : null;
      const set = {
        label: MixpanelLabels[entry.event_name],
        data: values ? values.map((v) => v) : [0, 0, 0, 0, 0, 0],
        backgroundColor: chartBarColor[index],
      };
      datasets.push(set);
      index++;
    }
    setMixpanelData({ labels: labels.reverse(), datasets: datasets });
  };

  //anytime checkeditems change update list
  useEffect(() => {
    if (fans.length) {
      const filter = fans.filter((fan) =>
        checkedItems.includes(String(fan.pick_id))
      );
      setFilterFans(filter);
    }
  }, [checkedItems]);

  useEffect(() => {
    fetchInsights();
  }, []);
  return (
    <div className={classes.root}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <BreadcrumbsBlock
            links={[
              { link: "/props/index", title: "Props", isCurrent: false },
              { title: "Insights", isCurrent: true },
            ]}
          ></BreadcrumbsBlock>
          <Divider orientation="horizontal" />
          <Flex flexDirection={"column"}>
            <Box className={classes.titleText}>{prop.proposition}</Box>

            <Box>
              {prop.tags &&
                prop.tags.map((tag, i) => (
                  <StatusBadge key={i} statusText={tag.name} />
                ))}
            </Box>

            <StatGroup>
              <Stat>
                <StatLabel>Total Picks</StatLabel>
                <StatNumber>
                  {prop.picks
                    .map((pick) => pick.fan_picks_count)
                    .reduce((a, c) => a + c, 0)}
                </StatNumber>
              </Stat>

              <Stat>
                <StatLabel>Total Verified Picks</StatLabel>
                <StatNumber>{fans.length}</StatNumber>
              </Stat>

              <Stat>
                <StatLabel>Verification Rate</StatLabel>
                <StatNumber>
                  {Math.round(
                    (fans.length /
                      prop.picks
                        .map((pick) => pick.fan_picks_count)
                        .reduce((a, c) => a + c, 0)) *
                      100
                  )}
                  %
                </StatNumber>
              </Stat>
            </StatGroup>

            {mixpanelData ? (
              <Flex flexDirection={"column"} className={classes.card}>
                <Box className={classes.titleText}>
                  Review of {mixpanelData.labels[0]} -{" "}
                  {mixpanelData.labels[mixpanelData.labels.length - 1]}
                </Box>
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                  <StatGroup flexDirection={"column"}>
                    {mixpanelData.datasets.map((data, i) => (
                      <Stat key={i} color={data.backgroundColor}>
                        <StatLabel>{data.label}</StatLabel>
                        <StatNumber>
                          {data.data.reduce((a, c) => a + c, 0)}
                        </StatNumber>
                      </Stat>
                    ))}
                  </StatGroup>

                  <Box width={500}>
                    <Chart data={mixpanelData} />
                  </Box>
                </Flex>
              </Flex>
            ) : null}
            <Flex flexWrap={"wrap"}>
              {prop.picks.map((pick, i) => (
                <Box key={i} width={300} padding={"2px 4px 2px 0"}>
                  <PickBar
                    result={Math.round(pick.pick_popularity)}
                    displayText={pick.title}
                    handleClick={handleClick}
                    pick={pick}
                  />

                  {pick.selected_tags.map((tag, i) => (
                    <StatusBadge key={i} statusText={tag.name} />
                  ))}
                </Box>
              ))}
            </Flex>
            <FormControl>
              <ToolTipButton>
                Click on picks to toggle displaying those fans who made pick
              </ToolTipButton>
            </FormControl>
          </Flex>
          <Divider orientation="horizontal" />

          <ListTable
            heading={"Fans"}
            headers={[
              { title: "Username" },
              { title: "City" },
              { title: "State" },
            ]}
            list={filterFans}
            columns={[
              { name: "username", type: "string" },
              { name: "last_known_city", type: "string" },
              { name: "last_known_state", type: "string" },
            ]}
          />
        </>
      )}
    </div>
  );
};

export default PropInsights;
