import React, { useState, useEffect } from "react";
import {
  Flex,
  Spacer,
  Input,
  InputGroup,
  InputLeftAddon,
  Box,
  Button,
  Divider,
  useTheme,
} from "@chakra-ui/react";
import {
  faPlus,
  faTrash,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader";
import ListTable from "../../components/common/Layouts/ListTable";
import { useSnackbar } from "notistack";
import { League } from "../../types";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import useLeaguesApi from "../../services/api/League";
import LeagueModal from "./LeagueModal";
import { FontAwesome } from "../../components/common/icon";

const LeaguesIndex: React.FC = () => {
  const [leagues, setLeagues] = useState([]);
  const [filteredLeagues, setFilteredLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const leaguesApi = useLeaguesApi();
  const [currentLeague, setCurrentLeague] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const theme = useTheme();

  const fetchLeagues = async () => {
    await leaguesApi
      .getAll()
      .then((res) => {
        setLeagues(res);
        setFilteredLeagues(res);
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("Error fetching leagues", { variant: "error" });
      });
  };
  useEffect(() => {
    fetchLeagues();
  }, []);

  const viewHandler = (item: any) => {
    setCurrentLeague(item);
    setModalIsOpen(true);
  };

  const deleteDialogOnConfirm = async (item: any) => {
    try {
      await leaguesApi.deleteLeague(item.id);
      const updatedtags = leagues.filter((t) => t.id != item.id);
      setLeagues(updatedtags);
      const updatedFiltertags = filteredLeagues.filter((t) => t.id != item.id);
      setFilteredLeagues(updatedFiltertags);
      enqueueSnackbar("League successfully deleted", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Could not delete League, try again later", {
        variant: "error",
      });
    }
  };

  const handleFilter = (search: string, leagues: League[]) => {
    let filtered = leagues;
    if (search.length) {
      filtered = filtered.filter((l) => l.label.toLowerCase().includes(search));
    }
    return filtered;
  };

  useEffect(() => {
    if (search.trim().length) {
      const filtered = handleFilter(search.trim().toLowerCase(), leagues);
      setFilteredLeagues(filtered);
    } else {
      setFilteredLeagues(leagues);
    }
  }, [search]);

  return (
    <Box w="full">
      <Flex paddingBottom={theme.spacing.base * 2}>
        <BreadcrumbsBlock
          links={[
            { title: "Props", isCurrent: false },
            { title: "Leagues", isCurrent: true },
          ]}
        ></BreadcrumbsBlock>
        <Spacer />
        <Button
          leftIcon={<FontAwesome icon={faPlus} />}
          onClick={() => setModalIsOpen(!modalIsOpen)}
        >
          Create New
        </Button>
      </Flex>
      <Flex>
        <Box>
          <InputGroup>
            <InputLeftAddon>Find League</InputLeftAddon>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={"Enter search term..."}
            />
          </InputGroup>
        </Box>
      </Flex>
      <Divider orientation="horizontal" />

      {loading ? (
        <Loader />
      ) : (
        <>
          <ListTable
            heading={"Leagues"}
            headers={[
              { title: "Actions" },
              { title: "Label" },
              { title: "Description" },
            ]}
            list={filteredLeagues}
            columns={[
              { name: "label", type: "string" },
              { name: "description", type: "string" },
            ]}
            onDelete={deleteDialogOnConfirm}
            alertTitle="Delete this League?"
            actions={[
              {
                name: "Edit",
                actionHandler: viewHandler,
                icon: <FontAwesome icon={faPenToSquare} />,
              },
              {
                name: "Delete",
                actionHandler: null,
                icon: <FontAwesome icon={faTrash} />,
              },
            ]}
          />
          <LeagueModal
            isOpen={modalIsOpen}
            onClose={() => {
              setModalIsOpen(!modalIsOpen);
              setCurrentLeague(null);
              fetchLeagues();
            }}
            league={currentLeague}
          />
        </>
      )}
    </Box>
  );
};

export default LeaguesIndex;
