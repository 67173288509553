import { mapArrayToMultiselect } from "../../../helpers/transformers";
import { Publisher, MultiSelectItem, CurrentUser } from "../../../types";

// Filter multiselect publisher list based on tenant
export const filterPublishersByTenant = (
  publishers: Publisher[],
  selectedPublishers: number[],
  currentUser: CurrentUser
): MultiSelectItem[] => {
  const { tenantOnly } = currentUser.permissions;
  const currentUserPublisher =
    publishers &&
    publishers.find((pub: Publisher) => pub.id === currentUser.publisher_id);
  // If user is restricted and not part of tenant, they can only see their publisher
  if (tenantOnly && !currentUserPublisher.tenant_id)
    return [currentUserPublisher];
  // Otherwise, see if we need to filter by tenant if the user is restricted or a tenant publisher has been selected
  let selectedTenant = tenantOnly && currentUserPublisher.tenant_id;
  if (!selectedTenant && selectedPublishers.length) {
    selectedTenant = publishers.find(
      (pub: Publisher) => Number(pub.id) === selectedPublishers[0]
    )?.tenant_id;
  }
  const filteredPublishers: Publisher[] = selectedTenant
    ? publishers.filter((publisher) => publisher?.tenant_id === selectedTenant)
    : publishers;
  return mapArrayToMultiselect(filteredPublishers, "name");
};
