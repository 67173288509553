import React, { useContext, useEffect, useState } from "react";
import { Picker } from "@playpickup/prop-picker";
import GlobalContext from "../GlobalContext";
import Loader from "../Loader";

const NODE_API_URL = process.env.REACT_APP_NODE_API_URL;

interface PropPickerProps {
  values: any;
  active?: boolean;
}

export const PropPickerDisplay: React.FC<PropPickerProps> = ({
  values,
  active,
}) => {
  const [displayProp, setDisplayProp] = useState(null);
  const { currentUser } = useContext(GlobalContext);
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const picks = [];
    async function getPicks() {
      for (let i = 0; i < values.pick_display_titles.length; i++) {
        if (values.pick_display_titles[i] !== "") {
          picks.push({
            id: i,
            title: "",
            created_at: new Date(),
            updated_at: null,
            state: "pending",
            fan_picks_count: 1,
            user_id: 1,
            graded_at: null,
            pick_popularity: null,
            display_title: values.pick_display_titles[i],
            prop_id: "1",
          });
        }
      }
    }
    getPicks()
      .then(() => {
        if (!active) {
          const next = (
            <Picker
              accessToken={currentUser.access_token}
              source_url={"https://fanpower.io"}
              publisher_id={currentUser.publisher_id}
              proposition={{
                id: "1",
                created_at: new Date(),
                updated_at: new Date(),
                publish_at: null,
                featured_leaderboard_id: null,
                tags: [],
                league: "nfl",
                user_id: 1,
                fan_picks_count: 0,
                utm_term_tag: null,
                utm_content_tag: null,
                close_time: null,
                close_at: null,
                close_time_id: null,
                event_id: null,
                publisher_id: String(109),
                slug: "draft-prop",
                proposition:
                  values.prop_title != ""
                    ? values.prop_title
                    : "Prop Title Displays Here",
                status: "pending",
                picks: picks,
                search_weight: null,
                deleted_at: null,
                fan_sms_graded_false: null,
                fan_sms_graded_true: null,
                grading_in_progress: null,
                contest_id: null,
              }}
              host={NODE_API_URL}
            ></Picker>
          );
          setDisplayProp(next);
        } else {
          setDisplayProp(null);
        }
      })
      .then(async () => {
        await sleep(1000);
        for (let i = picks.length - 1; i >= 0; i--) {
          const button = document.querySelector<HTMLButtonElement>(
            `[title="${picks[i].display_title}"]`
          );
          button ? button.setAttribute("style", "pointer-events:none") : null;
          button ? (button.disabled = true) : null;

          if (button) {
            button.childNodes.forEach((div) => {
              if (div.textContent.includes(picks[i].display_title)) {
                (div as HTMLElement).style.pointerEvents = "none";
              }
            });
          }
        }
      });
  }, [active]);

  return (
    <div style={{ width: 350 }}>{displayProp ? displayProp : <Loader />}</div>
  );
};
