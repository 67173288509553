import React from "react";
import AiJobGameList from "../NewJobGameList";
import { useSnackbar } from "notistack";
import {
  AiJobLeague,
  AiJobType,
  GamesAndTeams,
  ParsedSportsGame,
} from "../../../types";
import useAiApi from "../../../services/api/Ai";

const NcaafGameRecaps: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const aiApi = useAiApi();

  const parseGames = (gamesResponse, seasonType: string): GamesAndTeams => {
    const teams = {};
    const games: ParsedSportsGame[] = [];
    const conferences = [];
    const conferenceTeamMap = [];
    gamesResponse.forEach((game) => {
      const home = game.homeTeam.name;
      const away = game.awayTeam.name;
      teams[home] = 1;
      teams[away] = 1;

      if (!conferenceTeamMap.find((ctm) => ctm.team === home)) {
        conferenceTeamMap.push({
          team: home,
          conference: game.homeTeam.conference_name,
        });
      }
      if (!conferenceTeamMap.find((ctm) => ctm.team === away)) {
        conferenceTeamMap.push({
          team: away,
          conference: game.awayTeam.conference_name,
        });
      }
      if (!conferences.find((c) => c === game.homeTeam.conference_name)) {
        conferences.push(game.homeTeam.conference_name);
      }
      if (!conferences.find((c) => c === game.awayTeam.conference_name)) {
        conferences.push(game.awayTeam.conference_name);
      }

      const startTime = new Date(game.startTime);
      games.push({
        id: game.id,
        home,
        away,
        game_type: seasonType,
        date_time: game.startTime,
        date: `${startTime.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })}`,
        time: `${startTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZoneName: "short",
        })}`,
      });
    });
    return {
      conferences: { map: conferenceTeamMap, list: conferences },
      teams: Object.keys(teams),
      games: games.reverse(),
    };
  };
  const fetchGames = async (): Promise<GamesAndTeams> => {
    try {
      const currentDate = new Date();
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(currentDate.getDate() + 1);

      const gamesData = await aiApi.getNcaafRecapGames();
      const games = gamesData.games;
      const season = gamesData.season;

      return parseGames(games, season.typeCode);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("There was an error requesting games", {
        variant: "error",
      });
    }
  };

  return (
    <div>
      <AiJobGameList
        fetchGamesCallback={fetchGames}
        path={"/ai-content/ncaaf/recaps"}
        heading={"NCAAF Game Recap"}
        league={AiJobLeague.NCAAF}
        jobTitle={"NCAAF Game Recap"}
        jobType={AiJobType.SUMMARY}
      />
    </div>
  );
};

export default NcaafGameRecaps;
