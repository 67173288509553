import React, { useContext, useState } from "react";
import NoBorderLayout from "../../../components/common/Layouts/NoBorder";
import { createUseStyles } from "react-jss";
import { SingleSelectFieldset } from "../../../components/FormElements/Fieldsets/SingleSelectFieldset";
import { TagsMultiselectSet } from "../../../components/FormElements/Fieldsets/TagsMultiselectSet";
import { Form, Formik } from "formik";
import { LeaguesMultiselectSet } from "../../../components/FormElements/Fieldsets/LeaguesMultiselectSet";
import { TextInputGroup } from "../../../components/FormElements/TextInputGroup";
import GlobalContext from "../../../components/GlobalContext";
import { League, MultiSelectItem, Tag } from "../../../types";
import EmbedCodes from "./EmbedCodes";
import { Divider, Heading } from "@chakra-ui/react";

const useStyles = createUseStyles(() => ({
  wrap: {
    display: "flex",
  },
  columnLeft: {
    padding: "20px",
    minWidth: "350px",
  },
  columnRight: {
    padding: "20px",
  },
}));

export interface EmbedBuilderValues {
  type: string;
  tags: Tag[] | MultiSelectItem[] | string[] | number[] | null;
  leagues: League[] | MultiSelectItem[] | string[] | number[] | null;
  url: string | null;
  user?: any;
}

const EmbedBuilder: React.FC = () => {
  const classes = useStyles();
  const { currentUser } = useContext(GlobalContext);

  const [values, setValues] = useState<EmbedBuilderValues>({
    type: "",
    tags: [],
    leagues: [],
    url: "",
    user: currentUser,
  });

  const bread = [
    {
      title: "Props",
      link: "/prop-search",
      isCurrent: false,
    },
    {
      title: "Embed Builder",
      link: "/props/embed-builder",
      isCurrent: true,
    },
  ];
  const typeOptions = [
    {
      label: "Tags",
      value: "tags",
    },
    /*{
      label: 'URL',
      value: 'url'
    }*/
  ];

  const updateTagSelection = (selection: Tag[]) => {
    setValues({ ...values, tags: selection });
  };
  const updateLeagueSelection = (selection: MultiSelectItem[]) => {
    setValues({ ...values, leagues: selection });
  };

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValues({ ...values, type: e.target.value });
  };

  const handleOnChange = (change) => {
    if (change.target.id == "url") {
      setValues({ ...values, url: change.target.value });
    }
  };

  const onSubmit = (values: EmbedBuilderValues) => {
    //no-op
  };

  return (
    <div>
      <NoBorderLayout title={"Build a Prop Embed"} breadcrumb={bread}>
        <Divider orientation="horizontal" />
        <div className={classes.wrap}>
          <div className={classes.columnLeft}>
            <Heading variant={"heading3"}>Embed Options</Heading>
            <Formik initialValues={values} onSubmit={onSubmit}>
              <Form onChange={handleOnChange}>
                <SingleSelectFieldset
                  label={"Embed Type"}
                  id={"type"}
                  value={values.type}
                  options={typeOptions}
                  onChange={onTypeChange}
                  onBlur={() => {
                    // no-op
                  }}
                  errors={[]}
                  placeholder={"Select Type to Start"}
                  setFieldTouched={() => {
                    // no-op
                  }}
                />
                {values.type == "url" ? (
                  <div>
                    <TextInputGroup
                      label={"URL to create prop"}
                      id={"url"}
                      value={values.url}
                      onChange={handleOnChange}
                      errors={""}
                      placeholder={"target embed URL"}
                      setActive={() => {
                        // no op
                      }}
                      setFieldTouched={() => {
                        // no op
                      }}
                    />
                  </div>
                ) : null}
                {values.type == "tags" || values.type == "url" ? (
                  <div>
                    Tags
                    <TagsMultiselectSet
                      label={"Select one or more Tag"}
                      id={"tags"}
                      value={values.tags as Tag[]}
                      onChange={handleOnChange}
                      errors={""}
                      placeholder={"Select Tags to Start"}
                      reset={false}
                      updateSelectionCallback={updateTagSelection}
                    />
                    <LeaguesMultiselectSet
                      label={"Select a League"}
                      id={"league"}
                      value={values.leagues as League[]}
                      onChange={handleOnChange}
                      errors={""}
                      placeholder={"Search for a Leauge"}
                      reset={false}
                      updateSelectionCallback={updateLeagueSelection}
                    />
                  </div>
                ) : null}
              </Form>
            </Formik>
          </div>
          <div className={classes.columnRight}>
            <Heading variant={"heading3"}>Embed code</Heading>

            <EmbedCodes
              type={values.type}
              tags={values.tags}
              leagues={values.leagues}
              url={values.url}
              user={currentUser}
            />
          </div>
        </div>
      </NoBorderLayout>
    </div>
  );
};

export default EmbedBuilder;
