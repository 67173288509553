import React, { useState, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import { CopyButtonProps } from "../../../types";

const CopyButton: React.FC<CopyButtonProps> = ({
  propId,
  onClick,
  size = "xs",
}) => {
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        setClicked(false);
      }, 2000);
    }
  }, [clicked]);

  return (
    <Button
      {...(propId && { "data-prop-id": propId })}
      onClick={(e) => {
        e.preventDefault();
        setClicked(true);
        onClick();
      }}
      size={size}
    >
      {clicked ? "Copied" : "Copy"}
    </Button>
  );
};

export default CopyButton;
