import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import React, { ChangeEventHandler } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  useTheme,
} from "@chakra-ui/react";
import MultiSelect from "../MultiSelect";
import { mapArrayToMultiselect } from "../../helpers/transformers";
import { DraftPick, DraftProp, MultiSelectItem, Publisher } from "../../types";
import DatePicker from "../DatePicker";
import MobileButtons from "../FormElements/MobileButtons";
import { Form, FormikErrors, FormikTouched, FormikValues } from "formik";
import { PickGroupList } from "./PickGroupList";
import { PropPickerDisplay } from "./PropPicker";
import { ToolTipButton } from "../FormElements/ToolTipButton";
import DesktopButtons from "../FormElements/DesktopButtons";
import { TagsMultiselectSet } from "../FormElements/Fieldsets/TagsMultiselectSet";
import PropCard from "../../views/PropSearch/PropCard";
import useAiApi from "../../services/api/Ai";
import { GeneratedTagsList } from "./GeneratedTagsList";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      flexDirection: "row",
    },
  },
  left: {
    width: 320,
  },
  formComponent: {
    marginBottom: theme.spacing.base * 5,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      marginRight: theme.spacing.base * 4,
      minHeight: 0,
      width: 450,
    },
  },
  subheading: {
    fontWeight: 300,
    marginBottom: 20,
    fontFamily: theme.typography.fontFamilies.body,
  },
  label: {
    fontFamily: theme.typography.fontFamilies.body,
    fontSize: 15,
    color: theme.colors.grey.dark,
  },
  tip: {
    color: "#1AA1FE",
    whiteSpace: "nowrap",
    padding: "4px 2px 4px 2px",

    borderRadius: theme.spacing.base,
    "&:hover": {
      backgroundColor: theme.colors.grey.base,
    },
  },
  picker: {
    display: "block",
  },
  emBold: {
    fontWeight: "bold",
  },
  autoTagWrap: {
    paddingBottom: "16px",
    "& p": {
      fontSize: "80%",
      fontWeight: "bold",
    },
  },
}));

export interface PropFormProps {
  isSubmitting: boolean;
  values: FormikValues;
  handleChange: ChangeEventHandler;
  errors: FormikErrors<FormikValues>;
  leagues: MultiSelectItem[];
  tags: MultiSelectItem[];
  reset: boolean;
  touched: FormikTouched<FormikValues>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean
  ) => void;
  picks: DraftPick[];
  draftProp: DraftProp;
  loading: boolean;
  draftPickUpdateHandler: (picks: DraftPick[]) => void;
  triggerPickUpdate: () => void;
  removePickHandler: (index: number, removedPick: DraftPick | null) => void;
  active?: boolean;
  setActive?: (active: boolean) => void;
  publisher?: Publisher;
  showAutoTagButton?: boolean;
}

export const PropCreateEditForm: React.FC<PropFormProps> = ({
  isSubmitting,
  values,
  handleChange,
  errors,
  leagues,
  tags,
  reset,
  setFieldTouched,
  setFieldValue,
  picks,
  draftProp,
  loading,
  draftPickUpdateHandler,
  removePickHandler,
  active,
  setActive,
  publisher,
}) => {
  const classes = useStyles();
  const aiApi = useAiApi();
  const theme = useTheme<DefaultTheme>();
  const [suggestedTags, setSuggestedTags] = React.useState([]);
  const [suggestedPickTags, setSuggestedPickTags] = React.useState([]);
  const [selectedSuggestedTag, setSelectedSuggestedTag] = React.useState("");
  const [autoTagLoading, setAutoTagLoading] = React.useState(false);
  const autoTaggingAllowed = () => {
    if (!values) {
      return false;
    }
    return (
      values.prop_title &&
      values.pick_display_titles[0] &&
      values.pick_display_titles[1] &&
      suggestedTags.length < 1
    );
  };

  let showAutoTagButton = autoTaggingAllowed();

  const onChange = (e) => {
    if (autoTaggingAllowed()) {
      showAutoTagButton = true;
    }
  };

  const requestAutoTags = async () => {
    setAutoTagLoading(true);
    const prop = values.prop_title;
    const picks = values.pick_display_titles.filter((p) => p);
    const tags = await aiApi.generatePropTags({ prop, picks });
    setSuggestedTags(tags.question);
    setSuggestedPickTags(tags.choices);
    setAutoTagLoading(false);
  };

  const addSuggestedTag = (e) => {
    e.preventDefault();
    const tag = e.target.value;

    setSelectedSuggestedTag(tag);
    setSuggestedTags(suggestedTags.filter((t) => t !== tag));
  };

  return (
    <div>
      <Form onChange={onChange}>
        <div className={classes.container}>
          <div style={{ display: "block" }}>
            <Heading>
              {draftProp && draftProp.id
                ? "Edit Draft Prop"
                : "Create Draft Prop"}
            </Heading>
            <div style={{ marginBottom: 20 }}>
              <DesktopButtons
                isSubmitting={isSubmitting}
                cancelRoute={"/props/index"}
              ></DesktopButtons>
            </div>
            <Text mb={10}>* Required fields</Text>
            <Text
              style={{
                marginBottom: 10,
                maxWidth: 450,
                border: "1px solid gray",
                borderRadius: 4,
                padding: 10,
                fontSize: "10pt",
              }}
            >
              *<span className={classes.emBold}>Help Tips</span>: Use the the{" "}
              <Box as="span" className={classes.tip}>
                Help
              </Box>{" "}
              for each field for more information. <br />
              Leaving fields blank or incomplete is OK as it will be reviewed
              before publishing.
            </Text>

            <FormControl className={classes.formComponent} isRequired>
              <FormLabel className={classes.label}>Prop Title</FormLabel>
              <Input
                placeholder="Prop Title"
                name={"prop_title"}
                id="proposition_title"
                value={values.prop_title}
                maxLength={75}
                onChange={handleChange}
                onFocus={() => setActive(true)}
                onBlur={() => setActive(false)}
              />
              <Text
                className={classes.label}
                style={{ margin: "8px 0px" }}
              >{`${values.prop_title.length}/75 Characters`}</Text>
              <ToolTipButton>
                Prop Title is the question you want to present to the fan.
                Example: &quot;Who will win the Super Bowl?&quot;
              </ToolTipButton>
            </FormControl>
            <FormErrorMessage fontSize={14}>
              {errors.prop_title as string}
            </FormErrorMessage>
            <FormControl className={classes.formComponent}>
              <MultiSelect
                showAsRequired={true}
                name="leagues"
                items={leagues}
                values={
                  values.leagues
                    ? mapArrayToMultiselect(
                        values.leagues as MultiSelectItem[],
                        "label"
                      )
                    : null
                }
                label="Leagues"
                placeholder="Search league..."
                reset={reset}
                className={classes.formComponent}
              />
              <ToolTipButton>
                League examples are &quot;NFL&quot;, &quot;MLB&quot;,
                &quot;NBA&quot;, &quot;NASCAR&quot;, &quot;Other&quot; and more.
                If you cannot locate the desired league by typing the value into
                the field, select &quot;Other&quot;. The FanPower team can
                correct it if needed.
              </ToolTipButton>
            </FormControl>

            <FormControl className={classes.formComponent}>
              <>
                <FormLabel>
                  Prop Close Date/Time TZ(
                  {Intl.DateTimeFormat().resolvedOptions().timeZone})
                </FormLabel>
                <DatePicker
                  id="close_date"
                  selectedDate={
                    values.close_date ? new Date(values.close_date) : null
                  }
                  onBlur={() => {
                    setFieldTouched("close_date", true, true);
                  }}
                  onChange={(e) => {
                    setFieldValue("close_date", e);
                  }}
                  isClearable={true}
                />
                {errors.close_date}
                <FormErrorMessage fontSize={14}></FormErrorMessage>
                <ToolTipButton>
                  Prop close is the date and time that the prop will no longer
                  accept picks from fans, typically set before the event begins.
                </ToolTipButton>
              </>
            </FormControl>
            <FormControl className={classes.formComponent}>
              <div className={classes.autoTagWrap}>
                <Heading variant="heading2">AI Generated Tags</Heading>
                {!showAutoTagButton ? (
                  <Text>
                    Provide a complete prop (title and 2 or more props) to get
                    tag suggestions
                  </Text>
                ) : (
                  ""
                )}
                {showAutoTagButton ? (
                  <Button isLoading={autoTagLoading} onClick={requestAutoTags}>
                    Get Suggested Tags
                  </Button>
                ) : (
                  ""
                )}

                {suggestedTags.length > 0 ? (
                  <GeneratedTagsList
                    suggestedTags={suggestedTags}
                    onClick={addSuggestedTag}
                  />
                ) : null}
              </div>

              <TagsMultiselectSet
                id="tags"
                value={values.tags}
                label="Tags"
                placeholder="Search tags..."
                createNew={true}
                reset={reset}
                isRequired={false}
                onChange={handleChange}
                errors={""}
                setActive={setActive}
                touched={null}
                className={classes.formComponent}
                externalTag={selectedSuggestedTag}
              />
              <ToolTipButton>
                Tags on a prop should be related to the subject of the prop
                &mdash; a specific event location, athlete performance,
                conference, or division. The primary purpose is to track data
                retroactively.
              </ToolTipButton>
            </FormControl>
            {document.documentElement.clientWidth < theme.breakpoints.medium ? (
              <div>
                <PropPickerDisplay values={values} active={active} />
                {draftProp && draftProp.id && draftProp.status != "draft" ? (
                  <div style={{ marginTop: 20 }}>
                    <PropCard draftProp={draftProp} publisher={publisher} />
                  </div>
                ) : null}
              </div>
            ) : null}

            <Heading as={"h3"} size={"lg"} className={classes.subheading}>
              Picks ({picks.length} of 10)
            </Heading>

            {
              <PickGroupList
                draftPicks={picks}
                onChange={handleChange}
                errors={errors}
                tags={tags}
                reset={reset}
                values={values}
                loading={loading}
                draftPickUpdateHandler={draftPickUpdateHandler}
                setActive={setActive}
                setFieldValue={setFieldValue}
                removePickHandler={removePickHandler}
                suggestedPickTags={suggestedPickTags}
              />
            }

            <MobileButtons isSubmitting={isSubmitting} cancelRoute={"/props"} />
          </div>
          {document.documentElement.clientWidth >= theme.breakpoints.medium ? (
            <div className={classes.picker}>
              <PropPickerDisplay values={values} active={active} />
              {draftProp && draftProp.id && draftProp.status != "draft" ? (
                <div style={{ marginTop: 20 }}>
                  <PropCard draftProp={draftProp} publisher={publisher} />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </Form>
    </div>
  );
};
