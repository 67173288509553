import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "./types/theme";
import RestrictedRoute from "./components/RestrictedRoute";
import SiteHeader from "./components/common/AppHeader";
import AppContainer from "./components/common/AppContainer";
import Sidebar from "./components/Sidebar";
import { appStyles } from "./constants/styles";
import PageContainer from "./components/common/PageContainer";
import GlobalContext from "./components/GlobalContext";
import Login from "./views/Login";
import Recovery from "./views/Recovery";
import ThankYouReset from "./views/ThankYouReset";
import ResetPassword from "./views/ResetPassword";
import UserSignUp from "./views/SignUp/User";
import PropSearch from "./views/PropSearch";
import Profile from "./views/Profile";
import useWindowDimensions from "./components/common/useWindowDimensions";
import AdZones from "./views/AdZones";
import CreateAd from "./views/Ads/Create";
import CreateAdZone from "./views/AdZones/Create";
import Users from "./views/Users";
import Ads from "./views/Ads";
import CreateUser from "./views/Users/Create";
import PropsIndex from "./views/Props";
import PropsCreateIndex from "./views/Props/Create";
import PropsEditIndex from "./views/Props/Edit";
import Integrations from "./views/Integrations";
import PublisherSignUp from "./views/SignUp/Publisher";
import CarouselCreateEdit from "./views/Props/Carousels/CreateEdit";
import CarouselsIndex from "./views/Props/Carousels";
import { Reporting } from "./views/Reporting";
import PublishersIndex from "./views/Publishers";
import PublisherSettings from "./views/Publishers/PublisherSettings";
import Tutorials from "./views/Tutorials";
import FanEngagementTemplates from "./views/FanEngagement/Templates";
import ContentIndex from "./views/AiContent";
import JobView from "./views/AiContent/JobView";
import NflGamePreviews from "./views/AiContent/Nfl/NflPreview";
import NcaafGamePreviews from "./views/AiContent/Ncaaf/NcaafPreview";
import MlbGamePreview from "./views/AiContent/Mlb/MlbPreview";
import PersonaIndex from "./views/Personas";
import PersonasCreateEdit from "./views/Personas/CreateEdit";
import NflGameRecaps from "./views/AiContent/Nfl/NflRecap";
import useJwt from "./hooks/useJwt";
import NcaafGameRecaps from "./views/AiContent/Ncaaf/NcaafRecap";
import FanInsights from "./views/Insights/FanInsights";
import EmbedBuilder from "./views/Props/Embed/Builder";
import NascarPreviews from "./views/AiContent/Nascar/NascarPreview";
import PropInsights from "./views/Insights/PropInsights";
import TagsIndex from "./views/Tags";
import EmbedsIndex from "./views/Embeds";
import CreateEmbed from "./views/Embeds/Create";
import axios, { AxiosResponse } from "axios";
import { useTheme } from "@chakra-ui/react";
import ContestIndex from "./views/Contests";
import ContestDetailView from "./views/Contests/ContestDetailView";
import LeaguesIndex from "./views/Leagues";
import GettingStarted from "./views/GettingStarted";
import TenantsIndex from "./views/Tenants";
import FansIndex from "./views/Fans";

const REACT_APP_GTM = process.env.REACT_APP_GTM;

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    position: "relative",
    width: "100%",
    minHeight: `calc(100vh - ${appStyles.headerHeight}px)`,
    backgroundColor: theme.colors.grey.light,
    boxSizing: "border-box",
    overflow: "overlay",
    overflowY: "hidden",
  },
}));

// Function to load Google Tag Manager
const loadGoogleTagManager = () => {
  axios.get("/api/nonce").then((res: AxiosResponse) => {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GTM}`;
    script.async = true;
    document.head.appendChild(script);
    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.nonce = res.data;
    script2.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${REACT_APP_GTM}'); console.log("gtm fired")`;
    document.head.appendChild(script2);
  });
};

const App: React.FC = () => {
  const classes = useStyles();
  const { currentUser, showSidebar, isGlobalDataReady } =
    useContext(GlobalContext);
  const { width } = useWindowDimensions();
  const theme = useTheme<DefaultTheme>();

  // Load Google Tag Manager on initial app load
  useEffect(() => {
    loadGoogleTagManager();
  }, []);

  const sideBarStatus = (): boolean => {
    // desktop
    if (width > theme.breakpoints.medium && currentUser) {
      return true;
    } else if (width > theme.breakpoints.medium && !currentUser) {
      return false;
    }

    // mobile
    if (width <= theme.breakpoints.medium) {
      return showSidebar;
    }
  };

  return (
    <div className={classes.root}>
      <Router>
        <SiteHeader />
        <AppContainer>
          <Sidebar showSidebar={sideBarStatus()} />
          <PageContainer>
            {isGlobalDataReady ? (
              <Routes>
                <Route
                  path="/"
                  element={
                    <Navigate to={currentUser ? "/prop-search" : "/login"} />
                  }
                />
                <Route
                  path="/login"
                  element={
                    !currentUser ? <Login /> : <Navigate to="/prop-search" />
                  }
                />
                <Route
                  path="/login/recovery"
                  element={
                    !currentUser ? <Recovery /> : <Navigate to="/prop-search" />
                  }
                />
                <Route
                  path="/login/thankyou"
                  element={
                    !currentUser ? (
                      <ThankYouReset />
                    ) : (
                      <Navigate to="/prop-search" />
                    )
                  }
                />
                <Route
                  path="/login/recovery/reset"
                  element={
                    !currentUser ? (
                      <ResetPassword />
                    ) : (
                      <Navigate to="/prop-search" />
                    )
                  }
                />
                {/*<Route exact path="/sign-up/user">
                {!currentUser ? <UserSignUp /> : <Redirect to="/prop-search" />}
              </Route>*/}
                {/* the 'user sign up' route is a security vulnerability.
               if we need this type of page we need to rethink it. */}

                <Route path="/sign-up" element={<PublisherSignUp />} />

                <Route
                  path="/sign-up/publisher"
                  element={<Navigate to="/sign-up" />}
                />

                <Route
                  path="/prop-search"
                  element={
                    <RestrictedRoute
                      userPermission="propSearch"
                      fallbackRoute="/profile"
                      component={PropSearch}
                    />
                  }
                />
                <Route
                  path="/prop-carousels/create"
                  element={
                    <RestrictedRoute
                      userPermission="propCarousels"
                      fallbackRoute="/props-search"
                      component={CarouselCreateEdit}
                    />
                  }
                />
                <Route
                  path="/prop-carousels/:carousel_id/edit"
                  element={
                    <RestrictedRoute
                      userPermission="propCarousels"
                      fallbackRoute="/props-search"
                      component={CarouselCreateEdit}
                    />
                  }
                />
                <Route
                  path="/integrations"
                  element={
                    <RestrictedRoute
                      userPermission="propSearch"
                      fallbackRoute="/profile"
                      component={Integrations}
                    />
                  }
                />
                <Route
                  path="/tutorials"
                  element={
                    <RestrictedRoute
                      userPermission="propSearch"
                      fallbackRoute="/profile"
                      component={Tutorials}
                    />
                  }
                />
                <Route
                  path="/getting-started"
                  element={
                    <RestrictedRoute
                      userPermission="propSearch"
                      fallbackRoute="/profile"
                      component={GettingStarted}
                    />
                  }
                />
                <Route
                  path="/prop-carousels"
                  element={
                    <RestrictedRoute
                      userPermission="propCarousels"
                      fallbackRoute="/prop-search"
                      component={CarouselsIndex}
                    />
                  }
                />
                <Route
                  path="/props/index"
                  element={
                    <RestrictedRoute
                      userPermission="self_serve_props_access"
                      fallbackRoute="/prop-search"
                      component={PropsIndex}
                    />
                  }
                />
                <Route
                  path="/props/create"
                  element={
                    <RestrictedRoute
                      userPermission="self_serve_props_access"
                      fallbackRoute="/props-search"
                      component={PropsCreateIndex}
                    />
                  }
                />
                <Route
                  path="/props/:propId/edit"
                  element={
                    <RestrictedRoute
                      userPermission="self_serve_props_access"
                      fallbackRoute="/prop-search"
                      component={PropsEditIndex}
                    />
                  }
                />
                <Route
                  path="/profile"
                  element={
                    !currentUser ? <Navigate to="/login" /> : <Profile />
                  }
                />
                <Route
                  path="/ad-zones"
                  element={
                    <RestrictedRoute
                      userPermission="adZones"
                      fallbackRoute="/prop-search"
                      component={AdZones}
                    />
                  }
                />
                <Route
                  path="/ad-zones/create"
                  element={
                    <RestrictedRoute
                      userPermission="adZones"
                      fallbackRoute="/prop-search"
                      component={CreateAdZone}
                    />
                  }
                />
                <Route
                  path="/ad-zones/edit"
                  element={
                    <RestrictedRoute
                      userPermission="adZones"
                      fallbackRoute="/prop-search"
                      component={CreateAdZone}
                    />
                  }
                />
                <Route
                  path="/users"
                  element={
                    <RestrictedRoute
                      userPermission="manageUsers"
                      fallbackRoute="/prop-search"
                      component={Users}
                    />
                  }
                />
                <Route
                  path="/users/create"
                  element={
                    <RestrictedRoute
                      userPermission="manageUsers"
                      fallbackRoute="/prop-search"
                      component={CreateUser}
                    />
                  }
                />
                <Route
                  path="/users/edit/:userId"
                  element={
                    <RestrictedRoute
                      userPermission="manageUsers"
                      fallbackRoute="/prop-search"
                      component={CreateUser}
                    />
                  }
                />
                <Route
                  path="/ads"
                  element={
                    <RestrictedRoute
                      userPermission="adEditor"
                      fallbackRoute="/prop-search"
                      component={Ads}
                    />
                  }
                />
                <Route
                  path="/ads/create"
                  element={
                    <RestrictedRoute
                      userPermission="adEditor"
                      fallbackRoute="/prop-search"
                      component={CreateAd}
                    />
                  }
                />
                <Route
                  path="/ads/edit"
                  element={
                    <RestrictedRoute
                      userPermission="adEditor"
                      fallbackRoute="/prop-search"
                      component={CreateAd}
                    />
                  }
                />
                <Route
                  path="/reporting"
                  element={
                    <RestrictedRoute
                      userPermission="viewReporting"
                      fallbackRoute="/prop-search"
                      component={Reporting}
                    />
                  }
                />
                <Route
                  path="/publishers"
                  element={
                    <RestrictedRoute
                      userPermission="publisherSettings"
                      fallbackRoute="/prop-search"
                      component={PublishersIndex}
                    />
                  }
                />
                <Route
                  path="/publishers/:publisherId/edit"
                  element={
                    <RestrictedRoute
                      userPermission="publisherSettings"
                      fallbackRoute="/prop-search"
                      component={PublisherSettings}
                    />
                  }
                />
                <Route
                  path="/re-engagement"
                  element={
                    <RestrictedRoute
                      userPermission="fanEngagement"
                      fallbackRoute="/prop-search"
                      component={FanEngagementTemplates}
                    />
                  }
                />
                <Route
                  path="/ai-content/index"
                  element={
                    <RestrictedRoute
                      userPermission="aiContentView"
                      fallbackRoute="/prop-search"
                      component={ContentIndex}
                    />
                  }
                />

                <Route
                  path="/ai-content/:jobId/view"
                  element={
                    <RestrictedRoute
                      userPermission="aiContentView"
                      fallbackRoute="/prop-search"
                      component={JobView}
                    />
                  }
                />
                <Route
                  path="/ai-content/mlb/previews"
                  element={
                    <RestrictedRoute
                      userPermission="aiContentCreate"
                      fallbackRoute="/prop-search"
                      component={MlbGamePreview}
                    />
                  }
                />
                <Route
                  path="/ai-content/nfl/previews"
                  element={
                    <RestrictedRoute
                      userPermission="aiContentCreate"
                      fallbackRoute="/prop-search"
                      component={NflGamePreviews}
                    />
                  }
                />
                <Route
                  path="/ai-content/nfl/recaps"
                  element={
                    <RestrictedRoute
                      userPermission="aiContentCreate"
                      fallbackRoute="/prop-search"
                      component={NflGameRecaps}
                    />
                  }
                />
                <Route
                  path="/ai-content/ncaaf/previews"
                  element={
                    <RestrictedRoute
                      userPermission="aiContentCreate"
                      fallbackRoute="/prop-search"
                      component={NcaafGamePreviews}
                    />
                  }
                />
                <Route
                  path="/ai-content/ncaaf/recaps"
                  element={
                    <RestrictedRoute
                      userPermission="aiContentCreate"
                      fallbackRoute="/prop-search"
                      component={NcaafGameRecaps}
                    />
                  }
                />
                <Route
                  path="/ai-content/nascar/previews"
                  element={
                    <RestrictedRoute
                      userPermission="aiContentCreate"
                      fallbackRoute="/prop-search"
                      component={NascarPreviews}
                    />
                  }
                />
                <Route
                  path="/personas/create"
                  element={
                    <RestrictedRoute
                      userPermission="fanpoweradmin"
                      fallbackRoute="/props-search"
                      component={PersonasCreateEdit}
                    />
                  }
                />
                <Route
                  path="/personas/:persona_id/edit"
                  element={
                    <RestrictedRoute
                      userPermission="fanpoweradmin"
                      fallbackRoute="/props-search"
                      component={PersonasCreateEdit}
                    />
                  }
                />
                <Route
                  path="/personas"
                  element={
                    <RestrictedRoute
                      userPermission="fanpoweradmin"
                      fallbackRoute="/prop-search"
                      component={PersonaIndex}
                    />
                  }
                />
                <Route
                  path="/insights/fans"
                  element={
                    <RestrictedRoute
                      userPermission="propSearch"
                      fallbackRoute="/prop-search"
                      component={FanInsights}
                    />
                  }
                />
                <Route
                  path="/props/:prop_id/insight"
                  element={
                    <RestrictedRoute
                      userPermission="propSearch"
                      fallbackRoute="/prop-search"
                      component={PropInsights}
                    />
                  }
                />
                <Route
                  path="/props/embed-builder"
                  element={
                    <RestrictedRoute
                      userPermission="propSearch"
                      fallbackRoute="/prop-search"
                      component={EmbedBuilder}
                    />
                  }
                />
                <Route
                  path="/props/tags"
                  element={
                    <RestrictedRoute
                      userPermission="fanpoweradmin"
                      fallbackRoute="/prop-search"
                      component={TagsIndex}
                    />
                  }
                />
                <Route
                  path="/props/leagues"
                  element={
                    <RestrictedRoute
                      userPermission="fanpoweradmin"
                      fallbackRoute="/prop-search"
                      component={LeaguesIndex}
                    />
                  }
                />
                <Route
                  path="/props/embeds"
                  element={
                    <RestrictedRoute
                      userPermission="propSearch"
                      fallbackRoute="/prop-search"
                      component={EmbedsIndex}
                    />
                  }
                />
                <Route
                  path="/props/embeds/create"
                  element={
                    <RestrictedRoute
                      userPermission="propSearch"
                      fallbackRoute="/prop-search"
                      component={CreateEmbed}
                    />
                  }
                />
                <Route
                  path="/props/embeds/view/:id"
                  element={
                    <RestrictedRoute
                      userPermission="propSearch"
                      fallbackRoute="/prop-search"
                      component={CreateEmbed}
                    />
                  }
                />
                <Route
                  path="/props/contests"
                  element={
                    <RestrictedRoute
                      userPermission="publisherSettings"
                      fallbackRoute="/prop-search"
                      component={ContestIndex}
                    />
                  }
                />
                <Route
                  path="/props/contests/view"
                  element={
                    <RestrictedRoute
                      userPermission="publisherSettings"
                      fallbackRoute="/prop-search"
                      component={ContestDetailView}
                    />
                  }
                />
                <Route
                  path="/props/contests/view/:id"
                  element={
                    <RestrictedRoute
                      userPermission="publisherSettings"
                      fallbackRoute="/prop-search"
                      component={ContestDetailView}
                    />
                  }
                />
                <Route
                  path={"/settings/tenants"}
                  element={
                    <RestrictedRoute
                      userPermission="fanpoweradmin"
                      fallbackRoute="/prop-search"
                      component={TenantsIndex}
                    />
                  }
                />
                <Route
                  path={"/settings/tenants/:tenant_id"}
                  element={
                    <RestrictedRoute
                      userPermission="fanpoweradmin"
                      fallbackRoute="/prop-search"
                      component={TenantsIndex}
                    />
                  }
                />
                <Route
                  path={"/settings/fans"}
                  element={
                    <RestrictedRoute
                      userPermission="fanpoweradmin"
                      fallbackRoute="/prop-search"
                      component={FansIndex}
                    />
                  }
                />
              </Routes>
            ) : null}
          </PageContainer>
        </AppContainer>
      </Router>
    </div>
  );
};

export default App;
