import React from "react";
import { Box, Heading, Link, Text } from "@chakra-ui/react";

const AiDisclaimer: React.FC = () => {
  return (
    <Box
      mt={4}
      p={4}
      maxW="800px"
      border="1px"
      borderColor="#ffc65c"
      borderRadius="4px"
      bg="#ffc65c36"
      boxShadow="md"
    >
      <Heading>Written by AI, Human Review Still Required:</Heading>
      <Text>
        Our content is generated by an AI in its beta phase and needs thorough
        review before publishing.
      </Text>
      <Text>
        If you have questions or feedback, please contact{" "}
        <Link href={"mailto:support@fanpower.io"}>support@fanpower.io</Link>.
        Your input is crucial for enhancing the quality and precision of our
        content.
      </Text>
    </Box>
  );
};

export default AiDisclaimer;
