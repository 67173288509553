import React from "react";
import { useSnackbar } from "notistack";

import { AiJobLeague, GamesAndTeams, ParsedSportsGame } from "../../../types";
import useAiApi from "../../../services/api/Ai";

import AiJobGameList from "../NewJobGameList";

const NflGamePreviews: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const aiApi = useAiApi();

  const parseGames = (gamesResponse, seasonType: string): GamesAndTeams => {
    const teams = {};
    const games: ParsedSportsGame[] = [];
    gamesResponse.forEach((game) => {
      const home = game.homeTeam.name;
      const away = game.awayTeam.name;
      teams[home] = 1;
      teams[away] = 1;
      const startTime = new Date(game.startTime);
      games.push({
        id: game.id,
        home,
        away,
        game_type: seasonType,
        date_time: game.startTime,
        date: `${startTime.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })}`,
        time: `${startTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZoneName: "short",
        })}`,
      });
    });
    return {
      teams: Object.keys(teams),
      games: games,
    };
  };
  const fetchGames = async (): Promise<GamesAndTeams> => {
    try {
      const currentDate = new Date();
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(currentDate.getDate() + 1);

      const gamesData = await aiApi.getNflPreviewGames({
        date: currentDate.toISOString().split("T")[0],
      });
      let games = gamesData.games;
      const season = gamesData.season;
      games = games.filter(
        (g) => new Date(g.startTime).getTime() > new Date().getTime()
      );

      return parseGames(games, season.typeCode);
    } catch (error) {
      enqueueSnackbar("There was an error requesting games", {
        variant: "error",
      });
    }
  };

  return (
    <div>
      <AiJobGameList
        fetchGamesCallback={fetchGames}
        path={"/ai-content/nfl/previews"}
        heading={"NFL Game Previews"}
        league={AiJobLeague.NFL}
        jobTitle={"NFL Game Preview"}
      />
    </div>
  );
};

export default NflGamePreviews;
