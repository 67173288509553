import React, { useState } from "react";
import { Button, ModalFooter, useTheme } from "@chakra-ui/react";
import { DefaultTheme } from "../../types/theme";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { TextInputGroup } from "../../components/FormElements/TextInputGroup";
import useTagsApi from "../../services/api/Tags";
import { Tag } from "../../types";
import CommonModal from "../../components/Modal";

interface TagModalProps {
  isOpen: boolean;
  onClose: () => void;
  tag?: Tag;
}

const TagModal: React.FC<TagModalProps> = ({ isOpen, onClose, tag }) => {
  const [, setActive] = useState(false);
  const tagsApi = useTagsApi();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme<DefaultTheme>();

  const submitTag = async (values) => {
    if (values.id) {
      //update
      return await tagsApi
        .update(values.id, values.name, values.short_name)
        .then((res) => enqueueSnackbar("Tag updated!", { variant: "success" }))
        .catch((err) => {
          enqueueSnackbar(err.response.data, { variant: "error" });
          throw err;
        });
    } else {
      //create
      return await tagsApi
        .create(values.name, values.short_name)
        .then((res) => enqueueSnackbar("Tag created!", { variant: "success" }))
        .catch((err) => {
          enqueueSnackbar(err.response.data, { variant: "error" });
          throw err;
        });
    }
  };

  return (
    <CommonModal
      header={tag ? "Update Tag" : "Create Tag"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          id: tag && tag.id ? tag.id : "",
          name: tag && tag.name ? tag.name : "",
          short_name: tag && tag.short_name ? tag.short_name : "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required"),
          short_name: Yup.string().required("Short Name is required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await submitTag(values)
            .then((res) => {
              setSubmitting(false);
              onClose();
            })
            .catch((err) => {
              setSubmitting(false);
            });
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          handleChange,
          setFieldTouched,
          touched,
          handleBlur,
        }) => (
          <Form>
            <TextInputGroup
              placeholder="Name"
              id="name"
              value={values.name}
              onChange={handleChange}
              errors={errors.name}
              label={"Name"}
              isRequired={true}
              setFieldTouched={setFieldTouched}
              touched={touched.name}
              setActive={setActive}
            />
            <TextInputGroup
              placeholder="Short Name"
              id="short_name"
              value={values.short_name}
              onChange={handleChange}
              errors={errors.short_name}
              label={"Short Name"}
              isRequired={true}
              setFieldTouched={setFieldTouched}
              touched={touched.short_name}
              setActive={setActive}
            />
            <ModalFooter>
              <Button
                fontWeight="normal"
                fontFamily={theme.typography.fontFamilies.body}
                onClick={onClose}
              >
                Close
              </Button>
              <Button ml={3} type="submit" isDisabled={isSubmitting}>
                {tag ? "Update" : "Create"}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </CommonModal>
  );
};

export default TagModal;
