import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Textarea,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import Loader from "../../components/Loader";
import { useFormik } from "formik";
import useEmbedsApi from "../../services/api/Embeds";
import * as Yup from "yup";
import RenderContentType from "./RenderContentType";
import GlobalContext from "../../components/GlobalContext";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { DefaultTheme } from "../../types/theme";
import { EmbedTarget, EmbedTargetRecord, Publisher } from "../../types";
import StandalonePicker from "../../components/Props/StandalonePicker";
import usePublishersApi from "../../services/api/Publishers";

const NODE_API_URL = process.env.REACT_APP_NODE_API_URL || "";

export interface EmbedTargetFormValues {
  name: string;
  description: string;
  content_type: string;
  prop_id: number | null;
  carousel_id: number | null;
  prop_collection: number[];
  tags: number[];
  leagues: number[];
  sort_by: string;
  publisher_id: number;
}

const CreateEmbed = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [viewEmbed, setViewEmbed] = useState<EmbedTargetRecord>();
  const [embedCode, setEmbedCode] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [key, setKey] = useState(Math.random());
  const embedsApi = useEmbedsApi();
  const publisherApi = usePublishersApi();
  const { currentUser, publishers, publisher } = useContext(GlobalContext);
  const [ownerPublisher, setOwnerPublisher] = useState<Publisher | null>(
    publisher
  );
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const theme = useTheme<DefaultTheme>();
  const enablePublisherSelect = ["fanpoweradmin", "tenantadmin"].includes(
    currentUser.role
  );

  const formik = useFormik({
    initialValues: {
      name: viewEmbed?.name || "",
      description: viewEmbed?.description || "",
      content_type: viewEmbed?.content_type || "",
      prop_id: viewEmbed?.content.prop_id || null,
      carousel_id: viewEmbed?.content.carousel_id || null,
      prop_collection: viewEmbed?.content.prop_collection || [],
      tags: viewEmbed?.content.tags,
      leagues: viewEmbed?.content.leagues || [],
      sort_by: viewEmbed?.content.sort_by || "custom",
      publisher_id: viewEmbed?.publisher_id || currentUser.publisher_id,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Embed name is required"),
      description: Yup.string(),
      content_type: Yup.string().required("Content type is required"),
      prop_id: Yup.mixed().when("content_type", {
        is: "prop_id",
        then: Yup.mixed().required("Prop ID is required"),
        otherwise: Yup.mixed().nullable(),
      }),
      carousel_id: Yup.mixed().when("content_type", {
        is: "carousel_id",
        then: Yup.mixed().required("Carousel ID is required"),
        otherwise: Yup.mixed().nullable(),
      }),
      prop_collection: Yup.array().when("content_type", {
        is: "prop_collection",
        then: Yup.array()
          .min(2, "At least two Props are required")
          .max(5, "No more than five Props are allowed"),
        otherwise: Yup.array(),
      }),
      tags: Yup.array().when("content_type", {
        is: "tags",
        then: Yup.array().min(1, "At least one tag required"),
        otherwise: Yup.array(),
      }),
      leagues: Yup.array().when("content_type", {
        is: "leagues",
        then: Yup.array().min(1, "At least one league is required"),
        otherwise: Yup.array(),
      }),
      sort_by: Yup.string().when("content_type", {
        is: "prop_collection",
        then: Yup.string().required("Sort by is required"),
        otherwise: Yup.string().nullable(),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setIsLoading(true);
      await handleSubmit(values).then(() => {
        setSubmitting(false);
        setIsLoading(false);
      });
    },
  });

  const updateOwnerPublisher = async (pubId: number) => {
    if (ownerPublisher && ownerPublisher.id == pubId) return;
    const owner = await fetchOwnerPublisher(pubId);

    setOwnerPublisher(owner);
    setEmbedCode(
      `<div class="fanpower-embed-target" data-targetId="${id}" data-publisherid="${owner.id}" data-accesstoken="${owner.access_token}"></div>`
    );
  };

  const fetchOwnerPublisher = async (pubId: number) => {
    if (!pubId) return null;
    if (!enablePublisherSelect) return publisher;
    if (publisher && publishers.length > 0) {
      const owner = publishers.find((p) => p.id == pubId);
      if (owner) {
        return owner;
      }
    }
    try {
      return await publisherApi.getPublisher(pubId.toString(), undefined);
    } catch (error) {
      enqueueSnackbar("Could not retrieve owner publisher", {
        variant: "error",
      });
      return null;
    }
  };

  const getPublisherOptions = (currentUser: any) => {
    if (currentUser.role == "fanpoweradmin") {
      return publishers;
    }
    if (currentUser.role == "tenantadmin") {
      return publishers.filter((p) => p.tenant_id == publisher.tenant_id);
    }
    return [];
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      const fetchEmbed = async () => {
        try {
          const embedTarget = await embedsApi.getEmbedById(id);

          const owner = await fetchOwnerPublisher(embedTarget.publisher_id);
          setOwnerPublisher(owner);

          const pubId = embedTarget.publisher_id;
          const accessToken = owner?.access_token
            ? owner?.access_token
            : publisher?.access_token;
          setViewEmbed(embedTarget);
          setEmbedCode(
            `<div class="fanpower-embed-target" data-targetId="${embedTarget.id}" data-publisherid="${pubId}" data-accesstoken="${accessToken}"></div>`
          );

          setIsLoading(false);
        } catch (error) {
          enqueueSnackbar("Could not retrieve Embed Target", {
            variant: "error",
          });
          navigate("/props/embeds");
        }
      };
      fetchEmbed();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const createEmbedTarget = async (embedTarget: EmbedTarget) => {
    try {
      await embedsApi.createEmbed(embedTarget).then((res) => {
        enqueueSnackbar("Embed target created", { variant: "success" });
        navigate(`/props/embeds/view/${res.id}`);
      });
    } catch (error) {
      enqueueSnackbar("Error creating embed target", {
        variant: "error",
      });
    }
  };

  const updateEmbedTarget = async (embedTarget: EmbedTarget) => {
    try {
      await embedsApi.updateEmbed(id, embedTarget).then((res) => {
        enqueueSnackbar("Embed target updated", { variant: "success" });
        setKey(Math.random());
        navigate(`/props/embeds/view/${res.id}`);
      });
    } catch (error) {
      enqueueSnackbar("Error updating embed target", {
        variant: "error",
      });
    }
  };

  const handleSubmit = async (values: EmbedTargetFormValues) => {
    let pubId: number;
    if (!enablePublisherSelect) {
      pubId = currentUser.publisher_id;
    } else {
      pubId = values.publisher_id;
      await updateOwnerPublisher(pubId);
    }

    const embedTarget = {
      name: values.name,
      description: values.description,
      content_type: values.content_type,
      content: {
        prop_id: values.content_type === "prop_id" ? values.prop_id : null,
        carousel_id:
          values.content_type === "carousel_id" ? values.carousel_id : null,
        prop_collection:
          values.content_type === "prop_collection"
            ? values.prop_collection
            : [],
        tags: values.content_type === "tags" ? values.tags : [],
        leagues: values.content_type === "leagues" ? values.leagues : [],
        sort_by: values.sort_by,
      },
      publisher_id: pubId,
      author_user_id: currentUser.id,
    };
    try {
      if (id) {
        return await updateEmbedTarget(embedTarget);
      } else {
        return await createEmbedTarget(embedTarget);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(embedCode);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  if (isLoading) return <Loader />;

  return (
    <Box w="100%" h="100%">
      <Flex paddingBottom={theme.spacing.base * 2}>
        <BreadcrumbsBlock
          links={[
            { title: "Props", isCurrent: false },
            { title: "Embeds", isCurrent: false, link: "/props/embeds" },
            { title: id ? "View" : "Create", isCurrent: true },
          ]}
        />
      </Flex>
      <Flex direction="column" maxW={1000}>
        <Heading as="h1" marginBottom={theme.spacing.base}>
          {id ? "View Embed Target" : "Create Embed Target"}
        </Heading>
        <Box
          borderWidth={"2px"}
          borderRadius={"lg"}
          padding={"12px"}
          bg={"white"}
        >
          <form onSubmit={formik.handleSubmit}>
            <VStack alignItems="flex-start">
              <FormControl
                isInvalid={formik.touched.name && !!formik.errors.name}
              >
                <FormLabel htmlFor="name">Embed Name</FormLabel>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="description">Description</FormLabel>
                <Textarea
                  id="description"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
              </FormControl>
              {currentUser.role == "fanpoweradmin" ||
              currentUser.role == "tenantadmin" ? (
                <FormControl>
                  <FormLabel htmlFor="publisher_id">Publisher</FormLabel>
                  <Select
                    id="publisher_id"
                    name="publisher_id"
                    placeholder="Select Publisher"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.publisher_id}
                    isDisabled={
                      currentUser.role !== "fanpoweradmin" &&
                      currentUser.role !== "tenantadmin"
                    }
                  >
                    {getPublisherOptions(currentUser).map((p) => {
                      return (
                        <option key={p.id} value={p.id}>
                          {p.name}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : null}
              <FormControl
                isInvalid={
                  formik.touched.content_type && !!formik.errors.content_type
                }
              >
                <FormLabel htmlFor="content_type">Content Type</FormLabel>
                <Select
                  id="content_type"
                  name="content_type"
                  placeholder="Select Content Type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.content_type}
                >
                  <option value="prop_id">Single Prop</option>
                  <option value="prop_collection">Prop Collection</option>
                  <option value="carousel_id">Carousel</option>
                  {/*<option value="tags">Tags</option>
                <option value="leagues">Leagues</option>*/}
                </Select>
                <FormErrorMessage>
                  {formik.errors.content_type}
                </FormErrorMessage>
              </FormControl>
              <RenderContentType formik={formik} currentUser={currentUser} />
              <Button type="submit">
                {id ? "Update Embed Target" : "Create Embed Target"}
              </Button>
            </VStack>
          </form>
        </Box>
        {viewEmbed?.id && (
          <Box>
            <Box
              borderWidth={"2px"}
              borderRadius={"lg"}
              padding={"12px"}
              marginTop={"12px"}
              bg={"white"}
            >
              <Heading as="h3" size="lg" marginY={4}>
                Embed Target HTML Code
              </Heading>
              {enablePublisherSelect ? (
                <Alert status={"warning"}>
                  This embed target belongs to publisher: {ownerPublisher?.name}
                  .
                </Alert>
              ) : null}
              <HStack>
                <Input
                  value={embedCode}
                  onChange={(e) => setEmbedCode(e.target.value)}
                />
                <Button onClick={handleCopy}>
                  {isCopied ? "Copied" : "Copy"}
                </Button>
              </HStack>
            </Box>
            <Box
              borderWidth={"2px"}
              borderRadius={"lg"}
              padding={"12px"}
              marginTop={"12px"}
              bg={"white"}
            >
              <Heading as="h3" size="lg" marginY={4}>
                Prop Preview
              </Heading>
              {!isLoading ? (
                <StandalonePicker
                  key={key}
                  accessToken={
                    ownerPublisher?.access_token
                      ? ownerPublisher?.access_token
                      : currentUser.access_token
                  }
                  publisherId={
                    ownerPublisher?.id
                      ? ownerPublisher?.id
                      : currentUser.publisher_id
                  }
                  host={NODE_API_URL}
                  sourceUrl={"https://fanpower.io"}
                  targetId={viewEmbed?.id}
                />
              ) : (
                <Loader />
              )}
            </Box>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default CreateEmbed;
