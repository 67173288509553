import React from "react";
import { List, ListItem, Flex, Box, ListIcon, Link } from "@chakra-ui/react";
import {
  faCircleCheck,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import CommonModal from "../../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FontAwesome } from "../../components/common/icon";

interface ReferralViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  fan: any;
}

const ReferralViewModal: React.FC<ReferralViewModalProps> = ({
  isOpen,
  onClose,
  fan,
}) => {
  return (
    <CommonModal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <>
          {fan ? (
            <>
              {" "}
              <Box fontSize="lg">
                {fan && fan.username ? fan.username : fan.id}
              </Box>
              <Box fontSize="md">
                Location:
                {fan.last_known_state
                  ? ` ${fan.last_known_city} ${fan.last_known_state}`
                  : " unknown"}
              </Box>
            </>
          ) : null}
        </>
      }
    >
      {fan && fan.mapped_referral_events ? (
        <Flex flexDirection={"column"}>
          <Box fontSize="md" fontWeight={"bold"} padding={2}>
            Referrals:
          </Box>
          <List>
            {fan.mapped_referral_events.map((r, i) => (
              <Box key={i}>
                <ListItem>
                  <ListIcon
                    as={FontAwesomeIcon}
                    icon={faCircleCheck}
                    color="green.500"
                  />
                  {r.proposition}
                </ListItem>
                <ListItem fontWeight={"bold"}>Fans Accepted</ListItem>
                <ListItem>{r.accepting_fan_ids.length}</ListItem>
                <ListItem fontWeight={"bold"}>Source Url</ListItem>
                <ListItem>
                  <Link href={r.source_urls[0]} isExternal>
                    {new URL(r.source_urls[0]).origin}
                    <FontAwesome icon={faArrowUpRightFromSquare} />
                  </Link>
                </ListItem>
              </Box>
            ))}
          </List>
        </Flex>
      ) : (
        "Issue Loading Fan"
      )}
    </CommonModal>
  );
};

export default ReferralViewModal;
