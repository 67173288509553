import axios from "axios";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import { TokenUser } from "../types";

const NODE_API_URL_V2 = process.env.REACT_APP_NODE_API_URL_V2;

const useRefreshToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["pu_portal"]);

  const refresh = async () => {
    return await axios
      .get(`${NODE_API_URL_V2}/auth/jwt/refresh`, {
        headers: {
          Authorization: `Bearer ${cookies?.pu_portal?.refresh_token}`,
        },
      })
      .then(async (response) => {
        const tokenDecode = jwt_decode(response.data.token);
        const updatedUser = cookies.pu_portal;
        updatedUser.role = (tokenDecode as TokenUser).user.role;
        updatedUser.refresh_token = response.data.refreshToken;

        setCookie("pu_portal", updatedUser, {
          path: "/",
          secure: true,
          expires: new Date(Date.now() + 3600 * 1000 * 48),
          sameSite: true,
        });

        return { token: response.data.token, user: updatedUser };
      })
      .catch((err) => {
        document.cookie = `${"pu_portal"}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        removeCookie("pu_portal");
        throw err;
      });
  };

  return refresh;
};

export default useRefreshToken;
