import React from "react";
import {
  Button,
  ModalFooter,
  Box,
  Flex,
  Checkbox,
  useTheme,
} from "@chakra-ui/react";
import { DefaultTheme } from "../../types/theme";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { Prop } from "../../types";
import usePropsApi from "../../services/api/Props";
import CommonModal from "../../components/Modal";

interface PropGradeModalProps {
  isOpen: boolean;
  onClose: () => void;
  prop: Prop;
}

const PropGradeModal: React.FC<PropGradeModalProps> = ({
  isOpen,
  onClose,
  prop,
}) => {
  const propsApi = usePropsApi();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme<DefaultTheme>();

  return (
    <CommonModal
      header={prop ? prop.proposition : "Grade Prop"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          picks: prop
            ? prop.picks.map((pick) => {
                return { id: pick.id, state: "graded_false" };
              })
            : [],
        }}
        validationSchema={Yup.object().shape({
          picks: Yup.array()
            .of(
              Yup.object().shape({
                state: Yup.string(),
              })
            )
            .test(
              "at-least-one-selected",
              "At least one pick must be selected",
              (value) => {
                return value.some((pick) => pick.state == "graded_true");
              }
            ),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await propsApi
            .gradeProp(prop.id, values.picks)
            .then(() => {
              enqueueSnackbar("Prop grading has started", {
                variant: "success",
              });
              setSubmitting(false);
              prop.grading_in_progress = true;
              onClose();
            })
            .catch(() => {
              setSubmitting(false);
              enqueueSnackbar("Error with prop grading try again later", {
                variant: "error",
              });
            });
        }}
      >
        {({ isSubmitting, errors, values }) => (
          <Form>
            <Flex
              fontWeight="normal"
              fontFamily={theme.typography.fontFamilies.body}
              fontSize={14}
              color={theme.colors.grey.dark}
              flexDirection={"column"}
            >
              <Box>Select the correct pick(s)</Box>
              <Box>Unselected picks will be graded false</Box>
            </Flex>
            <Flex
              fontWeight="normal"
              fontFamily={theme.typography.fontFamilies.body}
              fontSize={14}
              color={theme.colors.grey.dark}
              flexDirection={"column"}
              border={"1px"}
              borderColor={theme.colors.grey.dark}
            >
              {prop.picks.map((pick, i) => (
                <Checkbox
                  height={50}
                  _hover={{
                    backgroundColor: theme.colors.grey.lightBase,
                  }}
                  padding={5}
                  colorScheme={"purple"}
                  key={i}
                  onChange={(e) => {
                    values.picks.forEach((pick) => {
                      if (pick.id == e.target.value) {
                        if (pick.state == "graded_false") {
                          pick.state = "graded_true";
                        } else {
                          pick.state = "graded_false";
                        }
                      }
                    });
                  }}
                  value={pick.id}
                >
                  {pick.title}
                </Checkbox>
              ))}
              <Box color={"red"}>{errors.picks as string}</Box>
            </Flex>

            <ModalFooter>
              <Button variant="outline" onClick={onClose}>
                Close
              </Button>
              <Button ml={3} type="submit" isDisabled={isSubmitting}>
                Grade
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </CommonModal>
  );
};

export default PropGradeModal;
