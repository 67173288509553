import { defineStyleConfig } from "@chakra-ui/react";

export const linkTheme = defineStyleConfig({
  baseStyle: {
    color: "blue.500",
    textDecoration: "underline",
    _hover: {
      color: "blue.700",
      textDecoration: "none",
    },
    fontWeight: "bold",
  },
});
