import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { AxiosError } from "axios";
const NODE_API_URL_v2 = process.env.REACT_APP_NODE_API_URL_V2;
import useJwt from "../../hooks/useJwt";
import { Contest, ContestRecord } from "../../types";
const url = "/contests";

const useContestsApi = () => {
  const { privateAxiosV2 } = useJwt();

  const getContests = async (): Promise<ContestRecord[]> => {
    return privateAxiosV2
      .get(`${url}`)
      .then((resp) => {
        return resp.data.contests;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const getContestbyId = async (id: string): Promise<ContestRecord> => {
    return privateAxiosV2
      .get(`${url}/${id}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const createContest = async (body: Contest): Promise<ContestRecord> => {
    return privateAxiosV2
      .post(`${url}`, { ...body })
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const updateContest = async (body: ContestRecord): Promise<ContestRecord> => {
    return privateAxiosV2
      .put(`${url}/${body.id}`, { ...body })
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const removeProp = async (contest_id: string, prop_id: string) => {
    return privateAxiosV2
      .delete(`${url}/${contest_id}/prop/${prop_id}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const addProp = async (
    contest_id: string,
    body: { prop_id: string; start_date: Date; play_order: number }
  ) => {
    return privateAxiosV2
      .post(`${url}/${contest_id}/prop`, { ...body })
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const getContestFans = async (id: string) => {
    return privateAxiosV2
      .get(`${url}/${id}/fans`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const addFan = async (
    contest_id: string,
    body: { fan_id: string; team?: string }
  ) => {
    return privateAxiosV2
      .post(`${url}/${contest_id}/fan`, { ...body })
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const removeFan = async (contest_id: string, fan_id: string) => {
    return privateAxiosV2
      .delete(`${url}/${contest_id}/fan/${fan_id}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  return {
    getContests,
    createContest,
    updateContest,
    getContestbyId,
    removeProp,
    addProp,
    getContestFans,
    addFan,
    removeFan,
  };
};

export default useContestsApi;
