import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  AiJobLeague,
  AiJobType,
  GamesAndTeams,
  NFLSeasonType,
  ParsedSportsGame,
} from "../../../types";
import useAiApi from "../../../services/api/Ai";
import NewJobGameList from "../NewJobGameList";

const seasonType = (seasonType: string) => {
  switch (seasonType) {
    case "regular":
      return NFLSeasonType.regular;
    default:
      return NFLSeasonType.regular;
  }
};

const NflGameRecaps = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const aiApi = useAiApi();

  const parseGames = (gamesResponse): GamesAndTeams => {
    const teams = {};
    const games: ParsedSportsGame[] = [];
    gamesResponse.forEach((game) => {
      const home = game.home.name;
      const away = game.away.name;
      teams[home] = 1;
      teams[away] = 1;
      const startTime = new Date(game.scheduled);
      games.push({
        id: game.id,
        home,
        away,
        game_type: seasonType(game.game_type),
        date_time: game.scheduled,
        date: `${startTime.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })}`,
        time: `${startTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZoneName: "short",
        })}`,
      });
    });
    return {
      teams: Object.keys(teams),
      games: games,
    };
  };

  const fetchGames = async (): Promise<GamesAndTeams> => {
    try {
      const gamesAndSeason = [];
      await aiApi.getNflRecapGames().then((res) => {
        for (const obj of res) {
          if (obj && obj.length) {
            for (const game of obj) {
              gamesAndSeason.push(game.games);
            }
          }
        }
      });
      const parsedGames: GamesAndTeams = { games: [], teams: [] };

      if (gamesAndSeason.length) {
        for (const res of gamesAndSeason) {
          const next = parseGames(res);
          parsedGames.games = [...parsedGames.games, ...next.games];
          parsedGames.teams = [...parsedGames.teams, ...next.teams];
        }
      }
      parsedGames.games.reverse();
      parsedGames.teams.reverse();
      return parsedGames;
    } catch (error) {
      console.error(error);
      enqueueSnackbar("There was an error requesting games", {
        variant: "error",
      });
    }
  };

  return (
    <div>
      <NewJobGameList
        fetchGamesCallback={fetchGames}
        path="/ai-content/nfl/recaps"
        heading="NFL Game Recaps"
        league={AiJobLeague.NFL}
        jobTitle="NFL Game Recap"
        jobType={AiJobType.SUMMARY}
      />
    </div>
  );
};

export default NflGameRecaps;
