import React, { useState, useEffect, useContext } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import {
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  Divider,
  Heading,
} from "@chakra-ui/react";
import raddad from "../PropSearch/raddad.svg";
import ListTable from "../../components/common/Layouts/ListTable";
import useTagsApi from "../../services/api/Tags";
import DropDown from "../../components/FilterDropDown";
import GlobalContext from "../../components/GlobalContext";
import MultiSelect from "../../components/MultiSelect";
import { Tag } from "../../types";
import { Form, Formik } from "formik";
import useFansApi from "../../services/api/Fans";
import { useSnackbar } from "notistack";
import { ToolTipButton } from "../../components/FormElements/ToolTipButton";
import StatusBadge from "../../components/StatusBadge";
import useJwt from "../../hooks/useJwt";
import Loader from "../../components/Loader";
import DownloadCsv from "../../components/DownloadCsv";
import FanViewModal from "./FanViewModal";
import ReferralViewModal from "./ReferralViewModal";
import { faCheck, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../../components/common/icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
  },
  formComponent: {
    marginBottom: theme.spacing.base * 5,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      marginRight: theme.spacing.base * 4,
      minHeight: 0,
      width: 450,
    },
  },
  initialResults: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: theme.spacing.base * 20,
    justifyContent: "center",
    alignItems: "center",
    "& div": {
      position: "relative",
      width: 100,
      height: "auto",
      marginBottom: theme.spacing.base * 6,
      "& img": {
        width: "100%",
        height: "auto",
      },
    },
  },
  formLabel: {
    fontSize: 15,
    color: theme.colors.grey.dark,
  },
}));

const FanInsights: React.FC = () => {
  const [propTags, setPropTags] = useState([]);
  const [pickTags, setPickTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const { publishers, publisher } = useContext(GlobalContext);
  const { decodeJwt } = useJwt();
  const [pubFilter, setPubFilter] = useState("");
  const [fans, setFans] = useState([]);
  const [filterFans, setFilterFans] = useState([]);
  const [resetTags, setResetTags] = useState(false);
  const [resetLocations, setResetLocations] = useState(false);
  const [locations, setLocations] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const tagsApi = useTagsApi();
  const fansApi = useFansApi();
  const [fanModalOpen, setFanModalOpen] = useState(false);
  const [currentFan, setCurrentFan] = useState(null);
  const [referralModalOpen, setReferralModalOpen] = useState(false);

  const fetchActiveTags = async (publisherId: string) => {
    await tagsApi
      .getActiveTags(publisherId)
      .then((res) => {
        const propTags = res[0].propTags.map((tag: Tag) => {
          return { id: tag.id, name: tag.name, value: tag.name };
        });

        const pickTags = res[1].pickTags.map((tag: Tag) => {
          return { id: tag.id, name: tag.name, value: tag.name };
        });
        setPropTags(propTags);
        setPickTags(pickTags);
        setResetTags(false);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Issue pulling tags", { variant: "error" });
      });
  };

  useEffect(() => {
    if (publisher && decodeJwt.user.role == "publisheradmin")
      setPubFilter(publisher.name);
  }, [publisher]);

  useEffect(() => {
    if (pubFilter.length) {
      const publisherId = publishers.filter(
        (pub) => pub.name.toLowerCase().trim() == pubFilter.toLowerCase().trim()
      )[0].id;
      setLoading(true);
      fetchActiveTags(String(publisherId));
    }
    setFans([]);
    setFilterFans([]);
    setLocations([]);
  }, [pubFilter]);

  const filterFansLocation = (locations: string[]) => {
    let filterFans;
    if (locations.length) {
      filterFans = fans.filter(
        (fan) =>
          locations.includes(fan.last_known_state) ||
          locations.includes(fan.last_known_city + "," + fan.last_known_state)
      );
    } else {
      filterFans = fans;
    }
    setFilterFans(filterFans);
  };

  const fetchFans = async (
    publisherId: string,
    pickTags: string[],
    propTags: string[]
  ) => {
    await fansApi
      .findFanInsights(publisherId, pickTags, propTags)
      .then((res) => {
        const locations = new Set();
        for (const fan of res) {
          if (fan.last_known_city && fan.last_known_state)
            locations.add(fan.last_known_city + "," + fan.last_known_state);
          if (fan.last_known_state) locations.add(fan.last_known_state);
        }

        setLocations(
          Array.from(locations).map((loc) => {
            return { id: loc, name: loc };
          })
        );
        //map fan pick count
        for (const fan of res) {
          fan["pick_count"] = fan.picks.length;
          fan["referral_count"] = fan.referral_events.length;

          fan["is_referred"] = fan.referred.length ? true : false;
        }
        setFans(res);
        setFilterFans(res);
        setResetLocations(false);
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Issue finding fans", {
          variant: "error",
        });
      });
  };

  const showPublisherSelect = () => {
    if (
      publishers.length &&
      (decodeJwt.user.role == "fanpoweradmin" ||
        decodeJwt.user.role == "tenantadmin")
    ) {
      return true;
    }
    return false;
  };

  const onView = (item) => {
    setCurrentFan(item);
    setFanModalOpen(true);
  };
  const closeFanModal = () => {
    setFanModalOpen(false);
    setCurrentFan(null);
  };

  const onViewRefrral = (item) => {
    const referralEventsFilter = [];

    for (const event of item.referral_events) {
      if (
        !referralEventsFilter.find((e) => e.proposition == event.proposition)
      ) {
        const acceptingFans = [];
        const sourceUrls = [];
        const tempFilter = item.referral_events.filter(
          (e) => e.proposition == event.proposition
        );
        for (const e of tempFilter) {
          acceptingFans.push(e.accepting_fan_id);
          sourceUrls.push(e.source_url);
        }
        referralEventsFilter.push({
          fan_id: event.fan_id,
          proposition: event.proposition,
          accepting_fan_ids: acceptingFans,
          source_urls: sourceUrls,
        });
      }
    }
    item.mapped_referral_events = referralEventsFilter;
    setCurrentFan(item);
    setReferralModalOpen(true);
  };
  const closeReferralModal = () => {
    setReferralModalOpen(false);
    setCurrentFan(null);
  };

  const publisherOptions = () => {
    if (decodeJwt.user.role == "fanpoweradmin") {
      return publishers
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        .map((p) => p.name);
    } else if (decodeJwt.user.role == "tenantadmin") {
      return publishers
        .filter((pub) => pub.tenant_id == publisher.tenant_id)
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        .map((p) => p.name);
    }
  };

  const sortByReferralCount = () => {
    const sorted = [...fans].sort((a, b) => {
      if (a.referral_count > b.referral_count) return -1;
      if (a.referral_count < b.referral_count) return 1;
      return 0;
    });
    setFilterFans(sorted);
  };

  const sortByPickCount = () => {
    const sorted = [...fans].sort((a, b) => {
      if (a.pick_count > b.pick_count) return -1;
      if (a.pick_count < b.pick_count) return 1;
      return 0;
    });
    setFilterFans(sorted);
  };

  return (
    <div className={classes.root}>
      <>
        <Flex flexDirection={"column"}>
          <Box>
            <Formik
              initialValues={{
                propTags: [],
                pickTags: [],
                locations: [],
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setResetLocations(true);
                const publisherId = publishers.filter(
                  (pub) =>
                    pub.name.toLowerCase().trim() ==
                    pubFilter.toLowerCase().trim()
                )[0].id;
                await fetchFans(
                  String(publisherId),
                  values.pickTags,
                  values.propTags
                );
                setHighlight(false);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  {showPublisherSelect() ? (
                    <FormControl className={classes.formComponent}>
                      <DropDown
                        options={publisherOptions()}
                        setValue={setPubFilter}
                        label={"Select a Publisher"}
                        overrides={{
                          noDefaultLabel: true,
                        }}
                        onChange={() => {
                          setResetTags(true);
                        }}
                      />
                      <ToolTipButton>
                        Begin by selecting a publisher to query fans on
                      </ToolTipButton>
                    </FormControl>
                  ) : null}
                  {loading && pubFilter.length ? (
                    <Loader />
                  ) : (
                    <Flex flexWrap={"wrap"}>
                      {propTags.length ? (
                        <FormControl className={classes.formComponent}>
                          <MultiSelect
                            name="propTags"
                            items={propTags}
                            label="Prop Tags"
                            placeholder="Select prop tags"
                            values={null}
                            reset={resetTags}
                            onSelect={() => setHighlight(true)}
                          />
                          <ToolTipButton>
                            Search and select Prop Tags to filter fans by
                          </ToolTipButton>
                          <Flex flexDirection={"column"}>
                            {propTags.length ? (
                              <FormLabel className={classes.formLabel}>
                                {pubFilter} commonly used prop tags
                              </FormLabel>
                            ) : null}
                            <Flex flexWrap={"wrap"}>
                              {propTags.slice(0, 10).map((tag, i) => (
                                <div key={i} style={{ marginLeft: 5 }}>
                                  <StatusBadge statusText={tag.name} />
                                </div>
                              ))}
                            </Flex>
                          </Flex>
                        </FormControl>
                      ) : null}

                      {pickTags.length ? (
                        <FormControl className={classes.formComponent}>
                          <MultiSelect
                            name="pickTags"
                            items={pickTags}
                            label="Pick Tags"
                            placeholder="Select pick  tags"
                            values={null}
                            reset={resetTags}
                            onSelect={() => setHighlight(true)}
                          />
                          <ToolTipButton>
                            Search and select Pick Tags to filter fans by
                          </ToolTipButton>
                          <Flex flexDirection={"column"}>
                            {pickTags.length ? (
                              <FormLabel className={classes.formLabel}>
                                {pubFilter} commonly used pick tags
                              </FormLabel>
                            ) : null}
                            <Flex flexWrap={"wrap"}>
                              {pickTags.slice(0, 10).map((tag, i) => (
                                <div key={i} style={{ marginLeft: 5 }}>
                                  <StatusBadge statusText={tag.name} />
                                </div>
                              ))}
                            </Flex>
                          </Flex>
                        </FormControl>
                      ) : null}
                    </Flex>
                  )}
                  {pubFilter.length ? (
                    <>
                      <Button
                        type="submit"
                        leftIcon={<FontAwesome icon={faCheck} />}
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                      >
                        Search
                      </Button>
                      <Divider orientation="horizontal" />
                    </>
                  ) : null}
                  {fans.length ? (
                    <FormControl className={classes.formComponent}>
                      <MultiSelect
                        name="locations"
                        items={locations}
                        label="Locations"
                        placeholder="Select Locations"
                        values={null}
                        onSelect={filterFansLocation}
                        reset={resetLocations}
                      />
                      <ToolTipButton>
                        Select multiple locations that will act as an
                        &quot;or&quot; condition
                      </ToolTipButton>
                    </FormControl>
                  ) : null}
                </Form>
              )}
            </Formik>
          </Box>
        </Flex>
        {fans.length ? (
          <>
            <ListTable
              heading={"Fans"}
              headers={[
                { title: "Action" },
                { title: "Username" },
                { title: "City" },
                { title: "State" },
                { title: "Pick Count", onClick: sortByPickCount },
                { title: "Referral Count", onClick: sortByReferralCount },
                { title: "Referred" },
              ]}
              list={filterFans}
              columns={[
                { name: "username", type: "string" },
                { name: "last_known_city", type: "string" },
                { name: "last_known_state", type: "string" },
                { name: "pick_count", type: "string" },
                { name: "referral_count", type: "string" },
                { name: "is_referred", type: "string" },
              ]}
              actions={[
                {
                  name: "View Picks",
                  actionHandler: onView,
                  icon: <FontAwesome icon={faPenToSquare} />,
                },
                {
                  name: "View Referrals",
                  actionHandler: onViewRefrral,
                  icon: <FontAwesome icon={faPenToSquare} />,
                },
              ]}
              disablePageTrack={true}
            />
            <Box mt={4}>
              <DownloadCsv
                data={filterFans}
                exclude={["id", "picks", "referred", "referral_events"]}
                fileName="fan-insights-data"
              />
            </Box>
            <FanViewModal
              isOpen={fanModalOpen}
              onClose={closeFanModal}
              fan={currentFan}
            />

            <ReferralViewModal
              onClose={closeReferralModal}
              isOpen={referralModalOpen}
              fan={currentFan}
            />
          </>
        ) : (
          <div className={classes.initialResults}>
            <div>
              <img src={raddad} alt="" aria-hidden role="presentation" />
            </div>
            <Heading variant="heading3">
              Follow help tips and hit search to find some fans!
            </Heading>
          </div>
        )}
      </>
    </div>
  );
};

export default FanInsights;
