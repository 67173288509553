import { io, Socket } from "socket.io-client";
const REACT_APP_NODE_API_URL = process.env.REACT_APP_NODE_API_URL;

export const socket = io(REACT_APP_NODE_API_URL.split("v1")[0], {
  autoConnect: false,
  transports: ["websocket"],
});

// leaving this here in case we need it later.
/*socket.on("connect", () => console.log("socket connected", socket.id));
socket.on("disconnect", () => {
  console.log("socket disconnected");
});*/
