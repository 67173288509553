import useJwt from "../../hooks/useJwt";
import { Fan, FansWithCount } from "../../types";

const url = "fans";

const useFansApi = () => {
  const { privateAxiosV2 } = useJwt();
  const findFanInsights = async (
    publisherId: string,
    pickTags: string[],
    propTags: string[]
  ): Promise<any> => {
    return privateAxiosV2
      .get(`/${url}/insights/${publisherId}`, {
        params: {
          pickTags,
          propTags,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  };

  const getFans = async ({
    search = null,
    offset = 0,
    limit = 25,
  }): Promise<FansWithCount> => {
    return privateAxiosV2
      .get(`/${url}`, {
        params: {
          search,
          offset,
          limit,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  };

  const updateFan = async (fanId: string, data: Partial<Fan>): Promise<Fan> => {
    return privateAxiosV2
      .patch(`/${url}/${fanId}`, data)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  };

  return {
    findFanInsights,
    getFans,
    updateFan,
  };
};
export default useFansApi;
