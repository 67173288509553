const purple = {
  light: "#F5F0FF",
  lightBase: "#AFA0FF",
  pickup: "#7A60FF",
  base: "#261733",
  dark: "#5E24FF",
  transparent: "#EAE5FF",
};

const green = {
  light: "#EAFDF7",
  base: "#25DA95",
};

const grey = {
  light: "#F3F2F5",
  lightBase: "#DDDDDD",
  base: "#9D9D9E",
  dark: "#615E66",
  medium: "#DFDAE6",
};

const red = {
  light: "#FFF1F4",
  base: "#FF1244",
  ruby: "#FB5858",
};

export const colors = {
  // from PickUp UI
  primary: { ...purple },
  secondary: { ...grey },
  green,
  purple,
  grey,
  red,
  black: "#2C2933",
  white: "#FFF",
  success: green.base,
  error: red.base,
  // new to FanPower-Chakra Theme
  fanpower: {
    50: "#E2C6FF",
    100: "#D0A8FF",
    200: "#BA8BFF",
    300: "#A56DFF",
    400: "#8B4EFF",
    500: "#764BFF", // PickUp purple
    600: "#653A9C",
    700: "#54286E",
    800: "#401A46",
    900: "#291535", // FanPower purple
  },
  gradients: {
    50: "linear-gradient(112.25deg, #EAD6FF -20.38%, #D4ADFF 122.56%)",
    100: "linear-gradient(112.25deg, #D4ADFF -20.38%, #BE85FF 122.56%)",
    200: "linear-gradient(112.25deg, #BE85FF -20.38%, #A85CFF 122.56%)",
    300: "linear-gradient(112.25deg, #A85CFF -20.38%, #9652FF 122.56%)",
    400: "linear-gradient(112.25deg, #9652FF -20.38%, #5D1BFF 122.56%)",
    500: "linear-gradient(112.25deg, #914EFF -20.38%, #5D1BFF 122.56%)", // PickUp gradient
    600: "linear-gradient(112.25deg, #66317C -20.38%, #402255 122.56%)",
    700: "linear-gradient(112.25deg, #502456 -20.38%, #341C3A 122.56%)",
    800: "linear-gradient(112.25deg, #3D1837 -20.38%, #291535 122.56%)",
    900: "linear-gradient(112.25deg, #291535 -20.38%, #200F2B 122.56%)",
    main: `linear-gradient(112.25deg, #914EFF -20.38%, #5D1BFF 122.56%)`,
    hover: `linear-gradient(112.25deg, #914EFF 35.38%, #5D1BFF 122.56%)`,
    disabled:
      "linear-gradient(112.25deg, rgba(145,78,255,0.2) -20.38%, rgba(93,27,255,0.2) 122.56%)",
    fanpower: `linear-gradient(112.25deg, #FA5757, #5D1BFF)`,
  },
};
