import React, { useState, useEffect, useContext } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import { Spacer, Button, Flex, Divider, useTheme } from "@chakra-ui/react";
import {
  faPlus,
  faTrash,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import ListTable from "../../components/common/Layouts/ListTable";
import useAiApi from "../../services/api/Ai";
import { useSnackbar } from "notistack";
import usePublishersApi from "../../services/api/Publishers";
import DropDown from "../../components/FilterDropDown";
import GlobalContext from "../../components/GlobalContext";
import { AiJobPersona, Publisher } from "../../types";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import Loader from "../../components/Loader";
import { FontAwesome } from "../../components/common/icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
  },
}));

const PersonaIndex: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [publishers, setPublishers] = useState<Publisher[]>([]);
  const [personas, setPersonas] = useState<AiJobPersona[]>([]);
  const [filteredPersonas, setFilteredPersonas] = useState<AiJobPersona[]>([]);
  const [publisherFilter, setPublisherFilter] = useState("");
  const aiApi = useAiApi();
  const publishersApi = usePublishersApi();
  const { enqueueSnackbar } = useSnackbar();
  const { publisher } = useContext(GlobalContext);
  const [options, setOptions] = useState<string[]>([]);
  const theme = useTheme<DefaultTheme>();

  const fetchPersonas = async () => {
    try {
      const personas = await aiApi.getPersonas();
      setPersonas(personas);

      return personas;
    } catch (error) {
      console.error(error);
      enqueueSnackbar("There was an error requesting personas", {
        variant: "error",
      });
    }
  };

  const fetchPublishers = async () => {
    const publishers = await publishersApi.getPublishers(null, null);
    setPublishers(publishers);
    return publishers;
  };

  useEffect(() => {
    if (publisher) {
      Promise.all([fetchPersonas(), fetchPublishers()]).then((values) => {
        //setup page
        const filtered = values[0].filter(
          (p) => p.publisher_id && p.publisher_id == publisher.id
        );
        setFilteredPersonas(filtered);

        //setup options
        const publishers = values[1].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        const tenants = publishers
          .map((pub) => {
            return pub.tenant_name;
          })
          .filter((value, i, self) => {
            return value != null && self.indexOf(value) == i;
          })
          .sort();

        // initially populate with non-tenant publishers
        const pubAndTenantOps: string[] = publishers
          .filter((pub) => pub.tenant_name == null)
          .map((pub) => pub.name);
        // add publishers by tenant
        for (const tenant of tenants) {
          for (const pub of publishers) {
            if (pub.tenant_name == tenant) {
              pubAndTenantOps.push(tenant + ": " + pub.name);
            }
          }
        }

        setOptions(pubAndTenantOps);
        setLoading(false);
      });
    }
  }, [publisher]);

  const viewHandler = (item: any) => {
    navigate(`personas/${item.id}/edit`);
  };

  const deleteDialogOnConfirm = async (item: any) => {
    try {
      await aiApi.deletePersona(item.id);
      const updatedPersonas = personas.filter((persona) => {
        if (persona.id != item.id) {
          return persona;
        }
      });
      setPersonas(updatedPersonas);
      const filter = filteredPersonas.filter((persona) => {
        if (persona.id != item.id) {
          return persona;
        }
      });
      setFilteredPersonas(filter);
      enqueueSnackbar("Job successfully deleted.", { variant: "success" });
    } catch (e) {
      pickupErrorHandlerWeb(e);
      enqueueSnackbar("Could not delete Persona, try again later", {
        variant: "error",
      });
    }
  };

  const getCreateUrl = () => {
    if (publisherFilter) {
      const pub = publishers.find((pub) => {
        return pub.name.trim() == publisherFilter.trim();
      });
      if (pub && pub.id) {
        return "/personas/create?publisher=" + pub.id;
      }
    }
    return "/personas/create";
  };

  const handleFilter = (publisherFilter: string, personas: any[]) => {
    let filtered = personas;
    if (publisherFilter.length) {
      const foundPub: Publisher[] = publishers.filter((pub) => {
        return pub.name.trim() == publisherFilter.trim();
      });
      let pubId = null;
      if (foundPub.length) {
        pubId = foundPub[0].id;
      } else {
        pubId = 999;
      }
      filtered = filtered.filter((p) => p.publisher_id == pubId);
    }
    return filtered;
  };

  useEffect(() => {
    const filtered = handleFilter(publisherFilter, personas);
    setFilteredPersonas(filtered);
  }, [publisherFilter]);

  return (
    <div className={classes.root}>
      <Flex paddingBottom={theme.spacing.base * 2}>
        <BreadcrumbsBlock
          links={[
            { title: "Content", isCurrent: false },
            { title: "Personas", isCurrent: true },
          ]}
        ></BreadcrumbsBlock>
        <Spacer />
        <Button
          leftIcon={<FontAwesome icon={faPlus} />}
          as={Link}
          to={getCreateUrl()}
        >
          Create New Persona
        </Button>
      </Flex>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex>
            <DropDown
              options={options}
              setValue={setPublisherFilter}
              overrides={{
                defaultValue: publisher.name,
                noPlaceholder: true,
              }}
            />
          </Flex>
          <Divider orientation="horizontal" />

          <ListTable
            heading={"Custom Personas for AI Content"}
            headers={[
              { title: "Action" },
              { title: "Name" },
              { title: "Description" },
            ]}
            list={filteredPersonas}
            columns={[
              { name: "name", type: "string" },
              { name: "description", type: "string" },
            ]}
            onDelete={deleteDialogOnConfirm}
            alertTitle="Delete this Persona?"
            actions={[
              {
                name: "View",
                actionHandler: viewHandler,
                icon: <FontAwesome icon={faPenToSquare} />,
              },
              {
                name: "Delete",
                actionHandler: null,
                icon: <FontAwesome icon={faTrash} />,
              },
            ]}
          />
        </>
      )}
    </div>
  );
};

export default PersonaIndex;
