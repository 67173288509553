import React from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import classNames from "classnames";
import { User } from "../../../server/models/user.model";
import { useSnackbar } from "notistack";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { Button, Divider, Heading, Input } from "@chakra-ui/react";
import Paper from "../../components/common/Paper";

const NODE_API_URL_V1 = process.env.REACT_APP_NODE_API_URL;
const ADMIN_TOKEN = process.env.REACT_APP_ADMIN_TOKEN;

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "center",
    alignItems: "100%",
    marginTop: "15vh",
  },
  paper: {
    maxWidth: 568,
    backgroundColor: theme.colors.white,
  },
  inputContainer: {
    marginBottom: theme.spacing.base * 5,
  },
  buttonContainer: {
    marginTop: theme.spacing.base * 5,
    "& button": {
      padding: 0,
    },
  },
}));

const Recovery: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className={classes.root}>
      <Paper
        className={classNames({
          [classes.paper]: true,
        })}
        padding={30}
      >
        <Heading variant="heading2">Reset Password</Heading>
        <Divider orientation="horizontal" />
        <div>
          <Formik
            enableReinitialize
            initialValues={{ email: "" }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Valid Email required!")
                .required("Missing valid Email!"),
            })}
            onSubmit={async (values) => {
              return await axios
                .post<User>(
                  `${NODE_API_URL_V1}/users/recover`,
                  {
                    email: values.email,
                    location: window.location.href,
                  },
                  {
                    headers: { token: ADMIN_TOKEN },
                  }
                )
                .then(() => {
                  return navigate("/login/thankyou");
                })
                .catch((err) => {
                  pickupErrorHandlerWeb(err);
                  enqueueSnackbar("Something went wrong, please try again", {
                    variant: "error",
                  });
                });
            }}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form noValidate={false}>
                <div className={classes.inputContainer}>
                  <Field
                    id="email"
                    name="email"
                    label="Email"
                    onChange={handleChange}
                    value={values.email}
                    component={Input}
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <Button disabled={isSubmitting} type={"submit"}>
                    Reset
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Paper>
    </div>
  );
};

export default Recovery;
