import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import GlobalContext from "../../components/GlobalContext";
import Loader from "../../components/Loader";

const useStyles = createUseStyles({
  root: {
    height: "100%",
  },
  iframe: {
    width: "100%",
    height: "100%",
  },
  error: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
});

const Reporting = (): JSX.Element => {
  const classes = useStyles();
  const { publisher } = useContext(GlobalContext);

  if (!publisher) {
    return <Loader />;
  }

  if (!publisher.settings.reporting) {
    return (
      <div className={classes.error}>
        <h1>Publisher Data Reporting Not Availble</h1>
        <h3>Contact support@fanpower.io to get set up</h3>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <iframe
        className={classes.iframe}
        src={publisher.settings.reporting}
        title="mixpanel reporting dashboard"
      />
    </div>
  );
};

export default Reporting;
