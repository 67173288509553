import { AxiosError, AxiosResponse } from "axios";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import useJwt from "../../hooks/useJwt";
import { MultiSelectItem, Tag } from "../../types";

const url = "tags";
const plural = "tags";
const single = "tag";
const v = "v1";

const useTagsApi = () => {
  const { privateAxiosV1 } = useJwt();

  const create = async (name: string, short_name: string) => {
    return await privateAxiosV1
      .post(`/${url}`, { name, short_name })
      .then((response) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };
  const getAll = async () => {
    return await privateAxiosV1
      .get(`/${url}`, {
        params: { limit: 10000 },
      })
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
  };

  const getTagsAsMultiSelect = async (): Promise<MultiSelectItem[]> => {
    const tags = await getAll();
    return tags.map((tag: Tag) => {
      return {
        id: tag.id,
        name: tag.name,
      };
    });
  };

  const getActiveTags = async (publsiherId: string): Promise<any> => {
    return await privateAxiosV1
      .get(`/${url}/active/${publsiherId}`)
      .then((response) => {
        return response.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  };

  const createAndAssignTags = async (
    tagNames: string[],
    propId: string | number
  ): Promise<void> => {
    return await privateAxiosV1
      .post(`/${url}/assign/prop`, { tag_names: tagNames, prop_id: propId })
      .then((response) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const createAndAssignPickTags = async (
    tagNames: string[],
    pickId: string | number
  ): Promise<void> => {
    return await privateAxiosV1
      .post(`/${url}/assign/pick`, { tag_names: tagNames, pick_id: pickId })
      .then((response) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const deleteTag = async (id: string): Promise<AxiosResponse> => {
    return await privateAxiosV1
      .delete(`/${url}`, {
        params: {
          id: id,
        },
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const update = async (id: string, name: string, short_name: string) => {
    return await privateAxiosV1
      .put(`/${url}`, { id, name, short_name })
      .then((response) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  return {
    getAll,
    getTagsAsMultiSelect,
    create,
    getActiveTags,
    createAndAssignTags,
    deleteTag,
    update,
    createAndAssignPickTags,
  };
};
export default useTagsApi;
