import React from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import { Field } from "formik";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  useTheme,
} from "@chakra-ui/react";
import FileUploader from "../../../components/FileUploader";
import HtmlEditor from "../../../components/HtmlEditor";
import { RenderAdTypeProps } from "../../../types";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  fileInputRow: {
    marginTop: theme.spacing.base * 2,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  formComponent: {
    maxWidth: 450,
    marginBottom: theme.spacing.base * 4,
    minHeight: 125,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      marginBottom: 0,
      marginRight: theme.spacing.base * 4,
      minHeight: 0,
    },
  },
  formLabel: {
    fontSize: 15,
    color: theme.colors.grey.dark,
    fontFamily: theme.typography.fontFamilies.body,
  },
  dynamicInput: {
    marginBottom: theme.spacing.base * 2,
    height: 230,
  },
  dynamicFileInputRow: {
    marginTop: theme.spacing.base * 2,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      display: "flex",
      flexDirection: "row",
      "& > :first-child": {
        marginRight: theme.spacing.base * 2,
      },
    },
  },
  dynamicInputContainer: {
    maxWidth: 360,
    minWidth: 230,
    minHeight: 125,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      maxWidth: 200,
    },
  },
}));

const RenderAdType: React.FC<RenderAdTypeProps> = ({
  ad,
  adPreview,
  formRef,
  selectedAdZone,
  MAX_FILE_SIZE_IN_BYTES,
  SUPPORTED_FORMATS,
}) => {
  const classes = useStyles();
  const theme = useTheme<DefaultTheme>();

  if (adPreview === "static") {
    return (
      <div className={classes.fileInputRow}>
        <FormControl
          className={classes.formComponent}
          style={{ marginRight: 0 }}
          isInvalid={
            formRef?.errors.background_image &&
            formRef?.touched.background_image
              ? true
              : false
          }
          isRequired
        >
          <FormLabel className={classes.formLabel}>Static Ad Image</FormLabel>
          <FileUploader
            name="background_image"
            maxFileSize={MAX_FILE_SIZE_IN_BYTES}
            supportedFormats={SUPPORTED_FORMATS}
          />
          <FormErrorMessage fontSize={14}>
            {formRef?.errors.background_image}
          </FormErrorMessage>
        </FormControl>
      </div>
    );
  }
  if (adPreview === "dynamic") {
    return (
      <div style={{ marginTop: theme.spacing.base * 2 }}>
        <FormControl
          className={classes.formComponent}
          style={{ marginBottom: theme.spacing.base * 4 }}
          isRequired
        >
          <Field
            id="ad_copy"
            name="ad_copy"
            label="Ad Copy (Shift+Enter Starts New Line)"
            placeholder="Live dynamic ad copy..."
            width={selectedAdZone?.width || 468}
            height={selectedAdZone?.height || 200}
            component={HtmlEditor}
            initialValue={ad ? ad.ad_copy : formRef?.initialValues.ad_copy}
          ></Field>
        </FormControl>
        <div className={classes.dynamicFileInputRow}>
          <FormControl
            className={classes.dynamicInputContainer}
            isInvalid={
              formRef?.errors.background_image &&
              formRef?.touched.background_image
                ? true
                : false
            }
            isRequired
          >
            <FormLabel className={classes.formLabel}>
              Background Image
            </FormLabel>
            <FileUploader
              name="background_image"
              maxFileSize={MAX_FILE_SIZE_IN_BYTES}
              supportedFormats={SUPPORTED_FORMATS}
              className={classes.dynamicInput}
            />
            <FormErrorMessage fontSize={14}>
              {formRef?.errors.background_image}
            </FormErrorMessage>
          </FormControl>
          <FormControl className={classes.dynamicInputContainer}>
            <FormLabel className={classes.formLabel}>
              Foreground Image
            </FormLabel>
            <FileUploader
              name="foreground_image"
              maxFileSize={MAX_FILE_SIZE_IN_BYTES}
              supportedFormats={SUPPORTED_FORMATS}
              className={classes.dynamicInput}
            />
          </FormControl>
        </div>
      </div>
    );
  }
  return null;
};

export default RenderAdType;
