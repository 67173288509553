import { Button, Tooltip } from "@chakra-ui/react";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ListTable from "../../components/common/Layouts/ListTable";
import useContestsApi from "../../services/api/Contests";
import { ContestRecord } from "../../types";
import ContestFanModal from "./ContestFanModal";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../../components/common/icon";

interface ContestFans {
  contestFans: any;
  setContestFans: React.Dispatch<any>;
}

const ContestFans: React.FC<ContestFans> = ({
  contestFans,
  setContestFans,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const contestsApi = useContestsApi();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();

  //removes fan from contest
  const handleRemoveFan = async (fan: any) => {
    await contestsApi
      .removeFan(id, fan.id)
      .then((res) => {
        setContestFans(contestFans.filter((f) => f.id != fan.id));
        enqueueSnackbar("Fan removed", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar("Error removing fan", { variant: "error" });
      });
  };

  return (
    <>
      <Tooltip label={"Search and add fans to private contests"}>
        <Button
          leftIcon={<FontAwesome icon={faPlus} />}
          onClick={() => setModalOpen(true)}
        >
          Add Fan
        </Button>
      </Tooltip>
      <ListTable
        heading={"Contest Fans"}
        list={contestFans}
        headers={[
          { title: "Action" },
          { title: "Email" },
          { title: "username" },
        ]}
        columns={[
          {
            name: "email",
            type: "string",
          },
          {
            name: "username",
            type: "string",
          },
        ]}
        actions={[
          {
            name: "Remove",
            actionHandler: null,
            icon: <FontAwesome icon={faTrash} />,
          },
        ]}
        onDelete={handleRemoveFan}
        alertTitle={"Remove this Fan?"}
        warnText={"Are you sure you want to remove this Fan?"}
        disablePageTrack={true}
      />
      <ContestFanModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        contestFans={contestFans}
        setContestFans={setContestFans}
      />
    </>
  );
};

export default ContestFans;
