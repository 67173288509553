import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Button,
  Input,
  FormLabel,
  Textarea,
  Box,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  FormControl,
  Divider,
  useTheme,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { faCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";

import { AdZone, EditAdZoneProps } from "../../types";
import useJwt from "../../hooks/useJwt";
import { FontAwesome } from "../../components/common/icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {},
  breadCrumbs: {
    fontSize: 15,
    fontFamily: theme.typography.fontFamilies.body,
    fontWeight: "normal",
  },
  error: {
    fontFamily: theme.typography.fontFamilies.body,
    color: theme.colors.red.base,
    fontSize: 14,
    position: "relative",
    // top: -40,
  },
  cancelButton: {
    fontSize: 15,
    fontFamily: theme.typography.fontFamilies.body,
    height: theme.spacing.base * 9 - 2,
    margin: theme.spacing.base,
    backgroundColor: "white",
    borderColor: theme.colors.grey.lightBase,
    color: theme.colors.grey.dark,
    "&:hover": {
      backgroundColor: theme.colors.grey.base,
    },
    boxShadow: "none",
  },
  name: {
    height: 40,
    marginBottom: theme.spacing.base * 10,
    fontSize: 16,
    fontFamily: theme.typography.fontFamilies.body,
    color: theme.colors.grey.dark,
    borderColor: theme.colors.grey.lightBase,
    backgroundColor: theme.colors.white,
  },
  number: {
    height: 40,
    borderColor: theme.colors.grey.lightBase,
    backgroundColor: theme.colors.white,

    fontSize: 16,
    minHeight: 40,
    color: theme.colors.grey.dark,
  },
  assetBorder: {
    border: `1px solid ${theme.colors.grey.lightBase}`,
    lineHeight: "0.1em",
    borderRadius: 6,
    paddingLeft: theme.spacing.base * 5,
    paddingRight: theme.spacing.base * 5,
  },
  asset: {
    fontFamily: theme.typography.fontFamilies.body,
    top: -3,
    height: 16,
    width: 145,
    position: "relative",
    background: theme.colors.grey.light,
    opacity: 1,
    marginBottom: theme.spacing.base * 8,
    marginLeft: theme.spacing.base * 3,
    color: theme.colors.grey.base,
  },
  label: {
    fontFamily: theme.typography.fontFamilies.body,
    fontSize: 15,
    color: theme.colors.grey.dark,
  },
  right: {
    width: 320,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      marginLeft: theme.spacing.base * 10,
      marginTop: theme.spacing.base * 10,
    },
  },
  left: {
    width: 320,
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      flexDirection: "row",
      alignItems: "normal",
    },
  },
  buttonGroup: {
    justifyContent: "space-between",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      justifyContent: "right",
    },
  },
}));

const CreateAdZone: React.FC = () => {
  const location = useLocation();
  const [adZone, setAdZone] = useState<AdZone>(
    location.state ? (location.state as EditAdZoneProps).adZone : null
  );
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { privateAxiosV1 } = useJwt();
  const theme = useTheme<DefaultTheme>();

  const createAdZone = async (data) => {
    const adZone = await privateAxiosV1.post(`/adzones`, data);

    if (adZone.data) {
      enqueueSnackbar("Adzone created!", { variant: "success" });
    }
    return adZone;
  };

  const editAdZone = async (data) => {
    const adZone = await privateAxiosV1.patch(`/adzones`, data);

    if (adZone.data) {
      setAdZone(adZone.data[0]);
      navigate(location.pathname, {
        replace: true,
        state: { adZone: adZone.data[0] },
      });
      enqueueSnackbar("Adzone updated!", { variant: "success" });
    }
    return adZone;
  };

  const handleSubmit = async (values) => {
    const { name, description, sms, height, width } = values;
    const data = {
      ...(adZone && adZone.id && { id: adZone.id }),
      ...(name.trim() != "" && { name: name.trim() }),
      ...{ description: description },
      ...{ sms: sms },
      ...{ height: height },
      ...{ width: width },
    };
    if (adZone) {
      return editAdZone(data).catch((err) => {
        enqueueSnackbar(err.response.data, { variant: "error" });
        pickupErrorHandlerWeb(err);
        throw err;
      });
    }
    return createAdZone(data).catch((err) => {
      enqueueSnackbar(err.response.data, { variant: "error" });
      pickupErrorHandlerWeb(err);
      throw err;
    });
  };

  return (
    <div className={classes.root}>
      <Flex>
        <Breadcrumb className={classes.breadCrumbs}>
          <BreadcrumbItem>
            <BreadcrumbLink color={theme.colors.purple.base}>
              Endzone
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/ad-zones" color={theme.colors.purple.base}>
              Ad Zones
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink color={theme.colors.grey.dark}>
              {location.pathname.split("/").pop()}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Divider orientation="horizontal" />
      <Formik
        initialValues={{
          name: (adZone && adZone.name) || "",
          description: (adZone && adZone.description) || "",
          width: (adZone && adZone.width) || 100,
          height: (adZone && adZone.height) || 50,
          sms: (adZone && adZone.sms.toString()) || "false",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(100, "character limit 100")
            .required("Name is required!"),
          description: Yup.string().nullable(),
          sms: Yup.string(),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await handleSubmit(values)
            .then((resp) => {
              if (resp.request.__sentry_xhr__.method == "POST") {
                resetForm();
              }
            })
            .catch();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, handleChange, setFieldValue, errors }) => (
          <Form>
            <Flex className={classes.flex}>
              <Box className={classes.left}>
                <Text mb={10}>* Required fields</Text>
                <FormControl isRequired>
                  <FormLabel className={classes.label}>Zone Name</FormLabel>
                  <Input
                    placeholder="Zone Name"
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </FormControl>
                <Box className={classes.error}>{errors.name}</Box>
                <FormLabel className={classes.label}>Description</FormLabel>
                <Textarea
                  id="description"
                  value={values.description}
                  onChange={handleChange}
                />
                <FormLabel className={classes.label}>SMS Support</FormLabel>
                <Select
                  id="sms"
                  name="sms"
                  value={values.sms}
                  onChange={handleChange}
                  marginBottom={theme.spacing.base * 2}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Select>
              </Box>
              <Box className={classes.right}>
                <Box className={classes.assetBorder}>
                  <Box className={classes.asset}>Asset Properties</Box>
                  <FormControl isRequired>
                    <FormLabel className={classes.label}>Width</FormLabel>
                    <NumberInput
                      step={5}
                      defaultValue={values.width}
                      min={0}
                      onChange={(stringValue) =>
                        setFieldValue("width", Number(stringValue))
                      }
                      marginBottom={theme.spacing.base * 2}
                    >
                      <NumberInputField
                        value={values.width}
                        className={classes.number}
                        id="width"
                      />
                      <NumberInputStepper
                        color={theme.colors.grey.dark}
                        borderColor={theme.colors.grey.lightBase}
                      >
                        <NumberIncrementStepper id="increasewidth" />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormLabel className={classes.label}>Height</FormLabel>
                    <NumberInput
                      step={5}
                      defaultValue={values.height}
                      min={0}
                      onChange={(stringValue) =>
                        setFieldValue("height", Number(stringValue))
                      }
                      marginBottom={theme.spacing.base * 2}
                    >
                      <NumberInputField
                        className={classes.number}
                        value={values.height}
                        id="height"
                      />
                      <NumberInputStepper
                        color={theme.colors.grey.dark}
                        borderColor={theme.colors.grey.lightBase}
                      >
                        <NumberIncrementStepper id="increaseheight" />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </Box>
              </Box>
            </Flex>
            <Divider orientation="horizontal" />
            <Flex className={classes.buttonGroup}>
              <Button
                isDisabled={isSubmitting}
                leftIcon={<FontAwesome icon={faCheck} />}
                type="submit"
              >
                Save
              </Button>
              <Button
                isDisabled={isSubmitting}
                variant="outline"
                leftIcon={<FontAwesome icon={faCircleXmark} />}
                type="button"
                onClick={() => navigate("/ad-zones")}
              >
                Cancel
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateAdZone;
