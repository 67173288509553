import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DraftPick,
  DraftProp,
  EditDraftPropProps,
  MultiSelectItem,
  Prop,
  Tag,
} from "../../../types";
import { Formik } from "formik";
import * as Yup from "yup";

import { pickupErrorHandlerWeb } from "../../../helpers/pickupErrorHandlerWeb";
import GlobalContext from "../../../components/GlobalContext";
import { useSnackbar } from "notistack";
import { PropCreateEditForm } from "../../../components/Props/PropCreateEditForm";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  useTheme,
} from "@chakra-ui/react";
import AiPropSuggestion from "../../../components/AiPropSuggestion";
import usePropsApi from "../../../services/api/Props";
import useJwt from "../../../hooks/useJwt";
import useLeaguesApi from "../../../services/api/League";
import usePicksApi from "../../../services/api/Picks";
import useAiApi from "../../../services/api/Ai";
import Loader from "../../../components/Loader";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
    maxWidth: "900px",
  },
  breadCrumbs: {
    fontSize: 15,
    fontFamily: theme.typography.fontFamilies.body,
    fontWeight: "normal",
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  left: {
    width: 320,
  },
  formComponent: {
    marginBottom: theme.spacing.base * 5,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      marginRight: theme.spacing.base * 4,
      minHeight: 0,
      width: 450,
    },
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      flexDirection: "row",
      alignItems: "normal",
    },
  },
  formColumn: {
    display: "flex",
    flexDirection: "column",
  },
  formRow: {
    flexDirection: "column",
    display: "flex",
    width: 600,

    [theme.mediaQuery(theme.breakpoints.small)]: {
      flexDirection: "row",
      marginBottom: theme.spacing.base * 4,
      minHeight: 125,
    },
  },
  heading: {
    fontWeight: 500,
    marginBottom: 24,
    fontFamily: theme.typography.fontFamilies.body,
  },
  subheading: {
    fontWeight: 300,
    marginBottom: 20,
    fontFamily: theme.typography.fontFamilies.body,
  },
  error: {
    fontFamily: theme.typography.fontFamilies.body,
    color: theme.colors.red.base,
    fontSize: 14,
    position: "relative",
    top: -40,
  },
  formLabel: {
    fontSize: 15,
    color: theme.colors.grey.dark,
    fontFamily: theme.typography.fontFamilies.body,
  },
  label: {
    fontFamily: theme.typography.fontFamilies.body,
    fontSize: 15,
    color: theme.colors.grey.dark,
  },
  proposition: {
    height: 40,
    fontSize: 16,
    fontFamily: theme.typography.fontFamilies.body,
    color: theme.colors.grey.dark,
    borderColor: theme.colors.grey.lightBase,
    backgroundColor: theme.colors.white,
  },
}));

const PropsCreateIndex: React.FC = () => {
  const location = useLocation();
  const [draftProp] = useState<DraftProp>(
    location.state ? (location.state as EditDraftPropProps).prop : null
  );
  const [draftPicks, setDraftPicks] = useState<Array<DraftPick>>([
    {
      selected_tags: [],
      display_title: "",
      id: 0,
      prop_id: "",
      state: null,
      title: "",
    },
    {
      selected_tags: [],
      display_title: "",
      id: 1,
      prop_id: "",
      state: null,
      title: "",
    },
  ]);
  const [draftPickDisplayTitles, setDisplayTitle] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [draftPickTitles, setTitles] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [draftPickTags, setDraftPickTags] = useState<Tag[][]>([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const { currentUser, tags } = useContext(GlobalContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [reset] = useState(false);
  const [leagues, setLeagues] = useState<Array<MultiSelectItem>>();
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const propsApi = usePropsApi();
  const { jwt } = useJwt();
  const leaguesApi = useLeaguesApi();
  const picksApi = usePicksApi();
  const aiApi = useAiApi();
  const [propSuggestionId, setPropSuggestionId] = useState<number>(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        setLeagues(await leaguesApi.getLeaguesAsMultiSelect());
      } catch (error) {
        pickupErrorHandlerWeb(error);
      }
    };

    Promise.all([fetchLeagues()]).then(() => setLoading(false));
  }, [reset, draftProp]);

  const removePickHandler = async (index: number) => {
    await refreshPicks(prunePicks([...draftPicks], index));
    return true;
  };
  const prunePicks = (pickList, index) => {
    if (pickList.length == 1) {
      pickList = [];
    } else {
      pickList.splice(index, 1);
    }

    return pickList;
  };
  /**
   * Same routine used to initially populate picks also used to update state when pick gets removed.
   * @param picks
   */
  const refreshPicks = async (picks: DraftPick[]) => {
    const titles = ["", "", "", "", "", "", "", "", "", ""];
    const displayTitles = ["", "", "", "", "", "", "", "", "", ""];
    const tags = [[], [], [], [], [], [], [], [], [], []];

    picks.map((row, index) => {
      if (row.title) {
        titles[index] = row.title;
      }
      if (row.display_title) {
        displayTitles[index] = row.display_title;
      }
      if (row.selected_tags) {
        tags[index] = row.selected_tags;
      }
    });

    setDraftPicks(picks);
    setDisplayTitle(displayTitles);
    setTitles(titles);
    setDraftPickTags(tags);
  };

  const handleSubmit = async (values) => {
    let newProp;
    try {
      newProp = await propsApi.createDraftProp(currentUser, {
        close_at: values.close_date ? values.close_date : null,
        proposition: values.prop_title,
        selected_leagues: values.leagues,
        league: leagues
          .filter((l) => l.id == values.leagues[0])[0]
          .name.toLowerCase(),
        selected_tags: values.tags,
        status: currentUser.permissions.self_serve_props_access,
        author_publisher_id: currentUser.publisher_id,
      });
    } catch (e) {
      enqueueSnackbar(e.response.data.error, { variant: "error" });
      return false;
    }

    if (newProp) {
      try {
        newProp.picks = await picksApi.processDraftPicksUpdate(
          draftPicks,
          newProp.id,
          currentUser,
          10,
          values.pick_display_titles,
          values.pick_titles,
          values.pick_tags
        );

        for (const pick of newProp.picks) {
          if (!pick.id) {
            enqueueSnackbar(
              "Prop created successfully, but there was an error saving one or more picks.",
              { variant: "warning", autoHideDuration: 10000 }
            );
            return newProp;
          }
        }
      } catch (e) {
        enqueueSnackbar(e.response.data.error, { variant: "error" });
        return false;
      }
    }

    if (propSuggestionId) {
      await aiApi.savePropSuggestion({
        prop_suggestion_id: propSuggestionId,
        prop_id: newProp.id,
        publisher_id: currentUser.publisher_id,
      });
    }

    enqueueSnackbar("Prop created successfully!", { variant: "success" });
    return newProp;
  };

  const pickUpdateHandler = (newPicks: DraftPick[]) => {
    setDraftPicks(newPicks);
  };

  const initEditView = (prop: Prop) => {
    navigate(`/props/${prop.id}/edit`);
  };

  if (loading) return <Loader />;

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          prop_title: "",
          leagues: [],
          close_date: null,
          tags: "",
          pick_display_titles: draftPickDisplayTitles,
          pick_titles: draftPickTitles,
          pick_tags: draftPickTags,
        }}
        validationSchema={Yup.object().shape({
          prop_title: Yup.string()
            .max(75, "character limit 75")
            .required("Prop name is required!"),
          leagues: Yup.array().min(1, "Must select at least one league"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await handleSubmit(values)
            .then((resp) => {
              if (resp.id) {
                setSubmitting(false);
                resetForm();
                initEditView(resp);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
        }) => (
          <>
            <div className={classes.nav}>
              <Breadcrumb className={classes.breadCrumbs}>
                <BreadcrumbItem>
                  <BreadcrumbLink href="/" color={theme.colors.purple.base}>
                    Tools
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink
                    href="/props/index"
                    color={theme.colors.purple.base}
                  >
                    Props
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink color={theme.colors.grey.dark}>
                    {location.pathname.split("/").pop()}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
            <Divider orientation="horizontal" />
            <AiPropSuggestion setActive={setActive} leagues={leagues} />
            <PropCreateEditForm
              isSubmitting={isSubmitting}
              values={values}
              handleChange={handleChange}
              errors={errors}
              leagues={leagues}
              tags={tags}
              reset={reset}
              touched={touched}
              setFieldTouched={setFieldTouched}
              picks={draftPicks}
              loading={loading}
              setFieldValue={setFieldValue}
              draftProp={draftProp}
              draftPickUpdateHandler={pickUpdateHandler}
              triggerPickUpdate={() => {
                /* no op */
              }}
              removePickHandler={removePickHandler}
              active={active}
              setActive={setActive}
            />
          </>
        )}
      </Formik>
      <Divider orientation="horizontal" />
    </div>
  );
};
export default PropsCreateIndex;
