import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import React, { ReactNode } from "react";

interface CommonModalProps {
  children: ReactNode;
  header: string | ReactNode;
  isOpen: boolean;
  onClose: () => void;
  overlayBg?: string;
  styles?: any;
}

const CommonModal: React.FC<CommonModalProps> = (props) => {
  const {
    children,
    header,
    isOpen,
    onClose,
    overlayBg = "blackAlpha.300",
    styles,
  } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg={overlayBg} />
      <ModalContent style={...styles}>
        {header ? <ModalHeader>{header}</ModalHeader> : null}
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CommonModal;
