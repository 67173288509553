import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useState,
} from "react";

import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import { FormikErrors } from "formik";
import { mapArrayToMultiselect } from "../../../helpers/transformers";
import { League, MultiSelectItem } from "../../../types";
import { pickupErrorHandlerWeb } from "../../../helpers/pickupErrorHandlerWeb";

import { MultiselectSet } from "./MultiselectSet";
import { SingleSelectFieldset } from "./SingleSelectFieldset";
import useLeaguesApi from "../../../services/api/League";
import Loader from "../../Loader";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  input: {
    height: 40,
    fontSize: 14,
    fontFamily: theme.typography.fontFamilies.body,
    color: theme.colors.grey.dark,
    borderColor: theme.colors.grey.lightBase,
    backgroundColor: theme.colors.white,
  },
}));

export interface LeaguesMultiselectProps {
  label: string;
  id: string;
  value?: League[] | string[] | number[] | null;
  singleValue?: string;
  onChange: ChangeEventHandler;
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  placeholder: string;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  setActive?: (boolean: boolean) => void;
  maxLength?: number;
  showCharCount?: boolean;
  wrapLabels?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  reset: boolean;
  single?: boolean;
  touched?: boolean;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  updateSelectionCallback?: (selection: MultiSelectItem[]) => void;
}

export const LeaguesMultiselectSet: React.FC<LeaguesMultiselectProps> = ({
  label,
  id,
  value,
  singleValue,
  isRequired,
  onChange,
  errors,
  placeholder,
  reset,
  single,
  onBlur,
  touched,
  setFieldTouched,
  updateSelectionCallback = null,
}) => {
  const classes = useStyles();
  const [leagues, setLeagues] = useState<Array<MultiSelectItem>>();
  const [singleLeagues, setSingleLeagues] = useState<
    {
      label: string;
      value: string | number;
    }[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState<Array<MultiSelectItem>>([]);
  const leaguesApi = useLeaguesApi();

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const leagueMulti = await leaguesApi.getLeaguesAsMultiSelect();
        setLeagues(leagueMulti);
        setSingleLeagues(
          leagueMulti.map((row) => {
            return { label: row.name, value: row.id };
          })
        );
      } catch (error) {
        pickupErrorHandlerWeb(error);
      }
    };

    fetchLeagues().then(() => setLoading(false));
  }, [reset]);

  useEffect(() => {
    // fetch the template
    if (value && value[0]) {
      setValues(
        mapArrayToMultiselect(value as unknown as MultiSelectItem[], "label")
      );
    }
  }, []);

  if (loading) return <Loader />;
  if (single)
    return (
      <>
        <SingleSelectFieldset
          label={label}
          id={id}
          isRequired={isRequired}
          value={singleValue}
          options={singleLeagues}
          onChange={onChange}
          onBlur={onBlur}
          errors={errors}
          touched={touched}
          setFieldTouched={setFieldTouched}
          placeholder={"Select a league"}
        />
      </>
    );
  return (
    <>
      <MultiselectSet
        label={label}
        id={id}
        value={values}
        items={leagues}
        onChange={onChange}
        errors={errors}
        placeholder={placeholder}
        reset={reset}
        updateSelectionCallback={updateSelectionCallback}
      />
    </>
  );
};
