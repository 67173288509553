import React, { useContext, useEffect, useState } from "react";
import NoBorderLayout from "../../../components/common/Layouts/NoBorder";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import DesktopButtons from "../../../components/FormElements/DesktopButtons";
import { createUseStyles } from "react-jss";
import { Divider, Heading, Text, useTheme } from "@chakra-ui/react";
import MobileButtons from "../../../components/FormElements/MobileButtons";
import { TextInputGroup } from "../../../components/FormElements/TextInputGroup";
import { TextAreaGroup } from "../../../components/FormElements/TextAreaGroup";
import { DatepickerFieldset } from "../../../components/FormElements/Fieldsets/DatepickerFieldset";
import { LeaguesMultiselectSet } from "../../../components/FormElements/Fieldsets/LeaguesMultiselectSet";
import { TagsMultiselectSet } from "../../../components/FormElements/Fieldsets/TagsMultiselectSet";
import { SingleSelectFieldset } from "../../../components/FormElements/Fieldsets/SingleSelectFieldset";
import { pickupErrorHandlerWeb } from "../../../helpers/pickupErrorHandlerWeb";
import GlobalContext from "../../../components/GlobalContext";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Carousel,
  CarouselOrderingOption,
  GetCarouselPayload,
  PropsSearchQuery,
} from "../../../types";
import StandalonePicker from "../../../components/Props/StandalonePicker";
import useCarouselsApi from "../../../services/api/Carousels";
import PropCard from "../../PropSearch/PropCard";
import { PropsMultiselectSet } from "../../../components/FormElements/Fieldsets/PropMutliSelect/PropsMultiselectSet";
import useJwt from "../../../hooks/useJwt";
import Loader from "../../../components/Loader";
import DropDown from "../../../components/FilterDropDown";

const NODE_API_URL = process.env.REACT_APP_NODE_API_URL;

const useStyles = createUseStyles(() => ({
  pickerGroup: {
    maxWidth: "400px",
    display: "block",
    width: "40%",
  },
  formGroup: {
    display: "block",
    width: "60%",
  },
  pickerWrap: {
    marginBottom: "24px",
  },
  carouselsInfo: {
    padding: "24px 0px",
  },
}));

const subscribe = (eventName: string, listener: (e) => void) => {
  document.addEventListener(eventName, listener);
};
const unsubscribe = (eventName: string, listener: () => void) => {
  document.removeEventListener(eventName, listener);
};

const CarouselCreateEdit: React.FC = () => {
  const classes = useStyles();
  const { carousel_id } = useParams<{ carousel_id: string }>();
  const [reset] = useState(false);
  const [editCarousel, setEditCarousel] = useState<GetCarouselPayload>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [propSearchQuery, setPropSearchQuery] =
    useState<PropsSearchQuery>(null);
  const navigate = useNavigate();
  const { currentUser, publishers, publisher } = useContext(GlobalContext);
  const carouselsApi = useCarouselsApi();
  const theme = useTheme();
  const { decodeJwt } = useJwt();
  const [publisherSelect, setPublisherSelect] = useState("");
  const [publisherEmbed, setPublisherEmbed] = useState(publisher);

  useEffect(() => {
    if (publisherSelect.length) {
      setPublisherEmbed(publishers.filter((p) => p.name == publisherSelect)[0]);
    }
  }, [publisherSelect]);

  useEffect(() => {
    subscribe("tagsOnMultiSelectChange", (event) => {
      if (propSearchQuery?.tags && !event.detail.length) {
        delete propSearchQuery.tags;
        setPropSearchQuery({
          ...propSearchQuery,
        });
      } else {
        setPropSearchQuery({
          ...propSearchQuery,
          tags: event.detail,
        });
      }
    });

    return () => {
      unsubscribe("tagsOnMultiSelectChange", () => {
        //void
      });
    };
  }, []);

  const bread = [
    {
      title: "Props",
      link: "/props/index",
      isCurrent: false,
    },
    {
      title: "Prop Carousels",
      link: "/prop-carousels",
      isCurrent: false,
    },
    {
      title: "Create/Edit",
      isCurrent: true,
    },
  ];
  const privatePropsOptions = [
    {
      label: "Use from all props",
      value: "false",
    },
    {
      label: "Use only props created by my publisher",
      value: "true",
    },
  ];
  const sdkOptions = [
    {
      label: "Yes",
      value: "true",
    },
    {
      label: "No",
      value: "false",
    },
  ];
  const navigateToEdit = (resp: Carousel) => {
    navigate(`/prop-carousels/${resp.id}/edit`);
    window.location.reload();
  };

  const fetchEditCarousel = async (id: number | string) => {
    if (!id) {
      return;
    }
    try {
      const carousel = await carouselsApi.fetchCarousel(id);
      if (carousel.tags.length || carousel.league) {
        const newPropSearchQuery = { ...propSearchQuery };
        if (carousel.tags.length) {
          newPropSearchQuery.tags = carousel.tags.map((tag) => tag.id);
        }
        if (carousel.league_id) {
          newPropSearchQuery.leagues = [carousel.league_id];
        }
        if (carousel.private_props) {
          newPropSearchQuery.publishers = [currentUser.publisher_id];
        }
        setPropSearchQuery(newPropSearchQuery);
      }
      await setEditCarousel(carousel);
    } catch (e) {
      pickupErrorHandlerWeb(e);
      enqueueSnackbar("Could not fetch carousel, please try again", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchEditCarousel(carousel_id).then(() => {
      setLoading(false);
      document.addEventListener("DOMSubtreeModified", (e) => {
        const buttons = document.querySelectorAll<HTMLButtonElement>("button");
        Array.from(buttons).forEach((e) => {
          if (e["title"]) {
            e["disabled"] = true;
            e.setAttribute("style", "pointer-events:none");
            e.childNodes.forEach((div) => {
              if (div.textContent) {
                (div as HTMLElement).style.pointerEvents = "none";
              }
            });
          }
        });
      });
    });
  }, [reset]);

  const handleOnChange = (change) => {
    if (change.target.id === "private_props") {
      if (change.target.value === "true") {
        propSearchQuery.publishers = [currentUser.publisher_id];
        setPropSearchQuery({ ...propSearchQuery });
      } else {
        propSearchQuery.publishers = [];
        setPropSearchQuery({
          ...propSearchQuery,
          publishers: [],
        });
      }
    }
    if (change.target.id === "league_id") {
      if (!change.target.value) {
        delete propSearchQuery.leagues;
        setPropSearchQuery({
          ...propSearchQuery,
        });
      } else {
        setPropSearchQuery({
          ...propSearchQuery,
          leagues: [change.target.value],
        });
      }
    }
  };

  const propOrderUpdateHandler = (propOrder: number[]) => {
    const order = propOrder.map((id, index) => {
      return {
        prop_id: id,
        order: index,
      };
    });

    setEditCarousel({
      ...editCarousel,
      prop_order: order,
    });
  };

  const publisherOptions = () => {
    if (decodeJwt.user.role == "fanpoweradmin") {
      return publishers
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        .map((p) => p.name);
    } else if (decodeJwt.user.role == "tenantadmin") {
      return publishers
        .filter((pub) => pub.tenant_id == publisher.tenant_id)
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        .map((p) => p.name);
    }
  };

  const handleSubmit = async (values): Promise<Carousel> => {
    const isUpdate = editCarousel && editCarousel?.id;
    const payload = {
      name: values.name,
      description: values.description,
      private_props: values.private_props == "true",
      league_id: Array.isArray(values.league_id)
        ? values.league_id[0]
        : values.league_id,
      tags: values.tags,
      prop_ids: values.prop_ids,
      start_date: values.start_date,
      end_date: values.end_date,
      publisher_id: currentUser.publisher_id,
      sdk: values.sdk,
      max_props: values.max_props,
      view_limit: values.view_limit,
      ordering_option: values.ordering_option,
      prop_order: editCarousel?.prop_order ? editCarousel.prop_order : [],
    };

    const errorMes = isUpdate
      ? "Error updating carousel"
      : "Error creating carousel";
    try {
      if (isUpdate) {
        const updated = await carouselsApi.update(editCarousel.id, payload);
        enqueueSnackbar("Updated successfully", { variant: "success" });
        setEditCarousel(updated);
        return updated;
      } else {
        const created = await carouselsApi.create(payload);
        enqueueSnackbar(`Carousel "${created.name}" has been created`, {
          variant: "success",
        });
        return created;
      }
    } catch (e) {
      pickupErrorHandlerWeb(e);
      enqueueSnackbar(errorMes, { variant: "error" });
      throw e;
    }
  };
  const getInitialValues = () => {
    if (editCarousel && editCarousel?.id) {
      return {
        name: editCarousel.name,
        description: editCarousel.description,
        private_props: editCarousel.private_props == true ? "true" : "false",
        league_id: [editCarousel.league_id],
        start_date: editCarousel.start_date,
        end_date: editCarousel.end_date,
        tags: editCarousel.tags,
        prop_ids: editCarousel.props,
        sdk: editCarousel.sdk == true ? "true" : "false",
        view_limit: editCarousel.view_limit ? editCarousel.view_limit : 5,
        max_props: editCarousel.max_props ? editCarousel.max_props : 5,
        ordering_option: editCarousel.ordering_option
          ? editCarousel.ordering_option
          : CarouselOrderingOption.UNORDERED,
        prop_order: editCarousel.prop_order ? editCarousel.prop_order : [],
      };
    }

    return {
      name: "",
      description: "",
      private_props: "false",
      league_id: [],
      start_date: null,
      end_date: null,
      tags: [],
      prop_ids: [],
      sdk: "false",
      view_limit: 5,
      max_props: 5,
      ordering_option: CarouselOrderingOption.UNORDERED,
      prop_order: [],
    };
  };
  if (loading) {
    return (
      <NoBorderLayout title={"Carousel of Props"} breadcrumb={bread}>
        <Loader />
      </NoBorderLayout>
    );
  }

  return (
    <NoBorderLayout title={"Carousel of Props"} breadcrumb={bread}>
      <div style={{ padding: "9px 0 15px" }}>
        <Formik
          initialValues={getInitialValues()}
          validationSchema={Yup.object().shape(
            {
              name: Yup.string().max(100).required("Name is a required field"),
              description: Yup.string().max(255),
              league_id: Yup.mixed().when("prop_ids", {
                is: (prop_ids) => {
                  return !!prop_ids?.length;
                },
                then: (schema) => schema.nullable().default(null),
                otherwise: (schema) =>
                  schema.required("Please select a league"),
              }),
              tags: Yup.array()
                .of(Yup.string())
                .when("prop_ids", {
                  is: (prop_ids) => {
                    return !!prop_ids?.length;
                  },
                  then: (schema) => schema.nullable().default(null),
                  otherwise: (schema) =>
                    schema.min(1, "Please select at least one tag"),
                }),
              prop_ids: Yup.array().of(Yup.string()),
              start_date: Yup.mixed()
                .when("end_date", {
                  is: (end_date) => {
                    return !!end_date;
                  },
                  then: Yup.date()
                    .max(
                      Yup.ref("end_date"),
                      "Start date must be before end date"
                    )
                    .nullable()
                    .default(null),
                })
                .nullable()
                .default(null),
              end_date: Yup.mixed()
                .test(
                  "checkDateInPast",
                  "End date cannot be in the past",
                  (d) => {
                    if (!d) {
                      return true;
                    }
                    return !!(d && d > new Date());
                  }
                )
                .when("start_date", {
                  is: (start_date) => {
                    return !!start_date;
                  },
                  then: Yup.date()
                    .min(
                      Yup.ref("start_date"),
                      "End date must be after start date"
                    )
                    .nullable()
                    .default(null),
                })
                .nullable()
                .default(null),
            },
            [["start_date", "end_date"]]
          )}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const isUpdate = editCarousel && editCarousel?.id;
            await handleSubmit(values)
              .then((resp) => {
                if (!resp) {
                  return;
                }
                if (resp.id) {
                  setSubmitting(false);

                  if (!isUpdate) {
                    resetForm();
                    navigateToEdit(resp);
                  }
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }}
        >
          {({
            isSubmitting,
            values,
            handleChange,
            errors,
            touched,
            setFieldTouched,
            setFieldValue,
            handleBlur,
          }) => (
            <div>
              <Divider orientation="horizontal" />
              <Heading>Prop Carousel</Heading>
              <Text className={classes.carouselsInfo}>
                Carousels are populated by your league and tag selections. Props
                with the same tags will be prioritized first, followed by the
                same league.
              </Text>
              <div style={{ display: "flex" }}>
                <div className={classes.formGroup}>
                  <Form onChange={handleOnChange}>
                    <DesktopButtons
                      isSubmitting={isSubmitting}
                      cancelRoute={"/prop-carousels"}
                    />
                    <TextInputGroup
                      label={"Name"}
                      id={"name"}
                      showCharCount={true}
                      maxLength={100}
                      value={values.name}
                      onChange={handleChange}
                      setFieldTouched={setFieldTouched}
                      errors={errors.name}
                      touched={touched.name}
                      placeholder={"Carousel Name"}
                      isRequired={true}
                      setActive={setActive}
                    />

                    <TextAreaGroup
                      label={"Description"}
                      id={"description"}
                      showCharCount={true}
                      maxLength={255}
                      value={values.description}
                      isRequired={false}
                      onChange={handleChange}
                      setActive={setActive}
                      errors={errors.description}
                      touched={touched.description}
                      setFieldTouched={setFieldTouched}
                    />

                    <DatepickerFieldset
                      label={"Start Date"}
                      id={"start_date"}
                      value={values.start_date}
                      onChange={handleChange}
                      setActive={setActive}
                      touched={touched.start_date}
                      errors={errors.start_date}
                      setFieldValue={setFieldValue}
                      touchedHandler={setFieldTouched}
                    />

                    {decodeJwt.user.role == "fanpoweradmin" ? (
                      <SingleSelectFieldset
                        label={"Use for mobile SDK"}
                        id={"sdk"}
                        value={values.sdk}
                        options={sdkOptions}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors.sdk}
                        placeholder={"Select Yes or No"}
                        setFieldTouched={setFieldTouched}
                      />
                    ) : null}

                    <SingleSelectFieldset
                      label={"Max props allowed"}
                      id={"max_props"}
                      value={values.max_props}
                      options={[
                        { label: "1", value: "1" },
                        { label: "2", value: 2 },
                        { label: "3", value: 3 },
                        { label: "4", value: 4 },
                        { label: "5", value: 5 },
                        { label: "6", value: 6 },
                        { label: "7", value: 7 },
                        { label: "8", value: 8 },
                        { label: "9", value: 9 },
                        { label: "10", value: 10 },
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.max_props}
                      placeholder={"Select max number of props"}
                      setFieldTouched={setFieldTouched}
                    />

                    <SingleSelectFieldset
                      label={"Number to show at a time"}
                      id={"view_limit"}
                      value={values.view_limit}
                      options={[
                        { label: "1", value: "1" },
                        { label: "2", value: 2 },
                        { label: "3", value: 3 },
                        { label: "4", value: 4 },
                        { label: "5", value: 5 },
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.view_limit}
                      placeholder={"Select number of props to show"}
                      setFieldTouched={setFieldTouched}
                    />

                    <Heading
                      variant={"heading3"}
                      as={"h4"}
                      style={{ paddingBottom: "15px" }}
                    >
                      Prop Selection Criteria:
                    </Heading>

                    <SingleSelectFieldset
                      label={"Exclude Props?"}
                      id={"private_props"}
                      value={values.private_props}
                      options={privatePropsOptions}
                      onChange={handleChange}
                      errors={errors.private_props}
                      onBlur={handleBlur}
                      touched={touched.private_props}
                      setFieldTouched={setFieldTouched}
                      placeholder={"How should props be selected"}
                      helperText={
                        "Select league and tag to filter props against or just search and select Props and leave league and tags blank"
                      }
                    />

                    <LeaguesMultiselectSet
                      label={"League"}
                      id={"league_id"}
                      value={values.league_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.league_id}
                      placeholder={"League for props"}
                      setActive={setActive}
                      reset={false}
                      isRequired={!values.prop_ids?.length}
                      single={true}
                      touched={touched.league_id}
                      setFieldTouched={setFieldTouched}
                      singleValue={values.league_id.toString()}
                    />

                    <TagsMultiselectSet
                      label={"Tags"}
                      id={"tags"}
                      value={values.tags}
                      onChange={handleChange}
                      isRequired={!values.prop_ids?.length}
                      errors={errors.tags}
                      placeholder={"Tags for prop selections"}
                      setActive={setActive}
                      touched={!!touched.tags}
                      reset={false}
                    />
                    <SingleSelectFieldset
                      label={"Prop Ordering"}
                      id={"ordering_option"}
                      value={values.ordering_option}
                      options={[
                        {
                          label: "Custom Order",
                          value: CarouselOrderingOption.CUSTOM,
                        },
                        {
                          label: "Random",
                          value: CarouselOrderingOption.RANDOM,
                        },
                        {
                          label: "Newest",
                          value: CarouselOrderingOption.NEWEST,
                        },
                        {
                          label: "Oldest",
                          value: CarouselOrderingOption.OLDEST,
                        },
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.ordering_option}
                      setFieldTouched={setFieldTouched}
                      placeholder={"Select Prop Ordering Strategy"}
                      helperText={
                        "Select Custom Order to Adjust the Order Your Props Appear in the Embed"
                      }
                    />
                    <PropsMultiselectSet
                      label={"Props"}
                      id={"prop_ids"}
                      query={propSearchQuery}
                      value={values.prop_ids}
                      onChange={handleChange}
                      errors={errors.prop_ids}
                      placeholder={""}
                      setActive={setActive}
                      touched={!!touched.prop_ids}
                      reset={false}
                      orderUpdateHandler={propOrderUpdateHandler}
                      useDraggable={
                        values.ordering_option == CarouselOrderingOption.CUSTOM
                      }
                      order={values.ordering_option}
                    />
                    <MobileButtons
                      isSubmitting={isSubmitting}
                      cancelRoute={"/prop-carousels"}
                    />
                  </Form>
                </div>
                {document.documentElement.clientWidth >=
                theme.breakpoints.medium ? (
                  <>
                    {editCarousel && editCarousel?.prop_ids && publisher ? (
                      <div className={classes.pickerGroup}>
                        {decodeJwt.user.role == "fanpoweradmin" ||
                        decodeJwt.user.role == "tenantadmin" ? (
                          <DropDown
                            label="Publisher Embed"
                            options={publisherOptions()}
                            setValue={setPublisherSelect}
                            overrides={{
                              placeHolder: "publisher",
                              noDefaultLabel: true,
                              defaultValue:
                                (publisherEmbed && publisherEmbed.name) ||
                                publisher.name,
                            }}
                          />
                        ) : null}
                        <PropCard
                          carousel={editCarousel}
                          publisher={{
                            id:
                              (publisherEmbed && publisherEmbed.id) ||
                              currentUser.publisher_id,
                            access_token:
                              (publisherEmbed && publisherEmbed.access_token) ||
                              currentUser.access_token,
                          }}
                        />
                        <Heading variant={"heading3"} as={"h4"}>
                          Selected Props
                        </Heading>
                        <p>
                          We found {editCarousel?.prop_ids?.length} prop(s) that
                          match your criteria
                        </p>
                        <div>
                          Update settings and save to see updated prop selection
                        </div>
                        <StandalonePicker
                          carouselId={editCarousel.id}
                          accessToken={currentUser.access_token}
                          publisherId={currentUser.publisher_id}
                          host={NODE_API_URL}
                          sourceUrl={"https://fanpower.io"}
                        />
                      </div>
                    ) : (
                      <div>Save carousel to populated props</div>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          )}
        </Formik>
      </div>
    </NoBorderLayout>
  );
};

export default CarouselCreateEdit;
