import React from "react";
import {
  Table,
  TableContainer,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  useTheme,
} from "@chakra-ui/react";
import { DefaultTheme } from "../../types/theme";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../../components/common/icon";

export interface FanSearchItem {
  id: number;
  username: string;
  email: string;
}

interface EmbedContentTableProps {
  title: string;
  caption: string;
  collection: FanSearchItem[];
  onClick: (item: FanSearchItem) => void;
}

const FanSearchTable = ({
  title,
  caption,
  collection,
  onClick,
}: EmbedContentTableProps) => {
  const theme = useTheme<DefaultTheme>();

  return (
    <TableContainer marginTop={4}>
      <Table size="sm">
        <TableCaption>{caption}</TableCaption>
        <Thead>
          <Tr>
            <Th>{title}</Th>
            <Th>{}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {collection.map((item) => {
            return (
              <Tr key={item.id}>
                <Td>{item.username}</Td>
                <Td>{item.email}</Td>
                <Td>
                  <Button
                    size="sm"
                    onClick={() => {
                      onClick(item);
                    }}
                    leftIcon={<FontAwesome icon={faPlus} />}
                  >
                    Select
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default FanSearchTable;
