import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import GlobalContext from "../../components/GlobalContext";
import { AiJobPersona, Publisher, SingleSelectItem } from "../../types";
import useAiApi from "../../services/api/Ai";
import FileUploader from "../../components/FileUploader";
import NoBorderLayout from "../../components/common/Layouts/NoBorder";
import { TextAreaGroup } from "../../components/FormElements/TextAreaGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePublishersApi from "../../services/api/Publishers";
import { SingleSelectFieldset } from "../../components/FormElements/Fieldsets/SingleSelectFieldset";
import Loader from "../../components/Loader";
import { faCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../../components/common/icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
    fontFamily: theme.typography.fontFamilies.body,
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  breadcrumbs: {
    fontSize: 15,
  },
  formRow: {
    flexDirection: "column",
    display: "flex",
    marginLeft: "1px",
    marginBottom: theme.spacing.base * 4,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      flexDirection: "row",
      minHeight: 125,
    },
  },
  desktopButtons: {
    display: "none",
    [theme.mediaQuery(theme.breakpoints.small)]: {
      display: "block",
    },
  },
  mobileButtons: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing.base * 4,
    "& > *": {
      height: 50,
      margin: [theme.spacing.base * 2, 0],
    },
    [theme.mediaQuery(theme.breakpoints.small)]: {
      display: "none",
    },
  },
  imageContainer: {
    width: 84,
    height: 84,
    position: "relative",
    borderRadius: "50%",
    border: `1px solid ${theme.colors.grey.light}`,
    overflow: "hidden",
    margin: "0 auto 24px auto",
  },
  emptyAvatar: {
    width: 84,
    height: 84,
    borderRadius: "50%",
    color: theme.colors.grey.base,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto 24px auto",
    overflow: "hidden",
    backgroundColor: theme.colors.grey.lightBase,
    border: `2px dashed ${theme.colors.grey.base}`,
  },
  image: {
    position: "relative",
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
}));

const bread = [
  {
    title: "Content",
    link: "/personas",
    isCurrent: false,
  },
  {
    title: "Personas",
    link: "/personas",
    isCurrent: false,
  },
  {
    title: "Create/Edit",
    isCurrent: true,
  },
];

const MAX_FILE_SIZE_IN_BYTES = 2000 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

interface PersonaForm {
  name: string;
  description: string;
  publisher_id: string | number;
  voice: string;
  audience: string;
  avatar_source?: string;
  avatar_file?: File;
}

const subscribe = (eventName: string, listener: (e) => void) => {
  document.addEventListener(eventName, listener);
};
const unsubscribe = (eventName: string, listener: () => void) => {
  document.removeEventListener(eventName, listener);
};

const PersonasCreateEdit: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(GlobalContext);
  const formRef = useRef<FormikProps<PersonaForm>>();
  const { persona_id } = useParams<{ persona_id: string }>();
  const queryPublisher = location.search.split("=")[1];
  const [persona, setPersona] = useState<AiJobPersona>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imgLoadError, setImgLoadError] = useState(false);
  const [reset, setReset] = useState(false);
  const { publisher } = useContext(GlobalContext);
  const [, setPublishers] = useState<Publisher[]>([]);
  const [pubOptions, setPubOptions] = useState<SingleSelectItem[]>([]);
  const publishersApi = usePublishersApi();
  const aiApi = useAiApi();

  const fetchPublisherOptions = async () => {
    if (publisher) {
      const publishers = await publishersApi.getPublishers(null, null);
      setPublishers(publishers.sort((a, b) => a.name.localeCompare(b.name)));

      const tenants = publishers
        .map((pub) => {
          return pub.tenant_name;
        })
        .filter((value, i, self) => {
          return value != null && self.indexOf(value) == i;
        })
        .sort();

      // initially populate with non-tenant publishers
      const pubAndTenantOps: SingleSelectItem[] = publishers
        .filter((pub) => pub.tenant_name == null)
        .map((pub) => {
          return {
            label: pub.name,
            value: pub.id.toString(),
          };
        });
      // add publishers by tenant
      for (const tenant of tenants) {
        for (const pub of publishers) {
          if (pub.tenant_name == tenant) {
            pubAndTenantOps.push({
              label: tenant + ": " + pub.name,
              value: pub.id.toString(),
            });
          }
        }
      }

      setPubOptions(pubAndTenantOps);
    }
  };

  useEffect(() => {
    fetchPublisherOptions();
  }, [publisher]);

  const navigateToEdit = (resp: AiJobPersona) => {
    navigate(`${resp.id}/edit`);
  };

  useEffect(() => {
    subscribe("avatar_fileOnUploadFileChange", (event) => {
      if (!event.detail) {
        formRef.current.setFieldValue("avatar_source", "");
      } else {
        formRef.current.setFieldValue(
          "avatar_source",
          URL.createObjectURL(event.detail)
        );
      }
    });

    return () => {
      unsubscribe("avatar_fileOnUploadFileChange", () => {
        //void
      });
    };
  }, []);

  const fetchEditPersona = async (id: number | string) => {
    if (!id) {
      return;
    }
    setLoading(true);
    try {
      const persona = await aiApi.getPersonaById(id);
      setPersona(persona);
    } catch (e) {
      pickupErrorHandlerWeb(e);
      enqueueSnackbar("Could not fetch persona, please try again", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchEditPersona(persona_id).then(() => {
      setLoading(false);
    });
  }, [reset]);

  const createPersona = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    const createdPersona = await aiApi.createPersona(
      formData as unknown as Omit<AiJobPersona, "id">
    );

    if (createdPersona) {
      enqueueSnackbar(`New Persona "${createdPersona.name}" created!`, {
        variant: "success",
      });
      setReset(true);
    }
    return createdPersona;
  };

  const editPersona = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    const editedPersona = await aiApi.updatePersona(
      persona_id,
      formData as unknown as AiJobPersona
    );

    if (editedPersona) {
      setLoading(false);
      enqueueSnackbar("Persona updated!", {
        variant: "success",
      });
    }
    return editedPersona;
  };

  const covertUrlToFile = (url: string) => {
    if (!url) return;
    const fileName = url.split("/").pop();
    const ext = fileName.split(".").pop();
    return new File([url], fileName, { type: `image/${ext || "png"}` });
  };

  const handleSubmit = async (values: PersonaForm) => {
    const { name, description, voice, audience, avatar_file, publisher_id } =
      values;

    if (imgLoadError && avatar_file) {
      setImgLoadError(false);
    }

    const data = {
      ...(persona && persona.id && { id: persona.id }),
      name,
      description,
      publisherId: publisher_id,
      avatar_file,
      voice,
      audience,
    };

    if (persona) {
      return editPersona(data).catch((error) => {
        if (error?.response?.status === 500) {
          enqueueSnackbar("That name already exits!", { variant: "error" });
        } else {
          enqueueSnackbar("There was an error saving your Persona.", {
            variant: "error",
          });
        }
        pickupErrorHandlerWeb(error);
        throw error;
      });
    }
    return createPersona(data).catch((error) => {
      if (error?.response?.status === 500) {
        enqueueSnackbar("That name already exits!", { variant: "error" });
      } else {
        enqueueSnackbar("There was an error saving your Persona.", {
          variant: "error",
        });
      }
      pickupErrorHandlerWeb(error);
      throw error;
    });
  };

  if (loading) {
    return (
      <NoBorderLayout title={"Persona"} breadcrumb={bread}>
        <Loader />
      </NoBorderLayout>
    );
  }

  return (
    <NoBorderLayout title={"Persona"} breadcrumb={bread}>
      <Formik
        initialValues={{
          name: persona?.name || "",
          publisher_id: persona?.publisher_id || queryPublisher || "",
          description: persona?.description || "",
          voice: persona?.voice || "",
          audience: persona?.audience || "",
          avatar_source: persona?.avatar?.src ? persona.avatar.src : "",
          avatar_file: covertUrlToFile(persona?.avatar?.src) || undefined,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(100, "Character limit 100")
            .required("Name is required"),
          description: Yup.string()
            .max(280, "Character limit 280")
            .required("Description is required"),
          voice: Yup.string()
            .max(280, "Character limit 280")
            .required("Voice is required"),
          audience: Yup.string()
            .max(280, "Character limit 280")
            .required("Audience is required"),
        })}
        innerRef={formRef}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const isUpdate = persona && persona?.id;
          await handleSubmit(values)
            .then((resp) => {
              if (!resp) {
                return;
              }
              if (resp.id) {
                setSubmitting(false);

                if (!isUpdate) {
                  resetForm();
                  navigateToEdit(resp);
                }
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          errors,
          handleBlur,
          touched,
          setFieldTouched,
        }) => (
          <Form>
            <div className={classes.nav}>
              <div className={classes.desktopButtons}>
                <Button
                  leftIcon={<FontAwesome icon={faCheck} />}
                  type="submit"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>

                <Button
                  leftIcon={<FontAwesome icon={faCircleXmark} />}
                  variant="outline"
                  as={Link}
                  to="/personas"
                >
                  Cancel
                </Button>
              </div>
            </div>
            <Divider orientation="horizontal" />
            <Heading>{persona ? "Edit Persona" : "Create Persona"}</Heading>

            <Text style={{ marginBottom: 10 }}>* Required fields</Text>

            <SingleSelectFieldset
              label={"Publisher"}
              id={"publisher_id"}
              options={pubOptions}
              value={values.publisher_id?.toString()}
              onChange={handleChange}
              errors={errors.publisher_id}
              onBlur={handleBlur}
              touched={touched.publisher_id}
              placeholder={"Select publisher"}
              setFieldTouched={setFieldTouched}
            />

            <div className={classes.formRow}>
              <FormControl isInvalid={errors.name && touched.name} isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  id="name"
                  placeholder="Happy Gilmore"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormErrorMessage fontSize={14}>{errors.name}</FormErrorMessage>
              </FormControl>
            </div>

            <div style={{ minHeight: "164px" }} className={classes.formRow}>
              <TextAreaGroup
                label={"Description"}
                id={"description"}
                showCharCount={true}
                maxLength={280}
                value={values.description}
                isRequired={true}
                setActive={setActive}
                onChange={handleChange}
                errors={errors.description}
                touched={touched.description}
                setFieldTouched={setFieldTouched}
                helperText="Describe your persona: what kind of person are they, what are their likes and dislikes, be as descriptive as possible."
                isInvalid={errors.description && touched.description}
              />
            </div>

            <div style={{ minHeight: "164px" }} className={classes.formRow}>
              <TextAreaGroup
                label={"Voice"}
                id={"voice"}
                showCharCount={true}
                maxLength={280}
                value={values.voice}
                isRequired={true}
                setActive={setActive}
                onChange={handleChange}
                errors={errors.voice}
                touched={touched.voice}
                setFieldTouched={setFieldTouched}
                isInvalid={errors.voice && touched.voice}
                helperText="How does your persona write? You can compare them to popular publications or journalists."
              />
            </div>

            <div style={{ minHeight: "164px" }} className={classes.formRow}>
              <TextAreaGroup
                label={"Audience"}
                id={"audience"}
                showCharCount={true}
                maxLength={280}
                value={values.audience}
                isRequired={true}
                setActive={setActive}
                onChange={handleChange}
                errors={errors.audience}
                touched={touched.audience}
                setFieldTouched={setFieldTouched}
                isInvalid={errors.audience && touched.audience}
                helperText="Who is your persona's audience? What kind of people read the content your persona will write?"
              />
            </div>

            <div className={classes.formRow}>
              <FormControl
                style={{ marginRight: 0 }}
                isInvalid={errors.avatar_source && touched.avatar_source}
              >
                <FormLabel>Avatar</FormLabel>

                {!values.avatar_source || imgLoadError ? (
                  <div className={classes.emptyAvatar}>
                    <FontAwesomeIcon
                      style={{ marginBottom: "3px" }}
                      size="2x"
                      icon={faRobot}
                    />
                  </div>
                ) : (
                  <div className={classes.imageContainer}>
                    <img
                      src={values.avatar_source || currentUser.gravatar_url}
                      onError={() => {
                        setImgLoadError(true);
                        enqueueSnackbar(
                          "There was an error loading your avatar image.",
                          { variant: "error" }
                        );
                      }}
                      alt=""
                      className={classes.image}
                    />
                  </div>
                )}
                <FileUploader
                  name="avatar_file"
                  maxFileSize={MAX_FILE_SIZE_IN_BYTES}
                  supportedFormats={SUPPORTED_FORMATS}
                />
                <FormErrorMessage fontSize={14}>
                  {errors.avatar_file as string}
                </FormErrorMessage>
              </FormControl>
            </div>

            <div className={classes.mobileButtons}>
              <Button
                leftIcon={<FontAwesome icon={faCheck} />}
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Save
              </Button>

              <Button
                leftIcon={<FontAwesome icon={faCircleXmark} />}
                variant="outline"
                as={Link}
                to="/personas"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </NoBorderLayout>
  );
};

export default PersonasCreateEdit;
