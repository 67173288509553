import React, { useContext, useEffect, useState } from "react";
import NoBorderLayout from "../../components/common/Layouts/NoBorder";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { TextAreaGroup } from "../../components/FormElements/TextAreaGroup";
import { TextInputGroup } from "../../components/FormElements/TextInputGroup";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import DesktopButtons from "../../components/FormElements/DesktopButtons";
import MobileButtons from "../../components/FormElements/MobileButtons";
import GlobalContext from "../../components/GlobalContext";
import { EmailTemplateTypes, PublisherEmailTemplate } from "../../types";
import { useSnackbar } from "notistack";
import useFanEngagementApi from "../../services/api/FanEngagement";
import Loader from "../../components/Loader";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
  },

  columnLeft: {
    width: "50%",
    paddingRight: "20px",
  },
  columnRight: {
    width: "50%",
  },
  hideLabel: {
    paddingTop: "15px",
    " & label": {
      display: "none",
    },
  },
  fieldWrap: {
    borderLeft: `1px solid ${theme.colors.grey.dark}`,
    paddingLeft: "20px",
    " & label": {
      width: "94px",
    },
    " & input": {
      height: "30px",
    },
  },
}));
const FanEngagementTemplates: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser, publisher } = useContext(GlobalContext);
  const [noTemplates, setNoTemplates] = useState<boolean>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const classes = useStyles();
  const bread = [
    {
      title: "Tools",
      link: "/",
      isCurrent: false,
    },
    {
      title: "Re Engagement",
      isCurrent: true,
    },
  ];
  const [templates, setTemplates] = useState<PublisherEmailTemplate[]>([]);
  const [currentTemplate, setCurrentTemplate] =
    useState<PublisherEmailTemplate>({
      created_at: undefined,
      html: "",
      id: 0,
      preheader: "",
      publisher_id: 0,
      subject: "",
      template_id: "",
      template_type: EmailTemplateTypes.firstTimeFan,
      text: "",
      updated_at: undefined,
    });
  const fanEngagementApi = useFanEngagementApi();

  const fetchTemplates = async () => {
    try {
      const pubId =
        publisher && publisher.id ? publisher.id : currentUser.publisher_id;
      const result = await fanEngagementApi.getEmailTemplatesByPublisher(pubId);
      console.log("templatres", result);
      if (!result || result.length < 1) {
        setNoTemplates(true);
      } else {
        setTemplates(result);
        setCurrentTemplate(result[0]); // key off selector index when other template types are implemented
        setNoTemplates(false);
      }
    } catch (e) {
      enqueueSnackbar("Could not retrieve template values", {
        variant: "error",
      });
      console.log(e);
    }
  };

  useEffect(() => {
    // fetch the template
    fetchTemplates().then(() => {
      setLoading(false);
    });
  }, []);

  const handleSubmit = async (values) => {
    const pubId =
      publisher && publisher.id ? publisher.id : currentUser.publisher_id;
    setLoading(true);
    try {
      const updated = await fanEngagementApi.updateTemplate(
        pubId,
        EmailTemplateTypes.firstTimeFan, // this would be defined by the selected type
        values.template_id,
        values.email_subject,
        values.email_preheader,
        values.email_body,
        values.sms_body
      );
      await setCurrentTemplate(updated);
      values.email_body = updated.text;
      values.email_preheader = updated.preheader;
      values.email_subject = updated.subject;
      values.sms_body = updated.sms_body;
      values.template_id = updated.template_id;
      setLoading(false);
      enqueueSnackbar("Fan Engagement values updated.", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(
        "We got an error on update and are looking into it. Try again later or contact support",
        { variant: "error" }
      );
      return false;
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <NoBorderLayout title={"Re Engagement"} breadcrumb={bread}>
      <div style={{ padding: "9px 0 15px" }}>
        <div>
          {/* <Dropdown> to choose template or workflow should go here. will select from available template types */}
        </div>

        <Text size={"sm"}>
          Define the messaging to directly engage with your fans{" "}
        </Text>
        <Heading
          as={"h4"}
          size={"sm"}
          style={{ paddingLeft: "0", paddingTop: "9px" }}
        >
          Engage after first Pick
        </Heading>
        <Text variant={"body"}>
          Messaging will be sent to Fans after they have made their first pick
          on your site.
        </Text>
      </div>

      <Formik
        initialValues={{
          template_id: currentTemplate?.template_id,
          sms_body: currentTemplate?.sms_body ? currentTemplate.sms_body : "",
          email_subject: currentTemplate?.subject
            ? currentTemplate.subject
            : "",
          email_preheader: currentTemplate?.preheader
            ? currentTemplate.preheader
            : "",
          email_body: currentTemplate?.text ? currentTemplate.text : "",
        }}
        validationSchema={Yup.object().shape({
          sms_body: Yup.string().max(160, "Must be 160 characters or less"),
          email_subject: Yup.string().max(
            100,
            "Must be 100 characters or less"
          ),
          template_id: Yup.string().max(100, "Must be 100 characters or less"),
          email_preheader: Yup.string().max(
            100,
            "Must be 100 characters or less"
          ),
          email_body: Yup.string().max(1000, "Must be 1000 characters or less"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form>
            <Flex>
              <div className={classes.columnLeft}>
                <Heading as={"h4"} size={"sm"} style={{ fontStyle: "italic" }}>
                  Text Message:
                </Heading>
                <div className={classes.hideLabel}>
                  <TextAreaGroup
                    label={"Text Message"}
                    id={"sms_body"}
                    value={values.sms_body}
                    errors={[]}
                    onChange={handleChange}
                    placeholder={"placeholder"}
                    maxLength={160}
                    isDisabled={loading}
                    showCharCount={true}
                    setActive={(value) => {
                      return;
                    }}
                  />
                </div>
              </div>
              <div className={classes.columnRight}>
                <Heading
                  as={"h4"}
                  size={"sm"}
                  style={{
                    fontStyle: "italic",
                    marginBottom: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  Email:
                </Heading>
                {noTemplates ? (
                  <Text
                    variant={"body"}
                    style={{ padding: "12px", fontStyle: "italic" }}
                  >
                    Please contact support to setup email templates with
                    SendGrid
                  </Text>
                ) : (
                  ""
                )}

                <div className={classes.fieldWrap}>
                  <TextInputGroup
                    label={"Template ID:"}
                    wrapLabels={true}
                    id={"template_id"}
                    value={values.template_id}
                    onChange={handleChange}
                    errors={[]}
                    setActive={() => {
                      return;
                    }}
                    placeholder={"Template ID"}
                    maxLength={100}
                    isDisabled={loading}
                    showCharCount={true}
                  />
                  <TextInputGroup
                    label={"Subject:"}
                    wrapLabels={true}
                    id={"email_subject"}
                    value={values.email_subject}
                    onChange={handleChange}
                    errors={[]}
                    setActive={() => {
                      return;
                    }}
                    placeholder={"Subject"}
                    maxLength={100}
                    isDisabled={loading}
                    showCharCount={true}
                  />
                  <TextInputGroup
                    label={"Preheader:"}
                    id={"email_preheader"}
                    value={values.email_preheader}
                    onChange={handleChange}
                    setActive={() => {
                      return;
                    }}
                    errors={[]}
                    placeholder={"Preheader"}
                    maxLength={100}
                    isDisabled={loading}
                    wrapLabels={true}
                    showCharCount={true}
                  />
                  <TextAreaGroup
                    label={"Body:"}
                    id={"email_body"}
                    value={values.email_body}
                    onChange={handleChange}
                    errors={[]}
                    setActive={() => {
                      return;
                    }}
                    maxLength={1000}
                    isDisabled={loading}
                    wrapLabels={true}
                    showCharCount={true}
                  />
                </div>
              </div>
            </Flex>
            <DesktopButtons isSubmitting={isSubmitting} cancelRoute={""} />
            <MobileButtons isSubmitting={isSubmitting} cancelRoute={""} />
          </Form>
        )}
      </Formik>
    </NoBorderLayout>
  );
};
export default FanEngagementTemplates;
