import { AxiosError } from "axios";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { EmailTemplateTypes, PublisherEmailTemplate } from "../../types";
import useJwt from "../../hooks/useJwt";

const NODE_API_URL_v2 = process.env.REACT_APP_NODE_API_URL_V2;

const useFanEngagementApi = () => {
  const { privateAxiosV2 } = useJwt();

  const getEmailTemplatesByPublisher = async (
    pubId: number
  ): Promise<PublisherEmailTemplate[]> => {
    return await privateAxiosV2
      .get(`/publishers/${pubId}/templates`)
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
      });
  };

  const updateTemplate = async (
    pubId: number,
    type: EmailTemplateTypes,
    templateId: string,
    subject,
    preheader,
    body,
    sms
  ): Promise<PublisherEmailTemplate> => {
    return await privateAxiosV2
      .patch(`/publishers/${pubId}/templates/${type}`, {
        template_id: templateId,
        subject: subject,
        text: body,
        preheader: preheader,
        sms_body: sms,
      })
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
      });
  };

  return { getEmailTemplatesByPublisher, updateTemplate };
};

export default useFanEngagementApi;
