import React from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { Badge, useTheme } from "@chakra-ui/react";
import { StatusBadgeProps } from "../../types";

const colors = {
  draft: {
    foreground: "#91979D",
    background: "#F9F9FA",
  },
  published: {
    foreground: "#3CC13B",
    background: "#EBF9EB",
  },
  scheduled: {
    foreground: "#3E66FB",
    background: "#EBEFFF",
  },
  archived: {
    foreground: "#B67F15",
    background: "#FEF6E8",
  },
  aiChip: {
    foreground: "#7A60FF",
    background: "#EBF9EB",
  },
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  badge: {
    padding: [theme.spacing.base, theme.spacing.base * 1.5],
    border: ({ statusText, color }: StatusBadgeProps) =>
      "1px solid " + colors[color || statusText]?.foreground || "#91979D",
    backgroundColor: ({ statusText, color }: StatusBadgeProps) =>
      colors[color || statusText]?.background || "#F9F9FA",
    color: ({ statusText, color }: StatusBadgeProps) =>
      colors[color || statusText]?.foreground || "#91979D",
    borderRadius: 5,
    fontSize: 12,
    textTransform: "capitalize",
  },
}));

const StatusBadge: React.FC<StatusBadgeProps> = ({ statusText, color }) => {
  const theme = useTheme<DefaultTheme>();
  const classes = useStyles({ statusText, color, theme });
  return <Badge className={classes.badge}>{statusText}</Badge>;
};

export default StatusBadge;
