import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { Button, Flex, Spacer, useTheme } from "@chakra-ui/react";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { AdZone } from "../../types";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useJwt from "../../hooks/useJwt";
import Loader from "../../components/Loader";
import ListTable from "../../components/common/Layouts/ListTable";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import {
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../../components/common/icon";

const FETCH_LIMIT = 100;

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
  },
}));

const AdZones: React.FC = () => {
  const [adZones, setAdZones] = useState<AdZone[]>([]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { privateAxiosV1 } = useJwt();
  const theme = useTheme<DefaultTheme>();

  const createAdZone = () => {
    navigate("/ad-zones/create");
  };

  const editAdZone = (adZone: AdZone) => {
    navigate("/ad-zones/edit", { state: { adZone } });
  };
  const deleteAdZone = async (item: any) => {
    try {
      const adZone = await privateAxiosV1
        .delete(`/adzones`, {
          params: { id: item.id },
        })
        .catch((err) => {
          throw err;
        });
      if (!adZone.data) {
        throw new Error("Error deleting adZone");
      }
      getAdZones()
        .then(() =>
          enqueueSnackbar("Successfully deleted AdZone", {
            variant: "success",
          })
        )
        .catch((err) => {
          throw err;
        });
    } catch (err) {
      enqueueSnackbar("Adzone could not be deleted", { variant: "error" });
      pickupErrorHandlerWeb(err);
    }
  };

  const getAdZones = async (): Promise<void> => {
    try {
      const adZones = await privateAxiosV1.get(`/adzones`, {
        params: {
          limit: FETCH_LIMIT,
        },
      });
      if (!adZones.data) {
        throw new Error("Could not get adZones");
      }
      setAdZones(adZones.data.adzones);
    } catch (err) {
      pickupErrorHandlerWeb(err);
    }
  };
  useEffect(() => {
    getAdZones();
  }, []);

  return (
    <div className={classes.root}>
      {adZones ? (
        <div>
          <Flex paddingBottom={theme.spacing.base * 2}>
            <BreadcrumbsBlock
              links={[{ title: "Ad Zones", isCurrent: true }]}
            />

            <Spacer />
            <Button
              leftIcon={<FontAwesome icon={faPlus} />}
              onClick={() => createAdZone()}
            >
              Create New
            </Button>
          </Flex>
          <ListTable
            heading={"Ad Zones"}
            headers={[
              { title: "Action" },
              { title: "Name" },
              { title: "Description" },
            ]}
            list={adZones}
            columns={[
              { name: "name", type: "string" },
              { name: "description", type: "string" },
            ]}
            onDelete={deleteAdZone}
            alertTitle="Delete this AdZone?"
            actions={[
              {
                name: "Edit",
                actionHandler: editAdZone,
                icon: <FontAwesome icon={faPenToSquare} />,
              },
              {
                name: "Delete",
                actionHandler: null,
                icon: <FontAwesome icon={faTrash} />,
              },
            ]}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default AdZones;
