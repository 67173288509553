import React, { ChangeEventHandler, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../../types/theme";
import { FormikErrors, useFormikContext } from "formik";
import { mapArrayToMultiselect } from "../../../../helpers/transformers";
import { MultiSelectItem, Prop, PropsSearchQuery } from "../../../../types";
import { pickupErrorHandlerWeb } from "../../../../helpers/pickupErrorHandlerWeb";
import classNames from "classnames";
import usePropsApi from "../../../../services/api/Props";
import { InputGroupWrapper } from "../../InputGroupWrapper";
import {
  FormLabel,
  IconButton,
  Button,
  Text,
  Input,
  Tooltip,
  Box,
  Tag,
  useTheme,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { ToolTipButton } from "../../ToolTipButton";
import { faPlus, faTrash, faUpDown } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../Loader";
import { FontAwesome } from "../../../common/icon";
import FlipMove from "react-flip-move";
import { shuffle } from "lodash";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  input: {
    height: 40,
    fontSize: 14,
    fontFamily: theme.typography.fontFamilies.body,
    color: theme.colors.grey.dark,
    borderColor: theme.colors.grey.lightBase,
    backgroundColor: theme.colors.white,
  },
  root: {
    width: "100%",
    fontFamily: theme.typography.fontFamilies.body,
  },
  formLabel: {
    fontSize: 15,
    color: theme.colors.grey.dark,
  },
  searchText: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.colors.grey.dark,
  },
  resultContainer: {
    overflowX: "hidden",
    width: "100%",
    "& > :first-child": {
      marginTop: theme.spacing.base * 2,
    },
  },
  result: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "bold",
    color: theme.colors.black,
    marginBottom: theme.spacing.base,
    "& button": {
      marginRight: theme.spacing.base * 4,
    },
  },
  selectedContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: [theme.spacing.base * 2, 0],
  },
  selection: {
    display: "inline-flex",
    width: "calc(100% - 32px)",
    flexDirection: "row",
    alignItems: "center",
    background: theme.colors.primary.base,
    fontSize: 12,
    color: theme.colors.white,
    borderRadius: 6,
    margin: theme.spacing.base,
    padding: [0, theme.spacing.base, 0, theme.spacing.base * 2],
    "& button": {
      background: theme.colors.primary.base,
    },
  },
  selectionWrapper: {
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  help: {
    paddingTop: theme.spacing.base * 2,
  },
  selectionNumber: {
    minWidth: 16,
    display: "inline-block",
    fontSize: 14,
    verticalAlign: "middle",
    marginRight: theme.spacing.base,
    marginLeft: 8,
  },
  requiredAsterisk: {
    color: theme.colors.red.base,
  },
}));

export interface PropsMultiselectProps {
  label: string;
  id: string;
  value: Prop[];
  query: PropsSearchQuery;
  onChange: ChangeEventHandler;
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  placeholder: string;
  setActive?: (boolean: boolean) => void;
  maxLength?: number;
  showCharCount?: boolean;
  wrapLabels?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  reset: boolean;
  touched?: boolean;
  orderUpdateHandler?: (order: number[]) => void;
  useDraggable?: boolean;
  order: string;
}

export const PropsMultiselectSet: React.FC<PropsMultiselectProps> = ({
  label,
  id,
  query,
  value,
  onChange,
  errors,
  placeholder,
  reset,
  touched,
  isRequired,
  orderUpdateHandler,
  useDraggable,
  order,
}) => {
  const classes = useStyles();
  const [className] = useState();
  const [style] = useState();
  const [propsQuery, setPropsQuery] = useState<PropsSearchQuery>(query);
  const formikProps = useFormikContext();
  const [inputValue, setInputValue] = useState<string>("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [debounceTime] = useState<number>(500);
  const [results, setResults] = useState<Array<MultiSelectItem>>([]);
  const [selected, setSelected] = useState<Array<MultiSelectItem>>(
    mapArrayToMultiselect(value as unknown as MultiSelectItem[], "proposition")
  );

  const [loading, setLoading] = useState(false);
  const propsApi = usePropsApi();
  const [dragVisible, setDragVisible] = useState(false);
  const theme = useTheme<DefaultTheme>();

  const fetchProps = async () => {
    if (
      !propsQuery ||
      typeof propsQuery !== "object" ||
      (typeof propsQuery === "object" && !Object.keys(propsQuery).length)
    )
      return;
    try {
      setLoading(true);
      const props = await propsApi.getPropsAsMultiSelect(propsQuery);
      setResults(props);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      pickupErrorHandlerWeb(error);
    }
  };

  useEffect(() => {
    if (propsQuery?.leagues && !query?.leagues) {
      delete propsQuery.leagues;
    }
    if (propsQuery?.tags && !query?.tags) {
      delete propsQuery.tags;
    }
    setPropsQuery({ ...propsQuery, ...query });
  }, [query]);

  useEffect(() => {
    // Since the propsQuery can be updated outside the component, we only want to search
    // for props if there is an inputValue.
    if (inputValue && inputValue.length > 2) {
      fetchProps();
    }
  }, [propsQuery]);

  useEffect(() => {
    const selectedIds = selected.map((selection: MultiSelectItem) => {
      return selection.id;
    });
    // Compare to old form values and update if changed
    // This prevents an unneeded rerender on initial load
    if (
      JSON.stringify(selectedIds) !== JSON.stringify(formikProps.values[id])
    ) {
      formikProps.setFieldValue(id, selectedIds);
    }
  }, [selected]);

  useEffect(() => {
    if (order == "newest") {
      const sortedProps = selected.sort((a, b) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
      setSelected(sortedProps);
      orderUpdateHandler(sortedProps.map((p) => p.id));
    } else if (order == "oldest") {
      const sortedProps = selected.sort((a, b) => {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      });
      setSelected(sortedProps);
      orderUpdateHandler(sortedProps.map((p) => p.id));
    } else if (order == "random") {
      const shuffledProps = shuffle(selected);
      setSelected(shuffledProps);
      orderUpdateHandler(shuffledProps.map((p) => p.id));
    }
  }, [order]);

  const searchProps = (search: string) => {
    if (search.length < 3) return;
    if (!search) {
      setResults([]);
    } else {
      setPropsQuery({ ...propsQuery, search: inputValue });
    }
  };

  const handleOnKeyUp = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
      setDebounceTimeout(null);
    }
    setDebounceTimeout(
      setTimeout(() => {
        setDebounceTimeout(null);
        searchProps(inputValue);
      }, debounceTime)
    );
  };

  const handleOnChange = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    const search = e.target.value;
    setInputValue(e.target.value);
    if (!search) {
      setResults([]);
    }
  };

  const selectResult = (e: React.SyntheticEvent, result: MultiSelectItem) => {
    e.preventDefault();
    const index = selected.findIndex(
      (object: MultiSelectItem) =>
        object.name.toLowerCase() === result.name.toLowerCase()
    );
    if (index === -1) {
      setSelected([...selected, result]);
      document.getElementById(`${id}MultiSelectInput`).focus();
    }
  };

  const removeSelection = (
    e: React.SyntheticEvent,
    selection: MultiSelectItem
  ) => {
    e.preventDefault();
    setSelected(
      selected.filter(
        (element: MultiSelectItem) => element.name !== selection.name
      )
    );
  };

  const showResults = () => {
    if (results && results.length) {
      return results.map((result: MultiSelectItem) => {
        return (
          <div key={result.id} className={classes.result}>
            <IconButton
              aria-label="add result"
              onClick={(e) => selectResult(e, result)}
              icon={<FontAwesome icon={faPlus} />}
              variant="outline"
              size="xs"
            />
            <Tooltip
              label={result.name}
              placement="right"
              openDelay={500}
              fontSize="16px"
            >
              <Text
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >{`${result.id} - ${result.name}`}</Text>
            </Tooltip>
          </div>
        );
      });
    }
  };

  const showSelected = () => {
    if (selected && selected.length) {
      return selected.map((selection: MultiSelectItem, index) => {
        return (
          <FlipMove key={index}>
            <Box key={selection.id}>
              <Tag
                size="lg"
                variant="solid"
                background={theme.colors.primary.base}
                color={theme.colors.white}
                marginTop={4}
              >
                {useDraggable && (
                  <>
                    <IconButton
                      variant="link"
                      aria-label="Move Up"
                      icon={<FontAwesome icon={faUpDown} />}
                      onClick={() => {
                        if (index != 0) {
                          const newSelectedValues = [...selected];
                          const temp = newSelectedValues[index];
                          newSelectedValues[index] =
                            newSelectedValues[index - 1];
                          newSelectedValues[index - 1] = temp;
                          setSelected(newSelectedValues);
                          orderUpdateHandler(
                            newSelectedValues.map((p) => p.id)
                          );
                        }
                      }}
                      background={theme.colors.primary.base}
                      color={theme.colors.white}
                      px={0}
                    />
                    <IconButton
                      variant="link"
                      aria-label="Move Down"
                      icon={<FontAwesome icon={faUpDown} />}
                      onClick={() => {
                        if (index != selected.length - 1) {
                          const newSelectedValues = [...selected];
                          const temp = newSelectedValues[index];
                          newSelectedValues[index] =
                            newSelectedValues[index + 1];
                          newSelectedValues[index + 1] = temp;
                          setSelected(newSelectedValues);
                          orderUpdateHandler(
                            newSelectedValues.map((p) => p.id)
                          );
                        }
                      }}
                      background={theme.colors.primary.base}
                      color={theme.colors.white}
                      px={0}
                    />
                  </>
                )}
                <TagLabel>{`${selection.id} - ${selection.name}`}</TagLabel>
                <TagCloseButton
                  as="button"
                  onClick={(e) => removeSelection(e, selection)}
                />
              </Tag>
            </Box>
          </FlipMove>
        );
      });
    }
  };

  const clearSelected = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    setInputValue("");
    setResults([]);
    setSelected([]);
    document.getElementById(`${id}MultiSelectInput`).focus();
  };

  /**
   * Update order of selection to match the custom order so it matches in display when user switches back.
   * @param newOrder
   */
  // const orderChangeHandler = (newOrder: any[]) => {
  //   const oldSelection = [...selected];
  //   const newSelection = [];
  //   for (let i = 0; i < newOrder.length; i++) {
  //     const id = newOrder[i];
  //     const index = oldSelection.findIndex((selection) => selection.id == id);
  //     if (index > -1) {
  //       newSelection.push(oldSelection[index]);
  //     }
  //   }

  //   setSelected(newSelection);
  //   orderUpdateHandler(newOrder);
  // };

  const renderSelectionContainer = () => {
    if (selected.length) {
      return (
        <>
          <Text
            className={classes.searchText}
            style={{ marginTop: 8 }}
          >{`Selected ${label}:`}</Text>

          <div className={classes.selectedContainer}>{showSelected()}</div>
          <Button
            variant="outline"
            onClick={(e) => clearSelected(e)}
            leftIcon={<FontAwesome icon={faTrash} />}
            size="sm"
          >
            {`Clear All ${label}`}
          </Button>
        </>
      );
    }
  };
  return (
    <>
      <InputGroupWrapper
        label={null}
        value={""}
        errors={errors}
        touched={touched}
        showCharCount={false}
        wrapLabels={false}
        maxLength={null}
        isRequired={false}
      >
        <div className={classNames(classes.root, className)} style={style}>
          <FormLabel className={classes.formLabel}>
            {label}
            {isRequired == true ? (
              <span className={classes.requiredAsterisk}> *</span>
            ) : (
              ""
            )}
          </FormLabel>
          <Input
            id={`${id}MultiSelectInput`}
            value={inputValue}
            placeholder={placeholder}
            onChange={(e) => handleOnChange(e)}
            onKeyUp={(e) => handleOnKeyUp(e)}
            autoComplete="off"
          />
          <div
            style={{ height: results.length > 0 ? 150 : null }}
            className={classes.resultContainer}
          >
            {loading ? <Loader /> : showResults()}
          </div>
          {renderSelectionContainer()}

          <div className={classes.help}>
            <ToolTipButton>
              Search for specific Props to add to the carousel by Prop title or
              Prop ID. Props will appear in the carousel in the order they are
              selected.
            </ToolTipButton>
          </div>
        </div>
      </InputGroupWrapper>
    </>
  );
};
