import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Spacer,
} from "@chakra-ui/react";
import { LeaguesMultiselectSet } from "../../components/FormElements/Fieldsets/LeaguesMultiselectSet";
import usePropsApi from "../../services/api/Props";
import { useSnackbar } from "notistack";
import useEmbedsApi from "../../services/api/Embeds";
import useJwt from "../../hooks/useJwt";
import StandalonePicker from "../../components/Props/StandalonePicker";
import GlobalContext from "../../components/GlobalContext";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

const NODE_API_URL = process.env.REACT_APP_NODE_API_URL || "";

const GettingStarted: React.FC = () => {
  const propsApi = usePropsApi();
  const embedsApi = useEmbedsApi();
  const [formData, setFormData] = useState({
    league: "",
  });
  const [embed, setEmbed] = useState(null);
  const [embedCode, setEmbedCode] = useState("");
  const [headerCode, setHeaderCode] = useState(
    `<script src="${NODE_API_URL.replace(
      "/v1",
      ""
    )}/pickup-picker-v1.js"></script>`
  );
  const [headerCopied, setHeaderCopied] = useState(false);
  const [scriptCopied, setScriptCopied] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { decodeJwt } = useJwt();
  const { currentUser, publisher } = useContext(GlobalContext);
  const [prop, setProp] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    handleChangeEmbed(value);
  };

  useEffect(() => {
    if (embed) {
      setEmbedCode(
        `<div class="fanpower-embed-target" data-targetId="${embed.id}" data-publisherid="${currentUser.publisher_id}" data-accesstoken="${currentUser.access_token}"></div>`
      );
      if (embed.content_type == "prop_id" && embed.content.prop_id) {
        propsApi.getProp(embed.content.prop_id).then((res) => {
          setProp(res);
          if (!formData.league)
            setFormData({ ["league"]: String(res.leagues[0].id) });
        });
      }
    }
  }, [embed]);

  useEffect(() => {
    if (publisher) {
      embedsApi.getAllEmbeds(decodeJwt.user.publisher_id).then((res) => {
        const firstEmbed = res.find(
          (e) => e.name == `${publisher.name} first embed`
        );
        if (firstEmbed) {
          setEmbed(firstEmbed);
        }
      });
    }
  }, [publisher]);

  const handleChangeEmbed = (league: number) => {
    setLoading(true);

    const payload = {
      name: `${publisher.name} first embed`,
      description: "First embed",
      content_type: "prop_id",
      publisher_id: Number(decodeJwt.user.publisher_id),
      author_user_id: Number(decodeJwt.user.id),
      content: {
        prop_id: null,
        prop_collection: [],
        carousel_id: null,
        tags: [],
        leagues: [],
        sort_by: "custom",
      },
    };
    //get prop from league
    propsApi
      .contextualProp(league)
      .then((res) => {
        payload.content.prop_id = res[0].id;
        if (embed) {
          //update embed
          embedsApi.updateEmbed(embed.id, payload).then((res) => {
            setEmbed(res);
            setLoading(false);
          });
        } else {
          embedsApi.createEmbed(payload).then((res) => {
            setEmbed(res);
            setLoading(false);
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error fetching prop", { variant: "error" });
      });
  };

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
  };

  return (
    <Flex flexDirection={"column"}>
      <Heading marginY={4}>Welcome to FanPower</Heading>
      <Box>
        Thank you for choosing FanPower. We are excited to help you collect as
        much valuable data as possible. Feel free to email support@fanpower.io
        or connect with your FanPower contact on Slack if you have any
        questions. We are here to help!
      </Box>
      <Heading as="h3" size="lg" marginY={4}>
        Embedding Content
      </Heading>
      <Spacer />
      <Heading as="h4" size="md" marginY={4}>
        Props
      </Heading>
      <Box>
        The FanPower ecosystem revolves around creating polls, referred to as
        props, that consist of questions relevant to the website or content in
        which they appear. A well crafted prop will be relevant to the reader
        and cause them to share their opinion by interacting with your FanPower
        widget.
      </Box>
      <Spacer />
      <Heading as="h4" size="md" marginY={4}>
        Carousels
      </Heading>
      <Box>
        When one prop is not enough to capture the data or attention you are
        seeking, carousels come into play. They allow you to carefully select
        multiple props to be bundled together allowing the user to cycle through
        them in a custom order. A good carousel will have an engaging first prop
        that gets the user to make a pick… then, while you have their attention,
        guide them to make more relevant picks on either the article topic or
        brand related props that you define ahead of time.
      </Box>
      <Spacer />
      <Heading as="h4" size="md" marginY={4}>
        Embed Targets
      </Heading>
      <Box>
        Once you have created some props or a carousel and are ready to embed
        them into your website, embed targets come into play. Embed targets are
        placed into the website’s content and then managed from the FanPower
        portal, allowing you to select which props are displayed on any page at
        anytime without having to make any changes on your website itself. Embed
        Targets can serve single props, carousels, or multiple props assigned
        individually or as tags. You can change the content frequently or
        infrequently depending on your needs.
      </Box>
      <Heading as="h4" size="md" marginY={4}>
        Leagues and Tags
      </Heading>
      <Box>
        We use Leagues as a way to broadly categorize props. If your content
        generally deals with a single sport, using the default league will save
        you time. Meanwhile, Tags help keep props organized and empower you to
        drive personalization for your audience. Each fan gets associated with a
        tag based on a prop they answer and/or pick they make
      </Box>
      <Spacer />
      <Heading as="h4" size="md" marginY={4}>
        Getting Started
      </Heading>
      <Box>
        Let’s setup the first embed target by selecting a league - we will use
        it to create your first embed target so you can get started embedding
        props.
      </Box>
      <LeaguesMultiselectSet
        label={"League"}
        id={"league"}
        onChange={handleChange}
        errors={""}
        placeholder={"League for props"}
        reset={false}
        single={true}
        setFieldTouched={() => console.log("touched")}
        onBlur={() => console.log("blur")}
        singleValue={formData["league"]}
      />
      The following is hidden until league selection is made
      {embed?.id && !loading && (
        <Box>
          <Heading as="h4" size="md" marginY={4}>
            Customize Picker
          </Heading>
          <Box>
            Apply your own brand and style to the picker with customizations:{" "}
            <ChakraLink
              as={ReactRouterLink}
              to={`/publishers/${decodeJwt.user.publisher_id}/edit`}
              state={{ item: publisher }}
            >
              Customize Picker
            </ChakraLink>
          </Box>
          <Heading as="h4" size="md" marginY={4}>
            Header Script Code
          </Heading>
          <Box>
            This is the header script. It needs to be placed in the header of
            the website to allow the embed target HTML code to be activated.
          </Box>
          <HStack>
            <Input
              value={headerCode}
              onChange={(e) => setHeaderCode(e.target.value)}
            />
            <Button
              onClick={() => {
                handleCopy(headerCode);
                setHeaderCopied(true);
                setTimeout(() => setHeaderCopied(false), 2000);
              }}
            >
              {headerCopied ? "Copied" : "Copy"}
            </Button>
          </HStack>
          <Heading as="h4" size="md" marginY={4}>
            Embed Target HTML Code
          </Heading>
          <Box>
            This is the embed target embed code. Paste this where you want the
            embed target to display.
          </Box>
          <HStack>
            <Input
              value={embedCode}
              onChange={(e) => setEmbedCode(e.target.value)}
            />
            <Button
              onClick={() => {
                handleCopy(embedCode);
                setScriptCopied(true);
                setTimeout(() => setScriptCopied(false), 2000);
              }}
            >
              {scriptCopied ? "Copied" : "Copy"}
            </Button>
          </HStack>
          <Heading as="h4" size="md" marginY={4}>
            Prop Preview
          </Heading>
          <StandalonePicker
            key={embed?.id}
            accessToken={currentUser.access_token}
            publisherId={currentUser.publisher_id}
            host={NODE_API_URL}
            sourceUrl={"https://fanpower.io"}
            targetId={embed?.id}
          />
          <Divider />
          <Flex flexDirection={"column"}>
            <Heading as="h4" size="md" marginY={4}>
              Creating Props
            </Heading>
            <Box>
              Get started{" "}
              <ChakraLink as={ReactRouterLink} to="/props/create">
                creating your own props
              </ChakraLink>{" "}
              and see{" "}
              <ChakraLink as={ReactRouterLink} to="/prop-search">
                what else is available
              </ChakraLink>
            </Box>

            <Heading as="h4" size="md" marginY={4}>
              Tutorials
            </Heading>
            <Box>
              Continue to more tutorials for a further breakdown of all FanPower
              tools{" "}
              <ChakraLink as={ReactRouterLink} to={`/tutorials`}>
                Tutorials
              </ChakraLink>
            </Box>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default GettingStarted;
