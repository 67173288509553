import React, { useState, useEffect, useContext } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Box,
  Heading,
  useTheme,
} from "@chakra-ui/react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { useParams, useLocation } from "react-router-dom";
import useJwt from "../../hooks/useJwt";
import useAiApi from "../../services/api/Ai";
import { useSnackbar } from "notistack";
import ReactMarkdown from "react-markdown";
import { AiJob, MyrtleJob } from "../../types";
import CodeCopyBtn from "./CodeCopyBtn";
import { PropPickerDisplay } from "../../components/Props/PropPicker";
import GlobalContext from "../../components/GlobalContext";
import NoBorderLayout from "../../components/common/Layouts/NoBorder";
import { socket } from "../../helpers/sockets";
import JobTracker from "../../components/JobTracker";
import AiDisclaimer from "./Disclaimer";
import Loader from "../../components/Loader";
import Paper from "../../components/common/Paper";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
    maxWidth: "900px",
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  breadCrumbs: {
    fontSize: 15,
    fontFamily: theme.typography.fontFamilies.body,
    fontWeight: "normal",
  },
  page: {
    flexDirection: "column",
    paddingTop: 32,
    "& h1": { fontSize: 28, paddingBottom: 5 },
    "& p": { fontSize: 14, padding: 5 },
    "& h2": { fontSize: 24 },
    "& li": { listStyleType: "circle", fontSize: 14 },
    "& ul": { paddingLeft: 20 },
  },
  paper: {
    marginTop: theme.spacing.base * 10,
    display: "flex",
    justifyContent: "center",
  },
  gptResult: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 1500,
  },
}));

const statuses = {
  default: [
    { status: "started", display: "outline" },
    { status: "scraper", display: "research" },
    { status: "ai-prompt", display: "text" },
    { status: "prop-suggestion", display: "widget" },
    { status: "completed", display: "Styling" },
  ],
};

const JobView: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const aiApi = useAiApi();
  const { jobId } = useParams<{ jobId: string }>();
  const { jwt } = useJwt();
  const [job, setJob] = useState<MyrtleJob | AiJob>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [chunk, setChunk] = useState([]);
  const [loading, setLoading] = useState(true);
  const { publisher } = useContext(GlobalContext);
  const [status, setStatus] = useState(null);
  const theme = useTheme<DefaultTheme>();

  const fetchJob = async () => {
    try {
      return await aiApi.getJob(jobId).then((res) => {
        setJob(res);
        setStatus(res.status ? res.status : "completed");
        if ((res as MyrtleJob)[`${res.type}`]) {
          setChunk((res as MyrtleJob)[`${res.type}`].gptResult.split("\n\n"));
        }
        setLoading(false);
        return res;
      });
    } catch (error) {
      enqueueSnackbar("Could not load job", { variant: "error" });
    }
  };

  //useEffect socket handler
  useEffect(() => {
    if (publisher) {
      socket.connect();
    }
    return () => {
      publisher ? socket.removeListener(String(publisher.id)) : null;
      socket.disconnect();
    };
  }, [publisher]);

  useEffect(() => {
    if (publisher) {
      fetchJob().then(async (res: AiJob) => {
        socket.on(String(publisher.id), async (data) => {
          // only update events that match this views uuid
          if (res?.status && res.uuid == data.uuid) {
            setStatus(data.status);
          }
          // if complete get finished job
          if (data.status == "completed") {
            fetchJob();
          }
        });
      });
    }
  }, [publisher]);

  if (loading) {
    return (
      <NoBorderLayout title={"View AI Job"} breadcrumb={[]}>
        <Loader />
      </NoBorderLayout>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <Breadcrumb className={classes.breadCrumbs}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/" color={theme.colors.purple.base}>
              Content
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              href="/ai-content/index"
              color={theme.colors.purple.base}
            >
              Archive
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink color={theme.colors.grey.dark}>
              {location.pathname.split("/").pop()}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Flex className={classes.page}>
        <Heading variant="heading2">{(location.state as AiJob).title}</Heading>
        <AiDisclaimer />
        {job && (job as MyrtleJob)[`${job.type}`]?.gptResult ? (
          <Flex flexDirection={"column"}>
            <Paper className={classes.paper}>
              <div id="gptResult" className={classes.gptResult}>
                <Box marginBottom={2}>
                  <ReactMarkdown>{chunk[0]}</ReactMarkdown>
                </Box>
                <Flex flexDirection={"row"}>
                  <Box flex={"0 0 50%"}>
                    {chunk.slice(1).map((chunk, i) => {
                      return (
                        <Box key={i} marginBottom={1}>
                          <ReactMarkdown>{chunk}</ReactMarkdown>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    flex={"0 0 50%"}
                    display={"flex"}
                    justifyContent={"left"}
                    marginLeft={10}
                  >
                    {(job as MyrtleJob)[`${job.type}`]?.propSuggestion ? (
                      <PropPickerDisplay
                        values={{
                          prop_title: (job as MyrtleJob)[`${job.type}`]
                            ?.propSuggestion.title,
                          pick_display_titles: (job as MyrtleJob)[`${job.type}`]
                            ?.propSuggestion.picks,
                        }}
                      />
                    ) : null}
                  </Box>
                </Flex>
              </div>
            </Paper>

            <CodeCopyBtn
              prop={{
                id: (job as MyrtleJob)[`${job.type}`]?.propSuggestion?.prop_id,
                proposition: (job as MyrtleJob)[`${job.type}`]?.propSuggestion
                  ?.title,
              }}
              publisher={publisher}
              gptResult={(job as MyrtleJob)[`${job.type}`]?.gptResult}
            />
          </Flex>
        ) : (
          <Flex className={classes.paper} flexDirection={"column"}>
            <Box style={{ marginBottom: "20px" }}>Content in progress</Box>

            <JobTracker
              statuses={statuses["default"]}
              status={status.toLowerCase()}
            />
          </Flex>
        )}
      </Flex>
    </div>
  );
};

export default JobView;
