import React, { ChangeEventHandler } from "react";
import { FormikErrors } from "formik";
import { InputGroupWrapper } from "../InputGroupWrapper";
import MultiSelect from "../../MultiSelect";
import { MultiSelectItem } from "../../../types";

export interface MultiselectProps {
  label: string;
  id: string;
  value: MultiSelectItem[];
  items: MultiSelectItem[];
  onChange: ChangeEventHandler;
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  placeholder: string;
  setActive?: (boolean: boolean) => void;
  maxLength?: number;
  showCharCount?: boolean;
  wrapLabels?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  reset: boolean;
  touched?: boolean;
  createNew?: {
    isCreating: boolean;
    onCreate: (inputValue: string) => void;
  };
  updateSelectionCallback?: (selection: MultiSelectItem[]) => void;
  updateInputValue?: MultiSelectItem;
}

export const MultiselectSet: React.FC<MultiselectProps> = ({
  label,
  value,
  id,
  items,
  errors,
  placeholder,
  isRequired,
  wrapLabels = false,
  reset,
  touched,
  createNew = null,
  updateSelectionCallback = null,
  updateInputValue = null,
}) => {
  return (
    <>
      <InputGroupWrapper
        label={null}
        value={""}
        errors={errors}
        touched={touched}
        showCharCount={false}
        wrapLabels={wrapLabels}
        maxLength={null}
        isRequired={false}
      >
        <MultiSelect
          showAsRequired={isRequired}
          name={id}
          items={items}
          values={value}
          label={label}
          placeholder={placeholder}
          reset={reset}
          createNew={createNew}
          updateSelectionCallback={updateSelectionCallback}
          updateInputValue={updateInputValue}
        />
      </InputGroupWrapper>
    </>
  );
};
