import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { Button, Flex, Heading } from "@chakra-ui/react";
import DropDown from "../../components/FilterDropDown";
import Paginator from "../../components/Pagination";
import { useNavigate, useLocation } from "react-router-dom";
import useAiApi from "../../services/api/Ai";
import {
  AiJobLeague,
  AiJobPayload,
  AiJobType,
  GamesAndTeams,
  League,
  ParsedSportsGame,
} from "../../types";
import GlobalContext from "../../components/GlobalContext";
import useLeaguesApi from "../../services/api/League";
import Loader from "../../components/Loader";

const PAGE_LIMIT = 10; // Max items displayed per page

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  selected: {
    border: `2px solid ${theme.colors.red.base} !Important`,
  },
  row: {
    background: "white",
    borderRadius: "8px",
    marginBottom: "12px",
    width: "100%",
    padding: "12px",
    border: `2px solid white`,
    cursor: "pointer",

    "&:hover": {
      border: `2px solid ${theme.colors.red.base}`,
    },
  },
  tableWrapper: {
    marginBottom: "24px",
  },
  tableHeader: { width: "100%", padding: "12px" },
}));
export interface NewJobGameListComponentProps {
  fetchGamesCallback: () => Promise<GamesAndTeams>;
  path: string;
  heading?: string;
  jobTitle?: string;
  league?: AiJobLeague;
  jobType?: AiJobType;
}

const NewJobGameList: React.FC<NewJobGameListComponentProps> = ({
  fetchGamesCallback,
  path,
  heading,
  league,
  jobTitle,
  jobType,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);

  const [teams, setTeams] = useState<string[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<string[]>([]);
  const [teamFilter, setTeamFilter] = useState("");
  const [conferenceFilter, setConferenceFilter] = useState("");
  const [games, setGames] = useState<ParsedSportsGame[]>([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [gamesDisplay, setGamesDisplay] = useState<ParsedSportsGame[]>([]);
  const [selectedGame, setSelectedGame] = useState("");
  const [conferences, setConferences] = useState<string[]>([]);
  const [conferenceTeamMap, setConferenceTeamMap] = useState<any[]>([]);

  const [persona, setPersona] = useState("");
  const [personas, setPersonas] = useState([]);
  const [language, setLanguage] = useState("English");
  const [languages] = useState(["English", "Spanish"]);
  const [leagues, setLeagues] = useState<League[]>([]);
  const leaguesApi = useLeaguesApi();
  const aiApi = useAiApi();

  const [count, setCount] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState<number>(
    Number(location.search.substring(6))
      ? Number(location.search.substring(6))
      : 1
  );

  const updateGamesDisplay = (page: number) => {
    setPage(page);
    setGamesDisplay(
      filteredGames.slice(page * PAGE_LIMIT - PAGE_LIMIT, page * PAGE_LIMIT)
    );
  };

  const handleFilter = () => {
    const filtered = [];
    games.forEach((game) => {
      if (game.home === teamFilter) {
        filtered.push(game);
      }
      if (game.away === teamFilter) {
        filtered.push(game);
      }
    });
    return filtered;
  };

  useEffect(() => {
    if (teamFilter.length) {
      const filtered = handleFilter();
      setFilteredGames(filtered);
      setGamesDisplay(filtered.slice(0, PAGE_LIMIT));
      setCount(filtered.length);
    } else if (conferenceFilter.length) {
      applyConferenceFilter();
    } else {
      setFilteredGames(games);
      setGamesDisplay(games.slice(0, PAGE_LIMIT));
      setCount(games.length);
    }
    setPage(1);
  }, [teamFilter]);

  const applyConferenceFilter = () => {
    if (conferenceFilter.length) {
      // get list of teams in conference and set team filter
      const filteredTeams = [];

      for (const conf of conferenceTeamMap) {
        if (
          conf.conference == conferenceFilter &&
          !filteredTeams.find((row) => row === conf.team)
        ) {
          filteredTeams.push(conf.team);
        }
      }
      const gamesFiltered = games.filter((game) => {
        return (
          filteredTeams.includes(game.home) || filteredTeams.includes(game.away)
        );
      });
      setFilteredTeams(filteredTeams);
      setFilteredGames(gamesFiltered);
      setGamesDisplay(gamesFiltered.slice(0, PAGE_LIMIT));
      setCount(gamesFiltered.length);
    } else {
      setFilteredTeams(teams);
      setFilteredGames(games);
      setGamesDisplay(games.slice(0, PAGE_LIMIT));
      setTeamFilter("");
      setCount(games.length);
    }
  };

  useEffect(() => {
    applyConferenceFilter();
    setPage(1);
  }, [conferenceFilter]);

  const fetchPersonas = async () => {
    try {
      const per = await aiApi.getPersonasForPublisher(currentUser.publisher_id);
      setPersonas(per);
      setPersona(per[0].name);
    } catch (error) {
      enqueueSnackbar(
        "There was an error requesting personas:" + error.message,
        {
          variant: "error",
        }
      );
    }
  };
  const fetchGames = async (page?: number) => {
    try {
      const parsedGames = await fetchGamesCallback();
      setGames(parsedGames.games);
      setTeams(parsedGames.teams);
      setFilteredTeams(parsedGames.teams);
      setFilteredGames(parsedGames.games);
      setGamesDisplay(
        parsedGames.games.slice(
          page * PAGE_LIMIT - PAGE_LIMIT,
          page * PAGE_LIMIT
        )
      );
      if (parsedGames.conferences) {
        setConferences(parsedGames.conferences.list);
        setConferenceTeamMap(parsedGames.conferences.map);
      }
      setCount(parsedGames.games.length);
    } catch (error) {
      enqueueSnackbar("There was an error requesting list", {
        variant: "error",
      });
    }
    return;
  };

  const fetchLeagues = async () => {
    leaguesApi.getAll().then((res) => setLeagues(res));
  };

  const determineTitle = (game: ParsedSportsGame) => {
    if (game.game_type == "nascar") {
      return `${game.home} ${game.away} ${jobTitle} - ${new Date(
        game.date_time
      ).toLocaleDateString()}`;
    } else {
      return `${game.home} vs. ${game.away} ${jobTitle} - ${new Date(
        game.date_time
      ).toLocaleDateString()}`;
    }
  };

  const createJob = async () => {
    const personaMatch = personas.filter((p) => p.name === persona)[0];
    const game = games.filter((g) => g.id === selectedGame)[0];
    const title = determineTitle(game);
    const job: AiJobPayload = {
      type: jobType ? jobType : AiJobType.PREVIEW,
      title,
      user_id: currentUser.id,
      publisher_id: currentUser.publisher_id,
      game_type: game.game_type ? game.game_type : "",
      params: {
        persona: {
          id: personaMatch.id,
          name: personaMatch.name,
          description: personaMatch.description,
          voice: personaMatch.voice,
          audience: personaMatch.audience,
        },
        selected_leagues: [
          Number(leagues.filter((l) => l.label == league)[0].id),
        ],
        gameId: selectedGame,
        language: language === "English" ? "en" : "sp",
        leagueId: league,
        event_id: game?.event_id,
        series_key: game?.series_key,
      },
    };
    await aiApi
      .createJob(job)
      .then((result) => {
        if (result.id) {
          navigate(`/ai-content/${result.id}/view`, { state: { job } });
        } else {
          navigate(`/ai-content`);
        }
      })
      .catch(() => {
        enqueueSnackbar("There was an error creating your game preview", {});
      });
  };

  useEffect(() => {
    window.sessionStorage.setItem("previewGamesPage", String(page));
    navigate({ pathname: path, search: `page=${page}` });
  }, [page]);

  useEffect(() => {
    const currentPage = location.search.substring(6)
      ? location.search.substring(6)
      : page;

    Promise.all([
      fetchGames(Number(currentPage)),
      fetchPersonas(),
      fetchLeagues(),
    ]).then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Flex flexDirection={"column"}>
        <Heading variant="heading2">{heading}</Heading>
        {conferences.length ? (
          <DropDown
            label={"Filter by NCAAF Conference"}
            overrides={{ noDefaultLabel: true }}
            options={conferences}
            setValue={setConferenceFilter}
          />
        ) : null}
        <DropDown options={filteredTeams} setValue={setTeamFilter} />

        <Flex className={classes.tableHeader}>
          <div style={{ flexGrow: 1 }}>Game</div>
          <div style={{ paddingRight: "24px", width: "150px" }}>Date</div>
          <div style={{ width: "130px" }}>Time</div>
        </Flex>

        <Flex className={classes.tableWrapper} flexDirection={"column"}>
          {games && games.length > 0
            ? gamesDisplay.map((game) => (
                <Flex
                  key={game.id}
                  className={`${classes.row} ${
                    selectedGame === game.id ? classes.selected : ""
                  }`}
                  onClick={() => {
                    if (selectedGame === game.id) {
                      setSelectedGame("");
                    } else {
                      setSelectedGame(game.id);
                    }
                  }}
                >
                  <Flex style={{ flexGrow: 1 }}>
                    {game.home} {game.game_type == "nascar" ? "" : "vs."}{" "}
                    {game.away}
                  </Flex>
                  <Flex style={{ paddingRight: "24px", width: "150px" }}>
                    {game.date}
                  </Flex>
                  <Flex style={{ width: "130px" }}>{game.time}</Flex>
                </Flex>
              ))
            : null}
          <Paginator
            onPaginate={updateGamesDisplay}
            page={page}
            pageLimit={PAGE_LIMIT}
            totalItems={count}
          ></Paginator>
        </Flex>
      </Flex>
      <Flex>
        <div style={{ marginRight: "8px" }}>
          <DropDown
            options={personas.map((p) => p.name)}
            setValue={setPersona}
            label={"by"}
            overrides={{
              noDefaultLabel: true,
              noPlaceholder: true,
            }}
          />
        </div>
        <div style={{ marginRight: "8px" }}>
          <DropDown
            options={languages}
            setValue={setLanguage}
            label={"in"}
            overrides={{
              noDefaultLabel: true,
              noPlaceholder: true,
            }}
          />
        </div>
        <Button onClick={() => createJob()} disabled={!selectedGame}>
          Generate Article
        </Button>
      </Flex>
    </div>
  );
};

export default NewJobGameList;
