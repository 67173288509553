import React, { useContext, useEffect, useState } from "react";
import { Button, Tooltip } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { TextInputGroup } from "../../components/FormElements/TextInputGroup";
import useContestsApi from "../../services/api/Contests";
import { ContestRecord } from "../../types";
import GlobalContext from "../../components/GlobalContext";
import { SingleSelectFieldset } from "../../components/FormElements/Fieldsets/SingleSelectFieldset";
import { DatepickerFieldset } from "../../components/FormElements/Fieldsets/DatepickerFieldset";
import { useNavigate } from "react-router-dom";
import useJwt from "../../hooks/useJwt";
import Loader from "../../components/Loader";

interface ContestDetailProps {
  contest: ContestRecord;
  setContest: React.Dispatch<any>;
}

export const formatDate = (dateObject: Date) => {
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const setupDate = (dateString: string) => {
  const date = dateString.split("T")[0];
  const localDate = new Date(date);

  // Calculate the UTC offset for Local
  const localOffsetHours = new Date().getTimezoneOffset() / 60;
  localDate.setHours(localDate.getHours() + localOffsetHours);

  return localDate;
};

const ContestDetailForm: React.FC<ContestDetailProps> = ({
  contest,
  setContest,
}) => {
  const [, setActive] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { publishers, publisher } = useContext(GlobalContext);
  const contestsApi = useContestsApi();
  const navigate = useNavigate();
  const { decodeJwt } = useJwt();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (publisher) setLoading(false);
  }, [publisher]);

  const updateState = (res: ContestRecord) => {
    res["props"] = contest && contest.props ? contest.props : [];
    setContest(res);
  };

  const handleSubmit = async (values): Promise<ContestRecord | void> => {
    const payload = { ...values };
    payload.end_date = formatDate(payload.end_date);
    payload.start_date = formatDate(payload.start_date);

    if (contest && contest.id) {
      payload["id"] = contest.id;
      return await contestsApi
        .updateContest(payload)
        .then((res) => {
          enqueueSnackbar("Contest updated", { variant: "success" });
          return res;
        })
        .catch((err) => {
          enqueueSnackbar("Error updating contest", { variant: "error" });
        });
    } else {
      return await contestsApi
        .createContest(payload)
        .then((res) => {
          enqueueSnackbar("Contest created", { variant: "success" });
          return res;
        })
        .catch((err) => {
          enqueueSnackbar("Error creating contets", { variant: "error" });
        });
    }
  };

  const publisherOptions = () => {
    if (decodeJwt.user.role == "fanpoweradmin") {
      return publishers
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        .map((p) => {
          return { label: p.name, value: p.id };
        });
    } else if (decodeJwt.user.role == "tenantadmin") {
      return publishers
        .filter((pub) => pub.tenant_id == publisher.tenant_id)
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        .map((p) => {
          return { label: p.name, value: p.id };
        });
    } else if (decodeJwt.user.role == "publisheradmin") {
      return [{ label: publisher.name, value: publisher.id }];
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            name: contest && contest.name ? contest.name : "",
            description:
              contest && contest.description ? contest.description : "",
            start_date:
              contest && contest.start_date
                ? setupDate(contest.start_date)
                : null,
            end_date:
              contest && contest.end_date ? setupDate(contest.end_date) : null,
            publisher_id:
              contest && contest.publisher_id ? contest.publisher_id : "",
            contest_type:
              contest && contest.contest_type ? contest.contest_type : "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            description: Yup.string().required("Description is required"),
            start_date: Yup.mixed().required("Start Date can not be empty"),
            end_date: Yup.mixed()
              .when(["start_date"], (start_date: Date, Yup) => {
                return Yup.test({
                  test: (end_date) =>
                    start_date && end_date ? start_date < end_date : true,
                  message: "Start Date can not be greater than End Date",
                });
              })
              .required("End Date can not be empty"),
            publisher_id: Yup.number().required("Publisher can not be empty"),
            contest_type: Yup.string().required(
              "Content Type can not be empty"
            ),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await handleSubmit(values).then((res: ContestRecord) => {
              setSubmitting(false);

              if (res && res.id) {
                updateState(res);
                navigate(`/props/contests/view/${res.id}`, { state: res });
              }
            });
          }}
        >
          {({
            isSubmitting,
            errors,
            values,
            handleChange,
            setFieldTouched,
            setFieldValue,
            touched,
            handleBlur,
          }) => (
            <Form>
              <TextInputGroup
                placeholder="Name"
                id="name"
                value={values.name}
                onChange={handleChange}
                errors={errors.name}
                label={"Name"}
                isRequired={true}
                setFieldTouched={setFieldTouched}
                touched={touched.name}
                setActive={setActive}
              />
              <TextInputGroup
                placeholder="Description"
                id="description"
                value={values.description}
                onChange={handleChange}
                errors={errors.description}
                label={"Description"}
                isRequired={true}
                setFieldTouched={setFieldTouched}
                touched={touched.description}
                setActive={setActive}
              />
              <DatepickerFieldset
                label={"Start Date (UTC)"}
                id={"start_date"}
                value={values.start_date}
                onChange={handleChange}
                setActive={setActive}
                touched={touched.start_date}
                errors={errors.start_date}
                setFieldValue={setFieldValue}
                touchedHandler={setFieldTouched}
                isRequired={true}
                showTimeSelect={false}
                dateFormat={"MM/dd/yyyy"}
              />
              <DatepickerFieldset
                label={"End Date (UTC)"}
                id={"end_date"}
                value={values.end_date}
                onChange={handleChange}
                setActive={setActive}
                errors={errors.end_date}
                touched={touched.end_date}
                setFieldValue={setFieldValue}
                touchedHandler={setFieldTouched}
                isRequired={true}
                showTimeSelect={false}
                dateFormat={"MM/dd/yyyy"}
              />
              <SingleSelectFieldset
                label={"Publisher"}
                id={"publisher_id"}
                value={values.publisher_id}
                options={publisherOptions()}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.publisher_id}
                setFieldTouched={setFieldTouched}
                placeholder={"Select Publisher"}
                isRequired
              />
              <SingleSelectFieldset
                label={"Contest Type"}
                id={"contest_type"}
                value={values.contest_type}
                options={[
                  {
                    label: "Manual: Private, fans assigned manually",
                    value: "manual",
                  },
                  {
                    label: "Public: playable via link or share",
                    value: "open",
                  },
                ]}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.contest_type}
                setFieldTouched={setFieldTouched}
                placeholder={"Select Content Type"}
                isRequired
              />
              <Tooltip
                label={
                  "Once created prop and fan information will appear below"
                }
              >
                <Button type="submit" isDisabled={isSubmitting}>
                  {contest ? "Update" : "Create"}
                </Button>
              </Tooltip>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default ContestDetailForm;
