import React, { useState, useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import { useFormikContext } from "formik";
import { Button, HStack, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center",
    border: `1px solid ${theme.colors.grey.lightBase}`,
    borderRadius: 5,
    height: 300,
    overflow: "hidden",
    padding: [theme.spacing.base * 4, 0],
  },
  rootOverlay: {
    border: `1px dotted ${theme.colors.primary.base}`,
  },
  icon: {
    color: theme.colors.grey.base,
  },
  input: {
    display: "none",
  },
  overlay: {
    position: "absolute",
    background: theme.colors.grey.dark,
    opacity: "50%",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
  },
}));

interface FileUploaderProps {
  name: string;
  className?: string;
  maxFileSize?: number;
  supportedFormats?: string[];
}

const publishOnUploadFileChange = (name: string, data) => {
  const event = new CustomEvent(`${name}OnUploadFileChange`, { detail: data });
  document.dispatchEvent(event);
};

const FileUploader: React.FC<FileUploaderProps> = ({
  name,
  className,
  maxFileSize = 5000000,
  supportedFormats = ["image/jpg", "image/jpeg", "image/png"],
}) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const dropRef = useRef(null);
  const dragRef = useRef(null);
  const formikProps = useFormikContext();
  const [dragging, setDragging] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dropRef.current.addEventListener("dragover", handleDragOver);
    dropRef.current.addEventListener("drop", handleDrop);
    dropRef.current.addEventListener("dragenter", handleDragEnter);
    dropRef.current.addEventListener("dragleave", handleDragLeave);

    return () => {
      if (dropRef.current) {
        dropRef.current.removeEventListener("dragover", handleDragOver);
        dropRef.current.removeEventListener("drop", handleDrop);
        dropRef.current.removeEventListener("dragenter", handleDragEnter);
        dropRef.current.removeEventListener("dragleave", handleDragLeave);
      }
    };
  }, []);

  const checkFileSize = (file: Blob) => {
    if (file.size > maxFileSize) return true;
    return false;
  };

  const checkFileType = (file: Blob) => {
    if (!supportedFormats.includes(file.type)) return true;
    return false;
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      const fileUpload = e.dataTransfer.files[0];

      // internal validation
      if (checkFileSize(fileUpload)) {
        setErrorMessage(`Exceeds max file size ${FILE_SIZE_IN_MEGABYTES}MB`);
        formikProps.setFieldValue(name, null);
      } else if (checkFileType(fileUpload)) {
        setErrorMessage(`${fileUpload.type} is not a valid file type`);
        formikProps.setFieldValue(name, null);
      } else {
        formikProps.setFieldValue(name, fileUpload);
        formikProps.setFieldError(name, null);
        setErrorMessage("");
      }
    }

    setDragging(false);
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== dragRef.current) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === dragRef.current) {
      setDragging(false);
    }
  };

  const handleClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleClear = () => {
    formikProps.setFieldValue(name, null);
    publishOnUploadFileChange(name, null);
    fileInputRef.current.value = "";
  };

  const handleChange = (e: React.BaseSyntheticEvent) => {
    const fileUpload = e.target.files[0];

    // internal validation
    if (checkFileSize(fileUpload)) {
      setErrorMessage(`Exceeds max file size ${FILE_SIZE_IN_MEGABYTES}MB`);
      formikProps.setFieldValue(name, null);
      publishOnUploadFileChange(name, null);
    } else if (checkFileType(fileUpload)) {
      setErrorMessage(`${fileUpload.type} is not a valid file type`);
      formikProps.setFieldValue(name, null);
      publishOnUploadFileChange(name, null);
    } else {
      formikProps.setFieldValue(name, fileUpload);
      publishOnUploadFileChange(name, fileUpload);
      formikProps.setFieldError(name, null);
      setErrorMessage("");
    }
  };

  const renderStatusText = () => {
    if (formikProps.values[name] && !errorMessage) {
      return (
        <HStack>
          <Button onClick={handleClear}>
            <i style={{ paddingBottom: "3px" }}>&times;</i>
          </Button>
          {formikProps.values[name].name ? (
            <Text color="green">{formikProps.values[name].name}</Text>
          ) : (
            <Text>Clear</Text>
          )}
        </HStack>
      );
    } else if (errorMessage) {
      return <Text>{errorMessage}</Text>;
    } else {
      return <Text>{`Max File Size: ${FILE_SIZE_IN_MEGABYTES}MB`}</Text>;
    }
  };

  const FILE_SIZE_IN_MEGABYTES = Math.round((maxFileSize / 1000000) * 10) / 10;

  return (
    <div
      ref={dropRef}
      className={classNames({
        [classes.root]: true,
        [classes.rootOverlay]: dragging,
        [className]: true,
      })}
    >
      <FontAwesomeIcon className={classes.icon} icon={faArrowUp} size="lg" />
      {!dragging ? (
        <div>
          <Text>Drag and Drop</Text>
          <Text>OR</Text>
        </div>
      ) : (
        <div>
          <Text fontWeight="bold">DROP HERE</Text>
          <Text fontWeight="bold">TO ADD FILE</Text>
        </div>
      )}
      <Button onClick={handleClick}>Browse Files</Button>
      {renderStatusText()}

      <input
        type="file"
        id={name}
        ref={fileInputRef}
        onChange={handleChange}
        className={classes.input}
      />
      {dragging ? <div ref={dragRef} className={classes.overlay} /> : null}
    </div>
  );
};

export default FileUploader;
