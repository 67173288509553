import React, { useEffect, useState } from "react";
import {
  Button,
  ModalFooter,
  ModalBody,
  useTheme,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Input,
  Box,
  Flex,
  FormErrorMessage,
} from "@chakra-ui/react";
import { ContestRecord } from "../../types";
import { DefaultTheme } from "../../types/theme";
import EmbedContentTable from "../Embeds/EmbedContentTable";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DatepickerFieldset } from "../../components/FormElements/Fieldsets/DatepickerFieldset";
import { useParams } from "react-router-dom";
import useContestsApi from "../../services/api/Contests";
import { useSnackbar } from "notistack";
import { formatDate } from "./ContestDetailsForm";
import CommonModal from "../../components/Modal";
import usePropsApi from "../../services/api/Props";
import debounce from "lodash/debounce";

interface ContestPropModalProps {
  isOpen: boolean;
  onClose: () => void;
  setContest: React.Dispatch<any>;
  contest: ContestRecord;
}

const ContestPropModal: React.FC<ContestPropModalProps> = ({
  isOpen,
  onClose,
  setContest,
  contest,
}) => {
  const theme = useTheme<DefaultTheme>();
  const [search, setSearch] = useState("");
  const [propsSearch, setPropsSearch] = useState([]);
  const [, setActive] = useState(false);
  const [prop, setProp] = useState(null);
  const { id } = useParams<{ id: string }>();
  const contestsApi = useContestsApi();
  const { enqueueSnackbar } = useSnackbar();
  const { searchProps } = usePropsApi();

  const debouncedSearchProps = debounce(async (searchQuery) => {
    if (searchQuery.length > 2) {
      const searchResults = await searchProps({
        search: searchQuery,
        status: "pending",
        limit: 10,
        open: true,
      });

      const mappedProps = searchResults.props.map((p) => {
        return {
          id: p.id,
          title: p.proposition,
          created_at: p.created_at,
        };
      });
      setPropsSearch(mappedProps);
    } else {
      setPropsSearch([]);
    }
  }, 300);

  useEffect(() => {
    debouncedSearchProps(search);
    return () => {
      debouncedSearchProps.cancel();
    };
  }, [search]);

  const handleSubmit = async (values) => {
    values.start_date = formatDate(values.start_date);
    return await contestsApi
      .addProp(id, values)
      .then((res) => {
        setContest(res);
        enqueueSnackbar("Prop added", { variant: "success" });
        return res;
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, { variant: "error" });
      });
  };

  return (
    <CommonModal
      header={"Search Props"}
      isOpen={isOpen}
      onClose={() => {
        setProp(null);
        onClose();
      }}
      styles={{ maxWidth: "1000px" }}
    >
      <Formik
        initialValues={{
          prop_id: null,
          start_date: null,
          play_order: 1,
        }}
        validationSchema={Yup.object().shape({
          prop_id: Yup.number()
            .required()
            .test(
              "prop-already-added",
              "Prop already added to contest",
              (value) => {
                return contest.props.find((p) => p.prop_id == value)
                  ? false
                  : true;
              }
            ),
          start_date: Yup.date().required("Start Date can not be empty"),
          play_order: Yup.number().test(
            "play-order-taken",
            "That play order is already assigned",
            (value) => {
              return contest.props.find((p) => p.play_order == value)
                ? false
                : true;
            }
          ),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await handleSubmit(values).then((res) => {
            resetForm();
            setProp(null);
            setSearch("");
          });
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          handleChange,
          setFieldTouched,
          touched,
          setFieldValue,
        }) => (
          <Form>
            <ModalBody>
              {prop ? (
                <FormControl
                  isRequired
                  isInvalid={touched.prop_id && !!errors.prop_id}
                >
                  <Flex justifyContent={"space-between"}>
                    <Box>{prop.title}</Box>
                    <Button
                      type="button"
                      size={"sm"}
                      onClick={() => setProp(null)}
                    >
                      Remove
                    </Button>
                  </Flex>
                  <FormErrorMessage>
                    {typeof errors.prop_id == "string" ? errors.prop_id : null}
                  </FormErrorMessage>
                </FormControl>
              ) : (
                <>
                  <Input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={"search for a prop"}
                  />

                  {propsSearch.length ? (
                    <EmbedContentTable
                      title="Prop"
                      caption="Select a prop to add to Contest"
                      collection={propsSearch}
                      onClick={(e) => {
                        setFieldValue("prop_id", e.id);
                        setProp(e);
                      }}
                    />
                  ) : null}
                </>
              )}
              <Box padding={"5"}></Box>
              <DatepickerFieldset
                label={"Start Date"}
                id={"start_date"}
                value={values.start_date}
                onChange={handleChange}
                setActive={setActive}
                touched={touched.start_date}
                errors={errors.start_date}
                setFieldValue={setFieldValue}
                touchedHandler={setFieldTouched}
                isRequired={true}
              />
              <FormControl
                isRequired
                isInvalid={touched.play_order && !!errors.play_order}
              >
                <FormLabel>Play Order</FormLabel>
                <NumberInput
                  step={1}
                  defaultValue={1}
                  min={1}
                  onChange={(stringValue) =>
                    setFieldValue("play_order", Number(stringValue))
                  }
                  marginBottom={theme.spacing.base * 2}
                >
                  <NumberInputField value={values.play_order} id="play_order" />
                  <NumberInputStepper
                    color={theme.colors.grey.dark}
                    borderColor={theme.colors.grey.lightBase}
                  >
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormErrorMessage>{errors.play_order}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  setProp(null);
                  onClose();
                }}
              >
                Close
              </Button>
              <Button ml={3} type="submit" isDisabled={isSubmitting}>
                {"Add"}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </CommonModal>
  );
};

export default ContestPropModal;
