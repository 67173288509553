import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Publisher } from "../../types";
import usePublishersApi from "../../services/api/Publishers";
import { useSnackbar } from "notistack";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { useNavigate } from "react-router-dom";

export const DeactivatePublisher = ({
  publisher,
}: {
  publisher: Publisher;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [canDeactivate, setCanDeactivate] = useState(false);
  const { deactivatePublisher } = usePublishersApi();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const deactivate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    deactivatePublisher(publisher.id)
      .then((res) => {
        if (res[0].deleted_at) {
          enqueueSnackbar("Publisher has been deactivated.", {
            variant: "success",
          });
          onClose();
          navigate("/publishers");
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error deactivating publisher.", {
          variant: "error",
        });
        pickupErrorHandlerWeb(err);
        onClose();
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === publisher.name) {
      setCanDeactivate(true);
    } else {
      setCanDeactivate(false);
    }
  };

  return (
    <Box py={6}>
      <Button variant="alert" onClick={onOpen}>
        Deactivate Publisher
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Deactive Publisher: ${publisher.name}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Text>{`Are you sure you want to deactivate this publisher? This action is permanent and will disable all distribution by this publisher. Type the publisher name "${publisher.name}" (case sensitive) to enable deactivation.`}</Text>
              <Input onChange={handleChange} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              variant="alert"
              onClick={deactivate}
              disabled={!canDeactivate}
            >
              Deactivate Publisher
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
