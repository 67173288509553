import React from "react";
import { useSnackbar } from "notistack";
import useAiApi from "../../../services/api/Ai";
import AiJobGameList from "../NewJobGameList";
import { AiJobLeague, GamesAndTeams, ParsedSportsGame } from "../../../types";

const NascarPreviews: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const aiApi = useAiApi();

  const parseRaces = (racesResponse): GamesAndTeams => {
    const teams = {};
    const games: ParsedSportsGame[] = [];
    racesResponse.forEach((race) => {
      const home = race.name;
      const away = race.series.name;
      teams[home] = 1;
      teams[away] = 1;
      const startTime = new Date(race.startTime);
      games.push({
        id: race.id,
        home,
        away,
        game_type: "nascar",
        date_time: race.startTime,
        date: `${startTime.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })}`,
        time: `${startTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZoneName: "short",
        })}`,
        event_id: race.event.id,
        series_key: race.series.key,
      });
    });

    return {
      teams: Object.keys(teams),
      games: games,
    };
  };

  const fetchRaces = async (): Promise<GamesAndTeams> => {
    const racesData = await aiApi.getNascarPreviewRaces().catch((err) => {
      enqueueSnackbar("There was an error requesting races", {
        variant: "error",
      });
    });
    return parseRaces(racesData);
  };

  return (
    <div>
      <AiJobGameList
        fetchGamesCallback={fetchRaces}
        path={"/ai-content/nascar/previews"}
        heading={"NASCAR Race Previews"}
        league={AiJobLeague.NASCAR}
        jobTitle={"NASCAR Race Preview"}
      />
    </div>
  );
};

export default NascarPreviews;
