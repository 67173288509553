import React, { useState, useEffect } from "react";
import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  Center,
  PopoverBody,
  SimpleGrid,
  Input,
} from "@chakra-ui/react";

type ColorPickerProps = {
  initialColor: string;
  name: string;
  setFieldValue: (field: string, value: string) => void;
};

const ColorPicker = ({
  initialColor,
  name,
  setFieldValue,
}: ColorPickerProps) => {
  const [color, setColor] = useState(initialColor);

  const colors = [
    "#A0AEC0",
    "#F56565",
    "#4A5568",
    "#48BB78",
    "#4299E1",
    "#2C5282",
    "#ECC94B",
    "#ED8936",
    "#9F7AEA",
    "#ED64A6",
  ];

  useEffect(() => {
    setFieldValue(name, color);
  }, [color]);

  return (
    <Popover variant="picker">
      <PopoverTrigger>
        <Button
          aria-label={color}
          background={initialColor ? color : "gray.500"}
          height="22px"
          width="85px"
          padding={0}
          minWidth="unset"
          borderRadius={3}
        ></Button>
      </PopoverTrigger>
      <PopoverContent width="170px">
        <PopoverArrow bg={color} />
        <PopoverCloseButton color="white" />
        <PopoverHeader
          height="100px"
          backgroundColor={color}
          borderTopLeftRadius={5}
          borderTopRightRadius={5}
          color="white"
        >
          <Center height="100%">{color}</Center>
        </PopoverHeader>
        <PopoverBody height="120px">
          <SimpleGrid columns={5} spacing={2}>
            {colors.map((c) => (
              <Button
                key={c}
                aria-label={c}
                background={c}
                height="22px"
                width="22px"
                padding={0}
                minWidth="unset"
                borderRadius={3}
                _hover={{ background: c }}
                onClick={() => {
                  setColor(c);
                }}
              ></Button>
            ))}
          </SimpleGrid>
          <Input
            borderRadius={3}
            marginTop={3}
            placeholder="red.100"
            size="sm"
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPicker;
