import React, { useState, useEffect, useContext } from "react";
import { DefaultTheme } from "../../types/theme";
import ListTable from "../../components/common/Layouts/ListTable";
import useEmbedsApi from "../../services/api/Embeds";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import useJwt from "../../hooks/useJwt";
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  useTheme,
} from "@chakra-ui/react";
import BreadcrumbsBlock from "../../components/common/Layouts/BreadcrumbsBlock";
import Loader from "../../components/Loader";
import { EmbedTargetRecord, Publisher } from "../../types";
import {
  faPlus,
  faTrash,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../../components/common/icon";
import DropDown from "../../components/FilterDropDown";
import globalContext from "../../components/GlobalContext";

const EmbedsIndex: React.FC = () => {
  const embedsApi = useEmbedsApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [embeds, setEmbeds] = useState([]);
  const [filteredEmbeds, setFilteredEmbeds] = useState([]);
  const [loading, setLoading] = useState(true);
  const { decodeJwt } = useJwt();
  const [search, setSearch] = useState("");
  const theme = useTheme<DefaultTheme>();
  const { currentUser, publisher, publishers } = useContext(globalContext);
  const [pubFilter, setPubFilter] = useState("");

  useEffect(() => {
    const publisher_id =
      decodeJwt.user.role !== "fanpoweradmin"
        ? decodeJwt.user.publisher_id
        : undefined;
    const fetchEmbeds = async () => {
      await embedsApi
        .getAllEmbeds(publisher_id)
        .then((res) => {
          setEmbeds(res);
          setFilteredEmbeds(res);
          setLoading(false);
        })
        .catch(() => {
          enqueueSnackbar("Error fetching embeds", { variant: "error" });
        });
    };
    fetchEmbeds();
  }, []);

  const handleFilter = (
    search: string,
    publisher: string,
    embeds: EmbedTargetRecord[]
  ) => {
    let filtered = embeds;
    if (search.trim().length) {
      filtered = filtered.filter(
        (e) =>
          e.name.toLowerCase().includes(search) ||
          e.description.toLowerCase().includes(search)
      );
    }
    let pubId = null;
    if (publisher.length) {
      const findPub = publishers.find(
        (p) => p.name.toLowerCase().trim() == publisher.toLowerCase()
      );
      pubId = findPub ? findPub.id : null;
      filtered = filtered.filter((item) => item.publisher_id == pubId);
    }
    return filtered;
  };

  const getPublisherOptions = (publishers: Publisher[]) => {
    const userRole = currentUser.role;
    if (userRole == "fanpoweradmin") {
      return publishers
        .filter(
          (pub) =>
            embeds.filter((item) => Number(item.publisher_id) == pub.id).length
        )
        .map((p) => p.name);
    }
    if (userRole == "tenantadmin") {
      return publishers
        .filter((p) => p.tenant_id == publisher.tenant_id)
        .map((p) => p.name);
    }
    return [];
  };

  useEffect(() => {
    if (search.trim().length || pubFilter.length) {
      const filtered = handleFilter(
        search.trim().toLowerCase(),
        pubFilter,
        embeds
      );
      setFilteredEmbeds(filtered);
    } else {
      setFilteredEmbeds(embeds);
    }
  }, [search, pubFilter]);

  const handleView = (item: EmbedTargetRecord) => {
    navigate(`/props/embeds/view/${item.id}`);
  };

  const deleteDialogOnConfirm = async (item: any) => {
    try {
      await embedsApi.deleteEmbed(item.id);
      const updatedEmbeds = embeds.filter((e) => e.id != item.id);
      setEmbeds(updatedEmbeds);
      const updatedFilterEmbeds = filteredEmbeds.filter((e) => e.id != item.id);
      setFilteredEmbeds(updatedFilterEmbeds);
      enqueueSnackbar("Embed successfully deleted", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Could not delete embed, try again later", {
        variant: "error",
      });
    }
  };

  return (
    <Box w="full">
      <Flex
        paddingBottom={theme.spacing.base * 2}
        justifyContent="space-between"
      >
        <BreadcrumbsBlock
          links={[
            { title: "Props", isCurrent: false },
            { title: "Embeds", isCurrent: true },
          ]}
        ></BreadcrumbsBlock>
        <Button
          as={Link}
          to="/props/embeds/create"
          leftIcon={<FontAwesome icon={faPlus} />}
        >
          Create Embed Target
        </Button>
      </Flex>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex>
            <InputGroup>
              <InputLeftAddon>Search</InputLeftAddon>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={"Enter search term..."}
              />
            </InputGroup>
            {publishers.length &&
            ["fanpoweradmin", "tenantadmin"].includes(currentUser.role) ? (
              <DropDown
                options={getPublisherOptions(publishers)}
                setValue={setPubFilter}
                overrides={{ placeHolder: "All Publishers" }}
              />
            ) : null}
          </Flex>
          <Divider orientation="horizontal" />
          <ListTable
            heading={"Embeds"}
            headers={[
              { title: "Action" },
              { title: "Name" },
              { title: "Content Type" },
              { title: "View Count" },
              { title: "FanPick Count" },
              ["fanpoweradmin", "tenantadmin"].includes(currentUser.role) && {
                title: "Publisher",
              },
            ]}
            list={filteredEmbeds}
            columns={[
              { name: "name", type: "string" },
              { name: "content_type", type: "string" },
              { name: "view_count", type: "string" },
              { name: "fan_pick_count", type: "string" },
              ["fanpoweradmin", "tenantadmin"].includes(currentUser.role) && {
                name: "publisher_name",
                type: "string",
              },
            ]}
            onDelete={deleteDialogOnConfirm}
            alertTitle="Delete this Embed?"
            actions={[
              {
                name: "View",
                actionHandler: handleView,
                icon: <FontAwesome icon={faPenToSquare} />,
              },
              {
                name: "Delete",
                actionHandler: null,
                icon: <FontAwesome icon={faTrash} />,
              },
            ]}
          />
        </>
      )}
    </Box>
  );
};

export default EmbedsIndex;
