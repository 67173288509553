import { defineStyleConfig } from "@chakra-ui/react";
import { colors } from "../colors";

export const formLabel = defineStyleConfig({
  baseStyle: {
    fontSize: 15,
    color: colors.grey.dark,
    fontFamily: "'Inter', Helvetica, sans-serif",
  },
});
