import React, { createContext, useEffect, useState } from "react";
import axios, { AxiosInstance } from "axios";
import useRefreshToken from "../../hooks/useRefreshToken";
import jwt_decode from "jwt-decode";
import { TokenUser } from "../../types";

interface JwtContextType {
  jwt: string;
  setJwt: (value: string) => void;
  privateAxiosV1: AxiosInstance;
  privateAxiosV2: AxiosInstance;
  decodeJwt: TokenUser;
}

const JwtContext = createContext<JwtContextType>({
  jwt: null,
  setJwt: (value: string) => null,
  privateAxiosV1: null,
  privateAxiosV2: null,
  decodeJwt: null,
});
const NODE_API_URL_v1 = process.env.REACT_APP_NODE_API_URL;
const NODE_API_URL_v2 = process.env.REACT_APP_NODE_API_URL_V2;
export const JwtProvider = ({ children }) => {
  const [jwt, setJwt] = useState(null);
  const refresh = useRefreshToken();

  const createAxios = (url?: string) => {
    const requestArray = [];
    const makeAxios = axios.create({ baseURL: url ? url : NODE_API_URL_v1 });
    makeAxios.interceptors.request.use(
      async (config) => {
        if (!config.headers["Authorization"] && jwt) {
          config.headers["Authorization"] = `Bearer ${jwt}`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    makeAxios.interceptors.response.use(
      (response) => {
        if (requestArray.length != 0) {
          requestArray.forEach((x, i) => {
            if (response.config.url == x.url) {
              requestArray.splice(i, 1);
            }
          });
        }
        return response;
      },

      async (error) => {
        const prevRequest = error?.config;
        requestArray.push(prevRequest);
        if (
          error?.response?.status === 403 &&
          error?.response?.data == "access expired" &&
          !prevRequest?.sent &&
          requestArray.length <= 1
        ) {
          prevRequest.sent = true;

          const newToken = await refresh()
            .then((res) => {
              setJwt(res.token);
              if (requestArray.length != 0) {
                requestArray.forEach((x, i) => {
                  try {
                    x.headers["Authorization"] = `Bearer ${res.token}`;

                    if (i > 0) {
                      makeAxios(x);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                });
              }
              return res.token;
            })
            .catch((err) => {
              console.error(err);
            });
          if (newToken) {
            prevRequest.headers["Authorization"] = `Bearer ${newToken}`;
            makeAxios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${newToken}`;
            return makeAxios(prevRequest);
          }
        }

        return Promise.reject(error);
      }
    );
    return makeAxios;
  };

  useEffect(() => {
    if (jwt)
      createAxios().defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  }, [jwt]);

  return (
    <JwtContext.Provider
      value={{
        jwt,
        setJwt,
        privateAxiosV1: createAxios(),
        privateAxiosV2: createAxios(NODE_API_URL_v2),
        decodeJwt: jwt ? jwt_decode(jwt) : null,
      }}
    >
      {children}
    </JwtContext.Provider>
  );
};

export default JwtContext;
