import { AxiosError } from "axios";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { League, Leagues, MultiSelectItem } from "../../types";
import { toNumber } from "lodash";
import useJwt from "../../hooks/useJwt";

const useLeaguesApi = () => {
  const { privateAxiosV1 } = useJwt();

  const getAll = async (): Promise<Leagues> => {
    const leagues = await privateAxiosV1
      .get(`/leagues`)
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
        throw er;
      });
    return leagues;
  };

  const mapLeaguesToMultiSelect = (leagues: Leagues): MultiSelectItem[] => {
    return leagues.map((league: League) => {
      return {
        id: toNumber(league.id),
        name: league.label,
      };
    });
  };

  const getLeaguesAsMultiSelect = async (): Promise<MultiSelectItem[]> => {
    const leagues = await getAll();
    return mapLeaguesToMultiSelect(leagues);
  };

  const deleteLeague = async (id: number): Promise<League> => {
    return await privateAxiosV1
      .delete(`/leagues`, { params: { id: id } })
      .then((response) => {
        return response.data;
      })
      .catch((er: AxiosError) => {
        pickupErrorHandlerWeb(er);
      });
  };

  const updateLeague = async (
    id: string,
    label: string,
    description: string
  ) => {
    return await privateAxiosV1
      .patch(`/leagues`, { id, label, description })
      .then((response) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  const createLeague = async (label: string, description: string) => {
    return await privateAxiosV1
      .post(`/leagues`, { label, description })
      .then((response) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        pickupErrorHandlerWeb(error);
        throw error;
      });
  };

  return {
    getAll,
    mapLeaguesToMultiSelect,
    getLeaguesAsMultiSelect,
    deleteLeague,
    updateLeague,
    createLeague,
  };
};

export default useLeaguesApi;
