import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  useTheme,
} from "@chakra-ui/react";
import { DefaultTheme } from "../../../types/theme";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SingleSelectItem } from "../../../types";
import useTenantsApi from "../../../services/api/Tenants";
import usePublishersApi from "../../../services/api/Publishers";
import { useSnackbar } from "notistack";
import useJwt from "../../../hooks/useJwt";
import GlobalContext from "../../GlobalContext";
import { TextInputGroup } from "../../FormElements/TextInputGroup";
import { SingleSelectFieldset } from "../../FormElements/Fieldsets/SingleSelectFieldset";

interface CreatePubModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreatePubModal: React.FC<CreatePubModalProps> = ({ isOpen, onClose }) => {
  const [tenantOptions, setTenantOptions] = useState<SingleSelectItem[]>([]);
  const tenantsApi = useTenantsApi();
  const publishersApi = usePublishersApi();
  const { enqueueSnackbar } = useSnackbar();
  const { publisher } = useContext(GlobalContext);
  const { decodeJwt } = useJwt();
  const [, setActive] = useState(false);
  const theme = useTheme<DefaultTheme>();

  const loadTenants = async () => {
    let tenantOpts = [];
    const tenants = await tenantsApi.getAllTenants();
    if (tenants) {
      tenantOpts = tenants.map((row) => {
        return { label: row.name, value: row.id };
      });
      setTenantOptions(tenantOpts);
    }
  };

  const submitPublisher = async (values) => {
    const tenant_id = values.tenant_id.length ? values.tenant_id : null;
    return await publishersApi
      .createPublisher(
        values.name,
        values.url,
        values.picker_preference,
        tenant_id
      )
      .then((res) =>
        enqueueSnackbar("Publisher created!", { variant: "success" })
      )
      .catch((err) => {
        enqueueSnackbar(err.response.data, { variant: "error" });
        throw err;
      });
  };

  useEffect(() => {
    loadTenants();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" />
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="bold">
            Create Publisher
          </ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{
              name: "",
              url: "",
              picker_preference: "email",
              tenant_id:
                decodeJwt.user.role != "fanpoweradmin" &&
                publisher &&
                publisher.tenant_id
                  ? publisher.tenant_id
                  : "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              url: Yup.string()
                .url("Invalid URL")
                .required("Website URL is required"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await submitPublisher(values)
                .then((res) => {
                  setSubmitting(false);
                  resetForm();
                })
                .catch((err) => {
                  setSubmitting(false);
                });
            }}
          >
            {({
              isSubmitting,
              errors,
              values,
              handleChange,
              setFieldTouched,
              touched,
              handleBlur,
            }) => (
              <Form>
                <ModalBody>
                  <TextInputGroup
                    placeholder="Publisher Name"
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    errors={errors.name}
                    label={"Name"}
                    isRequired={true}
                    setFieldTouched={setFieldTouched}
                    touched={touched.name}
                    setActive={setActive}
                  />

                  <TextInputGroup
                    placeholder="https://yourwebsite.com"
                    id="url"
                    value={values.url}
                    onChange={handleChange}
                    errors={errors.url}
                    label={"URL"}
                    isRequired={true}
                    setFieldTouched={setFieldTouched}
                    touched={touched.url}
                    setActive={setActive}
                  />

                  <SingleSelectFieldset
                    label={"Picker Preference"}
                    id={"picker_preference"}
                    value={values.picker_preference}
                    options={[
                      { label: "email", value: "email" },
                      { label: "phone", value: "phone" },
                      { label: "both phone first", value: "phone_first" },
                      { label: "both email first", value: "email_first" },
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.picker_preference}
                    placeholder={"email"}
                    setFieldTouched={setFieldTouched}
                  />
                  {decodeJwt.user.role == "fanpoweradmin" ? (
                    <SingleSelectFieldset
                      label={"Tenant"}
                      id={"tenant_id"}
                      value={values.tenant_id}
                      options={tenantOptions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.tenant_id}
                      placeholder={"No Tenant"}
                      setFieldTouched={setFieldTouched}
                    />
                  ) : null}
                </ModalBody>

                <ModalFooter>
                  <Button
                    fontWeight="normal"
                    variant="outline"
                    onClick={onClose}
                  >
                    Close
                  </Button>
                  <Button ml={3} type="submit" isDisabled={isSubmitting}>
                    Create
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreatePubModal;
