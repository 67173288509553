import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import moment from "moment";
import { Carousel, PropAPIResponse, Publisher, DraftProp } from "../../types";
import CopyButton from "../../components/common/Buttons/CopyButton";
import {
  generateCarouselEmbedCode,
  generateCarouselHtmlEmbedCode,
  generateCarouselShortcode,
  generatePropEmbedCode,
  generatePropHtmlEmbedCode,
  generatePropShortcode,
} from "../../helpers/embedCodes";
import Paper from "../../components/common/Paper";
import { Heading, Input, Tag, Text } from "@chakra-ui/react";

const renderCloseTime = (prop: PropAPIResponse): string => {
  if (!prop.event_end_at.raw && prop.close_at.raw) {
    return moment(prop.close_at.raw).fromNow();
  } else if (!prop.close_at.raw || prop.close_at.raw > prop.event_end_at.raw) {
    return moment(prop.event_end_at.raw).fromNow();
  } else {
    return "TBD";
  }
};

const handleCopy = (target: string) => {
  const input = document.getElementById(target) as HTMLInputElement;
  navigator.clipboard.writeText(input.value);
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    flex: "0 1 100%",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: theme.spacing.base * 4,
    marginBottom: theme.spacing.base * 6,
    backgroundColor: theme.colors.white,
    "&:hover": {
      border: `1px solid ${theme.colors.purple.lightBase}`,
    },
    [theme.mediaQuery(926)]: {
      flex: "0 1 47%",
    },
    [theme.mediaQuery(1340)]: {
      flex: "0 1 28%",
    },
  },
  meta: {
    display: "flex",
    flexFlow: "row nowrap",
    position: "relative",
    width: "100%",
    marginBottom: theme.spacing.base * 4,
    color: theme.colors.grey.dark,
    "&  div": {
      position: "relative",
      paddingRight: theme.spacing.base * 3.5,
      "&:after": {
        display: "block",
        position: "absolute",
        right: 7,
        top: 3,
        height: "75%",
        width: 1,
        backgroundColor: theme.colors.grey.dark,
        content: '""',
      },
    },
    "& p": {
      display: "inline-block",
      fontSize: 10,
      [theme.mediaQuery(theme.breakpoints.medium)]: {
        fontSize: 12,
      },
    },
  },
  propIdContainer: {
    "&:after": {
      display: "none !important",
    },
  },
  propositionContainer: {
    textAlign: "left",
    width: "100%",
  },
  chipContainer: {
    display: "flex",
    width: "100%",
    position: "relative",
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: theme.spacing.base * 4,
    marginBottom: theme.spacing.base * 3,
  },
  chip: {
    flex: "0 1 auto",
    height: "auto",
    backgroundColor: theme.colors.grey.lightBase,
    marginRight: theme.spacing.base,
    marginBottom: theme.spacing.base,
    cursor: "default",
    "&:hover, &:active, &:focus": {
      backgroundColor: theme.colors.grey.lightBase,
      border: `1px solid ${theme.colors.grey.lightBase}`,
    },
  },
  closeContainer: {
    width: "100%",
    textAlign: "left",
    color: theme.colors.grey.dark,
    marginTop: theme.spacing.base,
    marginBottom: theme.spacing.base * 3,
  },
  scriptContainer: {
    display: "flex",
    position: "relative",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "& > :first-child": {
      marginRight: 4,
    },
  },
  wide: {
    width: "100%",
    "& h5": {
      margin: [theme.spacing.base, 0],
    },
  },
}));

type PropCardProps = {
  publisher: Partial<Publisher>;
  prop?: PropAPIResponse;
  carousel?: Carousel;
  draftProp?: DraftProp;
};

const PropCard = ({
  prop,
  carousel,
  publisher,
  draftProp,
}: PropCardProps): JSX.Element => {
  const classes = useStyles();

  const [useEmbedCode, setUseEmbedCode] = useState(null);
  const fullJStarget = `js-embed-${
    (prop && prop.id.raw) ||
    (carousel && carousel.id) ||
    (draftProp && draftProp.id)
  }`;
  const htmlTarget = `html-embed-${
    (prop && prop.id.raw) ||
    (carousel && carousel.id) ||
    (draftProp && draftProp.id)
  }`;
  const wpTarget = `wp-embed-${
    (prop && prop.id.raw) ||
    (carousel && carousel.id) ||
    (draftProp && draftProp.id)
  }`;

  useEffect(() => {
    setUseEmbedCode(
      prop || draftProp
        ? generatePropEmbedCode(publisher, prop, draftProp)
        : generateCarouselEmbedCode(carousel, publisher)
    );
  }, [publisher]);

  return (
    <Paper className={classes.root}>
      {prop ? (
        <>
          <div className={classes.meta}>
            <div>
              <Text variant="body2">{prop.league.raw.toUpperCase()}</Text>
            </div>
            <div>
              <Text variant="body2">
                {moment(prop.created_at.raw).format("ddd MMM Do, YYYY")}
              </Text>
            </div>
            <div>
              <Text variant="body2">{prop.fan_picks_count.raw} Picks</Text>
            </div>
            <div className={classes.propIdContainer}>
              <Text variant="body2">{`#${prop.id.raw}`}</Text>
            </div>
          </div>
          <div className={classes.propositionContainer}>
            <Heading size="h4" as="h4">
              {prop.proposition.raw}
            </Heading>
          </div>
          <div className={classes.chipContainer}>
            {prop.picks.raw.map((pick) => (
              <Tag color="secondary" className={classes.chip} key={pick.id}>
                {pick.display_title}
              </Tag>
            ))}
          </div>
          <div className={classes.closeContainer}>
            <Text variant="body2">{`Closes ${renderCloseTime(prop)}`}</Text>
          </div>
        </>
      ) : null}
      <div className={classes.wide}>
        <Heading size="h5" as="h5">
          Full JavaScript Embed
        </Heading>
        <div className={classes.scriptContainer}>
          <Input
            type="text"
            id={fullJStarget}
            name={fullJStarget}
            defaultValue={useEmbedCode}
          />
          <CopyButton
            propId={
              (prop && prop.id.raw) ||
              (carousel && carousel.id) ||
              (draftProp && draftProp.id)
            }
            onClick={() => handleCopy(fullJStarget)}
            size="sm"
          />
        </div>
      </div>
      <div className={classes.wide}>
        <Heading size="h5" as="h5">
          HTML Only Embed
        </Heading>
        <div className={classes.scriptContainer}>
          <Input
            type="text"
            id={htmlTarget}
            name={htmlTarget}
            defaultValue={
              prop || draftProp
                ? generatePropHtmlEmbedCode(prop, draftProp)
                : generateCarouselHtmlEmbedCode(carousel)
            }
          />
          <CopyButton
            propId={
              (prop && prop.id.raw) ||
              (carousel && carousel.id) ||
              (draftProp && draftProp.id)
            }
            onClick={() => handleCopy(htmlTarget)}
            size="sm"
          />
        </div>
      </div>
      <div className={classes.wide}>
        <Heading size="h5" as="h5">
          WordPress Shortcode
        </Heading>
        <div className={classes.scriptContainer}>
          <Input
            type="text"
            id={wpTarget}
            name={wpTarget}
            defaultValue={
              prop || draftProp
                ? generatePropShortcode(prop, draftProp)
                : generateCarouselShortcode(carousel)
            }
          />
          <CopyButton
            propId={
              (prop && prop.id.raw) ||
              (carousel && carousel.id) ||
              (draftProp && draftProp.id)
            }
            onClick={() => handleCopy(wpTarget)}
            size="sm"
          />
        </div>
      </div>
    </Paper>
  );
};

export default PropCard;
