import React from "react";
import { Box, Select, Text } from "@chakra-ui/react";

export interface DropDownProps {
  options: string[];
  setValue: React.Dispatch<React.SetStateAction<string>>;
  label?: string;
  onChange?: () => void;
  overrides?: {
    noDefaultLabel?: boolean;
    noPlaceholder?: boolean;
    placeHolder?: string;
    defaultValue?: string;
  };
}

const DropDown: React.FC<DropDownProps> = ({
  options,
  setValue,
  label,
  overrides,
  onChange,
}) => {
  return (
    <Box
      display="flex"
      alignItems={"center"}
      width={"100%"}
      maxWidth={400}
      paddingTop={1}
    >
      <Text>
        {label} {overrides?.noDefaultLabel ? "" : "Filter By:"}
      </Text>
      <Select
        placeholder={
          overrides?.placeHolder
            ? overrides.placeHolder
            : overrides?.noPlaceholder
            ? ""
            : "Empty"
        }
        maxWidth={250}
        onChange={(e) => {
          setValue(e.target.value);
          if (typeof onChange == "function") {
            onChange();
          }
        }}
        defaultValue={overrides?.defaultValue}
      >
        {options.map((o, i) => {
          return <option key={i}>{o}</option>;
        })}
      </Select>
    </Box>
  );
};

export default DropDown;
