import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import ClickAwayListener from "react-click-away-listener";
import { Box, Link } from "@chakra-ui/react";
import { NavLinks, SidebarProps } from "../../types";
import LinkBlock from "./LinkBlock";
import GlobalContext from "../GlobalContext";
import {
  faFlagCheckered,
  faCaretSquareUp,
  faBorderNone,
  faChartPie,
  faArchive,
  faPersonBooth,
  faUser,
  faTruck,
  faMagnifyingGlass,
  faPenToSquare,
  faCircleQuestion,
  faSun,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { appStyles } from "../../constants/styles";
import { FontAwesome } from "../common/icon";

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    display: "flex",
    position: "fixed",
    flexDirection: "column",

    backgroundColor: "#f0f0f0",
    borderRight: `1px solid ${theme.colors.grey.lightBase}`,
    minHeight: `calc(100vh - ${appStyles.headerHeight}px)`,
    height: "88%",
    width: appStyles.sidebarWidth,
    top: appStyles.headerHeight,
    zIndex: 1,
    overflowY: "auto",
    overflowX: "hidden",
  },

  auxLinks: {
    "& a": {
      display: "block",
      fontSize: 10,
      textDecoration: "none",
      color: "#615E66",
      paddingLeft: appStyles.sidebarLeftPadding,
      paddingBottom: theme.spacing.base * 4,
      "&:hover": {
        color: theme.colors.primary.dark,
      },
    },
  },
  copyright: {
    padding: `${appStyles.sidebarLeftPadding}px`,
    fontSize: 10,
    color: "#615E66",
    borderTop: `1px solid ${theme.colors.grey.lightBase}`,
  },
}));

const navLinks: NavLinks = {
  content: [
    {
      value: "Ai Content Archive",
      path: "/ai-content/index",
      permissions: "aiContentView",
      // icon: FontAwesome(faArchive),
      icon: <FontAwesome icon={faArchive} />,
    },
    {
      value: "MLB Previews",
      path: "/ai-content/mlb/previews",
      permissions: "aiContentCreate",
      icon: <FontAwesome icon={faFlagCheckered} />,
    },
    {
      value: "NFL Previews",
      path: "/ai-content/nfl/previews",
      permissions: "aiContentCreate",
      icon: <FontAwesome icon={faFlagCheckered} />,
    },
    {
      value: "NFL Recaps",
      path: "/ai-content/nfl/recaps",

      permissions: "aiContentCreate",
      icon: <FontAwesome icon={faFlagCheckered} />,
    },
    {
      value: "NCAAF Previews",
      path: "/ai-content/ncaaf/previews",

      permissions: "aiContentCreate",
      icon: <FontAwesome icon={faFlagCheckered} />,
    },
    {
      value: "NCAAF Recaps",
      path: "/ai-content/ncaaf/recaps",
      permissions: "aiContentCreate",
      icon: <FontAwesome icon={faFlagCheckered} />,
    },
    {
      value: "NASCAR Previews",
      path: "/ai-content/nascar/previews",
      permissions: "aiContentCreate",
      icon: <FontAwesome icon={faFlagCheckered} />,
    },
    {
      value: "Personas",
      path: "/personas",
      permissions: "fanpoweradmin",
      icon: <FontAwesome icon={faPersonBooth} />,
    },
  ],
  props: [
    {
      value: "Prop Search",
      path: "/prop-search",
      permissions: "propSearch",
      icon: <FontAwesome icon={faMagnifyingGlass} />,
    },
    {
      value: "Create Props",
      path: "/props/index",
      permissions: "self_serve_props_access",
      icon: <FontAwesome icon={faPenToSquare} />,
    },
    {
      value: "Prop Carousels",
      path: "/prop-carousels",
      permissions: "propCarousels",
      icon: <FontAwesome icon={faPenToSquare} />,
    },
    /*{
      value: "Prop Embed Builder",
      path: "/props/embed-builder",
      permissions: "propSearch",
      icon: (
        <IconWrapper>
          <Search />
        </IconWrapper>
      ),
    },*/
    {
      value: "Tags",
      path: "/props/tags",
      permissions: "fanpoweradmin",
      icon: <FontAwesome icon={faMagnifyingGlass} />,
    },
    {
      value: "Leagues",
      path: "/props/leagues",
      permissions: "fanpoweradmin",
      icon: <FontAwesome icon={faMagnifyingGlass} />,
    },
    {
      value: "Embeds",
      path: "/props/embeds",
      permissions: "propSearch",
      icon: <FontAwesome icon={faArchive} />,
    },
    {
      value: "Contests",
      path: "/props/contests",
      permissions: "publisherSettings",
      icon: <FontAwesome icon={faArchive} />,
    },
  ],
  tools: [
    {
      value: "Reporting",
      path: "/reporting",
      permissions: "viewReporting",
      icon: <FontAwesome icon={faChartPie} />,
    },
    {
      value: "Tutorials",
      path: "/tutorials",
      permissions: "propSearch",
      icon: <FontAwesome icon={faCircleQuestion} />,
    },
    {
      value: "Getting Started",
      path: "/getting-started",
      permissions: "propSearch",
      icon: <FontAwesome icon={faCircleQuestion} />,
    },
    {
      value: "Integrations",
      path: "/integrations",
      permissions: "propSearch",
      icon: <FontAwesome icon={faSun} />,
    },
    {
      value: "Re-Engagement",
      path: "/re-engagement",
      permissions: "fanEngagement",
      icon: <FontAwesome icon={faPenToSquare} />,
    },
    {
      value: "Fan Insights",
      path: "/insights/fans",
      permissions: "propSearch",
      icon: <FontAwesome icon={faCircleQuestion} />,
    },
  ],
  endzoneads: [
    {
      value: "Ad Zones",
      path: "/ad-zones",
      permissions: "adZones",
      icon: <FontAwesome icon={faBorderNone} />,
    },
    {
      value: "Ads",
      path: "/ads",
      permissions: "adEditor",
      icon: <FontAwesome icon={faCaretSquareUp} />,
    },
  ],
  settings: [
    {
      value: "Profile",
      path: "/profile",
      icon: <FontAwesome icon={faUser} />,
    },
    {
      value: "Tenants",
      path: "/settings/tenants",
      permissions: "fanpoweradmin",
      icon: <FontAwesome icon={faCalendar} />,
    },
    {
      value: "Publishers",
      path: "/publishers",
      permissions: "publisherSettings",
      icon: <FontAwesome icon={faCalendar} />,
    },
    {
      value: "Fans",
      path: "/settings/fans",
      permissions: "fanpoweradmin",
      icon: <FontAwesome icon={faCalendar} />,
    },
    {
      value: "Users",
      path: "/users",
      permissions: "manageUsers",
      icon: <FontAwesome icon={faPenToSquare} />,
    },
    {
      value: "Logout",
      path: "/",
      action: "logout",
      icon: <FontAwesome icon={faTruck} />,
    },
  ],
};

const Sidebar: React.FC<SidebarProps> = ({ showSidebar = true }) => {
  const classes = useStyles();
  const { setShowSidebar, currentUser } = useContext(GlobalContext);

  if (!showSidebar) {
    return null;
  }
  return (
    <ClickAwayListener onClickAway={() => setShowSidebar(false)}>
      <nav className={classes.root}>
        <Box>
          {currentUser.permissions.aiContentView ||
          currentUser.permissions.fanpoweradmin ? (
            <Box>
              <LinkBlock links={navLinks.content} title="Content" />
            </Box>
          ) : null}
          <Box>
            <LinkBlock links={navLinks.props} title="Props" />
          </Box>
          {currentUser.permissions.adZones ||
          currentUser.permissions.adEditor ? (
            <Box>
              <LinkBlock links={navLinks.endzoneads} title="Ads" />
            </Box>
          ) : null}
          <Box>
            <LinkBlock links={navLinks.tools} title="Tools" />
          </Box>
          <Box>
            <LinkBlock links={navLinks.settings} title="Settings" />
          </Box>
        </Box>
        <Box>
          <Box className={classes.auxLinks}>
            <Link
              href="https://www.playpickup.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
            <Link
              href="https://www.playpickup.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </Link>
          </Box>
          <Box className={classes.copyright}>
            &copy; {new Date().getFullYear()} FanPower
          </Box>
        </Box>
      </nav>
    </ClickAwayListener>
  );
};

export default Sidebar;
