import React, { useState, useEffect, useContext } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../types/theme";
import SearchBar from "./SearchBar";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { PropAPIResponse, Event, Pick, Publisher } from "../../types";
import PropCard from "./PropCard";
import raddad from "./raddad.svg";
import GlobalContext from "../../components/GlobalContext";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";
import { Box, Flex, Heading, Select, Text } from "@chakra-ui/react";
import useJwt from "../../hooks/useJwt";
import useEventsApi from "../../services/api/Events";
import usePicksApi from "../../services/api/Picks";

const ELASTIC_API_URL = process.env.ELASTIC_API_URL;
const ELASTIC_SEARCH_KEY = process.env.ELASTIC_SEARCH_KEY;
const ELASTIC_PROPS_ENGINE = process.env.ELASTIC_SEARCH_PROPS_PROVIDER;
const ELASTIC_ENGINES_ENDPOINT = process.env.ELASTIC_ENGINES_ENDPOINT;

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    position: "relative",
  },
  search: {
    position: "relative",
  },
  props: {
    position: "relative",
    width: "100%",
  },
  resultCount: {
    position: "relative",
    padding: `${theme.spacing.base * 4}px ${theme.spacing.base * 2}px`,
  },
  results: {
    display: "flex",
    position: "relative",
    flexFlow: "row wrap",
    justifyContent: "space-evenly",
    alignItems: "stretch",
    width: "100%",
  },
  initialResults: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: theme.spacing.base * 20,
    justifyContent: "center",
    alignItems: "center",
    "& div": {
      position: "relative",
      width: 100,
      height: "auto",
      marginBottom: theme.spacing.base * 6,
      "& img": {
        width: "100%",
        height: "auto",
      },
    },
  },
}));

// elastic search body object
const data = {
  page: {
    current: 1,
    size: 100,
  },
  sort: [{ _score: "desc" }, { created_at: "desc" }],
  filters: {
    status: ["pending"],
  },
  result_fields: {
    featured_leaderboard_id: {
      raw: {},
      snippet: { size: 100, fallback: true },
    },
    close_time_id: {
      raw: {},
      snippet: { size: 100, fallback: true },
    },
    league: { raw: {}, snippet: { size: 100, fallback: true } },
    created_at: { raw: {}, snippet: { size: 100, fallback: true } },
    close_time: { raw: {}, snippet: { size: 100, fallback: true } },
    publish_at: { raw: {}, snippet: { size: 100, fallback: true } },
    search_weight: {
      raw: {},
      snippet: { size: 100, fallback: true },
    },
    utm_content_tag: {
      raw: {},
      snippet: { size: 100, fallback: true },
    },
    event_id: { raw: {}, snippet: { size: 100, fallback: true } },
    updated_at: { raw: {}, snippet: { size: 100, fallback: true } },
    user_id: { raw: {}, snippet: { size: 100, fallback: true } },
    proposition: {
      raw: {},
      snippet: { size: 100, fallback: true },
    },
    fan_picks_count: {
      raw: {},
      snippet: { size: 100, fallback: true },
    },
    utm_term_tag: {
      raw: {},
      snippet: { size: 100, fallback: true },
    },
    close_at: { raw: {}, snippet: { size: 100, fallback: true } },

    slug: { raw: {}, snippet: { size: 100, fallback: true } },
    status: { raw: {}, snippet: { size: 100, fallback: true } },
    id: { raw: {}, snippet: { size: 100, fallback: true } },
  },
};

const PropSearch: React.FC = () => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [propResults, setPropResults] = useState<null | PropAPIResponse[]>(
    null
  );
  const [events, setEvents] = useState<Event[]>(null);
  const { publisher, publishers, currentUser } = useContext(GlobalContext);
  const { jwt } = useJwt();
  const { getEvents } = useEventsApi();
  const { getPicksByPropId } = usePicksApi();

  const [selectedPublisher, setSelectedPublisher] = useState<
    Partial<Publisher>
  >(
    publisher
      ? publisher
      : { id: currentUser.publisher_id, access_token: currentUser.access_token }
  );

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const events = await getEvents();

        if (!events) {
          throw new Error("Could not get Events");
        }
        setEvents(events);
      } catch (err) {
        pickupErrorHandlerWeb(err);
      }
    };

    fetchEventData();
  }, []);

  useEffect(() => {
    if (!selectedPublisher) {
      setSelectedPublisher(publisher);
    }
  }, [publisher]);

  const getPicks = async (prop_ids: string): Promise<Pick[]> => {
    try {
      const picks = await getPicksByPropId(prop_ids);

      if (!picks) {
        throw new Error("Could not get related Picks");
      }
      return picks as Pick[];
    } catch (err) {
      pickupErrorHandlerWeb(err);
    }
  };

  return (
    <div className={classes.root}>
      <Flex flexDirection={"column"}>
        <Heading variant="heading2">Prop Search</Heading>
        {publisher &&
        publishers &&
        publishers.length > 0 &&
        currentUser.tenant_id ? (
          <Flex flexDirection={"column"} width="300px" marginTop="20px">
            <Box color="gray.800" fontSize={"sm"}>
              Selected Publisher:{" "}
            </Box>
            <Box>
              <Select
                width="100"
                id="publisherSelect"
                placeholder={publisher.name}
                onChange={(event) => {
                  setSelectedPublisher(
                    publishers.filter(
                      (publisher) =>
                        publisher.access_token == event.target.value
                    )[0]
                  );
                }}
              >
                {publishers &&
                  publishers.length > 0 &&
                  publishers.map((publisher) => {
                    if (+publisher.tenant_id == currentUser.tenant_id) {
                      return (
                        <option
                          key={publisher.id}
                          value={publisher.access_token}
                        >
                          {publisher.name}
                        </option>
                      );
                    }
                  })}
              </Select>
            </Box>
            <Box color={"gray"} fontSize="xs" paddingTop={"5px"}>
              Optional selection to change javascript publisher embed
              credentials
            </Box>
          </Flex>
        ) : null}
      </Flex>

      <div className={classes.search}>
        <Formik
          enableReinitialize
          initialValues={{ search: "" }}
          validationSchema={Yup.object().shape({
            search: Yup.string()
              .min(3, "At least three characters required")
              .required("Search term cannot be empty"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            setSearchTerm(values.search);

            // add the query to the body object
            data["query"] = values.search;

            const headers = {
              Authorization: `Bearer ${ELASTIC_SEARCH_KEY}`,
              "Content-Type": "application/json",
            };
            try {
              const props = await axios
                .post(
                  `${ELASTIC_API_URL}${ELASTIC_ENGINES_ENDPOINT}${ELASTIC_PROPS_ENGINE}/search`,
                  data,
                  { headers }
                )
                .catch((err) => {
                  throw new Error(err);
                });
              if (!props || !props.data) {
                throw new Error(
                  "Could not get props from PickUp Search Engine!"
                );
              }
              //map event_end_at to event_id & collet prop_ids
              let prop_ids = "";
              props.data.results.forEach((prop: PropAPIResponse, index) => {
                prop["event_end_at"] = { raw: "" };
                if (prop.event_id.raw) {
                  const event = events.find(
                    (event: Event) =>
                      event.id == (prop.event_id.raw as unknown as string)
                  );
                  if (event && event.end_at) {
                    prop.event_end_at.raw = event.end_at;
                  }
                }
                prop_ids += prop.id.raw;
                if (index < props.data.results.length - 1) {
                  prop_ids += ",";
                }
              });
              //get picks data and map to prop response
              const picks: Pick[] | void = await getPicks(prop_ids);
              picks &&
                props.data.results.forEach((prop: PropAPIResponse) => {
                  prop["picks"] = { raw: [] };
                  const prop_picks = picks.filter(
                    (pick) => pick.prop_id == (prop.id.raw as unknown as string)
                  );
                  prop.picks.raw = prop_picks;
                });
              setPropResults(props.data.results);
              setSubmitting(false);
            } catch (err) {
              setSubmitting(false);
              pickupErrorHandlerWeb(err);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <Field
                id="search"
                name="search"
                isSubmitting={isSubmitting}
                placeholder="League, Team, Player..."
                component={SearchBar}
              />
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.props}>
        <div className={classes.resultCount}>
          <Text variant="body2">
            {propResults && searchTerm
              ? `${propResults.length} search results for "${searchTerm}"`
              : null}
          </Text>
        </div>
        <div className={classes.results}>
          {selectedPublisher && propResults && propResults.length > 0 ? (
            propResults.map((prop) => (
              <PropCard
                key={prop.id.raw}
                prop={prop}
                publisher={selectedPublisher}
              />
            ))
          ) : (
            <div className={classes.initialResults}>
              <div>
                <img src={raddad} alt="" aria-hidden role="presentation" />
              </div>
              <Heading variant="heading3">
                Enter a search term above to get started!
              </Heading>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropSearch;
