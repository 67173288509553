import axios from "axios";
import { pickupErrorHandlerWeb } from "../../helpers/pickupErrorHandlerWeb";

const sendIntegrationReq = async (
  publisherEmail: string,
  publicationName: string,
  url: string
) => {
  const body = { publicationName, publisherEmail, url };
  return await axios.post("/api/kasper/integration", body).catch((err) => {
    pickupErrorHandlerWeb(err);
  });
};

export const kasperService = {
  sendIntegrationReq,
};
