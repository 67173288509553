import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import useFansApi from "../../services/api/Fans";
import { useSnackbar } from "notistack";
import { Fan } from "../../types";

interface EditFanProps {
  isOpen: boolean;
  onClose: () => void;
  fan: Fan;
}

const EditFan = ({ isOpen, onClose, fan }: EditFanProps) => {
  const { updateFan } = useFansApi();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      id: fan?.id || "",
      first_name: fan?.first_name || "",
      last_name: fan?.last_name || "",
      email: fan?.email || "",
      phone_number: fan?.phone_number || "",
      username: fan?.username || "",
      created_at: fan?.created_at || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Must be valid email"),
    }),
    onSubmit: async (values) => {
      await handleSubmit(values);
    },
  });

  // Updates values after setState completes
  useEffect(() => {
    formik.setValues({
      id: fan?.id || "",
      first_name: fan?.first_name || "",
      last_name: fan?.last_name || "",
      email: fan?.email || "",
      phone_number: fan?.phone_number || "",
      username: fan?.username || "",
      created_at: fan?.created_at || "",
    });
  }, [fan]);

  const handleSubmit = async (values) => {
    const updatedValues = Object.entries(values).reduce((acc, [key, value]) => {
      if (value !== fan[key] && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});

    await updateFan(values.id, updatedValues)
      .then(() => {
        enqueueSnackbar("Fan updated successfully", { variant: "success" });
        onClose();
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data || "Could not update Fan", {
          variant: "error",
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Fan</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody>
            <VStack spacing={2} align="flex-start">
              <FormControl>
                <FormLabel htmlFor="id">Fan ID</FormLabel>
                <Input name="id" value={formik.values.id} readOnly disabled />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="first_name">First Name</FormLabel>
                <Input
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="last_name">Last Name</FormLabel>
                <Input
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="username">Username</FormLabel>
                <Input
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl
                isInvalid={!!formik.errors.email && !!formik.touched.email}
              >
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormErrorMessage>
                  {formik.errors.email && formik.errors.email.toString()}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="phone_number">Phone Number</FormLabel>
                <Input
                  name="phone_number"
                  value={formik.values.phone_number}
                  readOnly
                  disabled
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="created_at">Created At</FormLabel>
                <Input
                  name="created_at"
                  value={new Date(formik.values.created_at).toLocaleDateString(
                    "en-US",
                    {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    }
                  )}
                  readOnly
                  disabled
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button type="submit">Submit</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EditFan;
