import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { DefaultTheme } from "../../../types/theme";
import {
  Button,
  Flex,
  Heading,
  Table,
  Th,
  Thead,
  Tr,
  Tbody,
  Td,
  Box,
  useTheme,
} from "@chakra-ui/react";
import Paginator from "../../Pagination";
import StatusBadge from "../../StatusBadge";
import Paper from "../Paper";
import { useLocation, useNavigate } from "react-router-dom";
import { DeleteAlertDialog } from "../deleteAlertDialog";
import { faArrowsUpDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesome } from "../icon";

const PAGE_LIMIT = 10; // Max items displayed per page

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  paper: {
    backgroundColor: "white",
    paddingLeft: 0,
    paddingRight: 0,
  },
  heading: {
    backgroundColor: "white",
    fontFamily: theme.typography.fontFamilies.body,
    paddingLeft: theme.spacing.base * 4,
    marginBottom: theme.spacing.base * 4,
    fontSize: 20,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  tableHeader: {
    backgroundColor: theme.colors.grey.light,
    "& th": {
      textTransform: "none",
      fontFamily: theme.typography.fontFamilies.body,
      fontWeight: "bold",
      color: theme.colors.grey.base,
      letterSpacing: 0,
      padding: `${theme.spacing.base * 3 + 2}px ${theme.spacing.base * 4}px`,
    },
  },
  pageNumber: {
    height: 32,
    width: 32,
    padding: 0,
    fontFamily: theme.typography.fontFamilies.body,
    fontWeight: "normal",
    marginLeft: theme.spacing.base,
    marginRight: theme.spacing.base,
    boxShadow: "none",
    "&:focus, &:active": {
      border: `1px solid ${theme.colors.purple.base}`,
      backgroundColor: theme.colors.purple.light,
    },
  },
  tableRow: {
    "& td": {
      fontFamily: theme.typography.fontFamilies.body,
      fontSize: 12,
      padding: `${theme.spacing.base * 2}px ${theme.spacing.base * 4}px`,
      borderTop: `1px solid ${theme.colors.grey.light}`,
      lineHeight: "normal",
    },
  },
  smallColumn: {
    maxWidth: theme.spacing.base * 60,
    width: 50,
    minWidth: 50,
  },
  footer: {
    fontFamily: theme.typography.fontFamilies.body,
    color: theme.colors.grey.base,
    fontSize: 12,
    paddingLeft: theme.spacing.base * 6 + 3,
  },
}));

interface ListTableProps {
  heading: string;
  headers: Headers[];
  list: any[];
  columns: Columns[];
  actions?: Actions[];
  onDelete?: (item: any) => void;
  alertTitle?: string;
  disablePageTrack?: boolean;
  warnText?: string;
}

interface Headers {
  title: string;
  onClick?: () => void;
}

interface Columns {
  name: string;
  type: string;
  field?: string;
  color?: string;
}

interface Actions {
  name: string;
  actionHandler?: (input: any) => void;
  icon: any;
}

const ListTable: React.FC<ListTableProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<DefaultTheme>();
  const navigate = useNavigate();
  const location = useLocation();
  const [count, setCount] = useState(0);
  const [itemDisplay, setItemDisplay] = useState<any[]>(
    props.list.slice(0, PAGE_LIMIT)
  );
  const [page, setPage] = useState<number>(
    Number(location.search.substring(6))
      ? Number(location.search.substring(6))
      : 1
  );
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState(null);

  //useEffect to track page
  useEffect(() => {
    if (!props.disablePageTrack) {
      window.sessionStorage.setItem("listPage", String(page));
      navigate({ pathname: location.pathname, search: `page=${page}` });
    }
  }, [page]);
  //useEffect to track filtered changes
  useEffect(() => {
    setItemDisplay(
      props.list.slice(page * PAGE_LIMIT - PAGE_LIMIT, page * PAGE_LIMIT)
    );
    setCount(props.list.length);
  }, [props.list]);
  //updates item display
  const updateItemDisplay = (page: number) => {
    setPage(page);
    setItemDisplay(
      props.list.slice(page * PAGE_LIMIT - PAGE_LIMIT, page * PAGE_LIMIT)
    );
  };

  const deleteHandler = (item: any) => {
    setCurrentItem(item);
    setDeleteDialogIsOpen(true);
  };

  const deleteDialogOnClose = () => {
    setCurrentItem(null);
    setDeleteDialogIsOpen(false);
  };

  const convertDate = (timestamp: Date): string => {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };

    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };

  return (
    <Box>
      <Paper className={classes.paper}>
        <Heading as="h4" size="md" className={classes.heading}>
          {props.heading}
        </Heading>
        <div className={classes.tableWrapper}>
          <Table variant="unstyled">
            <Thead>
              <Tr className={classes.tableHeader}>
                {props.headers &&
                  props.headers.map((header, i) => (
                    <Th
                      key={i}
                      onClick={header.onClick ? header.onClick : null}
                      style={header.onClick ? { cursor: "pointer" } : null}
                    >
                      {header.title}
                      {header.onClick ? (
                        <FontAwesome icon={faArrowsUpDown} />
                      ) : null}
                    </Th>
                  ))}
              </Tr>
            </Thead>
            <Tbody>
              {itemDisplay.length
                ? itemDisplay.map((item, i) => (
                    <Tr
                      key={i}
                      _hover={{ bg: theme.colors.purple.light }}
                      className={classes.tableRow}
                    >
                      {props.actions ? (
                        <Td className={classes.smallColumn}>
                          <Flex alignItems="center">
                            {props.actions.map((action, i) => (
                              <Flex key={i} alignItems="center">
                                {i > 0 ? <Box>|</Box> : null}
                                <Button
                                  variant="action"
                                  aria-label={action.name}
                                  leftIcon={action.icon}
                                  onClick={
                                    action.actionHandler
                                      ? () => action.actionHandler(item)
                                      : () => deleteHandler(item)
                                  }
                                  //unique to props page only solution i can thank of
                                  isDisabled={
                                    action.name == "Grade" &&
                                    (item.status == "graded" ||
                                      item.grading_in_progress)
                                  }
                                >
                                  {action.name}
                                </Button>
                              </Flex>
                            ))}
                          </Flex>
                        </Td>
                      ) : null}
                      {props.columns.map((header, i) => (
                        <Td className={classes.smallColumn} key={i}>
                          {header.type == "badge" ? (
                            <StatusBadge
                              statusText={item[header.name]}
                              color={
                                header.color
                                  ? header.color
                                  : item[header.name].toLowerCase()
                              }
                            />
                          ) : header.type == "date" ? (
                            convertDate(item[header.name])
                          ) : header.type == "array" ? (
                            item[header.name]
                              .map((i) => i[header.field])
                              .join(", ")
                          ) : item[header.name] != null ? (
                            item[header.name].toString()
                          ) : null}
                        </Td>
                      ))}
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </div>
        <Paginator
          onPaginate={updateItemDisplay}
          page={page}
          pageLimit={PAGE_LIMIT}
          totalItems={count}
        ></Paginator>
        {props.onDelete ? (
          <DeleteAlertDialog
            isOpen={deleteDialogIsOpen}
            onClose={deleteDialogOnClose}
            alertTitle={props.alertTitle}
            objectId={null}
            confirmHandler={() => props.onDelete(currentItem)}
            warnText={props.warnText}
          />
        ) : null}
      </Paper>
    </Box>
  );
};

export default ListTable;
